import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
let url = `${
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_BASE_URL_DEV
    : process.env.REACT_APP_API_BASE_URL_PROD
}`;
const upload = (file, callback, setUploadProgress) => {
  axios
    .post(url + "upload", file, {
      headers: buildHeaders("file"),
      onUploadProgress: (progressEvent) => {
        setUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    })
    .then((response) => {
      if (response.data.success === true) {
        callback(response.data.data);
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message,
          heightAuto: false,
          icon: "error",
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        title: "Error!",
        text: error,
        heightAuto: false,
        icon: "error",
      });
    });
};

const buildHeaders = (type = "json") => {
  const headers = {
    "Content-Type":
      type === "json"
        ? "application/json"
        : "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
  };

  const token = Cookies.get("token");

  if (token && token !== null && token !== "") {
    headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
  }

  return headers;
};

export const uploadService = {
  upload,
};
