import { useEffect, useState } from "react";
import AllOrders from "../../../../components/ordersAdmin/allOrders/AllOrders";
import CompletedOrders from "../../../../components/ordersAdmin/completedOrders/CompletedOrders";
import NewOrders from "../../../../components/ordersAdmin/newOrders/NewOrders";
import "./Orders.scss";
import { BiFilterAlt, BiSearchAlt } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineClose, AiFillInfoCircle } from "react-icons/ai";
import Client1 from "../../../../assets/client1.png";
import Client2 from "../../../../assets/client2.png";
import Client3 from "../../../../assets/client3.png";
import Client4 from "../../../../assets/client4.png";
import Client5 from "../../../../assets/client5.png";
import { orderService } from "../../../../services/order.service";
import { useOutletContext } from "react-router";
import { userService } from "../../../../services/user.service";
import { clientService } from "../../../../services/client.service";
import { editorService } from "../../../../services/editor.service";

const EditorOrders = () => {
  const [addQuestion, setAddQuestion, user] = useOutletContext();
  const [currentPage, setCurrentPage] = useState("All Orders");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [status, setStatus] = useState([
    {
      name: "Completed",
      value: "completed",
      selected: false,
    },
    {
      name: "In Progress",
      value: "progress",
      selected: false,
    },
    {
      name: "New",
      value: "new",
      selected: false,
    },
    {
      name: "In Revision",
      value: "revision",
      selected: false,
    },
    {
      name: "Pending Approval",
      value: "pending",
      selected: false,
    },
    {
      name: "Submitted",
      value: "submitted",
      selected: false,
    },
  ]);

  const [clients, setClients] = useState([]);
  const [editors, setEditors] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedEditors, setSelectedEditors] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    setOrders([]);
    getOrders(1);
  }, [currentPage]);

  const getOrders = (page, text = "", filtersObj = null) => {
    let filters = { admin: false };

    if (currentPage === "Completed Orders") {
      filters.type = "completed";
    } else if (currentPage === "New Orders") {
      filters.type = "new";
    } else if (currentPage === "Draft Orders") {
      filters.type = "draft";
    }

    filters.name = text;

    if (filtersObj !== null) {
      filters.type = null;
      filters = { ...filters, ...filtersObj };
    }

    orderService.listOrders(page, filters, (data) => {
      setOrders(data.orders);
      setOrdersCount(data.count);
    });
  };

  useEffect(() => {
    getClients(0);
    getEditors(0);
  }, [orders]);

  const getEditors = (page) => {
    let type = "all";

    editorService.getAllEditors(page, type, (data) => {
      setEditors(data.editors);
    });
  };

  const getClients = (page) => {
    let type = "customer";

    clientService.getAllClients(page, type, (data) => {
      setClients(data.clients);
    });
  };

  const saveFilters = () => {
    setIsFiltersOpen(false);
    const obj = {
      customers: selectedClients,
      editors: selectedEditors,
      status: selectedStatus,
      date: {
        start: startDate,
        end: endDate,
      },
    };

    getOrders(1, "", obj);
  };

  return (
    <div className="admin-layout-orders-page">
      {isFiltersOpen && (
        <div className="filters">
          <div className="filters-container">
            <div className="filters-header">
              <span className="title">Filters</span>
              <div className="icon" onClick={() => setIsFiltersOpen(false)}>
                <AiOutlineClose />
              </div>
            </div>
            <div className="filters-body">
              <div className="period">
                <span className="title">Period</span>
                <input
                  placeholder="Start Date"
                  type="text"
                  value={startDate}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                  }}
                  onBlur={(e) => {
                    e.currentTarget.type = "text";
                    e.currentTarget.placeholder = "Select Period";
                  }}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </div>
              <div className="period">
                <span className="title">Period</span>
                <input
                  placeholder="End Date"
                  type="text"
                  value={endDate}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                  }}
                  onBlur={(e) => {
                    e.currentTarget.type = "text";
                    e.currentTarget.placeholder = "Select Period";
                  }}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </div>
              <div className="status">
                <span className="title">Status</span>
                <div className="selects">
                  {status.map((stats, index) => {
                    return (
                      <div className="select-item">
                        <input
                          type="checkbox"
                          checked={
                            selectedStatus.filter(
                              (item) => stats.value === item
                            ) &&
                            selectedStatus.filter(
                              (item) => stats.value === item
                            ).length
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedStatus([
                                ...selectedStatus,
                                stats.value,
                              ]);
                            } else {
                              setSelectedStatus((selectedStatus) =>
                                selectedStatus.filter(
                                  (item) => item !== stats.value
                                )
                              );
                            }
                          }}
                        />
                        <span>{stats.name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="clients">
                <span className="title">Clients</span>
                <div className="selects">
                  {(isShowingMore ? clients : clients.slice(0, 4)).map(
                    (client, index) => {
                      return (
                        <div className="select-item">
                          <input
                            value={
                              selectedClients.filter(
                                (item) => item.id === client.id
                              ) &&
                              selectedClients.filter(
                                (item) => item.id === client.id
                              ).length
                                ? true
                                : false
                            }
                            type="checkbox"
                            onChange={(e) => {
                              const obj = client;
                              if (e.target.checked) {
                                setSelectedClients([...selectedClients, obj]);
                              } else {
                                setSelectedClients((selectedClients) =>
                                  selectedClients.filter(
                                    (selectedClient) =>
                                      selectedClient.id !== client.id
                                  )
                                );
                              }
                            }}
                          />
                          <div className="image">
                            <img
                              src={
                                client.profilePicture !== null
                                  ? client.profilePicture.url
                                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                              }
                            />
                          </div>
                          <span>
                            {client.firstName} {client.lastName}
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
                <span
                  onClick={() => setIsShowingMore(!isShowingMore)}
                  className="show-more"
                >
                  View more{" "}
                  {isShowingMore ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              {/* <div className="clients">
                <span className="title">Editors</span>
                <div className="selects">
                  {(isShowingMore ? editors : editors.slice(0, 4)).map(
                    (editor, index) => {
                      return (
                        <div className="select-item">
                          <input
                            value={
                              selectedEditors.filter(
                                (item) => item.id === editor.id
                              ) &&
                              selectedEditors.filter(
                                (item) => item.id === editor.id
                              ).length
                                ? true
                                : false
                            }
                            type="checkbox"
                            onChange={(e) => {
                              const obj = editor;
                              if (e.target.checked) {
                                setSelectedEditors([...selectedEditors, obj]);
                              } else {
                                setSelectedEditors((selectedEditors) =>
                                  selectedEditors.filter(
                                    (selectedEditor) =>
                                      selectedEditor.id !== editor.id
                                  )
                                );
                              }
                            }}
                          />
                          <div className="image">
                            <img
                              src={
                                editor.profilePicture !== null
                                  ? editor.profilePicture.url
                                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                              }
                            />
                          </div>
                          <span>
                            {editor.firstName} {editor.lastName}
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
                <span
                  onClick={() => setIsShowingMore(!isShowingMore)}
                  className="show-more"
                >
                  View more{" "}
                  {isShowingMore ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div> */}
            </div>
            <div className="filters-footer">
              {/* <span>
                <AiFillInfoCircle color="#3F8CFF" /> 10 matches found
              </span> */}
              <button onClick={() => saveFilters()}>Save Filters</button>
            </div>
          </div>
        </div>
      )}
      <div className="header">
        <div className="types">
          <div
            onClick={() => setCurrentPage("All Orders")}
            className={`${currentPage === "All Orders" ? "active" : ""}`}
          >
            All Orders
          </div>
          <div
            onClick={() => setCurrentPage("Completed Orders")}
            className={`${currentPage === "Completed Orders" ? "active" : ""}`}
          >
            Completed Orders
          </div>
          {/* <div
            onClick={() => setCurrentPage("New Orders")}
            className={`${currentPage === "New Orders" ? "active" : ""}`}
          >
            New Orders
          </div> */}
        </div>
        <div className="icons-order">
          {!isSearchOpen ? (
            <div className="icon" onClick={() => setIsSearchOpen(true)}>
              <BiSearchAlt />
            </div>
          ) : (
            <div className="icon search">
              <input
                placeholder="Order Name"
                onChange={(e) => {
                  getOrders(1, e.target.value);
                }}
              />
              <AiOutlineClose
                size={15}
                className="close-search"
                onClick={() => setIsSearchOpen(false)}
              />
            </div>
          )}
          <div className="icon" onClick={() => setIsFiltersOpen(true)}>
            <BiFilterAlt />
          </div>
        </div>
      </div>
      {currentPage === "All Orders" && (
        <AllOrders
          ordersCount={ordersCount}
          getOrders={getOrders}
          orders={orders}
          user={user}
          allUsers={allUsers}
          setOrders={setOrders}
          setOrdersCount={setOrdersCount}
        />
      )}
      {/* {currentPage === "New Orders" && (
        <NewOrders
          ordersCount={ordersCount}
          getOrders={getOrders}
          orders={orders}
          user
        />
      )} */}
      {currentPage === "Completed Orders" && (
        <CompletedOrders
          ordersCount={ordersCount}
          getOrders={getOrders}
          orders={orders}
        />
      )}
    </div>
  );
};

export default EditorOrders;
