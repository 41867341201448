import { useState, useEffect } from "react";
import "./Music.scss";
import { FaCloudUploadAlt, FaRegTrashAlt } from "react-icons/fa";
import { AiFillFile } from "react-icons/ai";
import ProgressBar from "../../../../../../../components/progressBar/ProgressBar";
import { uploadService } from "../../../../../../../services/upload.service";
import LoadingUpload from "../../../../../../loadingUpload/LoadingUpload";
import UploadModal from "../../../../../../../components/uploadModal/UploadModal";
const Music = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
}) => {
  const [add, setAdd] = useState(false);
  const [studioDecides, setStudioDecides] = useState(false);
  const [music, setMusic] = useState(null);
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (order.music) {
      if (order.music.comments && order.music.comments !== null) {
        setComments(order.music.comments);
      }

      if (order.music.studioDecides && order.music.studioDecides !== null) {
        setStudioDecides(order.music.studioDecides);
      }

      if (order.music.music && order.music.music !== null) {
        setMusic(order.music.music);
      }
    }
  }, [order]);

  const changeStep = (isStepNext) => {
    if (isStepNext) {
      if (validateFields()) {
        const obj = !add
          ? null
          : {
              studioDecides,
              music,
              comments,
            };
        changeComponent("music", obj);

        nextStep();
      } else {
        validate("Please check that you added all required information");
      }
    } else {
      prevStep();
    }
  };

  const validateFields = () => {
    if (add && (!music || music === null) && !studioDecides) {
      return false;
    }

    return true;
  };

  return (
    <div className="music-new-order">
      {loading ? <UploadModal progress={uploadProgress} /> : null}
      <div className="inner-content">
        <div className="item">
          <span className="title-item">Do you need Music? *</span>
          <div className="checkboxes">
            <div className="radio-box">
              <input
                id="yes"
                name="yes"
                type="radio"
                checked={add}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAdd(true);
                  }
                }}
              />
              <label for="yes" className="legend">
                Yes
              </label>
            </div>
            <div className="radio-box">
              <input
                id="no"
                name="no"
                type="radio"
                checked={!add}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAdd(false);
                  }
                }}
              />
              <label for="no" className="legend">
                No, this video does not need music
              </label>
            </div>
          </div>
        </div>
        {add && (
          <div className="add-yes">
            {!studioDecides && (
              <div className="upload">
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    setLoading(true);

                    if (file) {
                      let data = new FormData();
                      data.append("file", file);

                      await uploadService.upload(
                        data,
                        (data) => {
                          setMusic(data);
                          setLoading(false);
                        },
                        setUploadProgress
                      );
                    }
                  }}
                  id="drop-files-music"
                />
                {!music || music === null ? (
                  <label className="upload-box" for="drop-files-music">
                    <FaCloudUploadAlt size={60} color="#D9DFE9" />
                    <span>Drag and drop a music *</span>
                  </label>
                ) : (
                  <div className="uploaded-box">
                    <div className="files">
                      <div className="item">
                        <AiFillFile size={60} color="#2A9DF4" />
                        <span>
                          {music.originalName}{" "}
                          <FaRegTrashAlt
                            onClick={() => setMusic(null)}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="let-studio-decides-box">
              <input
                id="let-studio"
                name="let-studio"
                type="checkbox"
                onChange={(e) => {
                  setStudioDecides(e.target.checked);
                }}
              />
              <label for="let-studio">
                Let Mark Studios pick music for this video
              </label>
            </div>
            <div className="item">
              <span className="title-item">Additional Comments</span>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                placeholder="Add some comments"
              />
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default Music;
