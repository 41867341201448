import { useState } from "react";
import "./AddPromoPopup.scss";
import { AiOutlineClose } from "react-icons/ai";
import Swal from "sweetalert2";
import { PromoService } from "../../../../services/promo.service";

const AddPromoPopup = ({ closeModal, addPromo }) => {
  // Form management
  const [promoCode, setPromoCode] = useState("");
  const [type, setType] = useState("amount");
  const [amount, setAmount] = useState(0);

  // Custom toast
  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
  });

  // Format discounted amount
  const checkAmount = (amount) => {
    amount = parseInt(amount);
    if (amount < 0) {
      amount = 0;
      Toast.fire({
        heightAuto: false,
        icon: "error",
        title: "Amount must be bigger than 0",
      });
    }

    if (type === "percentage") {
      if (amount > 100) {
        amount = 100;
        Toast.fire({
          heightAuto: false,
          icon: "error",
          title: "Amount must be lower than 100",
        });
      }
    }

    setAmount(amount);
  };

  // Save promo code on database
  const saveCode = async () => {
    const newCode = {
      code: promoCode,
      type,
      active: true,
      amount,
    };

    await PromoService.savePromo(newCode, (data) => {
      // Resetting data
      setPromoCode("");
      setType("amount");
      setAmount(0);
      addPromo(data);
    });
  };

  return (
    <div className="add-promo-popup-container" onClick={closeModal}>
      <div
        className="add-promo-popup"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <AiOutlineClose className="close-popup" onClick={closeModal} />
        <span className="popup-title">New promotion</span>
        <div className="input-fields">
          <div className="input-field">
            <span>Promo Code</span>
            <input
              type={"text"}
              placeholder="Promo Code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
          </div>
          <div className="row">
            <div className="input-field">
              <span>Type</span>
              <select value={type} onChange={(e) => setType(e.target.value)}>
                <option value={"amount"}>Amount</option>
                <option value={"percentage"}>Percentage</option>
              </select>
            </div>
            <div className="input-field">
              <span>Discount Amount</span>
              <input
                type={"number"}
                placeholder={`Discount Amount (${
                  type === "amount" ? "$" : "%"
                })`}
                min={0}
                max={type === "amount" ? null : 100}
                value={amount}
                onChange={(e) => checkAmount(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button className="submit" onClick={() => saveCode()}>
          Add
        </button>
      </div>
    </div>
  );
};

export default AddPromoPopup;
