import "./Recovery.scss";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import Modal from "react-modal/lib/components/Modal";
import LogoWhite from "../../../../assets/logo-white.png";
import PaperPlane from "../../../../assets/auth/paper-plane.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { authService } from "../../../../services/auth.service";

const GeneralAuthRecovery = () => {
  const params = useParams();
  const [isPasswordShown, setIsPasswordShows] = useState(false);
  const [isRepeatPasswordShown, setIsRepeatPasswordShows] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen] = useOutletContext();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      repeatPassword: "",
    },
    onSubmit: (values) => {
      if (
        !values.password ||
        values.password.trim() === "" ||
        !values.repeatPassword ||
        values.repeatPassword.trim() === "" ||
        values.password !== values.repeatPassword
      ) {
        Swal.fire({
          title: "Error!",
          text: "You need to enter the same non-empty password in the fields above",
          heightAuto: false,
          icon: "error",
        });
      } else {
        authService.resetPassword(
          params.uuid,
          values.email,
          values.password,
          () => {
            Swal.fire({
              title: "Password reset!",
              heightAuto: false,
              icon: "success",
            }).then(() => {
              navigate("/auth/sign-in");
            });
          }
        );
      }
    },
  });

  return (
    <div className="auth-general-recovery">
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Confirm Email"
        className="modal-recovery"
        overlayClassName="overlay-modal-recovery"
      >
        <div className="header-modal">
          <img src={LogoWhite} />
        </div>
        <div className="body-modal">
          <span className="title">Hello!</span>
          <span className="text">
            Thank you for your registration at Mark Studios. Please use this
            button to confirm your email address.
          </span>
          <button onClick={() => setIsModalOpen(false)}>Go to App</button>
        </div>
        <div className="footer-modal">
          <span>Please do not reply to this email.</span>
          <span>Copyright 2022 - Mark Studios LLC. All rights reserved.</span>
        </div>
        <img className="illustration" src={PaperPlane} />
      </Modal>
      <div className="login-box">
        <span className="title">Recovery</span>
        <div className="login-info">
          <div className="input-box">
            <span className="input-title">Email Address</span>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="youremail@gmail.com"
            />
          </div>
          <div className="input-box">
            <span className="input-title">Password</span>
            <div className="password">
              <input
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                type={isPasswordShown ? "text" : "password"}
                placeholder="••••••••"
              />
              {isPasswordShown ? (
                <AiOutlineEye
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              )}
            </div>
          </div>
          <div className="input-box">
            <span className="input-title">Password</span>
            <div className="password">
              <input
                id="repeatPassword"
                name="repeatPassword"
                onChange={formik.handleChange}
                value={formik.values.repeatPassword}
                type={isRepeatPasswordShown ? "text" : "password"}
                placeholder="••••••••"
              />
              {isRepeatPasswordShown ? (
                <AiOutlineEye
                  onClick={() =>
                    setIsRepeatPasswordShows(!isRepeatPasswordShown)
                  }
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() =>
                    setIsRepeatPasswordShows(!isRepeatPasswordShown)
                  }
                />
              )}
            </div>
          </div>
        </div>
        <button className="login" onClick={formik.submitForm}>
          Reset Password
        </button>
      </div>
      <div className="footer">
        <Link to={"/auth/sign-in"} className="already-have">
          Already have an account? <b>Sign in</b>
        </Link>
        <div className="terms-privacy-policy">
          <span onClick={() => setIsTermsOfServiceOpen(true)}>
            Terms of use
          </span>
          <span onClick={() => setIsPrivacyPolicyOpen(true)}>
            Privacy Policy
          </span>
          <span>Support</span>
        </div>
      </div>
    </div>
  );
};

export default GeneralAuthRecovery;
