import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import ProfileDashboard from "../../../../components/profileDashboard/ProfileDashboard";
import "./Dashboard.scss";
import "react-calendar/dist/Calendar.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LatestOrders from "../../../../components/ordersAdmin/latestOrders/LatestOrders";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { orderService } from "../../../../services/order.service";
import { useOutletContext } from "react-router";
import Loading from "../../../loading/Loading";
import { BsDot } from "react-icons/bs";
import moment from "moment-timezone";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const EditorDashboard = () => {
  const [dateValue, setDateValue] = useState(new Date());
  const [isNavBarShown, setIsNavBarShow] = useState(false);
  const [orders, setOrders] = useState([]);
  /*eslint no-unused-vars: "off"*/
  const [askAdmin, setAskAdmin, user] = useOutletContext();
  const [dayOrders, setDayOrders] = useState([]);
  const [reviews, setReviews] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [monthlyIncome, setMonthlyIncome] = useState(0);
  const [stars, setStars] = useState(0);
  const [editorsPay, setEditorsPay] = useState({});
  const [orderNumber, setOrderNumber] = useState({});
  const [editorsPayLabels, setEditorsPayLabels] = useState([]);
  const [editorsPayData, setEditorsPayData] = useState([]);
  const [orderNumberLabels, setOrderNumberLabels] = useState([]);
  const [orderNumberData, setOrderNumberData] = useState([]);
  const [marks, setMarks] = useState([]);
  const [orderNumberGraph, setOrderNumberGraph] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: "#00D097",
      },
    ],
  });
  const [editorsPayGraph, setEditorsPayGraph] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: "#00D097",
      },
    ],
  });

  useEffect(() => {
    orderService.listOrders(
      1,
      { admin: false, type: "all", order: "normal" },
      (data) => {
        setOrders(data.orders);

        orderService.listOrders(
          1,
          { admin: false, type: "all", listAll: true },
          (data) => {
            setMarks(
              data.orders.map((item) => {
                return moment(item.deadline)
                  .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format("DD-MM-YYYY");
              })
            );
          }
        );
      }
    );
  }, []);

  useEffect(() => {
    orderService.getReviews((data) => {
      setReviews(data);
      orderService.getOverviewEditor((data) => {
        setTotalOrders(data.totalOrders);
        setMonthlyIncome(data.monthlyIncome);
        setStars(data.stars);
      });
    });

    orderService.getGraphs((data) => {
      setOrderNumber(data.orderNumber);
      setEditorsPay(data.money);
    });
  }, [orders]);

  useEffect(() => {
    setOrderNumberLabels(
      Object.keys(JSON.parse(JSON.stringify(editorsPay))).map((data, index) => {
        const arrLength = Object.keys(
          JSON.parse(JSON.stringify(editorsPay))
        ).length;
        if (
          index === 0 ||
          index === arrLength - 1 ||
          (arrLength % 2 === 0 && index === arrLength / 2) ||
          (arrLength % 2 !== 0 && index === arrLength / 2 + 0.5)
        ) {
          return data;
        } else {
          return "";
        }
      })
    );

    setEditorsPayLabels(
      Object.keys(JSON.parse(JSON.stringify(editorsPay))).map((data, index) => {
        const arrLength = Object.keys(
          JSON.parse(JSON.stringify(editorsPay))
        ).length;
        if (
          index === 0 ||
          index === arrLength - 1 ||
          (arrLength % 2 === 0 && index === arrLength / 2) ||
          (arrLength % 2 !== 0 && index === arrLength / 2 + 0.5)
        ) {
          return data;
        } else {
          return "";
        }
      })
    );

    setOrderNumberData(Object.values(JSON.parse(JSON.stringify(orderNumber))));
    setEditorsPayData(Object.values(JSON.parse(JSON.stringify(editorsPay))));
  }, [orderNumber, editorsPay]);

  useEffect(() => {
    setEditorsPayGraph({
      labels: editorsPayLabels,
      datasets: [
        {
          data: editorsPayData,
          borderColor: "#00D097",
        },
      ],
    });

    setOrderNumberGraph({
      labels: orderNumberLabels,
      datasets: [
        {
          data: orderNumberData,
          borderColor: "#00D097",
        },
      ],
    });
  }, [orderNumberData, orderNumberLabels, editorsPayData, editorsPayLabels]);

  useEffect(() => {
    orderService.getOrdersOfDay(
      {
        date: dateValue,
        tz: window.Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      (data) => {
        setDayOrders(data);
      }
    );
  }, [dateValue]);

  return user && user.firstName ? (
    !orders || !orders.length ? (
      <div className="editor-layout-dashboard-page empty">
        <div className="empty-container">
          <div className="profile">
            <ProfileDashboard
              level={user.level}
              toNextLevel={user.toNextLevel}
              user={
                user.profilePicture
                  ? user.profilePicture.url
                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
              }
            />
          </div>
          <h2>
            You have not edited any videos yet.
            <br />
            Pick up an order to get started!
          </h2>
        </div>
      </div>
    ) : (
      <div className="editor-layout-dashboard-page">
        {getWindowDimensions().width < 450 && isNavBarShown && (
          <div className="side-nav-mobile">
            <div className="side-bar-container">
              <div className="side-nav-header">
                <span className="title">Details</span>
                <div className="icon" onClick={() => setIsNavBarShow(false)}>
                  <AiOutlineClose />
                </div>
              </div>
              <div className="profile">
                <ProfileDashboard
                  level={user.level}
                  toNextLevel={user.toNextLevel}
                  user={
                    user.profilePicture
                      ? user.profilePicture.url
                      : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                  }
                />
              </div>
              <div className="calendar">
                <Calendar
                  className="react-calendar"
                  onChange={setDateValue}
                  value={dateValue}
                  tileContent={({ date, view }) => {
                    if (
                      view === "month" &&
                      marks.find(
                        (currentDay) =>
                          currentDay ===
                          moment(date)
                            .tz(
                              window.Intl.DateTimeFormat().resolvedOptions()
                                .timeZone
                            )
                            .format("DD-MM-YYYY")
                      )
                    ) {
                      return <BsDot className="day-with-order" />;
                    }
                  }}
                />
                <div className="tasks">
                  {dayOrders.map((task) => {
                    return (
                      <div className="task">
                        <span className="time">
                          {new Date(
                            parseInt(task.deadline)
                          ).toLocaleDateString()}
                        </span>
                        <span className="name">
                          {task.basicDetails
                            ? task.basicDetails.title
                            : `Order #${task.number}`}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="reviews">
                <div className="header">
                  <span>Reviews</span>
                </div>
                <div className="line" />
                <div className="items">
                  {reviews && reviews.length
                    ? reviews.map((review) => {
                        return (
                          <div className="review">
                            <div className="review-image">
                              <img
                                alt="reviewer"
                                src={
                                  review.image
                                    ? review.image.url
                                    : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                                }
                              />
                            </div>
                            <div className="name-review">
                              <span>{review.name}</span>
                              <span>{review.description}</span>
                            </div>
                            <div className="rating">
                              <span>{`${review.rating}.0`}</span>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="content-dashboard">
          {getWindowDimensions().width < 450 && (
            <div className="icon" onClick={() => setIsNavBarShow(true)}>
              <BiMenuAltRight />
            </div>
          )}
          <div className="graphs">
            <div className="graph">
              <div className="header">
                <span>Money Received</span>
                {/* <div className="dropdown">
                  <span>Show: </span>
                  <select>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div> */}
              </div>
              <Line
                className="graph-inside"
                data={editorsPayGraph}
                options={{ plugins: { legend: false } }}
              />
            </div>
            <div className="graph">
              <div className="header">
                <span>Number of Orders</span>
                {/* <div className="dropdown">
                  <span>Show: </span>
                  <select>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div> */}
              </div>
              <Line
                className="graph-inside"
                data={orderNumberGraph}
                options={{ plugins: { legend: false } }}
              />
            </div>
          </div>
          <div className="overall">
            <div className="item">
              <span>Monthly Income</span>
              <span className="content-item">R${monthlyIncome}.00</span>
            </div>
            <div className="item">
              <span>Total Orders</span>
              <span className="content-item">{totalOrders}</span>
            </div>
            <div className="item">
              <span>Rating</span>
              <div>
                <span>{stars} stars</span>
              </div>
            </div>
          </div>
          <div className="latest-orders">
            <span className="header">Latest Orders</span>
            <LatestOrders orders={orders} />
          </div>
        </div>
        {getWindowDimensions().width > 450 && (
          <div className="side-nav">
            <div className="profile">
              <ProfileDashboard
                level={user.level}
                toNextLevel={user.toNextLevel}
                user={
                  user.profilePicture
                    ? user.profilePicture.url
                    : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                }
              />
            </div>
            <div className="calendar">
              <Calendar
                className="react-calendar"
                onChange={setDateValue}
                value={dateValue}
                tileContent={({ date, view }) => {
                  if (
                    view === "month" &&
                    marks.find(
                      (currentDay) =>
                        currentDay ===
                        moment(date)
                          .tz(
                            window.Intl.DateTimeFormat().resolvedOptions()
                              .timeZone
                          )
                          .format("DD-MM-YYYY")
                    )
                  ) {
                    return <BsDot className="day-with-order" />;
                  }
                }}
              />
              <div className="tasks">
                {dayOrders.map((task) => {
                  return (
                    <div className="task">
                      <span className="time">
                        {new Date(parseInt(task.deadline)).toLocaleDateString()}
                      </span>
                      <span className="name">
                        {task.basicDetails
                          ? task.basicDetails.title
                          : `Order #${task.number}`}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="reviews">
              <div className="header">
                <span>Reviews</span>
              </div>
              <div className="line" />
              <div className="items">
                {reviews && reviews.length
                  ? reviews.map((review) => {
                      return (
                        <div className="review">
                          <div className="review-image">
                            <img
                              alt="reviewer"
                              src={
                                review.image
                                  ? review.image.url
                                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                              }
                            />
                          </div>
                          <div className="name-review">
                            <span>{review.name}</span>
                            <span>{review.description}</span>
                          </div>
                          <div className="rating">
                            <span>{`${review.rating}.0`}</span>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  ) : (
    <Loading />
  );
};

export default EditorDashboard;
