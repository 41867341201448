import { coreService } from "./core.service";

const getAllPromos = (callback) => {
  coreService.get("promo/list", callback);
};

const savePromo = (newPromo, callback) => {
  coreService.post("promo/save", { promo: newPromo }, callback);
};

const deletePromo = (promoId, callback) => {
  coreService.post("promo/delete", { id: promoId }, callback);
};

const findPromo = (promoCode, callback, error) => {
  coreService.post(
    "promo-apply/find",
    { code: promoCode },
    callback,
    undefined,
    error
  );
};

export const PromoService = {
  getAllPromos,
  savePromo,
  deletePromo,
  findPromo,
};
