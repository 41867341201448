import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./AllClients.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate } from "react-router";
import { clientService } from "../../../services/client.service";
import Editor1 from "../../../assets/editor1.png";
import Editor2 from "../../../assets/editor2.png";
import Editor3 from "../../../assets/editor3.png";
import Editor4 from "../../../assets/editor4.png";
import Editor5 from "../../../assets/editor5.png";
import Editor6 from "../../../assets/editor6.png";
import Editor7 from "../../../assets/editor7.png";
import Editor8 from "../../../assets/editor8.png";
import { userService } from "../../../services/user.service";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AllClients = ({ clients, count, getClients }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentClients, setCurrentClients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getClients(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentClients(clients);
  }, [clients]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const formatCurrency = (amount) => {
    let newAmount = "";
    let arrCount = 0;
    let amountToCheck = `${parseFloat(amount).toFixed(0)}`;
    const arr = `${amountToCheck}`.split("").reverse();
    arr.map((letter) => {
      arrCount++;
      newAmount = `${letter}${newAmount}`;
      if (arrCount !== arr.length && arrCount % 3 === 0) {
        newAmount = `,${newAmount}`;
      }
    });

    let result = `$${newAmount}.00`;

    return result;
  };

  const goToPage = (index) => {
    navigate(`./${currentClients[index].status}`);
  };

  return (
    <div className="all-clients-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            customStyle=".5fr 1fr 2fr .5fr .5fr 1fr .5fr"
            titles={[
              "Photo",
              "Name",
              "Email",
              "Total Orders",
              "In Progress",
              // "Money Spent",
              "",
            ]}
          />
          <div className="clients-table">
            <TableRow
              customStyle=".5fr 1fr 2fr .5fr .5fr 1fr .5fr"
              click={goToPage}
              items={currentClients.map((client, index) => {
                return [
                  <div
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      height: "45px",
                      width: "45px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "45px" }}
                      src={
                        client.profilePicture
                          ? client.profilePicture.url
                          : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                      }
                    />
                  </div>,
                  client.firstName ? (
                    <span style={{ fontWeight: 500 }}>
                      {client.firstName} {client.lastName}
                    </span>
                  ) : (
                    <span style={{ fontWeight: 500 }}>New client</span>
                  ),
                  <span
                    style={{
                      fontWeight: 400,
                      color: "#A0AEC7",
                      fontSize: ".8em",
                    }}
                  >
                    {client.email}
                  </span>,
                  <div
                    style={{
                      padding: ".5em 0",
                      width: "30%",
                      fontSize: ".8em",
                      fontWeight: 500,
                      borderRadius: "8px",
                      backgroundColor: "#D8FFEE",
                      color: "#00D097",
                    }}
                  >
                    <span>{client.totalOrders}</span>
                  </div>,
                  <div
                    style={{
                      padding: ".5em 0",
                      width: "30%",
                      fontSize: ".8em",
                      fontWeight: 500,
                      borderRadius: "8px",
                      backgroundColor: "#FFEFD9",
                      color: "#FFB756",
                    }}
                  >
                    <span>{client.progressOrders}</span>
                  </div>,
                  // <span style={{ fontWeight: 500 }}>
                  //   {formatCurrency(client.totalSpent)}
                  // </span>,
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      position: "relative",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        const obj = currentClients[index];
                        obj.open = !obj.open;
                        setCurrentClients([
                          ...currentClients.slice(0, index),
                          obj,
                          ...currentClients.slice(index + 1),
                        ]);
                      }}
                      style={{
                        position: "absolute",
                        backgroundColor: "#F2F5FA",
                        width: "30px",
                        height: "30px",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "1.2em",
                        borderRadius: "7px",
                        cursor: "pointer",
                      }}
                    >
                      <AiOutlineMore />
                    </div>
                    {client.open && (
                      <div
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "white",
                          position: "absolute",
                          top: 40,
                          right: 0,
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "space-between",
                          boxShadow: "0 6px 40px 0 #7991AD4D",
                          padding: ".3em",
                        }}
                      >
                        <div
                          onClick={() => navigate(`./${client.id}`)}
                          style={{
                            fontWeight: "600",
                            padding: "0 .5em",
                            marginRight: ".5em",
                            backgroundColor: "#F4F9FD",
                            height: "30px",
                            flexWrap: "nowrap",
                            whiteSpace: "nowrap",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: ".8em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Show More
                        </div>
                        <div
                          onClick={() => {
                            clientService.changeBlock(
                              client.id,
                              true,
                              (data) => {
                                getClients(1);
                              }
                            );
                          }}
                          style={{
                            fontWeight: "600",
                            padding: "0 .5em",
                            marginRight: ".5em",
                            backgroundColor: "#F4F9FD",
                            height: "30px",
                            flexWrap: "nowrap",
                            whiteSpace: "nowrap",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: ".8em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Block Client
                        </div>
                        <div
                          onClick={() => {
                            userService.removeUser(client.id, (data) => {
                              getClients(1);
                            });
                          }}
                          style={{
                            fontWeight: "600",
                            padding: "0 .5em",
                            marginRight: ".5em",
                            backgroundColor: "#F4F9FD",
                            height: "30px",
                            flexWrap: "nowrap",
                            whiteSpace: "nowrap",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: ".8em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Remove Client
                        </div>
                        <div
                          onClick={() => {
                            userService.startNextPackage(client, () => {
                              Swal.fire({
                                title: "Done",
                                text: "Next Package Started",
                                heightAuto: false,
                                icon: "success",
                              });
                              const obj = currentClients[index];
                              obj.open = !obj.open;
                              setCurrentClients([
                                ...currentClients.slice(0, index),
                                obj,
                                ...currentClients.slice(index + 1),
                              ]);
                            });
                          }}
                          style={{
                            fontWeight: "600",
                            padding: "0 .5em",
                            marginRight: ".5em",
                            backgroundColor: "#F4F9FD",
                            height: "30px",
                            flexWrap: "nowrap",
                            whiteSpace: "nowrap",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: ".8em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Start Next Package
                        </div>
                      </div>
                    )}
                  </div>,
                ];
              })}
            />
          </div>
        </>
      ) : (
        <>
          {currentClients.map((client, index) => {
            return (
              <div className="client-mobile">
                <div className="header-mobile">
                  <div
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      height: "45px",
                      width: "45px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "45px" }}
                      src={
                        client.profilePicture
                          ? client.profilePicture.url
                          : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                      }
                    />
                  </div>
                  <div className="info">
                    <div className="name-email">
                      <span>
                        {client.firstName} {client.lastName}
                      </span>
                      <span>{client.email}</span>
                    </div>
                  </div>
                </div>
                <div className="body-mobile">
                  {/* <div className="item">
                    <span className="title">
                      Money
                      <br />
                      Spent
                    </span>
                    <span className="content-item-mobile">
                      {formatCurrency(client.totalSpent)}
                    </span>
                  </div> */}
                  <div className="item">
                    <span className="title">
                      Orders
                      <br />
                      Done
                    </span>
                    <span className="content-item-mobile">
                      {client.totalOrders}
                    </span>
                  </div>
                  <div className="item">
                    <span className="title">
                      Orders in
                      <br />
                      Progress
                    </span>
                    <span className="content-item-mobile">
                      {client.progressOrders}
                    </span>
                  </div>
                  <div className="item more">
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        position: "relative",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          const obj = currentClients[index];
                          obj.open = !obj.open;
                          setCurrentClients([
                            ...currentClients.slice(0, index),
                            obj,
                            ...currentClients.slice(index + 1),
                          ]);
                        }}
                        style={{
                          position: "absolute",
                          backgroundColor: "#F2F5FA",
                          width: "30px",
                          height: "30px",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "1.2em",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                      >
                        <AiOutlineMore />
                      </div>
                      {client.open && (
                        <div
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            position: "absolute",
                            top: 40,
                            right: 0,
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow: "0 6px 40px 0 #7991AD4D",
                            padding: ".3em",
                          }}
                        >
                          <div
                            onClick={() => navigate(`./${client.id}`)}
                            style={{
                              fontWeight: "600",
                              padding: "0 .5em",
                              marginRight: ".5em",
                              backgroundColor: "#F4F9FD",
                              height: "30px",
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: ".8em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            Show More
                          </div>
                          <div
                            onClick={() => {
                              clientService.changeBlock(
                                client.id,
                                true,
                                (data) => {
                                  getClients(1);
                                }
                              );
                            }}
                            style={{
                              fontWeight: "600",
                              padding: "0 .5em",
                              marginRight: ".5em",
                              backgroundColor: "#F4F9FD",
                              height: "30px",
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: ".8em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            Block Client
                          </div>
                          <div
                            onClick={() => {
                              userService.startNextPackage(client, () => {
                                Swal.fire({
                                  title: "Done",
                                  text: "Next Package Started",
                                  heightAuto: false,
                                  icon: "success",
                                });
                              });
                            }}
                            style={{
                              fontWeight: "600",
                              padding: "0 .5em",
                              marginRight: ".5em",
                              backgroundColor: "#F4F9FD",
                              height: "30px",
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: ".8em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            Start Next Package
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      {/* <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {count > currentPage * 6 ? currentPage * 6 : count} of {count}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={count < currentPage * 6 ? "disable" : "active"}
            onClick={nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default AllClients;
