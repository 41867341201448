import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import Profile from "../../components/profile/Profile";
import SideBar from "../../components/sidebar/SideBar";
import { sideBarItems } from "../../components/sidebar/sideBarItems";
import "./Layout.scss";
import { BiSearch, BiBell, BiLogOut } from "react-icons/bi";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import User2 from "../../assets/user2.png";
import User3 from "../../assets/user3.png";
import User4 from "../../assets/user4.png";
import Logo from "../../assets/logo.png";
import Mark from "../../assets/mark.png";
import { Link } from "react-router-dom";
import { userService } from "../../services/user.service";
import Loading from "../loading/Loading";
import { orderService } from "../../services/order.service";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import io, { Socket } from "socket.io-client";
import moment from "moment-timezone";
import NotificationSfx from "../../assets/songs/notification.mp3";
import { DriveService } from "../../services/drive.service";
import { onBoardingStepsPlugin } from "./OnBoardingSteps";
import { onBoardingStepsPackagePlugin } from "./OnBoardingStepsPackage";
import { compare } from "../../utils/sorting";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const CustomerLayout = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [currentPageName, setCurrentPageName] = useState("");
  const [askAdmin, setAskAdmin] = useState(false);
  const [orderTab, setOrderTab] = useState("All Orders");
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState({});
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [audio, setAudio] = useState(new Audio(NotificationSfx));
  const [newNotification, setNewNotification] = useState(false);
  const [step, setStep] = useState(1);
  const [onboardingStep, setOnboardingStep] = useState({});
  const [stepPackage, setStepPackage] = useState(1);
  const [onboardingStepPackage, setOnboardingStepPackage] = useState({});
  const [packageOnBoardingOpen, setPackageOnBoardingOpen] = useState(true);

  useEffect(() => {
    if (newNotification) {
      setTimeout(() => {
        setNewNotification(false);
      }, 5000);
    }
  }, [newNotification]);

  useEffect(() => {
    setCurrentPage(window.location.pathname.split("/")[2]);
    DriveService.initGoogleAuth();
    userService.getProfile(
      (data) => {
        setOnboardingStep(onBoardingStepsPlugin.currentStep(data, 1));
        setOnboardingStepPackage(
          onBoardingStepsPackagePlugin.currentStep(data, 1)
        );
        setStep(1);
        setStepPackage(1);
        if (data.type !== "customer") {
          Swal.fire({
            title: "Error!",
            text: "Please login before entering your account",
            heightAuto: false,
            icon: "error",
          }).then(() => {
            Cookies.remove("type");
            Cookies.remove("token");
            navigate("/auth/sign-in");
          });
        } else {
          setUser(data);
        }

        if (!data.onboarded) {
          navigate("/customer/dashboard");
        }
      },
      (e) => {
        Swal.fire({
          title: "Error!",
          text: "Please login before entering your account",
          heightAuto: false,
          icon: "error",
        }).then(() => {
          Cookies.remove("type");
          Cookies.remove("token");
          navigate("/auth/sign-in");
        });
      }
    );
  }, []);

  useEffect(() => {
    if (window.location.pathname.split("/")[3]) {
      if (window.location.pathname.split("/")[2] === "editors") {
        setCurrentPageName("Editor Details");
      } else if (window.location.pathname.split("/")[2] === "clients") {
        setCurrentPageName("Client Details");
      } else if (window.location.pathname.split("/")[2] === "orders") {
        setCurrentPageName("Order Delivery");
      } else if (window.location.pathname.split("/")[2] === "packages") {
        if (window.location.pathname.split("/")[3] === "my-package") {
          setCurrentPageName("My Package");
        }
      }
    } else {
      sideBarItems.sideBarItemsAdmin.map((item) => {
        if (item.id === currentPage) {
          setCurrentPageName(item.name);
        }
      });
    }
  }, [currentPage]);

  useEffect(() => {
    const newSocket = io(
      `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_API_BASE_URL_DEV
          : process.env.REACT_APP_API_BASE_URL_PROD
      }notification`,
      {
        query: { token: Cookies.get("token") },
      }
    );
    setSocket(newSocket);
    newSocket.emit("notification:join");
    newSocket.emit("notification:get");
    newSocket.on("notification:get-return", (data) => {
      const sortedNotifications = data.sort(compare);
      setNotifications(sortedNotifications);
    });

    return () => {
      console.log("disconnecting");
      newSocket.emit("chat:disconnect");
      // newSocket.close()
    };
  }, []);

  useEffect(() => {
    if (socket && socket !== null) {
      socket.on("notification:new", (data) => {
        audio.play();
        setNotifications([data, ...notifications]);
        setNewNotification(true);
      });
    }
  }, [notifications]);

  useEffect(() => {
    if (window.location.pathname.split("/")[2] === "editors") {
      setCurrentPageName("Editors");
      setCurrentPage("editors");
    } else if (window.location.pathname.split("/")[2] === "dashboard") {
      setCurrentPageName("Dashboard");
      setCurrentPage("dashboard");
    } else if (window.location.pathname.split("/")[2] === "earning") {
      setCurrentPageName("My Earnings");
      setCurrentPage("earnings");
    } else if (window.location.pathname.split("/")[2] === "inbox") {
      setCurrentPageName("Inbox");
      setCurrentPage("inbox");
    } else if (window.location.pathname.split("/")[2] === "support") {
      setCurrentPageName("FAQ");
      setCurrentPage("support");
    } else if (window.location.pathname.split("/")[2] === "profile") {
      setCurrentPageName("Profile");
      setCurrentPage("profile");
    } else if (window.location.pathname.split("/")[2] === "clients") {
      setCurrentPageName("Clients");
      setCurrentPage("clients");
    }

    if (window.location.pathname.split("/")[3]) {
      if (window.location.pathname.split("/")[2] === "editors") {
        setCurrentPageName("Editor Details");
      } else if (window.location.pathname.split("/")[2] === "clients") {
        setCurrentPageName("Client Details");
      } else if (window.location.pathname.split("/")[2] === "orders") {
        setCurrentPageName("Order Delivery");
      } else if (window.location.pathname.split("/")[2] === "packages") {
        if (window.location.pathname.split("/")[3] === "my-package") {
          setCurrentPageName("My Package");
        } else if (
          window.location.pathname.split("/")[3] === "select-package"
        ) {
          setCurrentPageName("Select your monthly package");
        } else if (
          window.location.pathname.split("/")[3] === "order-summary" ||
          window.location.pathname.split("/")[3] === "add-ons"
        ) {
          setCurrentPageName("Order Summary");
        } else if (
          window.location.pathname.split("/")[3] === "custom-package"
        ) {
          setCurrentPageName("Create Custom Package");
        } else if (window.location.pathname.split("/")[3] === "single-order") {
          setCurrentPageName("Single Order");
        }
      }
    }
  });

  const openNotifications = () => {
    setIsNotificationOpen(false);
    socket.emit("notification:read");
    socket.on("notification:read-return", (data) => {
      const sortedNotifications = data.sort(compare);
      setNotifications(sortedNotifications);
    });
  };

  const waitForElm = (selector) => {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  const finishTutorial = () => {
    const newUser = JSON.parse(JSON.stringify(user));
    newUser.onboarded = true;
    userService.updateUser(
      { id: newUser.id, onboarded: newUser.onboarded },
      (data) => {
        setUser(newUser);
      }
    );
  };

  const nextOnboardingStep = async (currStep = null) => {
    const curr = currStep === null ? step : currStep;
    console.log(curr);
    if (curr !== 6) {
      const newStep = parseInt(`${curr}`) + 1;
      console.log(newStep);
      setStep(newStep);
      setOnboardingStep(onBoardingStepsPlugin.currentStep(user, newStep));

      if (newStep === 3 && getWindowDimensions().width > 740) {
        const siderBarItem = document.querySelector(
          `#sidebar-item-myorders-customer`
        );
        siderBarItem.style.zIndex =
          "999999999999999999999999999999999999999999999999999999";
        siderBarItem.style.backgroundColor = "white";
        siderBarItem.style.borderRadius = "10px";
        siderBarItem.style.border = "2px #2a9df4 dashed";

        const action = () => {
          siderBarItem.style.zIndex = "10000";
          siderBarItem.style.backgroundColor = "transparent";
          siderBarItem.style.borderRadius = "0px";
          siderBarItem.style.border = "0px";
          nextOnboardingStep(curr + 1);
          siderBarItem.removeEventListener("click", action);
        };

        siderBarItem.addEventListener("click", action);
      } else if (getWindowDimensions().width < 740) {
        navigate("/customer/orders");
      }

      if (newStep === 4 && getWindowDimensions().width > 740) {
        const placeYourFirstOrder = await waitForElm("#place-your-first-order");
        console.log(placeYourFirstOrder);
        const placeYourFirstOrderButton = await waitForElm(
          "#place-your-first-order-button"
        );

        placeYourFirstOrder.style.zIndex =
          "999999999999999999999999999999999999999999999999999999";
        placeYourFirstOrder.style.borderRadius = "28px";
        placeYourFirstOrder.style.backgroundColor = "white";
        placeYourFirstOrder.style.border = "2px #2a9df4 dashed";

        const action = () => {
          placeYourFirstOrder.style.zIndex = "10000";
          placeYourFirstOrder.style.backgroundColor = "transparent";
          placeYourFirstOrder.style.borderRadius = "0px";
          placeYourFirstOrder.style.border = "0px";
          nextOnboardingStep(curr + 1);
          placeYourFirstOrderButton.removeEventListener("click", action);
        };

        placeYourFirstOrderButton.addEventListener("click", action);
      } else if (getWindowDimensions().width < 740) {
        navigate("/customer/orders/new-order");
      }

      if (newStep === 5 && getWindowDimensions().width > 740) {
        const typesOfVideo = await waitForElm("#types-of-video");
        const onBoardingBlock = await waitForElm("#onboarding-block");
        const startVideo = await waitForElm("#create-order");
        const startVideoButton = await waitForElm("#create-order-button");

        onBoardingBlock.style.top = "10px";
        startVideo.style.zIndex =
          "999999999999999999999999999999999999999999999999999999";
        startVideo.style.borderRadius = "28px";
        startVideo.style.backgroundColor = "white";
        startVideo.style.border = "2px #2a9df4 dashed";

        typesOfVideo.style.zIndex =
          "999999999999999999999999999999999999999999";
        typesOfVideo.style.borderRadius = "28px";
        typesOfVideo.style.backgroundColor = "white";
        typesOfVideo.style.border = "2px #2a9df4 dashed";

        const action = () => {
          onBoardingBlock.style.top = "auto";

          startVideo.style.zIndex = "10000";
          startVideo.style.backgroundColor = "transparent";
          startVideo.style.borderRadius = "0px";
          startVideo.style.border = "0px";

          typesOfVideo.style.zIndex = "10000";
          typesOfVideo.style.backgroundColor = "transparent";
          typesOfVideo.style.borderRadius = "0px";
          typesOfVideo.style.border = "0px";
          nextOnboardingStep(curr + 1);
          startVideoButton.removeEventListener("click", action);
        };

        startVideoButton.addEventListener("click", action);
      } else if (getWindowDimensions().width < 740) {
        navigate("/customer/orders/new-order");
      }
    } else {
      finishTutorial();
    }
  };

  const nextOnboardingStepPackage = async (currStep = null) => {
    const curr = currStep === null ? stepPackage : currStep;
    console.log(curr);
    if (curr !== 2) {
      const newStep = parseInt(`${curr}`) + 1;
      console.log(newStep);
      setStepPackage(newStep);
      setOnboardingStepPackage(
        onBoardingStepsPackagePlugin.currentStep(user, newStep)
      );

      if (newStep === 2 && getWindowDimensions().width > 740) {
        const siderBarItem = document.querySelector(
          `#sidebar-item-packages-customer`
        );
        siderBarItem.style.zIndex =
          "999999999999999999999999999999999999999999999999999999";
        siderBarItem.style.backgroundColor = "white";
        siderBarItem.style.borderRadius = "10px";
        siderBarItem.style.border = "2px #2a9df4 dashed";

        const action = () => {
          siderBarItem.style.zIndex = "10000";
          siderBarItem.style.backgroundColor = "transparent";
          siderBarItem.style.borderRadius = "0px";
          siderBarItem.style.border = "0px";
          nextOnboardingStepPackage(curr + 1);
          siderBarItem.removeEventListener("click", action);
          setPackageOnBoardingOpen(false);
        };

        siderBarItem.addEventListener("click", action);
      } else if (getWindowDimensions().width < 740) {
        setPackageOnBoardingOpen(false);
        navigate("/customer/packages");
      }
    } else {
      setPackageOnBoardingOpen(false);
    }
  };

  return user && user.firstName ? (
    <div className="customer-layout-main-container">
      {!user.onboarded && user.package ? (
        <div className="onbording">
          <div className="onboarding-content" id="onboarding-block">
            <span className="onboarding-title">{onboardingStep.title}</span>
            <span className="onboarding-text">{onboardingStep.text}</span>
            <div className="onboarding-buttons">
              {(step !== 3 &&
                step !== 4 &&
                step !== 5 &&
                getWindowDimensions().width > 740) ||
              getWindowDimensions().width < 740 ? (
                <button onClick={() => nextOnboardingStep()}>
                  {step < 6 ? "Next" : "Done"}
                </button>
              ) : null}
              {step < 6 ? (
                <button onClick={() => finishTutorial()} className="skip">
                  Skip
                </button>
              ) : null}
            </div>
            <div className="progress-bar">
              <div className={`ball${step === 1 ? " active" : ""}`}></div>
              <div className={`ball${step === 2 ? " active" : ""}`}></div>
              <div className={`ball${step === 3 ? " active" : ""}`}></div>
              <div className={`ball${step === 4 ? " active" : ""}`}></div>
              <div className={`ball${step === 5 ? " active" : ""}`}></div>
              <div className={`ball${step === 6 ? " active" : ""}`}></div>
            </div>
          </div>
        </div>
      ) : null}
      {!user.onboarded && !user.package && packageOnBoardingOpen ? (
        <div className="onbording">
          <div className="onboarding-content" id="onboarding-block">
            <span className="onboarding-title">
              {onboardingStepPackage.title}
            </span>
            <span className="onboarding-text">
              {onboardingStepPackage.text}
            </span>
            <div className="onboarding-buttons">
              {(stepPackage !== 2 && getWindowDimensions().width > 740) ||
              getWindowDimensions().width < 740 ? (
                <button onClick={() => nextOnboardingStepPackage()}>
                  {stepPackage < 2 ? "Next" : "Done"}
                </button>
              ) : null}
            </div>
            <div className="progress-bar">
              <div
                className={`ball${stepPackage === 1 ? " active" : ""}`}
              ></div>
              <div
                className={`ball${stepPackage === 2 ? " active" : ""}`}
              ></div>
            </div>
          </div>
        </div>
      ) : null}
      {newNotification && (
        <div
          className="new-notification"
          onClick={() => {
            if (notifications[0].link && notifications[0].link !== null) {
              navigate(`/customer${notifications[0].link}`);
            } else {
              navigate("/customer/inbox", {
                state: { chat: { data: { id: notifications[0].chatId } } },
              });
            }
          }}
        >
          <div className="notification">
            <div className="details">
              <div className="text">
                <span>
                  {notifications[0].link && notifications[0].link !== null
                    ? notifications[0].text
                    : "New Message From Mark Studios"}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMenuOpen && (
        <div className="menu-side">
          <div className="menu-container">
            <div className="menu-header">
              <img className="logo" src={Logo} />
              <div className="icon-menu" onClick={() => setIsMenuOpen(false)}>
                <AiOutlineClose />
              </div>
            </div>
            <div
              className="menu-profile"
              onClick={() => {
                setCurrentPage("profile");
                setCurrentPageName("Profile");
                setIsMenuOpen(false);
                navigate("./profile");
              }}
            >
              <Profile
                name={`${user.firstName} ${user.lastName}`}
                role="Customer"
                image={
                  user.profilePicture !== null
                    ? user.profilePicture.url
                    : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                }
              />
            </div>
            <div className="menu-body main">
              {sideBarItems.sideBarItemsCustomer.map((item) => {
                if (item.show) {
                  return (
                    <Link
                      onClick={() => {
                        setCurrentPage(item.id);
                        setCurrentPageName(item.name);
                        setIsMenuOpen(false);
                        setOrderTab("All Orders");
                      }}
                      to={`./${item.id}`}
                      className="item"
                    >
                      <span className="icon">{item.icon}</span>
                      <span>{item.name}</span>
                    </Link>
                  );
                }
              })}
            </div>
            <div className="menu-footer">
              <div className="menu-body">
                <div
                  className="item logout"
                  onClick={() => {
                    Cookies.remove("token");
                    navigate("/");
                  }}
                >
                  <span className="icon">
                    <BiLogOut />
                  </span>
                  <span>Logout</span>
                </div>
                <div className="terms-and-policy">
                  <span
                    onClick={() => {
                      navigate("/terms-of-service");
                    }}
                  >
                    Terms of Use
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isNotificationOpen && (
        <div className="notifications">
          <div className="notifications-container">
            <div className="notifications-header">
              <span className="title">Notifications</span>
              <div className="icon" onClick={() => openNotifications()}>
                <AiOutlineClose color="black" />
              </div>
            </div>
            <div className="notifications-body">
              {notifications && notifications.length
                ? notifications.map((notification) => {
                    return (
                      <div
                        className="notification"
                        onClick={() => {
                          if (notification.link && notification.link !== null) {
                            navigate(`/customer${notification.link}`);
                          } else {
                            navigate("/customer/inbox", {
                              state: {
                                chat: { data: { id: notification.chatId } },
                              },
                            });
                          }
                        }}
                      >
                        <div className="details">
                          <div className="text">
                            <span>
                              {notification.link && notification.link !== null
                                ? notification.text
                                : "New Message From Mark Studios"}
                              {notification.read ? "" : " - New"}
                            </span>
                          </div>
                          <span className="time">
                            {moment(new Date(notification.createdAt))
                              .tz(
                                window.Intl.DateTimeFormat().resolvedOptions()
                                  .timeZone
                              )
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                      </div>
                    );
                  })
                : "No Notifications"}
            </div>
          </div>
        </div>
      )}
      {getWindowDimensions().width > 950 && (
        <div className="sidebar">
          <SideBar
            notifications={notifications}
            type="customer"
            id={currentPage}
            goBackOrders={setOrderTab}
            changePage={setCurrentPage}
            changePageName={setCurrentPageName}
            setNotifications={setNotifications}
          />
        </div>
      )}
      <div className="content-container">
        <div className="header">
          {getWindowDimensions().width < 950 && (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="icon menu" onClick={() => setIsMenuOpen(true)}>
                <AiOutlineMenu />
              </div>
              {getWindowDimensions().width > 450 ? (
                <h1>{currentPageName}</h1>
              ) : (
                <h3>{currentPageName}</h3>
              )}
            </div>
          )}
          {getWindowDimensions().width > 950 && <h1>{currentPageName}</h1>}
          <div className="icons">
            {currentPage === "support" && getWindowDimensions().width > 450 && (
              <button
                onClick={() => {
                  orderService.chatWithAdmin("admin", null, (data) => {
                    navigate("./inbox", { state: { chat: data } });
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#2A9DF4",
                  padding: "1em 2em",
                  fontWeight: "500",
                  border: 0,
                  borderRadius: "25px",
                }}
              >
                Ask to Admin
              </button>
            )}
            {(currentPage === "orders" || currentPage === "dashboard") &&
              getWindowDimensions().width > 450 &&
              !window.location.pathname.split("/")[3] && (
                <button
                  onClick={() => navigate("./orders/new-order")}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "#2A9DF4",
                    padding: "1em 2em",
                    fontWeight: "500",
                    border: 0,
                    borderRadius: "25px",
                  }}
                >
                  + Create Order
                </button>
              )}
            {/* <div className="icon">
              <BiSearch />
            </div> */}
            <div
              className="icon notifications-icon"
              onClick={() => {
                setIsNotificationOpen(true);
              }}
            >
              {notifications.filter((item) => !item.read) &&
              notifications.filter((item) => !item.read).length ? (
                <div className="red-dot"></div>
              ) : null}
              <BiBell />
            </div>
            {getWindowDimensions().width > 450 && (
              <div
                className="profile"
                onClick={() => {
                  setCurrentPage("profile");
                  setCurrentPageName("Profile");
                  navigate("./profile");
                }}
              >
                <Profile
                  name={`${user.firstName} ${user.lastName}`}
                  role="Customer"
                  image={
                    user.profilePicture !== null
                      ? user.profilePicture.url
                      : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="content">
          <Outlet
            context={[
              askAdmin,
              setAskAdmin,
              setOrderTab,
              user,
              setUser,
              notifications,
              setNotifications,
              socket,
            ]}
          />
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerLayout;
