import "./Client.scss";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
} from "react-icons/ai";
import Youtube from "../../../../../assets/youtube.png";
import Facebook from "../../../../../assets/facebook.png";
import Instagram from "../../../../../assets/instagram.png";
import Twitter from "../../../../../assets/twitter.png";
import Tiktok from "../../../../../assets/tiktok.png";
import Linkedin from "../../../../../assets/linkedin.png";
import Snapchat from "../../../../../assets/snapchat.png";
import Website from "../../../../../assets/website.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { clientService } from "../../../../../services/client.service";
import Loading from "../../../../loading/Loading";
import { orderService } from "../../../../../services/order.service";
import { uploadService } from "../../../../../services/upload.service";
import { userService } from "../../../../../services/user.service";
import { BsCheck } from "react-icons/bs";
import moment from "moment-timezone";

const AdminClient = () => {
  const params = useParams();
  const [client, setClient] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBillingHistoryOpen, setIsBillingHistoryOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    clientService.getClientDetails(params.clientId, (data) => {
      console.log(data);
      setClient(data);
    });
  }, [params]);

  const changeAttribute = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(client));
    obj[attribute] = e.target.value;
    setClient(obj);
  };

  const changeImage = (attribute, data) => {
    const obj = JSON.parse(JSON.stringify(client));
    obj[attribute] = data;
    setClient(obj);
  };

  const changeAddress = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(client));
    if (e.target) {
      obj.billingAddress[attribute] = e.target.value;
    } else {
      obj.billingAddress[attribute] = e;
    }
    setClient(obj);
  };

  const changeBusinessAddress = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(client));
    if (e.target) {
      obj.business.address[attribute] = e.target.value;
    } else {
      obj.business.address[attribute] = e;
    }
    setClient(obj);
  };

  const changeBusinessAttribute = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(client));
    obj.business[attribute] = e.target.value;
    setClient(obj);
  };

  const networks = [
    {
      name: "Youtube",
      img: Youtube,
      data: "https://youtube.com/",
    },
    {
      name: "Facebook",
      img: Facebook,
      data: "https://facebook.com/",
    },
    {
      name: "Instagram",
      img: Instagram,
      data: "https://instagram.com/",
    },
    {
      name: "Twitter",
      img: Twitter,
      data: "https://twitter.com/",
    },
    {
      name: "Tiktok",
      img: Tiktok,
      data: "https://tiktok.com/",
    },
    {
      name: "Linkedin",
      img: Linkedin,
      data: "https://linkedin.com/",
    },
    {
      name: "Snapchat",
      img: Snapchat,
      data: "https://snapchat.com/",
    },
    {
      name: "Personal Website",
      img: Website,
      data: "https://",
    },
  ];

  const getTime = (minutes) => {
    return `${minutes >= 60 ? Math.floor(minutes / 60) : minutes} ${
      minutes >= 60 ? (minutes >= 120 ? "hours" : "hour") : "minutes"
    }`;
  };

  const formatCurrency = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return client && client.email ? (
    <div className="admin-layout-client-details-page">
      {isBillingHistoryOpen ? (
        <div
          className="billing-history-container"
          onClick={() => {
            setIsBillingHistoryOpen(false);
          }}
        >
          <div
            className="billing-history"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <AiOutlineClose
              className="billing-close"
              onClick={() => {
                setIsBillingHistoryOpen(false);
              }}
            />
            <span className="billing-history-title">Billing History</span>
            <div className="billing-history-content">
              <div className="single-bill header">
                <span>Type</span>
                <span>Amount</span>
                <span>Date</span>
              </div>
              <div className="bills">
                {client.bills.map((bill) => {
                  return (
                    <div className="single-bill">
                      <span>{bill.type}</span>
                      <span>${bill.amount}</span>
                      <span>
                        {moment(bill.createdAt)
                          .tz(
                            window.Intl.DateTimeFormat().resolvedOptions()
                              .timeZone
                          )
                          .format("MM/DD/YYYY, hh:mm a")}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="header-main">
        <Link
          to="../clients"
          className="link-back"
          style={{ textDecoration: "none" }}
        >
          <AiOutlineArrowLeft size={20} color="#2A9DF4" />
          <span>Back to Clients</span>
        </Link>
        {client.firstName ? (
          <div>
            <button
              onClick={() => {
                orderService.chatWithAdmin(null, client.id, (data) => {
                  navigate("../inbox", { state: { chat: data } });
                });
              }}
              className="chat"
            >
              Chat with Client
            </button>
            {edit ? (
              <button
                onClick={() => {
                  userService.updateUser(client, (data) => {
                    setEdit(false);
                    setClient(data);
                  });
                }}
                className="edit"
              >
                Save
              </button>
            ) : (
              <button onClick={() => setEdit(true)} className="edit">
                Edit
              </button>
            )}
          </div>
        ) : null}
      </div>
      <div className="profile-picture-info">
        {edit ? (
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            style={{ display: "none" }}
            id={`upload-profile-picture-profile-customer`}
            onChange={(e) => {
              let file = e.target.files[0];
              setLoading(true);

              if (file) {
                let data = new FormData();
                data.append("file", file);

                uploadService.upload(data, (data) => {
                  setLoading(false);
                  changeImage("profilePicture", data);
                });
              }
            }}
          />
        ) : null}
        <label
          for="upload-profile-picture-profile-customer"
          className="image-circle"
        >
          {/* <CircularProgressbar value={60} className="circular-level-bar" strokeWidth={2} counterClockwise={true}
                        styles={buildStyles({
                            trailColor: `#F2F5FA`,
                            pathColor: '#2A9DF4',
                            backgroundColor: 'white',
                        })} /> */}
          <div className="image">
            <img
              src={
                client.profilePicture
                  ? client.profilePicture.url
                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
              }
            />
          </div>
        </label>
        <span className="name">
          {client.firstName} {client.lastName}
        </span>
        <span className="title">Customer</span>
      </div>
      {client.package ? (
        <div className="package-info">
          <div className="package-header">
            <div className="name">
              <span className="name-title">
                {client.package.name === "Single" ||
                client.package.name === "Custom"
                  ? `${client.package.name} Order`
                  : `${client.package.name} Package`}{" "}
                - ${formatCurrency(client.package.price / 100)}
                {client.package.name === "Single"
                  ? ""
                  : client.package.type === "monthly"
                  ? "/mo"
                  : "/year"}
              </span>
              <span
                className="billing-history-span"
                onClick={() => {
                  navigate(`/admin/clients/billing-history/${client.id}`);
                }}
              >
                Billing History
              </span>
            </div>
            <div className="videos-wrapper-billing">
              <span className="videos">
                {client.package.videos} video
                {client.package.videos !== 1 ? "s" : ""}
                {client.package.name === "Single" ? "" : " per month"}
              </span>
              <span
                className="billing-history-span"
                onClick={() => {
                  navigate(`/admin/clients/order-history/${client.id}`);
                }}
              >
                Order History
              </span>
            </div>
          </div>
          <div className="package-features">
            <div className="feature">
              <BsCheck size={25} color="#2A9DF4"></BsCheck>
              {client.package.runningVideo ? (
                <span>
                  Up to {getTime(client.package.runningVideo)} of running time
                  per video
                </span>
              ) : (
                <span>Unlimited running video</span>
              )}
            </div>
            <div className="feature">
              <BsCheck size={25} color="#2A9DF4"></BsCheck>
              {client.package.rawFootage ? (
                <span>
                  Up to {getTime(client.package.rawFootage)} of raw provided
                  footage per video
                </span>
              ) : (
                <span>Unlimited provided footage</span>
              )}
            </div>
            <div className="feature">
              <BsCheck size={25} color="#2A9DF4"></BsCheck>
              <span>
                {client.package.deliveryTime - 1}-{client.package.deliveryTime}{" "}
                days delivery guaranteed
              </span>
            </div>
            {client.package.thumbnails === null ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>Unlimited Thumbnails</span>
              </div>
            ) : client.package.thumbnails > 0 ||
              client.package.additionalThumbnails > 0 ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>
                  {(client.package.thumbnails ? client.package.thumbnails : 0) +
                    (client.package.additionalThumbnails
                      ? client.package.additionalThumbnails
                      : 0)}{" "}
                  Thumbnails
                </span>
              </div>
            ) : null}
            {client.package.intro === null ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>Unlimited Intros</span>
              </div>
            ) : client.package.intro > 0 ||
              client.package.additionalIntro > 0 ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>
                  {(client.package.intro ? client.package.intro : 0) +
                    (client.package.additionalIntro
                      ? client.package.additionalIntro
                      : 0)}{" "}
                  Intro
                </span>
              </div>
            ) : null}
            {client.package.outro === null ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>Unlimited Outros</span>
              </div>
            ) : client.package.outro > 0 ||
              client.package.additionalOutro > 0 ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>
                  {(client.package.outro ? client.package.outro : 0) +
                    (client.package.additionalOutro
                      ? client.package.additionalOutro
                      : 0)}{" "}
                  Outro
                </span>
              </div>
            ) : null}
            {client.package.banner === null ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>Unlimited Banners</span>
              </div>
            ) : client.package.banner > 0 ||
              client.package.additionalBanner > 0 ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>
                  {(client.package.banner ? client.package.banner : 0) +
                    (client.package.additionalBanner
                      ? client.package.additionalBanner
                      : 0)}{" "}
                  Banner
                </span>
              </div>
            ) : null}
            {client.package.logo === null ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>Unlimited Logos</span>
              </div>
            ) : client.package.logo > 0 || client.package.additionalLogo > 0 ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>
                  {(client.package.logo ? client.package.logo : 0) +
                    (client.package.additionalLogo
                      ? client.package.additionalLogo
                      : 0)}{" "}
                  Logo
                </span>
              </div>
            ) : null}
            {client.package.consultationCall === null ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>Unlimited Consultation Calls</span>
              </div>
            ) : client.package.consultationCall > 0 ||
              client.package.additionalConsultationCall > 0 ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>
                  {(client.package.consultationCall
                    ? client.package.consultationCall
                    : 0) +
                    (client.package.additionalConsultationCall
                      ? client.package.additionalConsultationCall
                      : 0)}{" "}
                  15-min Consultation Call
                </span>
              </div>
            ) : null}
            {client.package.unlimitedStockFootage ? (
              <div className="feature">
                <BsCheck size={25} color="#2A9DF4"></BsCheck>
                <span>Unlimited Stock Footage</span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      <div className="mainInfo">
        <div className="name">
          <div className="item">
            <span className="title">First Name</span>
            <div className="input">
              {/* <span>Enter First Name</span> */}
              <input
                value={client.firstName}
                onChange={(e) => changeAttribute("firstName", e)}
              />
            </div>
          </div>
          <div className="item">
            <span className="title">Last Name</span>
            <div className="input">
              {/* <span>Enter Last Name</span> */}
              <input
                value={client.lastName}
                onChange={(e) => changeAttribute("lastName", e)}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <div className="item">
            <span className="title">Email</span>
            <div className="input">
              {/* <span>Enter Email</span> */}
              <input
                value={client.email}
                onChange={(e) => changeAttribute("email", e)}
              />
            </div>
          </div>
          <div className="item">
            <span className="title">Phone Number</span>
            <div className="input">
              {/* <span>Enter Phone Number</span> */}
              <input
                value={client.phoneNumber}
                onChange={(e) => changeAttribute("phoneNumber", e)}
              />
            </div>
          </div>
        </div>
        {client.business ? (
          <div className="name">
            <div className="item" style={{ marginLeft: 0 }}>
              <span className="title">Business Name</span>
              <div className="input">
                {/* <span>Enter Business Name</span> */}
                <input
                  value={client.business.name}
                  onChange={(e) => changeBusinessAttribute("name", e)}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {client.business ? (
        <div className="business-adress">
          <span className="title">Business Address</span>
          <div className="field">
            <div className="input">
              <span>Street</span>
              <input
                value={client.business.address.street}
                onChange={(e) => changeBusinessAddress("street", e)}
              />
            </div>
            <div className="input">
              <span>App/Suite #</span>
              <input
                value={client.business.address.complement}
                onChange={(e) => changeBusinessAddress("complement", e)}
              />
            </div>
          </div>
          <div className="field">
            {client.business.addresscountry ? (
              <div className="input">
                <span>Country</span>
                <input
                  value={client.business.address.country}
                  onChange={(e) => changeBusinessAddress("country", e)}
                />
              </div>
            ) : null}
            <div className="input">
              <span>Zip Code</span>
              <input
                value={client.business.address.zipCode}
                onChange={(e) => changeBusinessAddress("zipCode", e)}
              />
            </div>
          </div>
          <div className="field">
            <div className="input">
              <span>State</span>
              <input
                value={client.business.address.state}
                onChange={(e) => changeBusinessAddress("state", e)}
              />
            </div>
            <div className="input">
              <span>City</span>
              <input
                value={client.business.address.city}
                onChange={(e) => changeBusinessAddress("city", e)}
              />
            </div>
          </div>
        </div>
      ) : null}
      {client.business ? (
        <div className="bio">
          <span className="title">Company Bio</span>
          <input
            value={client.business.bio}
            onChange={(e) => changeBusinessAttribute("bio", e)}
          />
        </div>
      ) : null}
      {client.billingAddress ? (
        <div className="business-adress">
          <span className="title">Billing Address</span>
          <div className="field">
            <div className="input">
              <span>Street</span>
              <input
                value={client.billingAddress.street}
                onChange={(e) => changeAddress("street", e)}
              />
            </div>
            <div className="input">
              <span>App/Suite #</span>
              <input
                value={client.billingAddress.complement}
                onChange={(e) => changeAddress("complement", e)}
              />
            </div>
          </div>
          <div className="field">
            <div className="input">
              <span>Country</span>
              <input
                value={client.billingAddress.country}
                onChange={(e) => changeAddress("country", e)}
              />
            </div>
            <div className="input">
              <span>Zip Code</span>
              <input
                value={client.billingAddress.zipCode}
                onChange={(e) => changeAddress("zipCode", e)}
              />
            </div>
          </div>
          <div className="field">
            <div className="input">
              <span>State</span>
              <input
                value={client.billingAddress.state}
                onChange={(e) => changeAddress("state", e)}
              />
            </div>
            <div className="input">
              <span>City</span>
              <input
                value={client.billingAddress.city}
                onChange={(e) => changeAddress("city", e)}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className="bio">
        <span className="title">Personal Bio</span>
        <input value={client.bio} onChange={(e) => changeAttribute("bio", e)} />
      </div>
      <div className="networking-info">
        <span className="title">Networking Info</span>
        <div className="networks">
          {networks.map((network) => {
            if (
              client.socialMedias.filter(
                (net) => net.type === network.name.toLowerCase()
              ) &&
              client.socialMedias.filter(
                (net) => net.type === network.name.toLowerCase()
              ).length
            ) {
              return client.socialMedias.filter(
                (net) => net.type === network.name.toLowerCase()
              )[0].account ? (
                <div className="social-network">
                  <img src={network.img} />
                  <span style={{ marginLeft: ".5em" }}>{network.name}</span>
                  <div
                    className="arrow"
                    onClick={() => {
                      window
                        .open(
                          !client.socialMedias
                            .filter(
                              (net) => net.type === network.name.toLowerCase()
                            )[0]
                            .account.includes("https://")
                            ? `${network.data}${
                                client.socialMedias.filter(
                                  (net) =>
                                    net.type === network.name.toLowerCase()
                                )[0].account
                              }`
                            : client.socialMedias.filter(
                                (net) => net.type === network.name.toLowerCase()
                              )[0].account,
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <AiOutlineArrowRight />
                  </div>
                </div>
              ) : null;
            }
          })}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default AdminClient;
