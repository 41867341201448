import "./AddAddOns.scss";
import Video from "../../../../../assets/video-types/video.png";
import Thumbnail from "../../../../../assets/video-types/thumbnail.png";
import Banner from "../../../../../assets/video-types/banner.png";
import Logo from "../../../../../assets/video-types/logo.png";
import ConsultationCall from "../../../../../assets/video-types/consultation-call.png";
import Outro from "../../../../../assets/video-types/outro.png";
import Intro from "../../../../../assets/video-types/intro.png";
import UnlimitedStock from "../../../../../assets/video-types/unlimited-stock.png";
import { useLocation, useNavigate } from "react-router";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import Loading from "../../../../loading/Loading";
import Swal from "sweetalert2";

const CustomerAddAddOns = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [counterLogo, setCounterLogo] = useState(0);
  const [counterThumbnail, setCounterThumbnail] = useState(0);
  const [counterBanner, setCounterBanner] = useState(0);
  const [counterConsultationCall, setCounterConsultationCall] = useState(0);
  const [counterOutro, setCounterOutro] = useState(0);
  const [counterIntro, setCounterIntro] = useState(0);
  const [unlimitedStockFootage, setUnlimitedStockFootage] = useState(false);
  const [types, setTypes] = useState([]);
  const [order, setOrder] = useState({
    thumbnails: 0,
    banner: 0,
    consultationCall: 0,
    outro: 0,
    intro: 0,
  });

  useEffect(() => {
    let arr = [
      {
        name: "Unlimited Stock Footage With Every Order",
        price: "15/month",
        image: UnlimitedStock,
      },
      {
        name: "Logo",
        image: Logo,
        price: 15,
        setCounter: setCounterLogo,
        counter: counterLogo,
      },
    ];

    arr.push({
      name: "Thumbnail",
      image: Thumbnail,
      price: 15,
      setCounter: setCounterThumbnail,
      counter: counterThumbnail,
    });

    arr = [
      ...arr,
      {
        name: "Banner Design",
        image: Banner,
        price: 25,
        setCounter: setCounterBanner,
        counter: counterBanner,
      },
      {
        name: "Consultation Call",
        image: ConsultationCall,
        price: 15,
        setCounter: setCounterConsultationCall,
        counter: counterConsultationCall,
      },
      {
        name: "Outro",
        image: Outro,
        price: 70,
        setCounter: setCounterOutro,
        counter: counterOutro,
      },
      {
        name: "Intro",
        image: Intro,
        price: 60,
        setCounter: setCounterIntro,
        counter: counterIntro,
      },
    ];

    setTypes(arr);
  }, [
    counterBanner,
    counterConsultationCall,
    counterIntro,
    counterLogo,
    counterOutro,
    counterThumbnail,
  ]);

  const finishOrder = () => {
    if (
      counterBanner > 0 ||
      counterThumbnail > 0 ||
      counterConsultationCall > 0 ||
      counterIntro > 0 ||
      counterOutro > 0 ||
      unlimitedStockFootage
    ) {
      const item = {
        thumbnails: counterThumbnail,
        banner: counterBanner,
        consultationCall: counterConsultationCall,
        outro: counterOutro,
        intro: counterIntro,
        unlimitedStockFootage,
        price:
          counterThumbnail * 15 * 100 +
          counterBanner * 25 * 100 +
          counterConsultationCall * 15 * 100 +
          counterOutro * 70 * 100 +
          counterIntro * 60 * 100 +
          unlimitedStockFootage
            ? 1500
            : 0,
      };

      navigate("../add-ons-summary", { state: { order: item } });
    } else {
      Swal.fire({
        title: "Error!",
        text: "Please add an add-on to continue",
        heightAuto: false,
        icon: "error",
      });
    }
  };

  return types && types.length ? (
    <div className="customer-layout-add-ons-page">
      <span className="add-on-title">Choose your Add-ons</span>
      <div className="single-order-content">
        {!state.user.package.unlimitedStockFootage ? (
          <div className="item special">
            <div className="details">
              <img src={types[0].image} />
              <div className="text">
                <span>{types[0].name}</span>
                <span>${types[0].price}</span>
              </div>
            </div>
            <input
              type="checkbox"
              value={unlimitedStockFootage}
              onChange={(e) => setUnlimitedStockFootage(e.target.checked)}
            />
          </div>
        ) : null}
        <span className="sub-title-single">Buy Additional Items</span>
        <div className="items">
          {types.slice(2).map((type) => {
            return (
              <div className="item">
                <div className="details">
                  <img src={type.image} />
                  <div className="text">
                    <span>{type.name}</span>
                    <span>${type.price}</span>
                  </div>
                </div>
                <div className="counter">
                  <IoIosRemoveCircle
                    onClick={() => {
                      if (type.counter > 0) {
                        type.setCounter(type.counter - 1);
                      }
                    }}
                    size={25}
                    color="#2A9DF4"
                    className={type.counter === 0 ? "disabled" : ""}
                  />
                  <span>{type.counter}</span>
                  <IoIosAddCircle
                    onClick={() => {
                      type.setCounter(type.counter + 1);
                    }}
                    size={25}
                    color="#2A9DF4"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="footer">
        <button onClick={() => finishOrder()}>Continue</button>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerAddAddOns;
