import { useState, useEffect } from "react";
import "./ConsultationCall.scss";

const ConsultationCall = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
}) => {
  const [discussionTopic, setDiscussionTopic] = useState("");
  const [concerns, setConcerns] = useState("");
  const [goal, setGoal] = useState("");

  useEffect(() => {
    if (order.consultationCall) {
      if (
        order.consultationCall.discussionTopic &&
        order.consultationCall.discussionTopic !== null
      ) {
        setDiscussionTopic(order.consultationCall.discussionTopic);
      }

      if (
        order.consultationCall.concerns &&
        order.consultationCall.concerns !== null
      ) {
        setConcerns(order.consultationCall.concerns);
      }

      if (order.consultationCall.goal && order.consultationCall.goal !== null) {
        setGoal(order.consultationCall.goal);
      }
    }
  }, [order]);

  const changeStep = (isStepNext) => {
    if (isStepNext) {
      if (validateFields()) {
        changeComponent("consultationCall", {
          discussionTopic,
          concerns,
          goal,
        });

        nextStep();
      } else {
        validate("Please check that you added all required information");
      }
    } else {
      prevStep();
    }
  };

  const validateFields = () => {
    if (!discussionTopic || discussionTopic.trim() === "") {
      return false;
    }

    if (!concerns || concerns.trim() === "") {
      return false;
    }

    if (!goal || goal.trim() === "") {
      return false;
    }

    return true;
  };
  return (
    <div className="consultation-call-new-order">
      <div className="inner-content">
        <div className="item">
          <span className="title-item">
            What would you like to discuss on the call? *
          </span>
          <input
            value={discussionTopic}
            onChange={(e) => setDiscussionTopic(e.target.value)}
            placeholder="Enter text"
          />
        </div>
        <div className="item">
          <span className="title-item">What are your concerns? *</span>
          <input
            value={concerns}
            onChange={(e) => setConcerns(e.target.value)}
            placeholder="Enter text"
          />
        </div>
        <div className="item">
          <span className="title-item">What are your goals? *</span>
          <input
            value={goal}
            onChange={(e) => setGoal(e.target.value)}
            placeholder="Enter text"
          />
        </div>
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default ConsultationCall;
