import "react-circular-progressbar/dist/styles.css";
import "./LatestOrders.scss";
import { useNavigate } from "react-router";
import moment, { min } from "moment";
import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { reference } from "../../ordersReference";
import formatTimeStamp from "../../../utils/formatTimeStamp";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const LatestOrders = ({ orders }) => {
  const navigate = useNavigate();

  const formatTime = (time) => {
    return formatTimeStamp(time);
  };

  return (
    <div className="latest-orders-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            titles={["Name", "Order Id", "Status", "Delivery", "Delivery Date"]}
            customStyle="1fr 1fr 1fr 1fr 1fr"
          />
          <div className="order-table">
            <TableRow
              customStyle="1fr 1fr 1fr 1fr 1fr"
              click={(order) => navigate(`../orders/${order.id}`)}
              items={orders.map((order) => {
                return {
                  order,
                  items: [
                    <span style={{ fontWeight: 500, fontSize: ".7em" }}>
                      {order.basicDetails
                        ? order.basicDetails.title
                        : `Order #${order.number}`}
                    </span>,
                    <span style={{ color: "#A0AEC7", fontSize: ".7em" }}>
                      #{order.number}
                    </span>,
                    <div className={`status ${order.status}`}>
                      <span>
                        {
                          reference.filter(
                            (item) => item.status === order.status
                          )[0].name
                        }
                      </span>
                    </div>,
                    order.status !== "completed" ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            marginRight: ".3em",
                            width: "5px",
                            height: "5px",
                            borderRadius: "100%",
                            backgroundColor:
                              order.delivery > 1440
                                ? "#00D097"
                                : order.delivery > 60
                                ? "#FFB756"
                                : "#DA4747",
                          }}
                        ></div>
                        <span style={{ fontSize: "0.7em" }}>
                          {formatTime(order.deadline)}
                        </span>
                      </div>
                    ) : (
                      <span style={{ fontSize: "0.7em" }}>-</span>
                    ),
                    <span style={{ fontSize: "0.7em" }}>
                      {new Date(order.deadline).toLocaleDateString()}
                    </span>,
                  ],
                };
              })}
            />
          </div>
        </>
      ) : (
        <>
          <>
            {orders.map((order, index) => {
              return (
                <div
                  className="order-mobile"
                  onClick={(order) => navigate(`../orders/${order.id}`)}
                >
                  <div className="header-mobile">
                    <div className="details">
                      <span className="name">
                        {order.basicDetails
                          ? order.basicDetails.title
                          : `Order #${order.number}`}
                      </span>
                      <span className="number">#{order.number}</span>
                    </div>
                  </div>
                  <div className="body-mobile">
                    <div className={`status ${order.status}`}>
                      <span>
                        {
                          reference.filter(
                            (item) => item.status === order.status
                          )[0].name
                        }
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </>
      )}
    </div>
  );
};

export default LatestOrders;
