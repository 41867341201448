import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import formatTimeStamp from "../../../utils/formatTimeStamp";
import "react-circular-progressbar/dist/styles.css";
import "./AllOrders.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
  AiOutlineDown,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate } from "react-router";
import { reference } from "../../ordersReference";
import { orderService } from "../../../services/order.service";
import { createHTMLOrderPrint } from "../../setItemsPrint";
import moment from "moment-timezone";
import LoadingUpload from "../../../views/loadingUpload/LoadingUpload";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AllOrders = ({
  orders,
  getOrders,
  ordersCount,
  user,
  allUsers,
  setOrders,
  setOrdersCount,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [acceptOrder, setAcceptOrder] = useState(false);
  const [acceptOrderId, setAcceptOrderId] = useState(false);
  const [choseEditorId, setChoseEditorId] = useState(0);
  const [usersDisplayed, setUsersDisplayed] = useState([]);
  const [traferring, setTransferring] = useState(false);
  const [editorPay, setEditorPay] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingOrderStatus, setLoadingOrderStatus] = useState(false);

  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    searchUsers("");
  }, [allUsers]);

  const searchUsers = (search) => {
    const arr = [...allUsers];
    setUsersDisplayed(
      arr.filter(
        (user) =>
          user.firstName.includes(search) || user.lastName.includes(search)
      )
    );
  };

  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const downloadTxtFile = (order) => {
    orderService.getDetails(order.id, (order) => {
      const statusName = reference.filter(
        (item) => item.status === order.status
      )[0].name;
      const tableItems = createHTMLOrderPrint(order);

      const list = [
        `"Order Id": #${order.number}`,
        "Order Name: " + order.name,
        `Created at: ${moment(order.createdAt)
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("DD MMMM YYYY")}`,
        `Deadline at: ${moment(order.deadline)
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("DD MMMM YYYY")}`,
        "Status: " + statusName + "\n",
        "Delivery Items:",
        tableItems.map((tableItem, index) => {
          if (tableItem.shown) {
            return `\n\n${tableItem.name}:${tableItem.item.map((item) => {
              return `\n- ${item}`;
            })}`;
          } else {
            return "\n";
          }
        }),
      ];
      const element = document.createElement("a");
      const file = new Blob([list.join("\n")], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `invoice_${order.number}.txt`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    });
  };

  const goToPage = (order) => {
    navigate(`./${order.id}`);
  };

  const formatTime = (time) => {
    return formatTimeStamp(time);
  };

  const updateOrders = (page) => {
    const filters = { admin: true };
    filters.type = "progress";
    filters.listAll = true;
    orderService.listOrders(page, filters, (data) => {
      setOrders(data.orders);
      setOrdersCount(data.count);
    });
  };

  const updateOrderStatus = (order, newStatus, page) => {
    if (user.type !== "admin") {
      return;
    }
    setLoading(true);
    const filters = { admin: true };
    filters.type = "progress";
    filters.listAll = true;

    orderService.updateOrderStatus(order.id, newStatus, (data) => {
      orderService.listOrders(page, filters, (data) => {
        setOrders(data.orders);
        setOrdersCount(data.count);
        setLoading(false);
      });
    });
  };

  return (
    <div className="all-orders-component">
      {loading ? <LoadingUpload text="Updating status" /> : null}
      {loadingOrderStatus ? <LoadingUpload text="Updating order" /> : null}

      {acceptOrder ? (
        <div className="editors-modal">
          <div className="editors-modal-component">
            <span className="title">Choose your editor</span>
            <input
              onChange={(e) => {
                searchUsers(e.target.value);
              }}
              placeholder="Search Editor"
            />
            <input
              onChange={(e) => {
                setEditorPay(e.target.value);
              }}
              type="number"
              placeholder="Editor's Pay"
            />
            <div className="editors">
              {usersDisplayed.map((user) => {
                return (
                  <div
                    className={`editor ${
                      user.id === choseEditorId ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setChoseEditorId(user.id);
                    }}
                  >
                    <div className="image-container">
                      <img
                        src={
                          user.profilePicture
                            ? user.profilePicture.url
                            : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                        }
                      />
                    </div>
                    <span>
                      {user.firstName} {user.lastName}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="actions">
              <button
                className="cancel"
                onClick={() => {
                  setAcceptOrder(false);
                  setAcceptOrderId(0);
                  setChoseEditorId(0);
                  setEditorPay(0);
                  setTransferring(false);
                }}
              >
                Cancel
              </button>
              <button
                className="create"
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    choseEditorId &&
                    choseEditorId !== null &&
                    choseEditorId !== 0 &&
                    editorPay > 0
                  ) {
                    !traferring
                      ? orderService.changeAcceptance(
                          choseEditorId,
                          true,
                          acceptOrderId,
                          editorPay,
                          (data) => {
                            Swal.fire({
                              title: "Order accepted successfully",
                              heightAuto: false,
                              icon: "success",
                            }).then(() => {
                              updateOrders(1);
                              setAcceptOrder(false);
                              setAcceptOrderId(0);
                              setChoseEditorId(0);
                              setEditorPay(0);
                            });
                          }
                        )
                      : orderService.transferOrder(
                          choseEditorId,
                          acceptOrderId,
                          (data) => {
                            Swal.fire({
                              title: "Order transfered successfully",
                              heightAuto: false,
                              icon: "success",
                            }).then(() => {
                              updateOrders(1);
                              setAcceptOrder(false);
                              setAcceptOrderId(0);
                              setChoseEditorId(0);
                              setTransferring(false);
                            });
                          }
                        );
                  } else {
                    Swal.fire({
                      title: "Error!",
                      text: "Please choose an editor and add their payment",
                      heightAuto: false,
                      icon: "error",
                    });
                  }
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            titles={[
              "Name",
              "Client",
              "Time Estimate",
              "Delivery Date",
              "Date Placed",
              "Editor",
              "Status",
              "Order Id",
              "",
            ]}
            customStyle={
              user.type === "admin"
                ? "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                : " 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0fr"
            }
          />
          <div className="order-table">
            <TableRow
              customStyle={
                user.type === "admin"
                  ? "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                  : " 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0fr"
              }
              click={goToPage}
              items={currentOrders.map((order, index) => {
                return {
                  order,
                  items: [
                    <span
                      style={{ fontWeight: 500 }}
                      onClick={() => navigate(`./${order.id}`)}
                    >
                      {order.basicDetails
                        ? order.basicDetails.title
                        : `Order #${order.number}`}
                    </span>,
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: ".3em",
                      }}
                    >
                      <div
                        style={{
                          height: "30px",
                          width: "30px",
                          overflow: "hidden",
                          borderRadius: "100%",
                          backgroundColor: "rgb(230,230,230)",
                        }}
                      >
                        {order.client && order.client.profilePicture ? (
                          <img
                            src={order.client.profilePicture.url}
                            style={{ height: "100%" }}
                            alt="profile"
                          />
                        ) : (
                          <img
                            alt="profile"
                            src="https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                            style={{ height: "100%" }}
                          />
                        )}
                      </div>
                      <span style={{ flex: 1, fontSize: ".9em" }}>
                        {order.client
                          ? `${order.client.firstName} ${order.client.lastName}`
                          : ""}
                      </span>
                    </div>,
                    <span>
                      {order.status === "completed" ||
                      order.status === "cancelled"
                        ? "-"
                        : formatTime(order.deadline)}
                    </span>,
                    <span>
                      {moment(order.deadline)
                        .tz(
                          window.Intl.DateTimeFormat().resolvedOptions()
                            .timeZone
                        )
                        .format("DD MMMM YYYY")}
                    </span>,
                    <span>
                      {moment(order.createdAt)
                        .tz(
                          window.Intl.DateTimeFormat().resolvedOptions()
                            .timeZone
                        )
                        .format("DD MMMM YYYY")}
                    </span>,
                    <span>
                      {/* {order.status !== "completed"
                      ? formatTimeMinutes(
                          new Date().getTime() -
                            new Date(order.createdAt).getTime()
                        )
                      : formatTimeMinutes(
                          new Date(order.deliveredAt).getTime() -
                            new Date(order.createdAt).getTime()
                        )} */}
                      {order.editor && order.editor !== null
                        ? `${order.editor.firstName} ${order.editor.lastName}`
                        : "Not Assigned Yet"}
                    </span>,

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <div
                        className={`status ${order.status}`}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const obj = currentOrders[index];
                          obj.statusOpen = !obj.statusOpen;
                          setCurrentOrders([
                            ...currentOrders.slice(0, index),
                            obj,
                            ...currentOrders.slice(index + 1),
                          ]);
                        }}
                      >
                        <span>
                          {
                            reference.filter(
                              (item) => item.status === order.status
                            )[0].name
                          }
                        </span>
                      </div>
                      {order.statusOpen && (
                        <div
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            position: "absolute",
                            top: 40,
                            right: 0,
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow: "0 6px 40px 0 #7991AD4D",
                            padding: ".6em",
                            zIndex: "10",
                          }}
                        >
                          {reference.map((item) => {
                            return (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateOrderStatus(order, item.status, 1);
                                  const obj = currentOrders[index];
                                  obj.statusOpen = !obj.statusOpen;
                                  setCurrentOrders([
                                    ...currentOrders.slice(0, index),
                                    obj,
                                    ...currentOrders.slice(index + 1),
                                  ]);
                                }}
                                style={{
                                  fontWeight: "600",
                                  padding: "0 .5em",
                                  backgroundColor: "#F4F9FD",
                                  height: "30px",
                                  flexWrap: "nowrap",
                                  whiteSpace: "nowrap",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                  fontSize: ".8em",
                                  borderRadius: "7px",
                                  cursor: "pointer",
                                  width: "95%",
                                }}
                              >
                                {item.name}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>,

                    order.status !== "new" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignContent: "center",
                          alignItems: "stretch",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ color: "#A0AEC7", fontSize: ".8em" }}>
                          #{order.number}
                        </span>
                        {order.status === "complete" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {new Array(order.star).fill().map((_) => (
                              <AiFillStar color="#F1C40F" />
                            ))}
                            {new Array(5 - order.star).fill().map((_) => (
                              <AiFillStar color="#E0E0E0" />
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setAcceptOrderId(order.id);
                            setAcceptOrder(true);
                          }}
                          style={{
                            cursor: "pointer",
                            padding: ".7em 1.5em",
                            fontSize: ".8em",
                            color: "white",
                            fontWeight: "500",
                            border: 0,
                            borderRadius: "20px",
                            backgroundColor: "#2A9DF4",
                          }}
                        >
                          Accept
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            Swal.fire({
                              title: "Warning!",
                              text: "Are you sure you want to deny this order?",
                              heightAuto: false,
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: "Confirm",
                              cancelButtonText: "Cancel",
                            }).then(async (response) => {
                              if (response.isConfirmed) {
                                setLoadingOrderStatus(true);
                                await orderService.changeAcceptance(
                                  null,
                                  false,
                                  order.id,
                                  editorPay,
                                  (data) => {
                                    Swal.fire({
                                      title: "Order declined successfully",
                                      heightAuto: false,
                                      icon: "success",
                                    });
                                    updateOrders(1);
                                    setLoadingOrderStatus(false);
                                  }
                                );
                              }
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            padding: ".7em 1.5em",
                            fontSize: ".8em",
                            color: "white",
                            fontWeight: "500",
                            border: 0,
                            borderRadius: "20px",
                            backgroundColor: "#E74C3C",
                          }}
                        >
                          Deny
                        </button>
                      </div>
                    ),
                    user.type === "admin" ? (
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          position: "relative",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            const obj = currentOrders[index];
                            obj.open = !obj.open;
                            setCurrentOrders([
                              ...currentOrders.slice(0, index),
                              obj,
                              ...currentOrders.slice(index + 1),
                            ]);
                          }}
                          style={{
                            position: "absolute",
                            backgroundColor: "#F2F5FA",
                            width: "30px",
                            height: "30px",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: "1.2em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          <AiOutlineMore />
                        </div>
                        {order.open && (
                          <div
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "white",
                              position: "absolute",
                              top: 40,
                              right: 0,
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "space-between",
                              boxShadow: "0 6px 40px 0 #7991AD4D",
                              padding: ".3em",
                            }}
                          >
                            <div
                              onClick={() => navigate(`./${order.id}`)}
                              style={{
                                fontWeight: "600",
                                padding: "0 .5em",
                                marginRight: ".5em",
                                backgroundColor: "#F4F9FD",
                                height: "30px",
                                flexWrap: "nowrap",
                                whiteSpace: "nowrap",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                fontSize: ".8em",
                                borderRadius: "7px",
                                cursor: "pointer",
                              }}
                            >
                              Show More
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                downloadTxtFile(order);
                              }}
                              style={{
                                backgroundColor: "#F4F9FD",
                                width: "30px",
                                height: "30px",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                fontSize: "1.2em",
                                borderRadius: "7px",
                                cursor: "pointer",
                              }}
                            >
                              <AiOutlinePrinter />
                            </div>
                            {user.type === "admin" ? (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  orderService.cancelOrder(order.id, (data) => {
                                    updateOrders(1);
                                  });
                                }}
                                style={{
                                  marginLeft: ".5em",
                                  backgroundColor: "#F6516023",
                                  width: "30px",
                                  height: "30px",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                  fontSize: "1.2em",
                                  borderRadius: "7px",
                                  cursor: "pointer",
                                }}
                              >
                                <FiTrash2 color="#E74C3C" />
                              </div>
                            ) : null}
                            {user.type === "admin" &&
                            order.editor &&
                            order.editor !== null ? (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setAcceptOrder(true);
                                  setTransferring(true);
                                  setAcceptOrderId(order.id);
                                }}
                                style={{
                                  whiteSpace: "nowrap",
                                  marginLeft: ".5em",
                                  cursor: "pointer",
                                  padding: ".7em 1.5em",
                                  fontSize: ".8em",
                                  fontWeight: "500",
                                  border: 0,
                                  borderRadius: "20px",
                                  backgroundColor: "#F39C12",
                                }}
                              >
                                Transfer to another editor
                              </button>
                            ) : null}
                          </div>
                        )}
                      </div>
                    ) : null,
                  ],
                };
              })}
            />
          </div>
        </>
      ) : (
        <>
          {currentOrders.map((order, index) => {
            return (
              <div
                className="order-mobile"
                onClick={() => navigate(`./${order.id}`)}
              >
                <div className="header-mobile">
                  <div className={`status ${order.status}`}>
                    <span>
                      {
                        reference.filter(
                          (item) => item.status === order.status
                        )[0].name
                      }
                    </span>
                  </div>
                  <div className="details">
                    <span className="name">
                      {order.basicDetails
                        ? order.basicDetails.title
                        : `Order #${order.number}`}
                    </span>
                    <span className="number">#{order.number}</span>
                  </div>
                </div>
                {user.type === "admin" ? (
                  <div className="body-mobile">
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        position: "relative",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          const obj = currentOrders[index];
                          obj.open = !obj.open;
                          setCurrentOrders([
                            ...currentOrders.slice(0, index),
                            obj,
                            ...currentOrders.slice(index + 1),
                          ]);
                        }}
                        style={{
                          position: "absolute",
                          backgroundColor: "#F2F5FA",
                          width: "30px",
                          height: "30px",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "1.2em",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                      >
                        <AiOutlineMore />
                      </div>
                      {order.open && (
                        <div
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            position: "absolute",
                            top: 40,
                            right: 0,
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow: "0 6px 40px 0 #7991AD4D",
                            padding: ".3em",
                          }}
                        >
                          {user.type === "admin" &&
                          order.editor &&
                          order.editor !== null ? (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setAcceptOrder(true);
                                setTransferring(true);
                                setAcceptOrderId(order.id);
                              }}
                              style={{
                                whiteSpace: "nowrap",
                                marginLeft: ".5em",
                                cursor: "pointer",
                                padding: ".7em 1.5em",
                                fontSize: ".8em",
                                fontWeight: "500",
                                border: 0,
                                borderRadius: "20px",
                                backgroundColor: "#F39C12",
                              }}
                            >
                              Transfer to another editor
                            </button>
                          ) : null}
                          <div
                            onClick={() => downloadTxtFile(order)}
                            style={{
                              backgroundColor: "#F4F9FD",
                              width: "30px",
                              height: "30px",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "1.2em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            <AiOutlinePrinter />
                          </div>
                          {user.type === "admin" ? (
                            <div
                              onClick={() => {
                                orderService.cancelOrder(order.id, (data) => {
                                  updateOrders(1);
                                });
                              }}
                              style={{
                                marginLeft: ".5em",
                                backgroundColor: "#F6516023",
                                width: "30px",
                                height: "30px",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                fontSize: "1.2em",
                                borderRadius: "7px",
                                cursor: "pointer",
                              }}
                            >
                              <FiTrash2 color="#E74C3C" />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </>
      )}
      {/* <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {ordersCount > currentPage * 6 ? currentPage * 6 : ordersCount} of{" "}
            {ordersCount}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={currentPage === 1 ? null : previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={ordersCount < currentPage * 6 ? "disable" : "active"}
            onClick={ordersCount < currentPage * 6 ? null : nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default AllOrders;
