import { useEffect, useState } from "react";
import "./PackageList.scss";
import { AiOutlineSetting } from "react-icons/ai";
import { BiPackage } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";
import { BsCheck } from "react-icons/bs";
import { PackageManagementService } from "../../../../../services/package-management.service";

const CustomerTestPackageList = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [currentPage, setCurrentPage] = useState("Starter");
  const [currentPackage, setCurrentPackage] = useState({});

  useEffect(() => {
    const getPackagesAndAddOns = async () => {
      PackageManagementService.list((data) => {
        const standard = data.packages.filter(
          (pkg) => pkg.title === "Standard"
        )[0];
        const pro = data.packages.filter((pkg) => pkg.title === "Pro")[0];
        const starter = data.packages.filter(
          (pkg) => pkg.title === "Starter"
        )[0];
        setPackages([starter, standard, pro]);
        setCurrentPackage(starter);
      });
    };

    getPackagesAndAddOns();
  }, []);

  const choosePackage = (userPackage) => {
    const obj = {
      order: userPackage,
      update: false,
    };

    obj.order.name = obj.order.title;
    obj.order.price = obj.order.price * 100;
    obj.order.nextPrice = obj.order.price;

    delete obj.order.title;
    delete obj.order.createdAt;
    delete obj.order.updatedAt;
    delete obj.order.savings;

    if (
      state &&
      state.update &&
      state.update !== null &&
      state.update === true
    ) {
      obj["update"] = true;
    }
    navigate("../add-ons", { state: obj });
  };

  const getTime = (minutes) => {
    return `${minutes >= 60 ? Math.floor(minutes / 60) : minutes} ${
      minutes >= 60 ? (minutes >= 120 ? "hours" : "hour") : "minutes"
    }`;
  };

  const changePackage = (title) => {
    const pkg = packages.filter((pkg) => pkg.title === title)[0];
    setCurrentPackage(pkg);
    setCurrentPage(title);
  };

  return (
    <div className="customer-test-layout-package-list-page">
      <div className="white-container">
        <div className="title">
          <span>Choose Package</span>
        </div>
        <div className="packages">
          <div className="package-container">
            <div className="package-list">
              <div
                onClick={() => changePackage("Starter")}
                className={`package-name ${
                  currentPage === "Starter" ? "package-active" : ""
                }`}
              >
                Starter
              </div>
              <div
                onClick={() => changePackage("Standard")}
                className={`package-name ${
                  currentPage === "Standard" ? "package-active" : ""
                }`}
              >
                Standard
              </div>
              <div
                onClick={() => changePackage("Pro")}
                className={`package-name ${
                  currentPage === "Pro" ? "package-active" : ""
                }`}
              >
                Pro
              </div>
            </div>
            <div className="package">
              {currentPage === "Standard" ? (
                <div className="popular-choice">Popular Package</div>
              ) : null}
              <div className="package-header">
                <div className="name-video-count">
                  <span className="name">{currentPackage.title}</span>
                  <span className="videos">
                    {currentPackage.videos} videos per month
                  </span>
                </div>
              </div>
              <div className="price-overall">
                <span className="price">
                  ${currentPackage.price}
                  <span className="month">/mo</span>
                </span>
                {currentPackage.savings ? (
                  <span className="savings">
                    Save ${currentPackage.savings}
                  </span>
                ) : (
                  <span style={{ visibility: "hidden" }}>Save</span>
                )}
              </div>
              <div className="features">
                {currentPackage.packagesText &&
                  currentPackage.packagesText.map((item) => {
                    return (
                      <>
                        <div className="feature">
                          <BsCheck size={25} color="#2A9DF4"></BsCheck>
                          <span>
                            {eval(
                              "`" +
                                item.text.replace("pkg", "currentPackage") +
                                "`"
                            )}
                          </span>
                        </div>
                      </>
                    );
                  })}
              </div>
              {/* <div className="features">
                <div className="feature">
                  <BsCheck size={25} color="#2A9DF4"></BsCheck>
                  {currentPackage.runningVideo ? (
                    <span>
                      Up to {getTime(currentPackage.runningVideo)} of running
                      time per video
                    </span>
                  ) : (
                    <span>Unlimited running video</span>
                  )}
                </div>
                <div className="feature">
                  <BsCheck size={25} color="#2A9DF4"></BsCheck>
                  {currentPackage.rawFootage ? (
                    <span>
                      Up to {getTime(currentPackage.rawFootage)} of raw provided
                      footage per video
                    </span>
                  ) : (
                    <span>Unlimited provided footage</span>
                  )}
                </div>
                <div className="feature">
                  <BsCheck size={25} color="#2A9DF4"></BsCheck>
                  <span>
                    {currentPackage.deliveryTime - 1}-
                    {currentPackage.deliveryTime} days delivery guaranteed
                  </span>
                </div>
                {currentPackage.thumbnails && currentPackage.thumbnails > 0 ? (
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    <span>{currentPackage.thumbnails} thumbnails*</span>
                  </div>
                ) : currentPackage.thumbnails === 0 ? null : (
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    <span>Unlimited thumbnails</span>
                  </div>
                )}
                {currentPackage.outro &&
                currentPackage.intro &&
                currentPackage.banner ? (
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    <span>
                      Free Brand Identity Pack with Intro + Outro + Banner*
                    </span>
                  </div>
                ) : (
                  <>
                    {currentPackage.intro && currentPackage.intro > 0 ? (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>
                          {currentPackage.intro} free Personalized Intro*
                        </span>
                      </div>
                    ) : currentPackage.intro === 0 ? null : (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>Unlimited Intros</span>
                      </div>
                    )}
                    {currentPackage.outro ? (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>
                          {currentPackage.outro} free Personalized Outro*
                        </span>
                      </div>
                    ) : currentPackage.outro === 0 ? null : (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>Unlimited Outros</span>
                      </div>
                    )}
                    {currentPackage.banner ? (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>
                          {currentPackage.banner} free Personalized Banner*
                        </span>
                      </div>
                    ) : currentPackage.banner === 0 ? null : (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>Unlimited Banners</span>
                      </div>
                    )}
                  </>
                )}
                {currentPackage.logo ? (
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    <span>{currentPackage.logo} free Personalized Logo*</span>
                  </div>
                ) : currentPackage.logo === 0 ? null : (
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    <span>Unlimited Logos</span>
                  </div>
                )}
                {currentPackage.consultationCall ? (
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    <span>
                      {currentPackage.consultationCall} free 15 min Consultation
                      Call*
                    </span>
                  </div>
                ) : currentPackage.consultationCall === 0 ? null : (
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    <span>Unlimited 15 min Consultation Calls</span>
                  </div>
                )}
                <div className="feature">
                  <BsCheck size={25} color="#2A9DF4"></BsCheck>
                  <span>Unlimited Revisions</span>
                </div>
              </div> */}
              <span style={{ fontSize: ".8em" }}>* One-Time Purchase</span>
              <button
                className="choose-package"
                onClick={() => {
                  choosePackage(currentPackage);
                }}
              >
                Choose Package
              </button>
            </div>
          </div>
          <span className="or-actions">OR</span>
          <div className="actions">
            <button
              className="custom"
              onClick={() =>
                navigate("../custom-package", {
                  state:
                    state &&
                    state.update &&
                    state.update !== null &&
                    state.update === true
                      ? { update: true }
                      : { update: false },
                })
              }
            >
              <AiOutlineSetting className="icon" />
              <span>Create Custom Package</span>
            </button>
            <button
              className="single"
              onClick={() =>
                navigate("../single-order", {
                  state:
                    state &&
                    state.update &&
                    state.update !== null &&
                    state.update === true
                      ? { update: true }
                      : { update: false },
                })
              }
            >
              <BiPackage className="icon" />
              <span>Single Order</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTestPackageList;
