import "./Editor.scss";
import Josias from "../../../../../assets/josias.png";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { FiEdit } from "react-icons/fi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import Portfolio from "../../../../../assets/portfolio.png";
import Youtube from "../../../../../assets/youtube.png";
import Facebook from "../../../../../assets/facebook.png";
import Instagram from "../../../../../assets/instagram.png";
import Twitter from "../../../../../assets/twitter.png";
import Tiktok from "../../../../../assets/tiktok.png";
import Linkedin from "../../../../../assets/linkedin.png";
import Snapchat from "../../../../../assets/snapchat.png";
import Website from "../../../../../assets/website.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { editorService } from "../../../../../services/editor.service";
import { useNavigate, useParams } from "react-router";
import Slider from "../../../../../components/slider/Slider";
import { orderService } from "../../../../../services/order.service";
import Loading from "../../../../loading/Loading";

const AdminEditor = () => {
  const params = useParams();
  const [editor, setEditor] = useState({});
  const [socialMediaExist, setSocialMediaExist] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    editorService.getEditorDetails(params.editorId, (data) => {
      networks.map((network) => {
        if (
          data.socialMedias.filter(
            (net) => net.type === network.name.toLowerCase()
          ) &&
          data.socialMedias.filter(
            (net) => net.type === network.name.toLowerCase()
          ).length
        ) {
          setSocialMediaExist(true);
        }
      });

      setEditor(data);
    });
  }, [params]);

  const networks = [
    {
      name: "Youtube",
      img: Youtube,
      data: "https://youtube.com/",
    },
    {
      name: "Facebook",
      img: Facebook,
      data: "https://facebook.com/",
    },
    {
      name: "Instagram",
      img: Instagram,
      data: "https://instagram.com/",
    },
    {
      name: "Twitter",
      img: Twitter,
      data: "https://twitter.com/",
    },
    {
      name: "Tiktok",
      img: Tiktok,
      data: "https://tiktok.com/",
    },
    {
      name: "Linkedin",
      img: Linkedin,
      data: "https://linkedin.com/",
    },
    {
      name: "Snapchat",
      img: Snapchat,
      data: "https://snapchat.com/",
    },
    {
      name: "Personal Website",
      img: Website,
      data: "https://",
    },
  ];

  const formatCurrency = (amount) => {
    let newAmount = "";
    let arrCount = 0;
    let amountToCheck = `${parseFloat(amount).toFixed(0)}`;
    const arr = `${amountToCheck}`.split("").reverse();
    arr.map((letter) => {
      arrCount++;
      newAmount = `${letter}${newAmount}`;
      if (arrCount !== arr.length && arrCount % 3 === 0) {
        newAmount = `,${newAmount}`;
      }
    });

    let result = `R$${newAmount}.00`;

    return result;
  };

  return !editor || !editor.socialMedias || !editor.projects ? (
    <div className="admin-layout-editor-details-page">
      <Loading />
    </div>
  ) : (
    <div className="admin-layout-editor-details-page">
      <div className="header-main">
        <Link
          to="../editors"
          className="link-back"
          style={{ textDecoration: "none" }}
        >
          <AiOutlineArrowLeft size={20} color="#2A9DF4" />
          <span>Back to Editors</span>
        </Link>
        <button
          onClick={() => {
            orderService.chatWithAdmin(null, editor.id, (data) => {
              navigate("../inbox", { state: { chat: data } });
            });
          }}
          className="chat"
        >
          Chat with Editor
        </button>
      </div>
      <div className="profile-picture-info">
        <div className="image-circle">
          <CircularProgressbar
            value={(10 - editor.toNextLevel) * 10}
            className="circular-level-bar"
            strokeWidth={2}
            counterClockwise={true}
            styles={buildStyles({
              trailColor: `#F2F5FA`,
              pathColor: "#2A9DF4",
              backgroundColor: "white",
            })}
          />

          <div className="image">
            <img
              src={
                editor.profilePicture
                  ? editor.profilePicture.url
                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
              }
            />
          </div>
        </div>
        <div className="profile-details">
          <span className="name">
            {editor.firstName} {editor.lastName}
          </span>
          <span className="title">Editor Level {editor.level}</span>
          <div className="stats">
            <div className="item">
              <span className="number">
                {formatCurrency(editor.balanceHistory)}
              </span>
              <span className="text">
                Money
                <br />
                Received
              </span>
            </div>
            <div className="item">
              <span className="number">{editor.progressVideos}</span>
              <span className="text">
                Video
                <br />
                In Progress
              </span>
            </div>
            <div className="item">
              <span className="number">{editor.completedVideos}</span>
              <span className="text">
                Video
                <br />
                Completed
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container-editor">
        <div className="bio-network">
          <div className="bio">
            <span className="title">Bio Info</span>
            <span className="text">{editor.bio}</span>
          </div>
          <div className="Networking">
            <span className="title">Networking Info</span>
            <div className="networks">
              {networks.map((network) => {
                let socialMediaExist = false;
                if (
                  editor.socialMedias.filter(
                    (net) => net.type === network.name.toLowerCase()
                  ) &&
                  editor.socialMedias.filter(
                    (net) => net.type === network.name.toLowerCase()
                  ).length
                ) {
                  return (
                    <div className="social-network">
                      <img src={network.img} />
                      <span style={{ marginLeft: ".5em" }}>{network.name}</span>
                      <div
                        className="arrow"
                        onClick={() => {
                          window
                            .open(
                              !editor.socialMedias
                                .filter(
                                  (net) =>
                                    net.type === network.name.toLowerCase()
                                )[0]
                                .account.includes("https://")
                                ? `${network.data}${
                                    editor.socialMedias.filter(
                                      (net) =>
                                        net.type === network.name.toLowerCase()
                                    )[0].account
                                  }`
                                : editor.socialMedias.filter(
                                    (net) =>
                                      net.type === network.name.toLowerCase()
                                  )[0].account,
                              "_blank"
                            )
                            .focus();
                        }}
                      >
                        <AiOutlineArrowRight />
                      </div>
                    </div>
                  );
                }
              })}
              {socialMediaExist ? (
                <></>
              ) : (
                <>
                  <div>
                    <span>No social media added</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="portfolio">
          <span className="title">Portfolio Editor</span>
          {editor.projects && editor.projects.length > 0 ? (
            editor.projects.map((project) => {
              return (
                <div className="project">
                  <span className="project-title">{project.name}</span>
                  {/*  */}

                  <Slider images={project.files} />
                </div>
              );
            })
          ) : (
            <>
              <div>
                <span>No projects added</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminEditor;
