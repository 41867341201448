import { useEffect, useState } from "react";
import { PromoService } from "../../../../services/promo.service";
import AddPromoPopup from "./AddPromoPopup";
import "./Promos.scss";
import { FiTrash2 } from "react-icons/fi";

const AdminPromos = () => {
  // Tracks the add promo popup
  const [isAddPromoPopupOpen, setIsAddPromoPopupOpen] = useState(false);
  // List the existing codes
  const [promoCodes, setPromoCodes] = useState([]);

  useEffect(() => {
    const getExistingPromos = async () => {
      await PromoService.getAllPromos((data) => {
        setPromoCodes(data);
      });
    };

    getExistingPromos();
  }, []);

  // Return added promo from popup
  const addPromo = (newPromo) => {
    setIsAddPromoPopupOpen(false);
    const codes = [...promoCodes].map((code, index) => {
      return { code: code.code, index };
    });

    for (const code of codes) {
      if (code.code === newPromo.code) {
        const codesCopy = [...promoCodes];
        codesCopy[code.index] = { ...codesCopy[code.index], ...newPromo };
        setPromoCodes(codesCopy);
        return;
      }
    }

    setPromoCodes((currPromos) => [...currPromos, newPromo]);
  };

  // Change active status
  const changeActive = (index) => {
    const promoCopy = [...promoCodes];
    promoCopy[index].active = !promoCopy[index].active;

    PromoService.savePromo(promoCopy[index], () => {
      setPromoCodes(promoCopy);
    });
  };

  // Delete promo
  const deletePromo = (index) => {
    const promoCopy = [...promoCodes];

    PromoService.deletePromo(promoCopy[index].id, () => {
      promoCopy.splice(index, 1);
      setPromoCodes(promoCopy);
    });
  };

  // HTML
  return (
    <div className="admin-layout-promos-page">
      {isAddPromoPopupOpen ? (
        <AddPromoPopup
          closeModal={() => setIsAddPromoPopupOpen(false)}
          addPromo={addPromo}
        />
      ) : null}
      <button className="add-new" onClick={() => setIsAddPromoPopupOpen(true)}>
        Add Promotion
      </button>
      <div className="promotions">
        <span className="promotions-title">Existing promotions</span>
        <div className="promotions-list">
          <div className="promotion header">
            <span>Code</span>
            <span>Type</span>
            <span>Amount</span>
            <span>Active</span>
            <span></span>
          </div>
          {promoCodes.map((promotion, index) => {
            return (
              <div className="promotion">
                <span>{promotion.code}</span>
                <span>{promotion.type}</span>
                <span>
                  {promotion.type === "amount" ? "$" : ""}
                  {promotion.amount}
                  {promotion.type !== "amount" ? "%" : ""}
                </span>
                <input
                  type={"checkbox"}
                  checked={promotion.active}
                  onChange={() => changeActive(index)}
                />
                <FiTrash2
                  color="#E74C3C"
                  onClick={() => deletePromo(index)}
                  className="icon"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminPromos;
