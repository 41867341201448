import "./AddOnsSummary.scss";
import { AiFillCheckCircle, AiOutlineCalendar } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { purchaseService } from "../../../../../services/package.service";
import Swal from "sweetalert2";
import Loading from "../../../../loading/Loading";
import moment from "moment-timezone";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";

const CustomerAddOnsSummary = () => {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [features, setFeatures] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.order) {
      const arr = [];
      if (state.order.thumbnails === null) {
        arr.push(`Unlimited Thumbnails`);
      }

      if (state.order.thumbnails > 0) {
        arr.push(
          `${state.order.thumbnails} Thumbnail${
            state.order.thumbnails === 1 ? "" : "s"
          }`
        );
      }

      if (state.order.banner > 0) {
        arr.push(
          `${state.order.banner} Banner${state.order.banner === 1 ? "" : "s"}`
        );
      }

      if (state.order.consultationCall > 0) {
        arr.push(
          `${state.order.consultationCall} Consultation Call${
            state.order.consultationCall === 1 ? "" : "s"
          }`
        );
      }

      if (state.order.intro > 0) {
        arr.push(
          `${state.order.intro} Personalized Intro${
            state.order.intro === 1 ? "" : "s"
          }`
        );
      }

      if (state.order.outro > 0) {
        arr.push(
          `${state.order.outro} Personalized Outro${
            state.order.outro === 1 ? "" : "s"
          }`
        );
      }

      if (state.order.unlimitedStockFootage) {
        arr.push("Unlimited Stock Footage");
      }

      setFeatures(arr);
    }
  }, [state]);

  const purchase = () => {
    purchaseService.buyAddOn(state.order, (data) => {
      setLoading(false);
      if (data && data.firstName) {
        Swal.fire({
          title: "Purchase Successful!",
          heightAuto: false,
          icon: "success",
        }).then(() => {
          navigate("../my-package");
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong with your purchase, please try again later",
          heightAuto: false,
          icon: "error",
        });
      }
    });
  };

  return state.order ? (
    <div className="customer-layout-add-ons-summary-page">
      {loading ? <LoadingUpload text="Finishing Purchase" /> : null}
      <span className="package-title">Add Ons</span>
      <div className="package-content">
        <div className="features">
          <div className="features-list">
            <span className="features-list-title">Includes</span>
            {features.map((feature) => {
              return (
                <div className="feature">
                  <AiFillCheckCircle color="#2A9DF4" size={20} />
                  <span>{feature}</span>
                </div>
              );
            })}
          </div>
          <div className="footer">
            <div className="footer-text">
              <span>Total Package</span>
              <span>(Includes 7.5% Sales Tax)</span>
            </div>
            <div className="actions">
              <span>${state.order.price / 100}</span>
              <button onClick={() => purchase()}>Confirm Purchase</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerAddOnsSummary;
