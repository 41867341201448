import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router";
import "./Packages.scss";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useEffect, useState } from "react";

const CustomerTestPackages = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [prevPage, setPrevPage] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [prevPageState, setPrevPageState] = useState({});
  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get("email");

  useEffect(() => {
    if (email) {
      localStorage.setItem("customerTestEmail", email);
    }
    if (state) {
      if (state.finished) {
        if (state.order.name === "Custom") {
          setPrevPage("custom package");
          setPrevPageLink("/customer-test/packages/custom-package");
        } else if (state.order.name === "Single") {
          setPrevPage("/customer-test/packages/single-order");
          setPrevPageLink(-1);
        } else {
          setPrevPage("select package");
          setPrevPageLink("/customer-test/packages/select-package");
        }

        setPrevPageState({
          order: state.order,
          finished: false,
          update: state.update ? state.update : false,
        });
      } else {
        if (state.addOns) {
          setPrevPage("select package");
          setPrevPageLink("/customer-test/packages/select-package");
          setPrevPageState({ update: state.update ? state.update : false });
        } else {
          if (state.update) {
            setPrevPage("my package");
            setPrevPageLink("/customer-test/packages/my-package");
            setPrevPageState({});
          } else {
            setPrevPage("select package");
            setPrevPageLink("/customer-test/packages/select-package");
          }
        }
      }
    }
  }, [state]);

  return (
    <div className="customer-layout-packages-page">
      {state &&
      !window.location.href.includes("select-package") &&
      !state.update &&
      !window.location.href.includes("my-package") ? (
        <div
          className="back"
          onClick={() => {
            navigate(prevPageLink, { state: prevPageState });
          }}
        >
          <AiOutlineArrowLeft />
          <span>Go to {prevPage}</span>
        </div>
      ) : null}
      <Outlet />
    </div>
  );
};

export default CustomerTestPackages;
