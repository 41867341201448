import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./CompletedOrders.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { reference } from "../../ordersReference";
import { createHTMLOrder, createHTMLOrderPrint } from "../../setItemsPrint";
import moment from "moment-timezone";
import { orderService } from "../../../services/order.service";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const CompletedOrders = ({ orders, getOrders, ordersCount }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const formatCurrency = (amount) => {
    let newAmount = "";
    let arrCount = 0;
    let amountToCheck = `${parseFloat(amount).toFixed(0)}`;
    const arr = `${amountToCheck}`.split("").reverse();
    arr.map((letter) => {
      arrCount++;
      newAmount = `${letter}${newAmount}`;
      if (arrCount !== arr.length && arrCount % 3 === 0) {
        newAmount = `,${newAmount}`;
      }
    });

    let result = `R$${newAmount}.00`;

    return result;
  };

  const downloadTxtFile = (order) => {
    orderService.getDetails(order.id, (order) => {
      const statusName = reference.filter(
        (item) => item.status === order.status
      )[0].name;
      const tableItems = createHTMLOrderPrint(order);

      const list = [
        `"Order Id": #${order.number}`,
        "Order Name: " + order.name,
        `Created at: ${moment(order.createdAt)
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("DD MMMM YYYY")}`,
        `Deadline at: ${moment(order.deadline)
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("DD MMMM YYYY")}`,
        "Status: " + statusName + "\n",
        "Delivery Items:",
        tableItems.map((tableItem, index) => {
          if (tableItem.shown) {
            return `\n\n${tableItem.name}:${tableItem.item.map((item) => {
              return `\n- ${item}`;
            })}`;
          } else {
            return "\n";
          }
        }),
      ];
      const element = document.createElement("a");
      const file = new Blob([list.join("\n")], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `invoice_${order.number}.txt`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    });
  };

  return (
    <div className="completed-orders-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            titles={[
              "Name",
              "Client",
              "Order Id",
              "Date Placed",
              "Date Delivered",
              "Status",
              "Order Cost",
            ]}
          />
          <div className="order-table">
            <TableRow
              click={(order) => navigate(`./${order.id}`)}
              items={currentOrders.map((order, index) => {
                return {
                  order,
                  items: [
                    <span style={{ fontWeight: 500 }}>
                      {order.basicDetails
                        ? order.basicDetails.title
                        : `Order #${order.number}`}
                    </span>,
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: ".3em",
                      }}
                    >
                      <div
                        style={{
                          height: "30px",
                          width: "30px",
                          overflow: "hidden",
                          borderRadius: "100%",
                          backgroundColor: "rgb(230,230,230)",
                        }}
                      >
                        {order.client && order.client.profilePicture ? (
                          <img
                            src={order.client.profilePicture.url}
                            style={{ height: "100%" }}
                            alt="profile"
                          />
                        ) : (
                          <img
                            alt="profile"
                            src="https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                            style={{ height: "100%" }}
                          />
                        )}
                      </div>
                      <span style={{ flex: 1, fontSize: ".9em" }}>
                        {order.client
                          ? `${order.client.firstName} ${order.client.lastName}`
                          : ""}
                      </span>
                    </div>,
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                        alignItems: "stretch",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ color: "#A0AEC7", fontSize: ".8em" }}>
                        #{order.number}
                      </span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {order.stars === null
                          ? new Array(0)
                              .fill()
                              .map((_) => <AiFillStar color="#F1C40F" />)
                          : new Array(order.stars)
                              .fill()
                              .map((_) => <AiFillStar color="#F1C40F" />)}
                        {order.status === null
                          ? new Array(5)
                              .fill()
                              .map((_) => <AiFillStar color="#E0E0E0" />)
                          : new Array(5 - order.stars)
                              .fill()
                              .map((_) => <AiFillStar color="#E0E0E0" />)}
                      </div>
                    </div>,
                    <span>
                      {new Date(order.createdAt).toLocaleDateString()}
                    </span>,
                    <span>
                      {new Date(order.deliveredAt).toLocaleDateString()}
                    </span>,
                    <div className="status">
                      <span>Completed</span>
                    </div>,
                    <span>{formatCurrency(order.editorPay)}</span>,
                  ],
                };
              })}
            />
          </div>
        </>
      ) : (
        <>
          <>
            {currentOrders.map((order, index) => {
              return (
                <div
                  className="order-mobile"
                  onClick={() => navigate(`./${order.id}`)}
                >
                  <div className="header-mobile">
                    <div className="status">
                      <span>Completed</span>
                    </div>
                    <div className="details">
                      <span className="name">
                        {order.basicDetails
                          ? order.basicDetails.title
                          : `Order #${order.number}`}
                      </span>
                      <span className="number">#{order.number}</span>
                    </div>
                  </div>
                  <div className="body-mobile">
                    <span>${order.editorPay}</span>
                  </div>
                </div>
              );
            })}
          </>
        </>
      )}
      {/* <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {ordersCount > currentPage * 6 ? currentPage * 6 : ordersCount} of{" "}
            {ordersCount}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={ordersCount < currentPage * 6 ? "disable" : "active"}
            onClick={nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default CompletedOrders;
