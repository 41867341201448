import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import "./NewOrder.scss";
import { AiOutlineArrowLeft, AiFillCheckCircle } from "react-icons/ai";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import Video from "../../../../../assets/video-types/video.png";
import Thumbnail from "../../../../../assets/video-types/thumbnail.png";
import Banner from "../../../../../assets/video-types/banner.png";
import Logo from "../../../../../assets/video-types/logo.png";
import ConsultationCall from "../../../../../assets/video-types/consultation-call.png";
import Outro from "../../../../../assets/video-types/outro.png";
import Intro from "../../../../../assets/video-types/intro.png";
import PersonalizedApp from "../../../../../assets/video-types/personalized-app.png";
import Finish from "./steps/finish/Finish";
import { userService } from "../../../../../services/user.service";
import Loading from "../../../../loading/Loading";
import { orderSteps } from "./newOrderSteps";
import Swal from "sweetalert2";
import { orderService } from "../../../../../services/order.service";
import { PackageManagementService } from "../../../../../services/package-management.service";
import { purchaseService } from "../../../../../services/package.service";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const CustomerNewOrder = () => {
  const [isAddMoreOpen, setIsAddMoreOpen] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isCreated, setIsCreated] = useState(false);
  const [user, setUser] = useState({});
  const [itemsAdded, setItemsAdded] = useState([]);
  const [step, setStep] = useState(0);
  const [stepsCompleted, setStepsCompleted] = useState([]);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([]);
  const [order, setOrder] = useState({});
  const [addOns, setAddOns] = useState({});
  let saved = true;
  const [loadingDraft, setLoadingDraft] = useState(false);

  useEffect(() => {
    userService.getProfile(
      async (data) => {
        setUser(data);
        await PackageManagementService.list((data) => {
          data.addOns = data.addOns.map((addOn) => {
            addOn.count = 0;
            return addOn;
          });

          setAddOns(data.addOns);
        });
      },
      (e) => {
        Swal.fire({
          title: "Error!",
          text: "Please login before entering your account",
          heightAuto: false,
          icon: "error",
        }).then(() => {
          navigate("/auth/admin/sign-in");
        });
      }
    );
  }, []);

  useEffect(() => {
    if (user && user.package === null) {
      Swal.fire({
        title: "Please select a package before creating your order",
        heightAuto: false,
        icon: "warning",
      }).then(() => {
        navigate("/customer/packages/select-package");
      });
    } else if (user && user.package) {
      if (state && state !== null && state.order && state.order !== null) {
        orderService.getDetails(state.order.id, (data) => {
          const arr = [];
          if (data.fileRequired) {
            arr.push("videos");
          }

          if (data.thumbnail) {
            arr.push("thumbnails");
          }

          if (data.intro) {
            arr.push("intro");
          }

          if (data.outro) {
            arr.push("outro");
          }

          if (data.consultationCall) {
            arr.push("consultationCall");
          }

          if (data.logo) {
            arr.push("logo");
          }

          if (data.banner) {
            arr.push("banner");
          }

          setItemsAdded(arr);
          setOrder(data);
        });
      }

      console.log(user.package);
      if (user.package.videos - user.package.videosUsed > 0) {
        const arr = [...itemsAdded];
        if (!arr || !arr.length) {
          arr.push("videos");
          setItemsAdded(arr);
        }
      }

      let newOrder;

      if (
        itemsAdded.includes("videos") &&
        user.usualOrder &&
        user.usualOrder.basicDetails
      ) {
        console.log(user);
        let basicDetails = {
          ...user.usualOrder.basicDetails,
          deliveryTime: user.package.deliveryTime + 1,
        };

        let videoElements = {
          ...user.usualOrder.videoElements,
        };

        delete basicDetails.id;
        delete videoElements.id;

        newOrder = {
          basicDetails,
          videoElements,
          ...JSON.parse(JSON.stringify(order)),
        };
      } else {
        newOrder = {
          ...JSON.parse(JSON.stringify(order)),
        };
      }

      setOrder(newOrder);
    }
  }, [user]);

  useEffect(() => {
    if (itemsAdded !== []) {
      setSteps(
        orderSteps(
          itemsAdded,
          step,
          changeField,
          validate,
          nextStep,
          prevStep,
          user,
          order
        )
      );
    }
  }, [itemsAdded, order]);

  useEffect(() => {
    console.log(stepsCompleted);
  }, [stepsCompleted]);

  const nextStep = () => {
    const newStepsCompleted = [...stepsCompleted, step];
    setStepsCompleted(newStepsCompleted);
    const newStep = step + 1;
    setStep(newStep);
  };

  const prevStep = () => {
    setStep((step) => step - 1);
  };

  const validate = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      heightAuto: false,
      icon: "error",
    });
  };

  const changeField = (field, text) => {
    console.log(field);
    if (field.includes(".") && (field.match(/\./g) || []).length === 1) {
      const obj = {
        ...order,
        [field.split(".")[0]]: {
          ...order[[field.split(".")[0]]],
          [field.split(".")[1]]: text,
        },
      };

      saved = false;
      orderService.draft(obj, (data) => {
        setOrder(data);
        saved = true;
      });
    } else if (field.includes(".") && (field.match(/\./g) || []).length === 2) {
      const field2 =
        order[field.split(".")[0]] &&
        order[field.split(".")[0]][field.split(".")[1]]
          ? {
              ...order[field.split(".")[0]][field.split(".")[1]],
              [field.split(".")[2]]: text,
            }
          : {
              [field.split(".")[2]]: text,
            };

      const obj = {
        ...order,
        [field.split(".")[0]]: {
          ...order[[field.split(".")[0]]],
          [field.split(".")[1]]: field2,
        },
      };
      saved = false;

      orderService.draft(obj, (data) => {
        setOrder(data);
        saved = true;
      });
    } else {
      saved = false;
      const obj = { ...order, [field]: text };
      console.log(obj);
      orderService.draft(obj, (data) => {
        setOrder(data);
        saved = true;
      });
    }
  };

  useEffect(() => {
    if (user.package) {
      setTypes([
        {
          name: "Video",
          image: Video,
          left: user.package.videosUsed,
          total: user.package.videos,
          id: "videos",
        },
        {
          name: "Thumbnail",
          image: Thumbnail,
          left: user.package.thumbnailsUsed,
          total: user.package.thumbnails + user.package.additionalThumbnails,
          id: "thumbnails",
        },
        {
          name: "Banner",
          image: Banner,
          left: user.package.bannerUsed,
          total: user.package.banner + user.package.additionalBanner,
          id: "banner",
        },
        {
          name: "Logo",
          image: Logo,
          left: user.package.logoUsed,
          total: user.package.logo + user.package.additionalLogo,
          id: "logo",
        },
        {
          name: "Consultation Call",
          image: ConsultationCall,
          left: user.package.consultationCallUsed,
          total:
            user.package.consultationCall +
            user.package.additionalConsultationCall,
          id: "consultationCall",
        },
        {
          name: "Outro",
          image: Outro,
          left: user.package.outroUsed,
          total: user.package.outro + user.package.additionalOutro,
          id: "outro",
        },
        {
          name: "Intro",
          image: Intro,
          left: user.package.introUsed,
          total: user.package.intro + user.package.additionalIntro,
          id: "intro",
        },
      ]);
    }
  }, [user]);

  const validateNext = () => {
    while (Object.keys(order).length < step + 1) {
      continue;
    }

    if (Object.values(order).includes("error")) {
      return false;
    }

    return true;
  };

  const changeCount = (operation, type, amount = 1) => {
    const newAddOns = [...addOns].map((addOn) => {
      if (addOn.type === type && operation === "plus") {
        addOn.count = addOn.count + amount;
      } else if (
        addOn.type === type &&
        operation === "minus" &&
        addOn.count > 0
      ) {
        addOn.count = addOn.count - amount;
      }

      return addOn;
    });

    setAddOns(newAddOns);
  };

  const buyAddOns = async () => {
    let moreThanOne = false;
    const obj = {};
    for (const addOn of [...addOns]) {
      if (addOn.count > 0) {
        moreThanOne = true;
      }
      obj[addOn.codeId] = addOn.count;
    }

    if (!moreThanOne) {
      Swal.fire({
        title: "Oops!",
        text: "Please add at least one add-on",
        heightAuto: false,
        icon: "error",
      });
      return;
    }

    setLoading(true);

    purchaseService.buyAddOn(obj, (data) => {
      setLoading(false);
      if (data && data.firstName) {
        Swal.fire({
          title: "Add-Ons successfully added!!",
          heightAuto: false,
          icon: "success",
        }).then(() => {
          console.log(data);
          setIsAddMoreOpen(false);
          const curr = JSON.parse(JSON.stringify(user));
          curr.package = data.package;
          setUser(curr);
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong with your purchase, please try again later",
          heightAuto: false,
          icon: "error",
        });
      }
    });
  };

  return user && user.package ? (
    <div className="customer-layout-new-order-page">
      {loading ? <LoadingUpload text="Finishing Purchase" /> : null}
      {loadingDraft ? <LoadingUpload text="Saving your draft" /> : null}
      {isAddMoreOpen ? (
        <div className="add-add-ons-container">
          <div className="add-add-ons">
            <div className="add-add-ons-header">
              <span>Add-Ons</span>
              <span
                className="close"
                onClick={() => {
                  setIsAddMoreOpen(false);
                  const newAddOns = [...addOns].map((addOn) => {
                    addOn.count = 0;
                    return addOn;
                  });

                  setAddOns(newAddOns);
                }}
              >
                close
              </span>
            </div>
            <span className="description">One time purchases.</span>
            <div className="add-ons">
              {addOns
                .filter(
                  (addOn) =>
                    addOn.type !== "Video" &&
                    addOn.type !== "Raw Footage Time" &&
                    addOn.type !== "Final Running Time"
                )
                .map((addOn) => {
                  return (
                    <div className="add-on">
                      <div className="left">
                        <div className="image-container">
                          {addOn.type === "Thumbnail" ? (
                            <img src={Thumbnail} alt="add-on-symbol" />
                          ) : addOn.type === "Logo" ? (
                            <img src={Logo} alt="add-on-symbol" />
                          ) : addOn.type === "Banner Design" ? (
                            <img src={Banner} alt="add-on-symbol" />
                          ) : addOn.type === "Consultation Call" ? (
                            <img src={ConsultationCall} alt="add-on-symbol" />
                          ) : addOn.type === "Outro" ? (
                            <img src={Outro} alt="add-on-symbol" />
                          ) : (
                            <img src={Intro} alt="add-on-symbol" />
                          )}
                        </div>
                        <div className="details">
                          <span className="name">
                            {addOn.type === "Consultation Call"
                              ? "15-min Consultation Call"
                              : addOn.type}
                          </span>
                          <span className="price">${addOn.cost}</span>
                        </div>
                      </div>
                      <div className="right">
                        <div
                          className="symbol"
                          onClick={() => changeCount("minus", addOn.type)}
                        >
                          -
                        </div>
                        <div className="counter">{addOn.count}</div>
                        <div
                          className="symbol"
                          onClick={() => changeCount("plus", addOn.type)}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <button onClick={() => buyAddOns()}>Add</button>
          </div>
        </div>
      ) : null}
      <div className="header-main">
        <Link
          to="../orders"
          className="link-back"
          style={{ textDecoration: "none" }}
        >
          <AiOutlineArrowLeft size={20} color="#2A9DF4" />
          <span>Back to My Orders</span>
        </Link>
      </div>
      <div className="package-details">
        <span className="title">Your Package</span>
        <div className="actions">
          <div className="details">
            <span>{user.package.name} Package</span>
            {/* <span>2 of 4 videos remaining</span> */}
          </div>
          {/* <CircularProgressbar
            value={50}
            className="circular-level-bar"
            strokeWidth={15}
            counterClockwise={true}
            styles={buildStyles({
              trailColor: "#E0E5ED",
              pathColor: `#2ECC71`,
              backgroundColor: "white",
            })}
          /> */}
          {/* <button>Upgrade</button> */}
        </div>
      </div>
      {!isCreated ? (
        <div className="select-type">
          <span className="title">What do you want to order?</span>
          <span className="sub-title">
            You can add multiple items to an order.
          </span>
          <div className="types" id="types-of-video">
            {types.map((type) => {
              return (
                <div
                  onClick={() => {
                    if (
                      !itemsAdded.includes(type.id) &&
                      type.left !== type.total
                    ) {
                      setItemsAdded([...itemsAdded, type.id]);
                    } else {
                      let arr = [...itemsAdded];
                      arr = arr.filter((item) => item !== type.id);
                      setItemsAdded(arr);
                    }
                  }}
                  className={`type ${
                    itemsAdded.includes(type.id) ? "ended" : ""
                  }`}
                >
                  <div className="image">
                    <img src={type.image} />
                  </div>
                  {type.total !== null ? (
                    <div className="details">
                      <span>{type.name}</span>
                      <span>
                        {itemsAdded.includes(type.id)
                          ? type.total - type.left - 1
                          : type.total - type.left}
                        /{type.total} left
                      </span>
                    </div>
                  ) : (
                    <div className="details">
                      <span>{type.name}</span>
                      <span>Unlimited</span>
                    </div>
                  )}
                  {(itemsAdded.includes(type.id)
                    ? type.total - type.left - 1
                    : type.total - type.left) === 0 && type.name !== "Video" ? (
                    <div
                      className="add-more"
                      onClick={() => {
                        console.log(user);
                        if (user.onboarded) {
                          setIsAddMoreOpen(true);
                        }
                      }}
                    >
                      + Add More
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div className="create" id="create-order">
            <button
              id="create-order-button"
              className="create-button"
              onClick={() => {
                if (!itemsAdded || !itemsAdded.length) {
                  Swal.fire({
                    title: "Oops!",
                    text: "It looks like you forgot to add items to this order. Please add some item and try again",
                    heightAuto: false,
                    icon: "error",
                  });
                  return;
                }
                if (
                  itemsAdded.includes("videos") &&
                  user.usualOrder &&
                  user.usualOrder.basicDetails
                ) {
                  let basicDetails = {
                    ...user.usualOrder.basicDetails,
                    deliveryTime: user.package.deliveryTime + 1,
                  };

                  let videoElements = {
                    ...user.usualOrder.videoElements,
                  };

                  delete basicDetails.id;
                  delete videoElements.id;

                  setOrder({
                    basicDetails,
                    videoElements,
                    ...JSON.parse(JSON.stringify(order)),
                  });
                }

                if (user.package.unlimitedStockFootage) {
                  setItemsAdded([...itemsAdded, "unlimitedStockFootage"]);
                }
                setIsCreated(true);
              }}
            >
              Create Order
            </button>
          </div>
        </div>
      ) : (
        <div className="steps">
          {getWindowDimensions().width > 950 && (
            <div className="side-progress-details">
              <div className="progress-header">
                <CircularProgressbar
                  value={(100 / steps.length) * step}
                  className="circular-level-bar"
                  strokeWidth={15}
                  counterClockwise={true}
                  styles={buildStyles({
                    trailColor: "#E0E5ED",
                    pathColor: `#2A9DF4`,
                    backgroundColor: "white",
                  })}
                />
                <div className="details">
                  <span>Complete Your Order</span>
                  <span>
                    {Math.floor((100 / steps.length) * step)}% Completed
                  </span>
                </div>
              </div>
              <div className="steps-track">
                {steps.map((item, index) => {
                  return (
                    <div
                      className={`step ${index === step ? "active" : ""} ${
                        stepsCompleted.includes(index) ? "finished" : ""
                      }`}
                    >
                      <div className="details">
                        {stepsCompleted.includes(index) ? (
                          <span className="check-symbol">
                            <AiFillCheckCircle color="#2A9DF4" />
                          </span>
                        ) : (
                          <div className="circle" />
                        )}
                        <div className="line" />
                      </div>
                      <span>{item.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="create-order-actions">
            {step !== steps.length && (
              <span className="title">
                STEP {step + 1}/{steps.length}
              </span>
            )}
            {step !== steps.length && (
              <span className="sub-title">{steps[step].name}</span>
            )}
            <div className="inside-content">
              {step !== steps.length ? (
                steps[step].component
              ) : (
                <Finish order={order} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerNewOrder;
