import "./orderHistory.scss";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { AiOutlineArrowLeft, AiFillStar } from "react-icons/ai";
import { clientService } from "../../../../../services/client.service";
import TableHeader from "../../../../../components/table-header/TableHeader";
import TableRow from "../../../../../components/table-row/TableRow";
import moment from "moment";
import { orderService } from "../../../../../services/order.service";
import { reference } from "../../../../../components/ordersReference";
const OrderHistory = () => {
  const params = useParams();
  const [client, setClient] = useState({});
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    clientService.getClientDetails(params.clientId, (data) => {
      setClient(data);
    });
    orderService.listOrdersByUser(params.clientId, (data) => {
      console.log(data);
      setOrders(data);
    });
  }, [params]);

  useEffect(() => {
    console.log(
      orders.map((order, index) => {
        return [
          <div
            className="order-history-table__item"
            style={{ fontWeight: 500 }}
          >
            {order.basicDetails
              ? order.basicDetails.title
              : `Order #${order.number}`}
          </div>,
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: ".3em",
            }}
            className="order-history-table__item"
          >
            <div
              style={{
                height: "30px",
                width: "30px",
                overflow: "hidden",
                borderRadius: "100%",
                backgroundColor: "rgb(230,230,230)",
              }}
            >
              {order.editor && order.editor.profilePicture ? (
                <img
                  src={order.editor.profilePicture.url}
                  style={{ height: "100%" }}
                  alt="profile"
                />
              ) : (
                <img
                  alt="profile"
                  src="https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                  style={{ height: "100%" }}
                />
              )}
            </div>
            <span style={{ flex: 1, fontSize: ".9em" }}>
              {order.editor
                ? `${order.editor.firstName} ${order.editor.lastName}`
                : ""}
            </span>
          </div>,
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "stretch",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "#A0AEC7", fontSize: ".8em" }}>
              #{order.number}
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {order.stars === null
                ? new Array(0).fill().map((_) => <AiFillStar color="#F1C40F" />)
                : new Array(order.stars)
                    .fill()
                    .map((_) => <AiFillStar color="#F1C40F" />)}
              {order.status === null
                ? new Array(5).fill().map((_) => <AiFillStar color="#E0E0E0" />)
                : new Array(5 - order.stars)
                    .fill()
                    .map((_) => <AiFillStar color="#E0E0E0" />)}
            </div>
          </div>,
          <span>{moment(order.createdAt).format("MMM DD, YYYY")}</span>,
          <span>
            {order.deliveredAt ? (
              moment.unix(order.deliveredAt / 1000).format("MMM DD, YYYY")
            ) : (
              <>No Info</>
            )}
          </span>,
          <span>
            {order.deadline ? (
              moment.unix(order.deadline / 1000).format("MMM DD, YYYY")
            ) : (
              <>No Info</>
            )}
          </span>,

          <span>
            {order.editorPay.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </span>,
        ];
      })
    );
  }, [orders]);

  return (
    orders && (
      <>
        <div className="order-history">
          <div className="order-history__back">
            <Link
              to={`../clients/${params.clientId}`}
              className="link-back"
              style={{ textDecoration: "none" }}
            >
              <AiOutlineArrowLeft size={20} color="#2A9DF4" />
              <span>Back</span>
            </Link>
          </div>
          <div className="order-history__body">
            <div className="header">
              <h1>
                Order history - {client.firstName} {client.lastName}
              </h1>
              <span>Review the order history of your client.</span>
            </div>
            <div className="table">
              <TableHeader
                customStyle="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                titles={[
                  "Name",
                  "Editor",
                  "Order Id",
                  "Date Placed",
                  "Date Delivered",
                  "Deadline",
                  "Order Cost",
                  "Status",
                ]}
              />
              {orders ? (
                <div className="order-table">
                  <TableRow
                    customStyle="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                    items={orders.map((order, index) => {
                      return [
                        <div
                          className="order-history-table__item"
                          style={{ fontWeight: 500 }}
                        >
                          {order.basicDetails
                            ? order.basicDetails.title
                            : `Order #${order.number}`}
                        </div>,
                        <div
                          style={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: ".3em",
                          }}
                          className="order-history-table__item"
                        >
                          <div
                            style={{
                              height: "30px",
                              width: "30px",
                              overflow: "hidden",
                              borderRadius: "100%",
                              backgroundColor: "rgb(230,230,230)",
                            }}
                          >
                            {order.editor && order.editor.profilePicture ? (
                              <img
                                src={order.editor.profilePicture.url}
                                style={{ height: "100%" }}
                                alt="profile"
                              />
                            ) : (
                              <img
                                alt="profile"
                                src="https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                                style={{ height: "100%" }}
                              />
                            )}
                          </div>
                          <span style={{ flex: 1, fontSize: ".9em" }}>
                            {order.editor
                              ? `${order.editor.firstName} ${order.editor.lastName}`
                              : ""}
                          </span>
                        </div>,
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            alignItems: "stretch",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ color: "#A0AEC7", fontSize: ".8em" }}>
                            #{order.number}
                          </span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {order.stars === null
                              ? new Array(0)
                                  .fill()
                                  .map((_) => <AiFillStar color="#F1C40F" />)
                              : new Array(order.stars)
                                  .fill()
                                  .map((_) => <AiFillStar color="#F1C40F" />)}
                            {order.status === null
                              ? new Array(5)
                                  .fill()
                                  .map((_) => <AiFillStar color="#E0E0E0" />)
                              : new Array(5 - order.stars)
                                  .fill()
                                  .map((_) => <AiFillStar color="#E0E0E0" />)}
                          </div>
                        </div>,
                        <span>
                          {moment(order.createdAt).format("MMM DD, YYYY")}
                        </span>,
                        <span>
                          {order.deliveredAt ? (
                            moment
                              .unix(order.deliveredAt / 1000)
                              .format("MMM DD, YYYY")
                          ) : (
                            <>No Info</>
                          )}
                        </span>,
                        <span>
                          {order.deadline ? (
                            moment
                              .unix(order.deadline / 1000)
                              .format("MMM DD, YYYY")
                          ) : (
                            <>No Info</>
                          )}
                        </span>,

                        <span>
                          {order.editorPay.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </span>,
                        <div
                          className={`status ${order.status}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            {
                              reference.filter(
                                (item) => item.status === order.status
                              )[0].name
                            }
                          </span>
                        </div>,
                      ];
                    })}
                  />
                </div>
              ) : (
                <span>No orders found</span>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default OrderHistory;
