import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import "./Order.scss";
import {
  AiOutlineClose,
  AiOutlineArrowLeft,
  AiOutlineDownload,
  AiFillCalendar,
  AiOutlinePrinter,
  AiFillMinusCircle,
  AiOutlineFile,
  AiOutlinePlus,
  AiFillStar,
} from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import { HiOutlineChat } from "react-icons/hi";
import { RiAttachment2 } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import User2 from "../../../../../assets/user2.png";
import User1 from "../../../../../assets/user1.png";
import { reference } from "../../../../../components/ordersReference";
import { orderService } from "../../../../../services/order.service";
import Loading from "../../../../loading/Loading";
import { createHTMLOrder } from "./setItems";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import { createHTMLOrderPrint } from "../../../../../components/setItemsPrint";
import { uploadService } from "../../../../../services/upload.service";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";
import { useOutletContext } from "react-router";
import VideoReview from "../../../../customer/pages/orders/order/videoReview/VideoReview";
import { Calendar } from "react-calendar";
import axios from "axios";
import JSZip from "jszip";
import UploadModal from "../../../../../components/uploadModal/UploadModal";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AdminOrder = () => {
  const [addQuestion, setAddQuestion, user] = useOutletContext();
  const navigate = useNavigate();
  const params = useParams();
  const [orderStatusName, setOrderStatusName] = useState("");
  const [commentsShown, setCommentsShown] = useState(false);
  const [isFilesShown, setIsFilesShown] = useState(false);
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDeadline, setLoadingDeadline] = useState(false);
  const [tableItems, setTableItems] = useState();
  const [isVideoReviewOpen, setIsVideoReviewOpen] = useState(false);
  const [currentDeliverable, setCurrentDeliverable] = useState({});
  const [dateValue, setDateValue] = useState();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    orderService.getDetails(params.orderId, (order) => {
      // console.log("1678512555000")
      // console.log("1678501195000")
      console.log(order, "here is the order");
      setOrder(order);
      setDateValue(new Date());
    });
  }, [params]);

  useEffect(() => {
    if (order && order.status) {
      setOrderStatusName(
        reference.filter((item) => item.status === order.status)[0].name
      );
      setTableItems(createHTMLOrder(order));
    }
  }, [order]);

  const images = [
    {
      name: "site screens.png",
      size: "10 MB",
      type: "png",
    },
    {
      name: "site screens.png",
      size: "10 MB",
      type: "png",
    },
  ];

  const videos = ["Video Files.avi", "Video Files.avi"];

  const musics = ["Music Files.mp3", "Music Files.mp3"];

  const deliveries = ["sources.zip", "renders.zip"];

  const downloadTxtFile = () => {
    const statusName = reference.filter(
      (item) => item.status === order.status
    )[0].name;
    const tableItems = createHTMLOrderPrint(order);

    const list = [
      `"Order Id": #${order.number}`,
      "Order Name: " + order.name,
      `Created at: ${moment(order.createdAt)
        .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("DD MMMM YYYY")}`,
      `Deadline at: ${moment(order.deadline)
        .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("DD MMMM YYYY")}`,
      "Status: " + statusName + "\n",
      "Delivery Items:",
      tableItems.map((tableItem, index) => {
        if (tableItem.shown) {
          return `${tableItem.name} -
                    ${tableItem.item.map((item) => {
                      return `- ${item}`;
                    })}`;
        }
      }),
    ];
    const element = document.createElement("a");
    const file = new Blob([list.join("\n")], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `invoice_${order.number}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const comments = [
    {
      image: User2,
      name: "Piter Karter",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
  ];

  const formatCurrency = (amount) => {
    let newAmount = "";
    let arrCount = 0;
    let amountToCheck = `${parseFloat(amount).toFixed(0)}`;
    const arr = `${amountToCheck}`.split("").reverse();
    arr.map((letter) => {
      arrCount++;
      newAmount = `${letter}${newAmount}`;
      if (arrCount !== arr.length && arrCount % 3 === 0) {
        newAmount = `,${newAmount}`;
      }
    });

    let result = `$${newAmount}.00`;

    return result;
  };

  const changeOrderDeadline = async (newDateValue) => {
    setLoadingDeadline(true);
    setCalendarOpen(false);
    setDateValue(newDateValue);
    try {
      orderService.updateOrderDeadline(order.id, newDateValue, (data) => {
        orderService.getDetails(params.orderId, (order) => {
          setLoadingDeadline(false);
          setOrder(order);
          setDateValue(new Date(Number(order.deadline)));
        });
      });
    } catch (error) {
      setLoadingDeadline(false);
    }
  };

  const downloadMultipleFiles = async () => {
    let formData = new FormData();
    order.fileRequired.files.forEach((file) => {
      formData.append(file.originalName, file.url);
    });
    const urls = order.fileRequired.files.map((item) => {
      return item.url;
    });

    const zip = new JSZip();
    const promises = urls.map((url) =>
      axios.get(url, { responseType: "arraybuffer" })
    );

    const responses = await Promise.all(promises);

    responses.forEach((response, i) => {
      const filename = urls[i].split("/").pop();
      zip.file(filename, response.data, { binary: true });
    });

    const content = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = `${order.basicDetails.title}_${new Date().getTime()}.zip`;
    link.click();
  };

  return order && order.status && tableItems && tableItems.length ? (
    <div className="admin-layout-order-delivery-page">
      {isVideoReviewOpen && currentDeliverable ? (
        <VideoReview
          deliverable={currentDeliverable}
          closeModal={() => {
            setIsVideoReviewOpen(false);
            setCurrentDeliverable({});
          }}
          user={user}
        ></VideoReview>
      ) : null}
      {loading ? <UploadModal progress={uploadProgress} /> : null}
      {loadingDeadline ? <LoadingUpload text="Updating deadline..." /> : null}
      {getWindowDimensions().width < 950 && commentsShown && (
        <div className="comments-container-side">
          <div className="comments-container">
            <div className="comments-header">
              <span className="title">Comments</span>
              <div
                className="icon-comments"
                onClick={() => setCommentsShown(false)}
              >
                <AiOutlineClose />
                <AiOutlinePlus
                  color="#2a9df4"
                  size={20}
                  className="add-comment"
                  onClick={() => {
                    Swal.fire({
                      title: "Add a comment",
                      input: "text",
                      showCancelButton: true,
                      closeOnConfirm: false,
                      inputPlaceholder: "Write a comment",
                      preConfirm: (inputValue) => {
                        return inputValue;
                      },
                    }).then((inputValue) => {
                      if (inputValue.isConfirmed) {
                        if (inputValue.value === null) return false;

                        if (inputValue.value === "") {
                          Swal.showInputError("You need to write something!");
                          return false;
                        }

                        orderService.addComment(
                          params.orderId,
                          inputValue.value,
                          (data) => {
                            orderService.getDetails(params.orderId, (order) => {
                              setOrder(order);
                            });
                          }
                        );
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div className="comments-body">
              {order.comments.map((comment) => {
                return (
                  <div className="comment">
                    <div className="image">
                      <img
                        src={
                          comment.user.profilePicture !== null
                            ? comment.user.profilePicture.url
                            : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                        }
                      />
                    </div>
                    <div className="text">
                      <div className="header">
                        <span className="name">
                          {comment.user.firstName} {comment.user.lastName}
                        </span>
                        <span className="time">
                          {new Date(comment.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                      <span className="comment-inside">{comment.comment}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {getWindowDimensions().width < 950 && isFilesShown && (
        <div className="files-container-side">
          <div className="files-container">
            <div className="files-header">
              <span className="title">Order Attachments</span>
              <div className="date">
                <AiFillCalendar size={20} color="#00D097" />
                <span style={{ color: "#00D097" }}>
                  Created {new Date(order.createdAt).toLocaleDateString()}
                </span>
              </div>
              <div
                className="icon-files"
                onClick={() => setIsFilesShown(false)}
              >
                <AiOutlineClose />
              </div>
            </div>
            {order.deliveredAt ? (
              <div
                style={{
                  marginTop: "1em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
                className="date"
              >
                <AiFillCalendar size={20} color="#00D097" />
                <span style={{ color: "#00D097" }}>
                  Delivered at:{" "}
                  {moment.unix(order.deliveredAt / 1000).format("DD/MM/YYYY")}
                </span>
              </div>
            ) : null}
            <div className="files-body">
              <div className="items deliveries">
                <span className="title">
                  Deliveries{" "}
                  {order.deliverables.length > 1
                    ? ` (${order.deliverables.length})`
                    : ""}
                </span>
                <div className="files">
                  {order.deliverables.map((delivery) => {
                    return (
                      <div
                        className="item"
                        onClick={() => {
                          if (
                            delivery.url
                              .slice(
                                delivery.url.length - 3,
                                delivery.url.length
                              )
                              .toLowerCase() === "mp4" ||
                            delivery.url
                              .slice(
                                delivery.url.length - 3,
                                delivery.url.length
                              )
                              .toLowerCase() === "mov"
                          ) {
                            setIsVideoReviewOpen(true);
                            setCurrentDeliverable(delivery);
                          } else {
                            window.open(delivery.url, "_blank").focus();
                          }
                        }}
                      >
                        <div className="icon">
                          <RiAttachment2 />
                        </div>
                        <div className="specs">
                          <span>{delivery.originalName}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="link-back-container">
        <Link
          to="../orders"
          className="link-back"
          style={{ textDecoration: "none" }}
        >
          <AiOutlineArrowLeft size={20} color="#2A9DF4" />
          <span>Back to My Orders</span>
        </Link>
        {getWindowDimensions().width < 950 && (
          <div className="icons">
            <div
              className="icon"
              onClick={() => setIsFilesShown(!isFilesShown)}
            >
              <AiOutlineFile />
            </div>
            <div
              className="icon"
              onClick={() => setCommentsShown(!commentsShown)}
            >
              <HiOutlineChat
                color={comments && comments.length ? "#00D097" : "black"}
              />
            </div>
          </div>
        )}
      </div>
      <div className="order-container">
        <div className="details" style={{ flex: !commentsShown ? 8 : 5 }}>
          <div className="delivery-header">
            <div className="title-dates">
              <span className="order-number">
                #{order.number} by {order.client.firstName}{" "}
                {order.client.lastName}
              </span>
              <span className="order-name">
                {order.basicDetails
                  ? order.basicDetails.title
                  : `Order #${order.number}`}
              </span>
              <div className="dates">
                <div className="date">
                  <AiFillCalendar size={20} color="#00D097" />
                  <span style={{ color: "#00D097" }}>
                    Created {new Date(order.createdAt).toLocaleDateString()}
                  </span>
                </div>
                <div className="date" style={{ position: "relative" }}>
                  <AiFillCalendar size={20} color="#DA4747" />
                  <span style={{ color: "#DA4747" }}>
                    Deadline{" "}
                    {new Date(parseInt(order.deadline)).toLocaleDateString()}
                  </span>
                  {user.type === "admin" && (
                    <span
                      style={{ color: "#DA4747", cursor: "pointer" }}
                      onClick={() => setCalendarOpen(!calendarOpen)}
                    >
                      <BsPencilFill />
                    </span>
                  )}
                  {calendarOpen && (
                    <div
                      className="calendar"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        position: "absolute",
                        top: 40,
                        right: 0,
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                        boxShadow: "0 6px 40px 0 #7991AD4D",
                        padding: ".6em",
                        zIndex: "10",
                      }}
                    >
                      <Calendar
                        className="react-calendar"
                        onChange={changeOrderDeadline}
                        value={dateValue}
                        defaultValue={new Date(Number(order.deadline))}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="actions">
              {params.orderId !== "revision" && (
                <div className="action">
                  <span className="title">Price</span>
                  <div style={{ color: "#EEC800", backgroundColor: "#F6F8B6" }}>
                    {formatCurrency(order.editorPay)}
                  </div>
                </div>
              )}
              <div className="action">
                <span className="title">Status</span>
                <div className={order.status}>
                  <span>{orderStatusName}</span>
                </div>
              </div>
              {params.orderId === "revision" && (
                <div
                  onClick={() => downloadTxtFile()}
                  className="action"
                  style={{ cursor: "pointer" }}
                >
                  <AiOutlinePrinter size={20} />
                  <span className="print">Print Invoice</span>
                </div>
              )}
            </div>
          </div>
          {order.status === "revision" &&
          order.revisionNotes &&
          order.revisionNotes !== null &&
          order.revisionNotes.length ? (
            <div className="revision-notes">
              <span className="revision-title">Revision Instructions</span>
              <span className="revision-text">
                {order.revisionNotes[order.revisionNotes.length - 1].note}
              </span>
            </div>
          ) : null}
          {order.status === "completed" && order.review && order.stars ? (
            <div className="review-notes">
              <span className="review-title">Review</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {new Array(order.stars).fill().map((_) => (
                  <AiFillStar color="#F1C40F" />
                ))}
                {new Array(5 - order.stars).fill().map((_) => (
                  <AiFillStar color="#E0E0E0" />
                ))}
              </div>
              <span className="review-text">{order.review}</span>
            </div>
          ) : null}
          <div className="order-instructions">
            {tableItems.map((tableItem, index) => {
              if (tableItem.shown) {
                return (
                  <div className="sub-instruction">
                    <div className="header">
                      <span className="main-title">{tableItem.name}</span>
                      {tableItem.open ? (
                        <AiFillMinusCircle
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const obj = tableItems[index];
                            obj.open = !obj.open;
                            setTableItems([
                              ...tableItems.slice(0, index),
                              obj,
                              ...tableItems.slice(index + 1),
                            ]);
                          }}
                          size={20}
                          color="#2A9DF4"
                        />
                      ) : (
                        <IoMdAddCircle
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const obj = tableItems[index];
                            obj.open = !obj.open;
                            setTableItems([
                              ...tableItems.slice(0, index),
                              obj,
                              ...tableItems.slice(index + 1),
                            ]);
                          }}
                          size={20}
                          color="#2A9DF4"
                        />
                      )}
                    </div>
                    {tableItem.open && tableItem.item}
                  </div>
                );
              }
            })}
          </div>
          {(order.status !== "completed" &&
            order.status !== "submitted" &&
            order.status !== "cancelled" &&
            order.status !== "draft") ||
          user.type === "admin" ? (
            <div className="footer">
              <button
                onClick={() => {
                  orderService.cancelOrder(order.id, (data) => {
                    orderService.getDetails(params.orderId, (order) => {
                      setOrder(order);
                    });
                  });
                }}
              >
                Cancel Order
              </button>
              {order.status === "pending" ? (
                <button
                  onClick={() => {
                    Swal.fire({
                      title: "Add a revision",
                      input: "textarea",
                      showCancelButton: true,
                      closeOnConfirm: false,
                      inputPlaceholder: "Write a revision",
                      preConfirm: (inputValue) => {
                        return inputValue;
                      },
                    }).then((inputValue, text) => {
                      if (inputValue.isConfirmed) {
                        if (inputValue.value === null) return false;

                        if (inputValue.value === "") {
                          Swal.showInputError("You need to write something!");
                          return false;
                        }

                        orderService.acceptSubmitionAdmin(
                          order.id,
                          false,
                          inputValue.value,
                          (data) => {
                            orderService.getDetails(params.orderId, (order) => {
                              setOrder(order);
                            });
                          }
                        );
                      }
                    });
                  }}
                >
                  Reject Submission
                </button>
              ) : null}
              {order.status === "pending" ? (
                <button
                  onClick={() => {
                    Swal.fire({
                      title: "Success",
                      text: "You just accepted this order submission. Now, the customer needs to approve it.",
                      heightAuto: false,
                      icon: "success",
                    }).then(() => {
                      orderService.acceptSubmitionAdmin(
                        order.id,
                        true,
                        null,
                        (data) => {
                          orderService.getDetails(params.orderId, (order) => {
                            setOrder(order);
                          });
                        }
                      );
                    });
                  }}
                >
                  Accept Submission
                </button>
              ) : null}
              {order.status !== "pending" ||
              (user.type === "admin" && order.status === "submitted") ? (
                <>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    onChange={async (e) => {
                      const file = e.target.files[0];
                      setLoading(true);

                      if (file) {
                        let data = new FormData();
                        data.append("file", file);

                        await uploadService.upload(
                          data,
                          (data) => {
                            setLoading(false);
                            orderService.addFile(
                              params.orderId,
                              data,
                              (data) => {
                                orderService.getDetails(
                                  params.orderId,
                                  (order) => {
                                    setOrder(order);
                                  }
                                );
                              }
                            );
                          },
                          setUploadProgress
                        );
                      }
                    }}
                    id="drop-files-add-delivery"
                  />
                  <label for="drop-files-add-delivery">Submit File</label>
                </>
              ) : null}
              {order.status !== "pending" ||
              (user.type === "admin" && order.status === "submitted") ? (
                <button
                  onClick={() => {
                    orderService.completeOrder(order.id, (data) => {
                      orderService.getDetails(params.orderId, (order) => {
                        setOrder(order);
                      });
                    });
                  }}
                >
                  Complete
                </button>
              ) : null}
            </div>
          ) : (
            <></>
          )}
        </div>
        {getWindowDimensions().width > 950 && (
          <div className="files-container">
            <div className="files-header">
              <div>Order attachments</div>

              <div className="icons">
                {getWindowDimensions().width > 950 && (
                  <div
                    className="icon"
                    onClick={() => setCommentsShown(!commentsShown)}
                  >
                    <HiOutlineChat
                      color={comments && comments.length ? "#00D097" : "black"}
                    />
                  </div>
                )}
              </div>
            </div>
            {order.deliveredAt ? (
              <div
                style={{
                  marginTop: "1em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                  fontWeight: 600,
                  fontSize: "12px",
                }}
                className="date"
              >
                <AiFillCalendar size={20} color="#00D097" />
                <span style={{ color: "#00D097" }}>
                  Delivered at:{" "}
                  {moment.unix(order.deliveredAt / 1000).format("DD/MM/YYYY")}
                </span>
              </div>
            ) : null}
            <div className="items deliveries">
              <span className="title">
                Deliveries{" "}
                {order.deliverables > 1 ? ` (${order.deliverables})` : ""}
              </span>
              <div className="files">
                {order.deliverables.map((delivery) => {
                  return (
                    <div
                      className="item"
                      onClick={() => {
                        if (
                          delivery.url
                            .slice(delivery.url.length - 3, delivery.url.length)
                            .toLowerCase() === "mp4" ||
                          delivery.url
                            .slice(delivery.url.length - 3, delivery.url.length)
                            .toLowerCase() === "mov"
                        ) {
                          setIsVideoReviewOpen(true);
                          setCurrentDeliverable(delivery);
                        } else {
                          window.open(delivery.url, "_blank").focus();
                        }
                      }}
                    >
                      <div className="icon">
                        <RiAttachment2 />
                      </div>
                      <div className="specs">
                        <span>{delivery.originalName}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="items filesRequired">
              {order.fileRequired &&
                order.fileRequired.files &&
                order.fileRequired.files && (
                  <span
                    className="title"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    Files Required{" "}
                  </span>
                )}
              {order.fileRequired &&
                order.fileRequired.files &&
                order.fileRequired.files && (
                  <div className="files">
                    {order.fileRequired.files.map((item) => {
                      return (
                        <div
                          className="item"
                          onClick={() => {
                            axios({
                              url: item.url,
                              method: "GET",
                              responseType: "blob",
                            }).then((response) => {
                              const href = URL.createObjectURL(response.data);
                              const link = document.createElement("a");
                              link.href = href;
                              link.setAttribute(
                                "download",
                                `${item.originalName}`
                              );
                              document.body.appendChild(link);
                              link.click();

                              document.body.removeChild(link);
                              URL.revokeObjectURL(href);
                            });
                          }}
                        >
                          <div className="icon">
                            <RiAttachment2 />
                          </div>
                          <div className="specs">
                            <span>{item.originalName}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
          </div>
        )}
        {commentsShown && getWindowDimensions().width > 950 && (
          <div className="comments-container">
            <div
              className="comments-back"
              onClick={() => setCommentsShown(false)}
            >
              <AiOutlineArrowLeft size={20} color="#2A9DF4" />
              <span>Close Comments</span>
            </div>
            <div className="comments">
              <div className="comments-header">
                <span className="title">Comments</span>
                <AiOutlinePlus
                  color="#2a9df4"
                  size={20}
                  className="add-comment"
                  onClick={() => {
                    Swal.fire({
                      title: "Add a comment",
                      input: "text",
                      showCancelButton: true,
                      closeOnConfirm: false,
                      inputPlaceholder: "Write a comment",
                      preConfirm: (inputValue) => {
                        return inputValue;
                      },
                    }).then((inputValue) => {
                      if (inputValue.isConfirmed) {
                        if (inputValue.value === null) return false;

                        if (inputValue.value === "") {
                          Swal.showInputError("You need to write something!");
                          return false;
                        }

                        orderService.addComment(
                          params.orderId,
                          inputValue.value,
                          (data) => {
                            orderService.getDetails(params.orderId, (order) => {
                              setOrder(order);
                            });
                          }
                        );
                      }
                    });
                  }}
                />
              </div>
              <div className="comments-content">
                {order.comments.map((comment) => {
                  return (
                    <div className="comment">
                      <div className="image">
                        <img
                          src={
                            comment.user.profilePicture !== null
                              ? comment.user.profilePicture.url
                              : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                          }
                        />
                      </div>
                      <div className="text">
                        <div className="header">
                          <span className="name">
                            {comment.user.firstName} {comment.user.lastName}
                          </span>
                          <span className="time">
                            {new Date(comment.createdAt).toLocaleDateString()}
                          </span>
                        </div>
                        <span className="comment-inside">
                          {comment.comment}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default AdminOrder;
