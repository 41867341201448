import { useEffect } from "react";
import "./TableRow.scss";

const TableRow = ({
  items,
  customStyle = false,
  customColor = false,
  fourth = false,
  customRowItemClass,
  click = () => {},
}) => {
  return (
    <div className="table-row-component">
      {items && items.length
        ? !items[0].order
          ? items.map((item, index) => {
              return (
                <div
                  onClick={() => click}
                  key={`Row #${index + 1} of current table`}
                  className={`row-container ${
                    customColor && index % 2 === 0 ? "gray-row" : ""
                  }`}
                  style={{
                    gridTemplateColumns:
                      customStyle === false
                        ? `${items[0].map(() => "1fr ")}`.replaceAll(",", "")
                        : customStyle,
                  }}
                >
                  {item.map((element, i) => {
                    return (
                      <div
                        className={`row-item${fourth ? " fourth" : ""} ${
                          customRowItemClass ? customRowItemClass : ""
                        }`}
                        key={`Item #${i + 1} of row #${
                          index + 1
                        } of current table`}
                      >
                        {element}
                      </div>
                    );
                  })}
                </div>
              );
            })
          : items.map((item, index) => {
              return (
                <div
                  onClick={() => click(item.order)}
                  key={`Row #${index + 1} of current table`}
                  className={`row-container clickable ${
                    customColor && index % 2 === 0 ? "gray-row" : ""
                  }`}
                  style={{
                    gridTemplateColumns:
                      customStyle === false
                        ? `${items[0].items.map(() => "1fr ")}`.replaceAll(
                            ",",
                            ""
                          )
                        : customStyle,
                  }}
                >
                  {item.items.map((element, i) => {
                    return (
                      <div
                        className={`row-item${fourth ? " fourth" : ""} ${
                          customRowItemClass ? customRowItemClass : ""
                        }`}
                        key={`Item #${i + 1} of row #${
                          index + 1
                        } of current table`}
                      >
                        {element}
                      </div>
                    );
                  })}
                </div>
              );
            })
        : null}
    </div>
  );
};

export default TableRow;
