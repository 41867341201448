import "./billingHistory.scss";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { AiFillCheckCircle, AiOutlineCalendar } from "react-icons/ai";
import { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { clientService } from "../../../../../services/client.service";
import TableHeader from "../../../../../components/table-header/TableHeader";
import TableRow from "../../../../../components/table-row/TableRow";
import moment from "moment";
import { orderService } from "../../../../../services/order.service";
import { PackageManagementService } from "../../../../../services/package-management.service";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";
import { BsPencilFill } from "react-icons/bs";
import Modal from "react-modal/lib/components/Modal";
import Thumbnail from "../../../../../assets/video-types/thumbnail.png";
import Banner from "../../../../../assets/video-types/banner.png";
import Logo from "../../../../../assets/video-types/logo.png";
import ConsultationCall from "../../../../../assets/video-types/consultation-call.png";
import Outro from "../../../../../assets/video-types/outro.png";
import Intro from "../../../../../assets/video-types/intro.png";
import { purchaseService } from "../../../../../services/package.service";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
  },
};

const BillingHistory = () => {
  const params = useParams();
  const [client, setClient] = useState({});
  const [clientPackage, setPackage] = useState({});
  const [seeDetails, setSeeDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditPackage, setIsEditPackage] = useState(false);

  const [orders, setOrders] = useState([]);
  const [ordersDeliveredLastMonth, setOrdersDeliveredLastMonth] = useState(0);

  const [features1, setFeatures1] = useState({});
  const [features2, setFeatures2] = useState({});
  const [features3, setFeatures3] = useState({});
  const [addOnsPrice, setAddOnsPrice] = useState(0);
  const [addOns, setAddOns] = useState([]);
  const [featuresDetails, setFeaturesDetails] = useState({});
  const [totalActiveOrders, setTotalActiveOrders] = useState(0);
  const [newPackageInfo, setNewPackageInfo] = useState([]);
  const [loadingPackageInfo, setLoadingPackageInfo] = useState(false);

  const getPackagesAndAddOns = async (myClient) => {
    let myPkg = myClient.package;
    await PackageManagementService.list((data) => {
      setAddOns(data.addOns);
      console.log(myPkg);
      setNewPackageInfo(myPkg);
      myPkg = data.packages.filter(
        (pkg) => pkg.title === myClient.package.name
      )[0];

      const arr1 = [];
      const arr2Package = [];
      const arr3AddOns = [];

      if (myClient.package.rawFootage === null) {
        arr1.push(`Unlimited minutes of raw provided footage per video`);
      }

      if (myClient.package.rawFootage > 0) {
        arr1.push(
          `Up to ${myClient.package.rawFootage} minutes of raw provided footage per video`
        );
      }

      if (myClient.package.runningVideo > 0) {
        arr1.push(
          `Up to ${myClient.package.runningVideo} minutes of running time per video`
        );
      }

      arr1.push(
        `${myClient.package.deliveryTime}-${
          myClient.package.deliveryTime + 1
        } days delivery guaranteed`
      );

      if (myClient.package.unlimitedStockFootage) {
        arr1.push("Unlimited Stock Footage");
      }

      arr1.push(`Unlimited Revisions`);

      if (myClient.package.thumbnails === null) {
        arr1.push(`Unlimited Thumbnails`);
      }

      if (myClient.package.banner === null) {
        arr1.push(`Unlimited Banners`);
      }

      if (myClient.package.logo === null) {
        arr1.push(`Unlimited Logos`);
      }

      if (myClient.package.consultationCall === null) {
        arr1.push(`Unlimited Consultation Calls`);
      }

      if (myClient.package.intro === null) {
        arr1.push(`Unlimited Intros`);
      }

      if (myClient.package.outro === null) {
        arr1.push(`Unlimited Outros`);
      }

      let newAddOnsPrice = myClient.package.additionalPrice;

      if (myClient.package.thumbnails > 0) {
        let count = 0;

        if (myClient.package.thumbnails > 0) {
          count = count + myClient.package.thumbnails;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "thumbnails").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr2Package.push(
          `${count - myClient.package.thumbnailsUsed}/${count} Thumbnail${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.additionalThumbnails > 0) {
        let count = 0;

        if (myClient.package.additionalThumbnails > 0) {
          count = count + myClient.package.additionalThumbnails;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "thumbnails").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr3AddOns.push(
          `${count} Additional Thumbnail${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.banner > 0) {
        let count = 0;

        if (myClient.package.banner > 0) {
          count = count + myClient.package.banner;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "banner").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr2Package.push(
          `${count - myClient.package.bannerUsed}/${count} Banner${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.additionalBanner > 0) {
        let count = 0;

        if (myClient.package.additionalBanner > 0) {
          count = count + myClient.package.additionalBanner;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "banner").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr3AddOns.push(
          `${count} Additional Banner${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.videos > 0) {
        let count = 0;

        count = count + myClient.package.videos;

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "videos").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr2Package.push(
          `${count - myClient.package.videosUsed}/${count} Video${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.logo > 0) {
        let count = 0;

        if (myClient.package.logo > 0) {
          count = count + myClient.package.logo;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "logo").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr2Package.push(
          `${count - myClient.package.logoUsed}/${count} Logo${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.additionalLogo > 0) {
        let count = 0;

        if (myClient.package.additionalLogo > 0) {
          count = count + myClient.package.additionalLogo;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "logo").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr3AddOns.push(
          `${count} Additional Logo${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.consultationCall > 0) {
        let count = 0;

        if (myClient.package.consultationCall > 0) {
          count = count + myClient.package.consultationCall;
        }
        let finalCost = (
          count *
          data.addOns.find((item) => item.codeId === "consultationCall").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr2Package.push(
          `${
            count - myClient.package.consultationCallUsed
          }/${count} Consultation Call${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.additionalConsultationCall > 0) {
        let count = 0;

        if (myClient.package.additionalConsultationCall > 0) {
          count = count + myClient.package.additionalConsultationCall;
        }

        let finalCost = (
          count *
          data.addOns.find((item) => item.codeId === "consultationCall").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr3AddOns.push(
          `${count} Additional Consultation Call${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.intro > 0) {
        let count = 0;

        if (myClient.package.intro > 0) {
          count = count + myClient.package.intro;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "intro").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr2Package.push(
          `${count - myClient.package.introUsed}/${count} Intro${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.additionalIntro > 0) {
        let count = 0;

        if (myClient.package.additionalIntro > 0) {
          count = count + myClient.package.additionalIntro;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "intro").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr3AddOns.push(
          `${count} Additional Intro${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.outro > 0) {
        let count = 0;

        if (myClient.package.outro > 0) {
          count = count + myClient.package.outro;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "outro").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr2Package.push(
          `${count - myClient.package.outroUsed}/${count} Outro${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      if (myClient.package.additionalOutro > 0) {
        let count = 0;

        if (myClient.package.additionalOutro > 0) {
          count = count + myClient.package.additionalOutro;
        }

        let finalCost = (
          count * data.addOns.find((item) => item.codeId === "outro").cost
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        arr3AddOns.push(
          `${count} Additional Outro${
            count === 1 ? "" : "s"
          } Left - ${finalCost}`
        );
      }

      setAddOnsPrice(newAddOnsPrice);

      if (myClient.package.nextPackage) {
        const arrDetails = [];

        if (myClient.package.nextPackage.rawFootage === null) {
          arrDetails.push(
            `Unlimited minutes of raw provided footage per video`
          );
        }

        if (myClient.package.nextPackage.rawFootage > 0) {
          arrDetails.push(
            `Up to ${myClient.package.nextPackage.rawFootage} minutes of raw provided footage per video`
          );
        }

        if (myClient.package.nextPackage.runningVideo > 0) {
          arrDetails.push(
            `Up to ${myClient.package.nextPackage.runningVideo} minutes of running time per video`
          );
        }

        arrDetails.push(
          `${myClient.package.nextPackage.deliveryTime}-${
            myClient.package.nextPackage.deliveryTime + 1
          } days delivery guaranteed`
        );

        if (myClient.package.nextPackage.unlimitedStockFootage) {
          arrDetails.push("Unlimited Stock Footage");
        }

        arrDetails.push(`Unlimited Revisions`);

        if (myClient.package.nextPackage.thumbnails === null) {
          arrDetails.push(`Unlimited Thumbnails`);
        }

        if (myClient.package.nextPackage.thumbnails > 0) {
          arrDetails.push(
            `${myClient.package.nextPackage.thumbnails} Thumbnail${
              myClient.package.nextPackage.thumbnails === 1 ? "" : "s"
            }*`
          );
        }

        if (myClient.package.nextPackage.banner > 0) {
          arrDetails.push(
            `${myClient.package.nextPackage.banner} Banner${
              myClient.package.nextPackage.banner === 1 ? "" : "s"
            }*`
          );
        }

        if (myClient.package.nextPackage.logo > 0) {
          arrDetails.push(
            `${myClient.package.nextPackage.logo} Logo${
              myClient.package.nextPackage.logo === 1 ? "" : "s"
            }*`
          );
        }

        if (myClient.package.nextPackage.consultationCall > 0) {
          arrDetails.push(
            `${
              myClient.package.nextPackage.consultationCall
            } Consultation Call${
              myClient.package.nextPackage.consultationCall === 1 ? "" : "s"
            }*`
          );
        }

        if (myClient.package.nextPackage.intro > 0) {
          arrDetails.push(
            `${myClient.package.nextPackage.intro} Personalized Intro${
              myClient.package.nextPackage.intro === 1 ? "" : "s"
            }*`
          );
        }

        if (myClient.package.nextPackage.outro > 0) {
          arrDetails.push(
            `${myClient.package.nextPackage.outro} Personalized Outro${
              myClient.package.nextPackage.outro === 1 ? "" : "s"
            }*`
          );
        }

        setFeaturesDetails(arrDetails);
      }

      setFeatures1(arr1);
      setFeatures2(arr2Package);
      setFeatures3(arr3AddOns);
      setLoading(false);
    });
  };

  useEffect(() => {
    clientService.getClientDetails(params.clientId, (data) => {
      setPackage(data.package);
      setClient(data);
      getPackagesAndAddOns(data);
    });
    orderService.listOrdersByUser(params.clientId, (data) => {
      let lastMonthEnd = moment().subtract(1, "months").endOf("month");
      let lastMonthStart = moment().subtract(1, "months").startOf("month");
      setOrders(data);
      setOrdersDeliveredLastMonth(
        data.filter((order) => {
          if (order.status === "completed") {
            let deliveryOrder = moment.unix(order.deliveredAt / 1000);
            if (deliveryOrder.isBetween(lastMonthStart, lastMonthEnd)) {
              return order;
            }
          }
        }).length
      );
      console.log();
      setTotalActiveOrders();
    });
  }, [params]);

  const changeCount = (operation, type, amount = 1) => {
    let newList = { ...newPackageInfo };
    if (operation === "plus") {
      newList[type] = newList[type] + amount;
    } else if (operation === "minus" && newList[type] > 0) {
      newList[type] = newList[type] - amount;
    }

    setNewPackageInfo(newList);
  };

  const changePackageInformation = async () => {
    setLoadingPackageInfo(true);
    await purchaseService.changeUserPackage(
      { newPackage: newPackageInfo },
      (response) => {
        setPackage(response.data);
        setLoadingPackageInfo(false);
      }
    );
  };

  if (loading) return <LoadingUpload text="Fetching billing info" />;

  return (
    <>
      {loadingPackageInfo ? (
        <LoadingUpload text="Updating user package" />
      ) : null}
      <div className="billing-history">
        <Modal
          isOpen={isEditPackage}
          onRequestClose={() => setIsEditPackage(false)}
          contentLabel="Edit package"
          style={customStyles}
        >
          <div className="billing-modal-wrapper">
            <div className="add-add-ons-container">
              <div className="add-add-ons">
                <div className="add-add-ons-header">
                  <span>Package - Monthly recharge</span>
                </div>
                <div className="add-ons">
                  <div className="add-on">
                    <div className="left">
                      <div className="details">
                        <span className="name">Video used</span>
                        <input
                          type="number"
                          value={newPackageInfo.videosUsed}
                          className="value-input"
                          min={0}
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              videosUsed: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="left">/</div>
                    <div className="left">
                      <div className="details recurring-package">
                        <span className="name">Total videos</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.videos}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              videos: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="details">
                        <span className="name">Thumbnails used</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.thumbnailsUsed}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              thumbnailsUsed: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="left">/</div>
                    <div className="left">
                      <div className="details recurring-package">
                        <span className="name">Total thumbnails</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.thumbnails}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              thumbnails: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="add-on">
                    <div className="left">
                      <div className="details">
                        <span className="name">Logos used</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.logoUsed}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              logoUsed: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="left">/</div>
                    <div className="left">
                      <div className="details recurring-package">
                        <span className="name">Total logo</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.logo}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              logo: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="details">
                        <span className="name">Intros used</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.introUsed}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              introUsed: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="left">/</div>
                    <div className="left">
                      <div className="details recurring-package">
                        <span className="name">Total intro</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.intro}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              intro: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="details">
                        <span className="name">Outro used</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.outroUsed}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              outroUsed: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="left">/</div>
                    <div className="left">
                      <div className="details recurring-package">
                        <span className="name">Total outro</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.outro}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              outro: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="details">
                        <span className="name">Banner used</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.bannerUsed}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              bannerUsed: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="left">/</div>
                    <div className="left">
                      <div className="details recurring-package">
                        <span className="name">Total banner</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.banner}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              banner: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="details">
                        <span className="name">Consultation call used</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.consultationCallUsed}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              consultationCallUsed: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="left">/</div>
                    <div className="left">
                      <div className="details recurring-package">
                        <span className="name">Total Consultation Call</span>
                        <input
                          type="number"
                          min={0}
                          value={newPackageInfo.consultationCall}
                          className="value-input"
                          onChange={(e) => {
                            setNewPackageInfo({
                              ...newPackageInfo,
                              consultationCall: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="add-add-ons-container">
              <div className="add-add-ons">
                <div className="add-add-ons-header">
                  <span>Add-Ons - One time usage</span>
                </div>
                <div className="add-ons">
                  <div className="add-on">
                    <div className="left">
                      <div className="image-container">
                        <img src={Thumbnail} alt="add-on-symbol" />
                      </div>
                      <div className="details">
                        <span className="name">Thumbnails</span>
                        <span className="price">
                          $
                          {
                            addOns.find(
                              (addOn) => addOn.codeId === "thumbnails"
                            ).cost
                          }
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <div
                        className="symbol"
                        onClick={() =>
                          changeCount("minus", "additionalThumbnails")
                        }
                      >
                        -
                      </div>
                      <div className="counter">
                        {newPackageInfo.additionalThumbnails}
                      </div>
                      <div
                        className="symbol"
                        onClick={() =>
                          changeCount("plus", "additionalThumbnails")
                        }
                      >
                        +
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="image-container">
                        <img src={Logo} alt="add-on-symbol" />
                      </div>
                      <div className="details">
                        <span className="name">Logo</span>
                        <span className="price">
                          $
                          {addOns.find((addOn) => addOn.codeId === "logo").cost}
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <div
                        className="symbol"
                        onClick={() => changeCount("minus", "additionalLogo")}
                      >
                        -
                      </div>
                      <div className="counter">
                        {newPackageInfo.additionalLogo}
                      </div>
                      <div
                        className="symbol"
                        onClick={() => changeCount("plus", "additionalLogo")}
                      >
                        +
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="image-container">
                        <img src={Banner} alt="add-on-symbol" />
                      </div>
                      <div className="details">
                        <span className="name">Banner</span>
                        <span className="price">
                          $
                          {
                            addOns.find((addOn) => addOn.codeId === "banner")
                              .cost
                          }
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <div
                        className="symbol"
                        onClick={() => changeCount("minus", "additionalBanner")}
                      >
                        -
                      </div>
                      <div className="counter">
                        {newPackageInfo.additionalBanner}
                      </div>
                      <div
                        className="symbol"
                        onClick={() => changeCount("plus", "additionalBanner")}
                      >
                        +
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="image-container">
                        <img src={ConsultationCall} alt="add-on-symbol" />
                      </div>
                      <div className="details">
                        <span className="name">Consultation Call</span>
                        <span className="price">
                          $
                          {
                            addOns.find(
                              (addOn) => addOn.codeId === "consultationCall"
                            ).cost
                          }
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <div
                        className="symbol"
                        onClick={() =>
                          changeCount("minus", "additionalConsultationCall")
                        }
                      >
                        -
                      </div>
                      <div className="counter">
                        {newPackageInfo.additionalConsultationCall}
                      </div>
                      <div
                        className="symbol"
                        onClick={() =>
                          changeCount("plus", "additionalConsultationCall")
                        }
                      >
                        +
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="image-container">
                        <img src={Outro} alt="add-on-symbol" />
                      </div>
                      <div className="details">
                        <span className="name">Outro</span>
                        <span className="price">
                          $
                          {
                            addOns.find((addOn) => addOn.codeId === "outro")
                              .cost
                          }
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <div
                        className="symbol"
                        onClick={() => changeCount("minus", "additionalOutro")}
                      >
                        -
                      </div>
                      <div className="counter">
                        {newPackageInfo.additionalOutro}
                      </div>
                      <div
                        className="symbol"
                        onClick={() => changeCount("plus", "additionalOutro")}
                      >
                        +
                      </div>
                    </div>
                  </div>

                  <div className="add-on">
                    <div className="left">
                      <div className="image-container">
                        <img src={Intro} alt="add-on-symbol" />
                      </div>
                      <div className="details">
                        <span className="name">Intro</span>
                        <span className="price">
                          $
                          {
                            addOns.find((addOn) => addOn.codeId === "intro")
                              .cost
                          }
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <div
                        className="symbol"
                        onClick={() => changeCount("minus", "additionalIntro")}
                      >
                        -
                      </div>
                      <div className="counter">
                        {newPackageInfo.additionalIntro}
                      </div>
                      <div
                        className="symbol"
                        onClick={() => changeCount("plus", "additionalIntro")}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div style={{ display: "flex", gap: "4px" }}>
              <span>Package final price:</span>
              <input
                type="number"
                min={0}
                className="final-value-input"
                value={newPackageInfo.price / 100}
                onChange={(e) => {
                  setNewPackageInfo({
                    ...newPackageInfo,
                    price: Number(e.target.value) * 100,
                  });
                }}
              />
            </div>
            <div>
              <button
                style={{
                  width: "fit-content",
                  alignSelf: "flex-end",
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#2A9DF4",
                  padding: "1em 2em",
                  fontWeight: "500",
                  border: 0,
                  borderRadius: "8px",
                }}
                onClick={changePackageInformation}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
        <div className="billing-history__back">
          <Link
            to={`../clients/${params.clientId}`}
            className="link-back"
            style={{ textDecoration: "none" }}
          >
            <AiOutlineArrowLeft size={20} color="#2A9DF4" />
            <span>Back</span>
          </Link>
        </div>
        <div className="billing-history__header">
          <h1>
            Invoice and billing history - {client.firstName} {client.lastName}
          </h1>
          <span>Review your billing history and manage your invoices.</span>
        </div>
        <div className="billing-history__features">
          <div className="features-list-wrapper">
            <div className="features-list">
              <span className="features-list-title">Available Features</span>
              {features1 &&
                features1.length &&
                features1.map((feature) => {
                  return (
                    <div className="feature">
                      <AiFillCheckCircle color="#2A9DF4" size={25} />
                      <span>{feature}</span>
                    </div>
                  );
                })}
            </div>
            <div className="features-list">
              <span className="features-list-title">Additional Info</span>
              <div className="feature">
                • Total order amount: {orders.length}
              </div>
              <div className="feature">
                • Total video requested:{" "}
                {
                  orders.filter((item) => {
                    if (item.basicDetails && item.basicDetails.type) {
                      return item;
                    }
                  }).length
                }
              </div>

              <div className="feature">
                • Videos delivered last month: {ordersDeliveredLastMonth}
              </div>
              <div className="feature">
                • Total active orders:{" "}
                {
                  orders.filter((item) => {
                    if (
                      item.status != "completed" &&
                      item.status != "cancelled" &&
                      item.status != "draft" &&
                      item.status !== "new"
                    ) {
                      return item;
                    }
                  }).length
                }
              </div>
            </div>
            <div className="features-list">
              <span className="features-list-title add-ons">
                Package Information
              </span>
              {features2 && features2.length
                ? features2.map((feature) => {
                    return (
                      <div className="feature">
                        <AiFillCheckCircle color="#2A9DF4" size={25} />
                        <span>{feature}</span>
                      </div>
                    );
                  })
                : null}
            </div>

            <div className="features-list">
              <span className="features-list-title add-ons">Add-Ons</span>
              {features3 && features3.length
                ? features3.map((feature) => {
                    return (
                      <div className="feature">
                        <AiFillCheckCircle color="#2A9DF4" size={25} />
                        <span>{feature}</span>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div className="details-col">
            <div className="details">
              <div className="price-name package-info">
                <div className="price-name-content">
                  <span className="name">{clientPackage.name} Package</span>
                  <span className="price">
                    ${clientPackage.price / 100}/
                    {clientPackage.type === "monthly" ? "mo" : "year"}
                  </span>
                </div>

                <BsPencilFill
                  onClick={() => setIsEditPackage(true)}
                  color="white"
                  fontSize={34}
                  cursor={"pointer"}
                />
              </div>
              <div className="dates">
                <div className="date">
                  <AiOutlineCalendar color="#6872CF" size={20} />
                  <div className="texts">
                    <span className="text-title">Last Billing Date</span>
                    <span className="actual-date">
                      {moment(clientPackage.lastBilling)
                        .tz(
                          window.Intl.DateTimeFormat().resolvedOptions()
                            .timeZone
                        )
                        .format("DD MMMM YYYY")}
                    </span>
                  </div>
                </div>
                <div className="vertical-line" />
                <div className="date">
                  <AiOutlineCalendar color="white" size={20} />
                  <div className="texts">
                    <span className="text-title">Next Billing Date</span>
                    <span className="actual-date">
                      {clientPackage.active
                        ? moment(clientPackage.nextBilling)
                            .tz(
                              window.Intl.DateTimeFormat().resolvedOptions()
                                .timeZone
                            )
                            .format("DD MMMM YYYY")
                        : "Cancelled"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="actions"></div>
              {clientPackage.active &&
              clientPackage.nextPackage &&
              clientPackage.nextPackage !== null ? (
                <div className="other-packages">
                  <span className="other-title">
                    Next Package - Starting on{" "}
                    {moment(clientPackage.nextBilling)
                      .tz(
                        window.Intl.DateTimeFormat().resolvedOptions().timeZone
                      )
                      .format("DD MMMM YYYY")}
                  </span>
                  <div className="option starter">
                    <div className="name-price">
                      <div className="text">
                        <span className="option-title">
                          {clientPackage.nextPackage.name}
                          <span
                            onClick={() => setSeeDetails(!seeDetails)}
                            className="see-details"
                          >
                            {" "}
                            - {seeDetails ? "Hide details" : "See details"}
                          </span>
                        </span>
                      </div>
                      <span className="price">
                        $
                        {clientPackage.nextPackage.price / 100 +
                          clientPackage.nextPackage.additionalPrice}
                        /
                        {clientPackage.nextPackage.type === "monthly"
                          ? "mo"
                          : "year"}
                      </span>
                    </div>
                    {seeDetails ? (
                      <div className="features-list details-features">
                        {featuresDetails &&
                          featuresDetails.length &&
                          featuresDetails
                            .filter((item) => !item.includes("*"))
                            .map((feature) => {
                              return (
                                <div className="feature">
                                  <AiFillCheckCircle
                                    color="#2A9DF4"
                                    size={20}
                                  />
                                  <span>{feature}</span>
                                </div>
                              );
                            })}

                        <div className="one-time-purchases-container">
                          <div></div>
                          <span>One-time Purchases</span>
                          <div></div>
                        </div>
                        {featuresDetails &&
                          featuresDetails.length &&
                          featuresDetails
                            .filter((item) => item.includes("*"))
                            .map((feature) => {
                              return (
                                <div className="feature">
                                  <AiFillCheckCircle
                                    color="#2A9DF4"
                                    size={20}
                                  />
                                  <span>{feature}</span>
                                </div>
                              );
                            })}
                        <span style={{ fontSize: "0.8em" }}>
                          * One-time purchase
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
            {addOnsPrice > 0 ? (
              <div className="details">
                <div className="price-name">
                  <span className="name">Add-Ons</span>
                  <span className="price">
                    ${addOnsPrice}.00 one-time purchase
                  </span>
                </div>
              </div>
            ) : null}
            <div className="details black">
              <div className="price-name">
                <span className="name">{moment().format("MMMM")} Total</span>
                <span className="name-description">
                  Current month’s package plus any add-ons
                </span>
                <span className="price">
                  ${clientPackage.price / 100 + addOnsPrice}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="billing-history__body">
          <div className="table">
            <TableHeader
              customStyle="1fr 1fr .75fr .5fr .5fr 2.5fr"
              titles={[
                "Date",
                "Type",
                "Order Number",
                "Package",
                "Amount",
                "Action",
              ]}
              customClass="billing-history-header"
            />
            {client && client.bills && client.bills.length !== 0 ? (
              <div className="billing-history-table">
                <TableRow
                  customStyle="1fr 1fr .75fr .5fr .5fr 2.5fr"
                  customRowItemClass="billing-history-row"
                  items={client.bills.map((item, index) => {
                    return [
                      <div className="billing-history-table__item">
                        <span>{moment(item.date).format("MMM DD, YYYY")}</span>
                      </div>,
                      <div className="billing-history-table__item">
                        <span>{item.type}</span>
                      </div>,
                      <div className="billing-history-table__item">
                        <span>{index + 1}</span>
                      </div>,
                      <div
                        className="billing-history-table__item"
                        style={{ color: "#3B8755" }}
                      >
                        <span>{client.package.name}</span>
                      </div>,
                      <div className="billing-history-table__item">
                        <span>
                          {item.amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </span>
                      </div>,
                      <div className="billing-history-table__item button-field">
                        <button>View PDF</button>
                        <button>Download PDF</button>
                        <button>Email PDF</button>
                      </div>,
                    ];
                  })}
                />
              </div>
            ) : (
              <>
                <span>No records found</span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingHistory;
