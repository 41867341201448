import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./customPackageRequestsOrders.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { reference } from "../../ordersReference";
import { createHTMLOrder, createHTMLOrderPrint } from "../../setItemsPrint";
import moment from "moment-timezone";
import { orderService } from "../../../services/order.service";
import { useLocation, useOutletContext } from "react-router";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const CustomPackageRequestsOrders = ({
  orders,
  getOrders,
  ordersCount,
  setOrders,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    // console.log(orders);
    setCurrentOrders(orders);
  }, [orders]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="custom-requests-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            customStyle="1fr .5fr .5fr 1.5fr 1fr .5fr"
            fourth={true}
            titles={[
              "Name",
              "Budget",
              "Status",
              "Project Details",
              "",
              "Create package",
            ]}
          />
          <div className="order-table">
            <TableRow
              customStyle="1fr .5fr .5fr 1.5fr 1fr .5fr"
              fourth={true}
              items={currentOrders.map((order, index) => {
                return [
                  <span style={{ fontWeight: 500 }}>{order.name}</span>,
                  <span style={{ fontWeight: 500 }}>${order.budget}</span>,
                  <div className={`status ${order.status}`}>
                    <span>
                      {
                        reference.filter(
                          (item) => item.status === order.status
                        )[0].name
                      }
                    </span>
                  </div>,
                  <span style={{ fontWeight: 500 }}>{order.email}</span>,
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "flex-start",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span style={{ fontWeight: 500, textAlign: "start" }}>
                      {order.description.length > 20 && !order.open
                        ? `${order.description.substring(0, 20)}...`
                        : order.description}
                    </span>
                    {order.description.length > 20 ? (
                      <span
                        style={{
                          color: "#2a9df4",
                          cursor: "pointer",
                          fontWeight: "500",
                        }}
                        onClick={() => {
                          setOrders(
                            [...orders].map((o) => {
                              if (o.id === order.id) {
                                o.open = !o.open;
                              }

                              return o;
                            })
                          );
                        }}
                      >
                        Read {order.open ? "less" : "more"}
                      </span>
                    ) : null}
                  </div>,
                  <span
                    style={{ fontWeight: 500, cursor: "pointer" }}
                    onClick={() => {
                      // console.log(order);
                      navigate("../packages/custom-package", {
                        state:
                          state &&
                          state.update &&
                          state.update !== null &&
                          state.update === true
                            ? { update: true, customPackageRequested: order }
                            : { update: false, customPackageRequested: order },
                      });
                    }}
                  >
                    Create package
                  </span>,
                  // <div
                  //   style={{
                  //     display: "flex",
                  //     alignContent: "center",
                  //     alignItems: "center",
                  //     justifyContent: "center",
                  //     gap: "1em",
                  //   }}
                  // >
                  //   <button className="button accept">Accept</button>
                  //   <button className="button deny">Deny</button>
                  // </div>,
                ];
              })}
            />
          </div>
        </>
      ) : (
        <>
          <>
            {currentOrders.map((order, index) => {
              return (
                <div className="order-mobile">
                  <div className="header-mobile">
                    <div className={`status ${order.status}`}>
                      <span>
                        {
                          reference.filter(
                            (item) => item.status === order.status
                          )[0].name
                        }
                      </span>
                    </div>
                    <div className="details">
                      <span className="name">
                        {order.name} - {order.email}
                      </span>
                      <span className="number">${order.budget}</span>
                    </div>
                  </div>
                  <div className="body-mobile">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "flex-start",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <span style={{ fontWeight: 500, textAlign: "start" }}>
                        {order.description.length > 20 && !order.open
                          ? `${order.description.substring(0, 20)}...`
                          : order.description}
                      </span>
                      {order.description.length > 20 ? (
                        <span>Read more</span>
                      ) : null}
                    </div>
                    ,
                    {/* <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1em",
                      }}
                    >
                      <button className="button accept">Accept</button>
                      <button className="button deny">Deny</button>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </>
        </>
      )}
      {/* <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {ordersCount > currentPage * 6 ? currentPage * 6 : ordersCount} of{" "}
            {ordersCount}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={ordersCount < currentPage * 6 ? "disable" : "active"}
            onClick={nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default CustomPackageRequestsOrders;
