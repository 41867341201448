import { coreService } from "./core.service";

const getProfile = (callback, error = null) => {
  coreService.get("user/profile", callback, "json", error);
};

const getProfileDetails = (type, callback) => {
  coreService.get(`user/details/${type}`, callback);
};

const listUsers = (type, callback) => {
  coreService.get(`user/list/${type}`, callback);
};

const withdraw = (callback) => {
  coreService.post(`user/withdraw`, {}, callback);
};

const updateUser = (user, callback) => {
  coreService.put(`user/update`, user, callback);
};

const startNextPackage = (client, callback) => {
  coreService.post(`user/next-package`, { client }, callback);
};

const removeUser = (userId, callback) => {
  coreService.post("user/remove-user", { userId }, callback);
};

export const userService = {
  getProfile,
  listUsers,
  withdraw,
  getProfileDetails,
  updateUser,
  startNextPackage,
  removeUser,
};
