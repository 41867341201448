import {
  BiPieChartAlt2,
  BiPackage,
  BiShoppingBag,
  BiWallet,
  BiEnvelope,
  BiMessageSquareDetail,
  BiBriefcaseAlt,
  BiUser,
} from "react-icons/bi";
import { BsMegaphone } from "react-icons/bs";

const sideBarItemsEditor = [
  {
    name: "Dashboard",
    heightAuto: false,
    icon: <BiPieChartAlt2 />,
    path: "editor/dashboard",
    id: "dashboard",
    show: true,
  },
  {
    name: "My Orders",
    heightAuto: false,
    icon: <BiShoppingBag />,
    path: "editor/orders",
    id: "orders",
    show: true,
  },
  {
    name: "My Earnings",
    heightAuto: false,
    icon: <BiWallet />,
    path: "editor/earnings",
    id: "earnings",
    show: true,
  },
  {
    name: "Inbox",
    heightAuto: false,
    icon: <BiEnvelope />,
    path: "editor/inbox",
    id: "inbox",
    show: true,
  },
  {
    name: "Help",
    heightAuto: false,
    icon: <BiMessageSquareDetail />,
    path: "editor/support",
    id: "support",
    show: true,
  },
  {
    name: "My Profile",
    id: "profile",
    show: false,
  },
];

const sideBarItemsAdmin = [
  {
    name: "Dashboard",
    heightAuto: false,
    icon: <BiPieChartAlt2 />,
    path: "admin/dashboard",
    id: "dashboard",
    show: true,
  },
  {
    name: "My Orders",
    heightAuto: false,
    icon: <BiShoppingBag />,
    path: "admin/orders",
    id: "orders",
    show: true,
  },
  {
    name: "Inbox",
    heightAuto: false,
    icon: <BiEnvelope />,
    path: "admin/inbox",
    id: "inbox",
    show: true,
  },
  {
    name: "Editors",
    heightAuto: false,
    icon: <BiUser />,
    path: "admin/editors",
    id: "editors",
    show: true,
  },
  {
    name: "Clients",
    heightAuto: false,
    icon: <BiBriefcaseAlt />,
    path: "admin/clients",
    id: "clients",
    show: true,
  },
  {
    name: "Packages",
    heightAuto: false,
    icon: <BiPackage />,
    path: "admin/packages",
    id: "packages",
    show: true,
  },
  {
    name: "Promos",
    heightAuto: false,
    icon: <BsMegaphone />,
    path: "admin/promos",
    id: "promos",
    show: true,
  },
  {
    name: "Help",
    heightAuto: false,
    icon: <BiMessageSquareDetail />,
    path: "admin/support",
    id: "support",
    show: true,
  },
  {
    name: "My Profile",
    id: "profile",
    show: false,
  },
];

const sideBarItemsCustomer = [
  {
    name: "Dashboard",
    heightAuto: false,
    icon: <BiPieChartAlt2 />,
    path: "admin/dashboard",
    id: "dashboard",
    show: true,
    locked: true,
  },
  {
    name: "My Orders",
    heightAuto: false,
    icon: <BiShoppingBag />,
    path: "admin/orders",
    id: "orders",
    show: true,
    locked: true,
  },
  {
    name: "Inbox",
    heightAuto: false,
    icon: <BiEnvelope />,
    path: "admin/dashboard",
    id: "inbox",
    show: true,
    locked: true,
  },
  {
    name: "Packages",
    heightAuto: false,
    icon: <BiPackage />,
    path: "admin/packages",
    id: "packages",
    show: true,
  },
  {
    name: "Help",
    heightAuto: false,
    icon: <BiMessageSquareDetail />,
    path: "admin/dashboard",
    id: "support",
    show: true,
    locked: true,
  },
  {
    name: "My Profile",
    id: "profile",
    show: false,
  },
];

export const sideBarItems = {
  sideBarItemsEditor,
  sideBarItemsAdmin,
  sideBarItemsCustomer,
};
