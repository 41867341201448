import "./SideBar.scss";
import { sideBarItems } from "./sideBarItems";
import Logo from "../../assets/logo.png";
import { BiLogOut } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { BsLock } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { compare } from "../../utils/sorting";

const SideBar = ({
  notifications,
  id,
  changePageName,
  changePage,
  type,
  goBackOrders,
  setNotifications,
}) => {
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    const newSocket = io(
      `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_API_BASE_URL_DEV
          : process.env.REACT_APP_API_BASE_URL_PROD
      }notification`,
      {
        query: { token: Cookies.get("token") },
      }
    );
    setSocket(newSocket);
  }, []);

  const readNotification = () => {
    socket.emit("notification:read");
    socket.on("notification:read-return", (data) => {
      const sortedNotifications = data.sort(compare);
      setNotifications(sortedNotifications);
    });
  };

  const navigate = useNavigate();
  return (
    <div className="sidebar-component">
      <div className="header">
        <img src={Logo} alt="logo" />
      </div>
      <div className="items">
        {(type === "editor"
          ? sideBarItems.sideBarItemsEditor
          : type === "admin"
          ? sideBarItems.sideBarItemsAdmin
          : sideBarItems.sideBarItemsCustomer
        ).map((item, index) => {
          if (item.show) {
            return type === "customer-test" ? (
              item.locked ? (
                <div className={`item`} key={`Sidebar item #${index + 1}`}>
                  <span className="icon">{item.icon}</span>
                  <span>{item.name}</span>
                  <span style={{ marginLeft: "1em" }}>
                    <FaLock />
                  </span>
                </div>
              ) : item.name === "Packages" ? (
                <div
                  className="packages-container"
                  id={`sidebar-item-${item.name
                    .replace(/ /g, "")
                    .toLowerCase()}-${type}`}
                >
                  <Link
                    key={`Sidebar item #${index + 1}`}
                    onClick={() => {
                      changePage(item.id);
                      changePageName(item.name);
                      if (goBackOrders) {
                        goBackOrders("All Orders");
                      }
                    }}
                    to={`./${item.id}`}
                    className={`item ${id === item.id ? "active" : ""}`}
                  >
                    <span className="icon">{item.icon}</span>
                    <span>{item.name}</span>
                  </Link>
                  {/* {id === "dashboard" ? (
                    <div className="arrow">
                      <AiOutlineArrowLeft className="icon-arrow" />
                      <span>Please start with selecting a package</span>
                    </div>
                  ) : null} */}
                </div>
              ) : (
                <Link
                  key={`Sidebar item #${index + 1}`}
                  onClick={() => {
                    changePage(item.id);
                    changePageName(item.name);
                    if (goBackOrders) {
                      goBackOrders("All Orders");
                    }
                  }}
                  to={`./${item.id}`}
                  className={`item ${id === item.id ? "active" : ""}`}
                >
                  <span className="icon">{item.icon}</span>
                  <span>{item.name}</span>
                </Link>
              )
            ) : (
              <div
                className="sideber-item-free"
                id={`sidebar-item-${item.name
                  .replace(/ /g, "")
                  .toLowerCase()}-${type}`}
                onClick={item.name === "Inbox" ? readNotification : null}
              >
                <Link
                  key={`Sidebar item #${index + 1}`}
                  onClick={() => {
                    changePage(item.id);
                    changePageName(item.name);
                    if (goBackOrders) {
                      goBackOrders("All Orders");
                    }
                  }}
                  to={`./${item.id}`}
                  className={`item ${id === item.id ? "active" : ""}`}
                >
                  <span className="icon">{item.icon}</span>
                  <span>{item.name}</span>
                </Link>
                {notifications.filter((item) => !item.read && item.chatId) &&
                notifications.filter((item) => !item.read && item.chatId)
                  .length &&
                item.name === "Inbox" ? (
                  <div className="red-dot"></div>
                ) : null}
              </div>
            );
          }
        })}
      </div>
      <div className="footer">
        <div className="items">
          <div
            className="item logout"
            onClick={() => {
              Cookies.remove("token");
              navigate("/");
            }}
          >
            <span className="icon">
              <BiLogOut />
            </span>
            <span>Logout</span>
          </div>
          <div className="terms-and-policy">
            <span
              onClick={() => {
                navigate("/terms-of-service");
              }}
            >
              Terms of Use
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
