import TableHeader from "../table-header/TableHeader";
import TableRow from "../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./EarningsComponent.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const EarningsComponent = ({ orders, getOrders, ordersCount }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const formatCurrency = (amount) => {
    let newAmount = "";
    let arrCount = 0;
    let amountToCheck = `${parseFloat(amount).toFixed(0)}`;
    const arr = `${amountToCheck}`.split("").reverse();
    arr.map((letter) => {
      arrCount++;
      newAmount = `${letter}${newAmount}`;
      if (arrCount !== arr.length && arrCount % 3 === 0) {
        newAmount = `,${newAmount}`;
      }
    });

    let result = `$${newAmount}.00`;

    return result;
  };

  return orders && orders.length ? (
    <div className="earnings-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            titles={[
              "Name",
              "Order Id",
              "Date Placed",
              "Date Delivered",
              "Status",
              "Order Cost",
            ]}
          />
          <div className="order-table">
            <TableRow
              items={currentOrders.map((order, index) => {
                return [
                  <span style={{ fontWeight: 500 }}>
                    {order.basicDetails
                      ? order.basicDetails.title
                      : `Order #${order.number}`}
                  </span>,
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      alignItems: "stretch",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ color: "#A0AEC7", fontSize: ".8em" }}>
                      #{order.number}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {new Array(order.stars).fill().map((_) => (
                        <AiFillStar color="#F1C40F" />
                      ))}
                      {new Array(5 - order.stars).fill().map((_) => (
                        <AiFillStar color="#E0E0E0" />
                      ))}
                    </div>
                  </div>,
                  <span>{new Date(order.createdAt).toLocaleDateString()}</span>,
                  <span>
                    {new Date(order.deliveredAt).toLocaleDateString()}
                  </span>,
                  <div className="status">
                    <span>Completed</span>
                  </div>,
                  <span>{formatCurrency(order.editorPay)}</span>,
                  //   <div
                  //     style={{
                  //       width: "30px",
                  //       height: "30px",
                  //       position: "relative",
                  //       display: "flex",
                  //       alignContent: "center",
                  //       alignItems: "center",
                  //       justifyContent: "center",
                  //     }}
                  //   >
                  //     <div
                  //       onClick={() => {
                  //         const obj = currentOrders[index];
                  //         obj.open = !obj.open;
                  //         setCurrentOrders([
                  //           ...currentOrders.slice(0, index),
                  //           obj,
                  //           ...currentOrders.slice(index + 1),
                  //         ]);
                  //       }}
                  //       style={{
                  //         position: "absolute",
                  //         backgroundColor: "#F2F5FA",
                  //         width: "30px",
                  //         height: "30px",
                  //         alignContent: "center",
                  //         alignItems: "center",
                  //         justifyContent: "center",
                  //         display: "flex",
                  //         fontSize: "1.2em",
                  //         borderRadius: "7px",
                  //         cursor: "pointer",
                  //       }}
                  //     >
                  //       <AiOutlineMore />
                  //     </div>
                  //     {order.open && (
                  //       <div
                  //         style={{
                  //           borderRadius: "10px",
                  //           backgroundColor: "white",
                  //           position: "absolute",
                  //           top: 40,
                  //           right: 0,
                  //           display: "flex",
                  //           alignContent: "center",
                  //           alignItems: "center",
                  //           justifyContent: "space-between",
                  //           boxShadow: "0 6px 40px 0 #7991AD4D",
                  //           padding: ".3em",
                  //         }}
                  //       >
                  //         <div
                  //           onClick={() => navigate(`./${order.id}`)}
                  //           style={{
                  //             fontWeight: "600",
                  //             padding: "0 .5em",
                  //             marginRight: ".5em",
                  //             backgroundColor: "#F4F9FD",
                  //             height: "30px",
                  //             flexWrap: "nowrap",
                  //             whiteSpace: "nowrap",
                  //             alignContent: "center",
                  //             alignItems: "center",
                  //             justifyContent: "center",
                  //             display: "flex",
                  //             fontSize: ".8em",
                  //             borderRadius: "7px",
                  //             cursor: "pointer",
                  //           }}
                  //         >
                  //           Show More
                  //         </div>
                  //         <div
                  //           onClick={() => downloadTxtFile(order)}
                  //           style={{
                  //             backgroundColor: "#F4F9FD",
                  //             width: "30px",
                  //             height: "30px",
                  //             alignContent: "center",
                  //             alignItems: "center",
                  //             justifyContent: "center",
                  //             display: "flex",
                  //             fontSize: "1.2em",
                  //             borderRadius: "7px",
                  //             cursor: "pointer",
                  //           }}
                  //         >
                  //           <AiOutlinePrinter />
                  //         </div>
                  //       </div>
                  //     )}
                  //   </div>,
                ];
              })}
            />
          </div>
        </>
      ) : (
        <>
          {currentOrders.map((order, index) => {
            return (
              <div className="order-mobile">
                <div className="header-mobile">
                  <div className="status">
                    <span>Completed</span>
                  </div>
                  <div className="details">
                    <span className="name">
                      {order.basicDetails
                        ? order.basicDetails.title
                        : `Order #${order.number}`}
                    </span>
                    <span className="number">#{order.number}</span>
                  </div>
                </div>
                <div className="body-mobile">
                  <span>${order.editorPay}</span>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      position: "relative",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* <div
                      onClick={() => {
                        const obj = currentOrders[index];
                        obj.open = !obj.open;
                        setCurrentOrders([
                          ...currentOrders.slice(0, index),
                          obj,
                          ...currentOrders.slice(index + 1),
                        ]);
                      }}
                      style={{
                        position: "absolute",
                        backgroundColor: "#F2F5FA",
                        width: "30px",
                        height: "30px",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "1.2em",
                        borderRadius: "7px",
                        cursor: "pointer",
                      }}
                    >
                      <AiOutlineMore />
                    </div>
                    {order.open && (
                      <div
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "white",
                          position: "absolute",
                          top: 40,
                          right: 0,
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "space-between",
                          boxShadow: "0 6px 40px 0 #7991AD4D",
                          padding: ".3em",
                        }}
                      >
                        <div
                          onClick={() => navigate(`./${order.id}`)}
                          style={{
                            fontWeight: "600",
                            padding: "0 .5em",
                            marginRight: ".5em",
                            backgroundColor: "#F4F9FD",
                            height: "30px",
                            flexWrap: "nowrap",
                            whiteSpace: "nowrap",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: ".8em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Show More
                        </div>
                        <div
                          onClick={() => downloadTxtFile(order)}
                          style={{
                            backgroundColor: "#F4F9FD",
                            width: "30px",
                            height: "30px",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: "1.2em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          <AiOutlinePrinter />
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {orders.length > currentPage * 6 ? currentPage * 6 : orders.length}{" "}
            of {orders.length}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={orders.length < currentPage * 6 ? "disable" : "active"}
            onClick={nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

export default EarningsComponent;
