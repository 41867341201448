import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import "./Dashboard.scss";
import "react-calendar/dist/Calendar.css";
import NoDataDashboard from "../../../../assets/dashboard-no-data.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LatestOrders from "../../../../components/ordersAdmin/latestOrders/LatestOrders";
import { BiMenuAltRight } from "react-icons/bi";
import { BsDot } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import CircularBar from "react-multicolor-circular-progress-bar";
import { orderService } from "../../../../services/order.service";
import moment from "moment-timezone";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AdminDashboard = () => {
  const [numberOfVideos, setNumberOfVideos] = useState(1);
  const [dateValue, setDateValue] = useState(new Date());
  const [isNavBarShown, setIsNavBarShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [dayOrders, setDayOrders] = useState([]);
  const [rating, setRating] = useState(0);
  const [money, setMoney] = useState(0);
  const [revenue, setRenevue] = useState(0);
  const [editorPay, setEditorPay] = useState(0);
  const [netIncome, setNetIncome] = useState(0);
  const [angles, setAngles] = useState([0, 0]);
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  const [editorsPay, setEditorsPay] = useState({});
  const [orderNumber, setOrderNumber] = useState({});
  const [editorsPayLabels, setEditorsPayLabels] = useState([]);
  const [editorsPayData, setEditorsPayData] = useState([]);
  const [orderNumberLabels, setOrderNumberLabels] = useState([]);
  const [orderNumberData, setOrderNumberData] = useState([]);
  const [marks, setMarks] = useState([]);
  const [orderNumberGraph, setOrderNumberGraph] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: "#00D097",
      },
    ],
  });
  const [editorsPayGraph, setEditorsPayGraph] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: "#00D097",
      },
    ],
  });

  useEffect(() => {
    orderService.listOrders(
      1,
      { admin: true, type: "all", order: "normal" },
      (data) => {
        setOrders(data.orders);
        setOrdersCount(data.count);

        orderService.listOrders(
          1,
          { admin: true, type: "all", listAll: true },
          (data) => {
            console.log(data);
            setMarks(
              data.orders.map((item) => {
                return moment(item.deadline)
                  .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format("DD-MM-YYYY");
              })
            );
          }
        );
      }
    );
  }, []);

  useEffect(() => {
    orderService.getOverview((data) => {
      setRating(data.rating);
      setRenevue(data.revenue);
      setEditorPay(data.editorPay);
      setNetIncome(data.netIncome);
      setMoney(data.money);

      console.log(ordersCount, data.differentStatus);

      const anglesSingleUnit =
        ordersCount > 0 ? Math.floor(359 / ordersCount) : null;
      setAngles([
        anglesSingleUnit !== null
          ? 360 -
            (data.differentStatus.progress + data.differentStatus.rejected) *
              anglesSingleUnit
          : 359,
        anglesSingleUnit !== null
          ? 360 - data.differentStatus.rejected * anglesSingleUnit
          : 360,
      ]);

      setCompletedPercentage(
        ordersCount > 0
          ? Math.floor((data.differentStatus.completed / ordersCount) * 100)
          : 0
      );
    });
    orderService.getGraphs((data) => {
      setOrderNumber(data.orderNumber);
      setEditorsPay(data.money);
    });
  }, [orders]);

  useEffect(() => {
    setOrderNumberLabels(
      Object.keys(JSON.parse(JSON.stringify(orderNumber))).map(
        (data, index) => {
          return data;
        }
      )
    );

    setEditorsPayLabels(
      Object.keys(JSON.parse(JSON.stringify(editorsPay))).map((data, index) => {
        return data;
      })
    );

    setOrderNumberData(Object.values(JSON.parse(JSON.stringify(orderNumber))));
    setEditorsPayData(Object.values(JSON.parse(JSON.stringify(editorsPay))));
  }, [orderNumber, editorsPay]);

  useEffect(() => {
    setEditorsPayGraph({
      labels: editorsPayLabels,
      datasets: [
        {
          data: editorsPayData,
          borderColor: "#00D097",
        },
      ],
    });

    setOrderNumberGraph({
      labels: orderNumberLabels,
      datasets: [
        {
          data: orderNumberData,
          borderColor: "#00D097",
        },
      ],
    });
  }, [orderNumberData, orderNumberLabels, editorsPayData, editorsPayLabels]);

  useEffect(() => {
    orderService.getOrdersOfDay(
      {
        date: dateValue,
        tz: window.Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      (data) => {
        setDayOrders(data);
      }
    );
  }, [dateValue]);

  const config = {
    fontFamily: "roboto",
    gapGradient: 0,
    angleTransition: angles,
    colors: ["#00D097", "#FFB756", "#DA4747"],
    semiCircular: false,
    title: {
      name: `${completedPercentage}%`,
      position: {
        X: 0,
        Y: 0,
      },
      fontSize: 17,
      fontWeight: "bold",
      color: "#00D097",
      align: "middle",
    },
    percent: {
      value: 100,
      showValue: false,
    },
    stroke: {
      color: "#eeeeee",
      width: 10,
    },
  };

  const mark = ["09-01-2023", "10-01-2023"];

  return numberOfVideos === 0 ? (
    <div className="admin-layout-dashboard-page empty">
      <div className="empty-container">
        <img src={NoDataDashboard} />
        <span>No data to display</span>
      </div>
    </div>
  ) : (
    <div className="admin-layout-dashboard-page">
      {getWindowDimensions().width < 450 && isNavBarShown && (
        <div className="side-nav-mobile">
          <div className="side-bar-container">
            <div className="side-nav-header">
              <span className="title">Details</span>
              <div className="icon" onClick={() => setIsNavBarShow(false)}>
                <AiOutlineClose />
              </div>
            </div>
            <div className="calendar">
              <Calendar
                className="react-calendar"
                onChange={setDateValue}
                value={dateValue}
                tileContent={({ date, view }) => {
                  if (
                    view === "month" &&
                    marks.find(
                      (currentDay) =>
                        currentDay ===
                        moment(date)
                          .tz(
                            window.Intl.DateTimeFormat().resolvedOptions()
                              .timeZone
                          )
                          .format("DD-MM-YYYY")
                    )
                  ) {
                    return <BsDot className="day-with-order" />;
                  }
                }}
              />
              <div className="tasks">
                {dayOrders.map((task) => {
                  return (
                    <div className="task">
                      <span className="time">
                        {new Date(parseInt(task.deadline)).toLocaleDateString()}
                      </span>
                      <span className="name">
                        {task.basicDetails
                          ? task.basicDetails.title
                          : `Order #${task.number}`}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="orders_list">
              <div className="header">
                <span>Orders</span>
              </div>
              <div className="line" />
              <div className="multi_circular">
                <CircularBar {...config} scale={2} />
              </div>
              <div className="legend">
                <div className="item">
                  <div
                    style={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "100%",
                      backgroundColor: "#00D097",
                    }}
                  ></div>
                  <span>Completed</span>
                </div>
                <div className="item">
                  <div
                    style={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "100%",
                      backgroundColor: "#FFB756",
                    }}
                  ></div>
                  <span>In Progress</span>
                </div>
                <div className="item">
                  <div
                    style={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "100%",
                      backgroundColor: "#DA4747",
                    }}
                  ></div>
                  <span>Cancelled</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-dashboard">
        {getWindowDimensions().width < 450 && (
          <div className="icon" onClick={() => setIsNavBarShow(true)}>
            <BiMenuAltRight />
          </div>
        )}
        <div className="graphs">
          <div className="graph">
            <div className="header">
              <span>Editors' Pay</span>
            </div>
            <Line
              className="graph-inside"
              data={editorsPayGraph}
              options={{
                scales: {
                  x: {
                    ticks: {
                      autoSkip: true,
                      maxTicksLimit: 7,
                      maxRotation: 30,
                      minRotation: 30,
                    },
                  },
                },
                plugins: {
                  legend: false,
                },
              }}
            />
          </div>
          <div className="graph">
            <div className="header">
              <span>Number of Orders</span>
            </div>
            <Line
              className="graph-inside"
              data={orderNumberGraph}
              options={{
                scales: {
                  x: {
                    ticks: {
                      autoSkip: true,
                      maxTicksLimit: 7,
                      maxRotation: 30,
                      minRotation: 30,
                    },
                  },
                },
                plugins: {
                  legend: false,
                },
              }}
            />
          </div>
        </div>
        <div className="overall">
          <div className="item">
            <span>$/Completed Order</span>
            <span className="content-item">
              ${parseFloat(money).toFixed(2)}
            </span>
          </div>
          <div className="item">
            <span>Total Orders</span>
            <span className="content-item">{ordersCount}</span>
          </div>
          <div className="item">
            <span>Rating</span>
            <div>
              <span>{Math.round(parseFloat(rating) * 100) / 100} stars</span>
            </div>
          </div>
        </div>
        <div className="overall">
          <div className="item">
            <span>Net Income</span>
            <span className="content-item">
              ${parseFloat(netIncome).toFixed(2)}
            </span>
          </div>
          <div className="item">
            <span>Revenue</span>
            <span className="content-item">
              ${parseFloat(revenue).toFixed(2)}
            </span>
          </div>
          <div className="item">
            <span>Editors Payment</span>
            <span className="content-item">
              R${parseFloat(editorPay).toFixed(2)}
            </span>
          </div>
        </div>
        <div className="latest-orders">
          <span className="header">Latest Orders</span>
          <LatestOrders orders={orders} />
        </div>
      </div>
      {getWindowDimensions().width > 450 && (
        <div className="side-nav">
          <div className="calendar">
            <Calendar
              className="react-calendar"
              onChange={setDateValue}
              value={dateValue}
              tileContent={({ date, view }) => {
                if (
                  view === "month" &&
                  marks.find(
                    (currentDay) =>
                      currentDay ===
                      moment(date)
                        .tz(
                          window.Intl.DateTimeFormat().resolvedOptions()
                            .timeZone
                        )
                        .format("DD-MM-YYYY")
                  )
                ) {
                  return <BsDot className="day-with-order" />;
                }
              }}
            />
            <div className="tasks">
              {dayOrders.map((task) => {
                return (
                  <div className="task">
                    <span className="time">
                      {new Date(parseInt(task.deadline)).toLocaleDateString()}
                    </span>
                    <span className="name">
                      {task.basicDetails
                        ? task.basicDetails.title
                        : `Order #${task.number}`}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="orders_list">
            <div className="header">
              <span>Orders</span>
            </div>
            <div className="line" />
            <div className="multi_circular">
              <CircularBar {...config} scale={2} />
            </div>
            <div className="legend">
              <div className="item">
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    borderRadius: "100%",
                    backgroundColor: "#00D097",
                  }}
                ></div>
                <span>Completed</span>
              </div>
              <div className="item">
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    borderRadius: "100%",
                    backgroundColor: "#FFB756",
                  }}
                ></div>
                <span>In Progress</span>
              </div>
              <div className="item">
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    borderRadius: "100%",
                    backgroundColor: "#DA4747",
                  }}
                ></div>
                <span>Cancelled</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
