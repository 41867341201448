import React, { useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import "./uploadModal.scss";
function UploadModal({ progress }) {
  return (
    <div className="upload-container-upload">
      <div className="upload-wrapper">
        <p>Uploading...</p>
        <div class="progress-bar">
          <div
            class="progress-bar__fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <span className="progress-bar-percentage">{progress}%</span>
      </div>
    </div>
  );
}

export default UploadModal;
