import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import VideoJS from "../../../../../../components/videoJS/VideoJS";
import "./VideoReview.scss";
import { v4 as uuidv4 } from "uuid";
import { FileService } from "../../../../../../services/file.service";
import Loading from "../../../../../loading/Loading";

const VideoReview = ({ deliverable, closeModal, user }) => {
  const [comments, setComments] = useState([]);
  const playerRef = useRef(null);
  const [checked, setChecked] = useState(false);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: deliverable.url,
        type: "video/mp4",
      },
    ],
  };

  useEffect(() => {
    const getComments = async () => {
      await FileService.getComments(deliverable.id, (data) => {
        console.log(data);
        setComments(data);
        setChecked(true);
      });
    };

    getComments();
  }, [deliverable]);

  useEffect(() => {
    const textareas = document.querySelectorAll(
      ".video-review-container textarea"
    );

    for (const textarea of textareas) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [comments]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  const addComment = () => {
    const currentTime = playerRef.current.currentTime();
    const commentUuid = uuidv4();
    setComments((currComments) => [
      ...currComments,
      { time: currentTime, comment: "", uuid: commentUuid, resolved: false },
    ]);
  };

  const formatTime = (time) => {
    return moment.utc(time * 1000).format("HH:mm:ss");
  };

  const changeComment = (newComment, newIndex) => {
    const newComments = [...comments].map((comment, index) => {
      if (index === newIndex) {
        comment.comment = newComment;
      }

      return comment;
    });

    setComments(newComments);
  };

  const resolveComment = (newIndex) => {
    const newComments = [...comments].map((comment, index) => {
      if (index === newIndex) {
        comment.resolved = !comment.resolved;
      }

      return comment;
    });

    saveComments(newComments);
  };

  const goToTime = (time) => {
    playerRef.current.currentTime(time);
  };

  const saveComments = (newComment = null) => {
    FileService.saveComments(
      { ...deliverable, fileComments: newComment ? newComment : comments },
      (data) => {
        setComments(data.fileComments);
      }
    );
  };

  return (
    <div className="video-review-container" onClick={() => closeModal()}>
      {!checked ? <Loading /> : null}
      <div className="video-review" onClick={(e) => e.stopPropagation()}>
        <AiOutlineClose
          className="close-modal"
          onClick={() => closeModal()}
        ></AiOutlineClose>
        <div className="review-title">
          Review Video
          <span
            className="download-now"
            onClick={() => {
              window.open(deliverable.url, "_blank").focus();
            }}
          >
            Download now
          </span>
        </div>
        <div className="review-content">
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
          <div className="comments-container">
            <div className="comments-title">
              <span>Comments</span>
              {user.type === "customer" || user.type === "admin" ? (
                <AiOutlinePlusCircle
                  className="add-new-comment"
                  onClick={() => addComment()}
                />
              ) : null}
            </div>
            <div className="comments">
              {comments.map((comment, index) => {
                return (
                  <div className="comment">
                    <div className="comment-header">
                      <span
                        className="time"
                        onClick={() => goToTime(comment.time)}
                      >
                        {formatTime(comment.time)}
                      </span>
                      <span
                        className="resolve-comment"
                        onClick={() => {
                          resolveComment(index);
                        }}
                      >
                        {comment.resolved ? "Unresolve" : "Resolve"}
                      </span>
                    </div>
                    {(user.type === "customer" || user.type === "admin") &&
                    !comment.resolved ? (
                      <textarea
                        rows={1}
                        placeholder="Your comment..."
                        value={comment.comment}
                        id={`comment-textarea-${comment.uuid}`}
                        onChange={(e) => changeComment(e.target.value, index)}
                        onBlur={() => saveComments()}
                      />
                    ) : (
                      <span
                        className={`comment-text ${
                          comment.resolved ? "resolved" : ""
                        }`}
                      >
                        {comment.comment}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoReview;
