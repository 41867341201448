import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./AllOrders.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate } from "react-router";
import { reference } from "../../ordersReference";
import { createHTMLOrderPrint } from "../../setItemsPrint";
import moment from "moment-timezone";
import { orderService } from "../../../services/order.service";
import formatTimeStamp from "../../../utils/formatTimeStamp";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AllOrders = ({
  orders,
  getOrders,
  ordersCount,
  setOrders,
  setOrdersCount,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const navigate = useNavigate();

  const updateOrders = (page) => {
    const filters = { admin: true };
    filters.type = "progress";

    orderService.listOrders(page, filters, (data) => {
      setOrders(data.orders);
      setOrdersCount(data.count);
    });
  };

  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const downloadTxtFile = (order) => {
    orderService.getDetails(order.id, (order) => {
      const statusName =
        order.status !== "submitted"
          ? reference.filter((item) => item.status === order.status)[0].name
          : "Pending Approval";
      const tableItems = createHTMLOrderPrint(order);

      const list = [
        `"Order Id": #${order.number}`,
        "Order Name: " + order.name,
        `Created at: ${moment(order.createdAt)
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("DD MMMM YYYY")}`,
        `Deadline at: ${moment(order.deadline)
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("DD MMMM YYYY")}`,
        "Status: " + statusName + "\n",
        "Delivery Items:",
        tableItems.map((tableItem, index) => {
          if (tableItem.shown) {
            return `\n\n${tableItem.name}:${tableItem.item.map((item) => {
              return `\n- ${item}`;
            })}`;
          } else {
            return "\n";
          }
        }),
      ];
      const element = document.createElement("a");
      const file = new Blob([list.join("\n")], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `invoice_${order.number}.txt`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    });
  };

  const formatTime = (time) => {
    return formatTimeStamp(time);
  };

  return (
    <div className="all-orders-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            titles={[
              "Name",
              "Time Estimate",
              // "Time Spent",
              "Status",
              "Order Id",
            ]}
          />
          <div className="order-table">
            <TableRow
              click={(order) => {
                navigate(`./${order.id}`);
              }}
              items={currentOrders.map((order, index) => {
                return {
                  order,
                  items: [
                    <span
                      style={{ fontWeight: 500 }}
                      onClick={() => navigate(`./${order.id}`)}
                    >
                      {order.basicDetails
                        ? order.basicDetails.title
                        : `Order #${order.number}`}
                    </span>,
                    <span>
                      {order.status === "completed" ||
                      order.status === "cancelled"
                        ? "-"
                        : formatTime(order.deadline)}
                    </span>,
                    <div className={`status ${order.status}`}>
                      <span>
                        {order.status !== "submitted"
                          ? reference.filter(
                              (item) => item.status === order.status
                            )[0].name
                          : "Pending Approval"}
                      </span>
                    </div>,
                    order.status !== "pendent" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignContent: "center",
                          alignItems: "stretch",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ color: "#A0AEC7", fontSize: ".8em" }}>
                          #{order.number}
                        </span>
                        {order.status === "complete" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {new Array(order.star).fill().map((_) => (
                              <AiFillStar color="#F1C40F" />
                            ))}
                            {new Array(5 - order.star).fill().map((_) => (
                              <AiFillStar color="#E0E0E0" />
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <button
                          onClick={() =>
                            Swal.fire({
                              title: "Order Accepted!",
                              text: "You successfully accepted this order",
                              heightAuto: false,
                              icon: "success",
                            })
                          }
                          style={{
                            cursor: "pointer",
                            padding: ".7em 1.5em",
                            fontSize: ".8em",
                            color: "white",
                            fontWeight: "500",
                            border: 0,
                            borderRadius: "20px",
                            backgroundColor: "#2A9DF4",
                          }}
                        >
                          Accept
                        </button>
                        <button
                          onClick={() =>
                            Swal.fire({
                              title: "Order Denied...",
                              text: "You denied this order",
                              heightAuto: false,
                              icon: "error",
                            })
                          }
                          style={{
                            cursor: "pointer",
                            padding: ".7em 1.5em",
                            fontSize: ".8em",
                            color: "white",
                            fontWeight: "500",
                            border: 0,
                            borderRadius: "20px",
                            backgroundColor: "#E74C3C",
                          }}
                        >
                          Deny
                        </button>
                      </div>
                    ),
                  ],
                };
              })}
            />
          </div>
        </>
      ) : (
        <>
          {currentOrders.map((order, index) => {
            return (
              <div
                className="order-mobile"
                click={() => {
                  navigate(`./${order.id}`);
                }}
              >
                <div className="header-mobile">
                  <div className={`status ${order.status}`}>
                    <span>
                      {order.status !== "submitted"
                        ? reference.filter(
                            (item) => item.status === order.status
                          )[0].name
                        : "Pending Approval"}
                    </span>
                  </div>
                  <div className="details">
                    <span
                      className="name"
                      onClick={() => navigate(`./${order.id}`)}
                    >
                      {order.basicDetails
                        ? order.basicDetails.title
                        : `Order #${order.number}`}
                    </span>
                    <span className="number">#{order.number}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {ordersCount > currentPage * 6 ? currentPage * 6 : ordersCount} of{" "}
            {ordersCount}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={currentPage === 1 ? null : previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={ordersCount < currentPage * 6 ? "disable" : "active"}
            onClick={ordersCount < currentPage * 6 ? null : nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div>
    </div>
  );
};

export default AllOrders;
