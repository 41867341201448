import "./OrderSummary.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { purchaseService } from "../../../../../services/package.service";
import Swal from "sweetalert2";
import Loading from "../../../../loading/Loading";
import moment from "moment-timezone";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";
import Visa from "../../../../../assets/visa.png";
import Google from "../../../../../assets/google.svg";
import Master from "../../../../../assets/master.png";
import NeutralCard from "../../../../../assets/neutral-card.png";
import BgBlurred from "../../../../../assets/Bg-blurred.png";
import { authService } from "../../../../../services/auth.service";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import LogoLanding from "../../../../../assets/landing/logo-landing.png";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import { PromoService } from "../../../../../services/promo.service";
//TODO: Stripe key
// const stripe = require("stripe")(
//   process.env.NODE_ENV !== "development"
//     ? process.env.STRIPE_TEST_KEY
//     : process.env.STRIPE_LIVE_KEY
// );

//test key
const stripe = require("stripe")(
  "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"
);
const CustomerTestOrderSummary = () => {
  const [loading, setLoading] = useState(false);
  const [currentStripeSession, setCurrentStripeSession] = useState(false);
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  console.log(state.order.name && state.order.name !== "Single");

  const queryParameters = new URLSearchParams(window.location.search);
  const [emailPasswordOpen, setEmailPasswordOpen] = useState(
    state.order.name && state.order.name !== "Single" ? false : true
  );
  const [rememberMe, setRememberMe] = useState(false);
  const [isPasswordShown, setIsPasswordShows] = useState(false);
  const [card, setCard] = useState({
    name: "",
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState(null);

  const [order, setOrder] = useState({});

  // const [discount, setDiscount] = useState(null);
  // const [discount, setDiscount] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    retrieveSession();
  }, []);
  useEffect(() => {
    console.log(state.order.name);
  }, []);
  // Custom toast
  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
  });

  const applyCode = async () => {
    PromoService.findPromo(
      discountCode,
      (data) => {
        setDiscount(data);
      },
      () => {
        Toast.fire({
          heightAuto: false,
          icon: "error",
          title: "The discount code doesn't exist",
        });
      }
    );
  };

  const changeAttribute = (attribute, value) => {
    const obj = JSON.parse(JSON.stringify(card));
    obj[attribute] = value;
    setCard(obj);
  };

  const formatCurrency = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      localStorage.removeItem("customerTestEmail");
      if (rememberMe) {
        localStorage.setItem("rememberMe", true);
        localStorage.setItem("email", values.email);
        localStorage.setItem("password", values.password);
      }

      setLoading(true);

      //Check if package is custom
      if (state.order.name && state.order.name !== "Single") {
        console.log("reaching test");
        const newCard = JSON.parse(JSON.stringify(card));
        if (newCard.number) {
          newCard.number = newCard.number.trim();
        }

        if (state.order.thumbnailWithVideo) {
          state.order.thumbnails = state.order.videos;
        }

        delete state.order.thumbnailWithVideo;

        const newOrder = JSON.parse(JSON.stringify(state.order));
        if (newOrder.additionalPrice) {
          newOrder.additionalPrice = newOrder.additionalPrice / 100;
        }

        if (newOrder.id) {
          delete newOrder.id;
        }

        newOrder.discount = discount
          ? discount.type === "amount"
            ? parseInt(discount.amount)
            : (state.order.additionalPrice / 100 +
                (state.order.price *
                  (state.order.frequency === "year" ? 10 : 1)) /
                  100) *
              (discount.amount / 100)
          : 0;

        authService.registerWithPackage(
          { email: values.email, password: values.password, type: "customer" },
          newOrder,
          newCard,
          (data) => {
            if (data && data.email) {
              navigate("/auth/check-email", { state: { email: values.email } });
            } else {
              Swal.fire({
                title: "Purchase Failed!",
                text: data,
                heightAuto: false,
                icon: "error",
              }).then(() => {
                setLoading(false);
              });
            }
          }
        );

        return;
      }

      authService.registerWithSinglePackage(
        {
          email: values.email,
          password: values.password,
          type: "customer",
        },
        { packageId: currentStripeSession.metadata.packageUuid },
        // newOrder,
        // newCard,
        (data) => {
          if (data && data.email) {
            navigate("/auth/check-email", { state: { email: values.email } });
          } else {
            Swal.fire({
              title: "Purchase Failed!",
              text: data,
              heightAuto: false,
              icon: "error",
            }).then(() => {
              setLoading(false);
            });
          }
        }
      );
    },
  });

  const retrieveSession = async () => {
    // console.log(formik.handleChange({ email:  }));
    const currentSession = queryParameters.get("session_id");
    const session = await stripe.checkout.sessions.retrieve(currentSession);
    formik.setFieldValue("email", session.customer_details.email);
    setCurrentStripeSession(session);
    console.log(session, "session retrieved");
  };

  const validate = () => {
    console.log(card);
    if (!card.name) {
      return {
        success: false,
        message: "Add a valid name",
      };
    }

    if (
      card.number.replace(/ /g, "").length > 16 ||
      card.number.replace(/ /g, "").length < 15
    ) {
      return {
        success: false,
        message: "Add a valid card number",
      };
    }

    if (card.exp_month.length > 2 || card.exp_month.length === 0) {
      return {
        success: false,
        message: "Add a valid expiration month",
      };
    }

    if (card.exp_year.length !== 4 && card.exp_year.length !== 2) {
      return {
        success: false,
        message: "Add a valid expiration year",
      };
    }

    if (card.exp_year.length === 2) {
      changeAttribute("exp_year", `20${card.exp_year}`);
      return {
        success: true,
      };
    }

    if (card.cvc.length < 3) {
      return {
        success: false,
        message: "Add a valid CVV code",
      };
    }

    return {
      success: true,
    };
  };

  const purchase = () => {
    const validation = validate();
    if (!validation.success) {
      Swal.fire({
        title: "Purchase Failed!",
        text: validation.message,
        heightAuto: false,
        icon: "error",
      });
      setLoading(false);
      return;
    }

    if (localStorage.getItem("customerTestEmail")) {
      formik.setFieldValue("email", localStorage.getItem("customerTestEmail"));
    }

    setEmailPasswordOpen(true);
  };

  const editPackage = () => {
    state.order.price = state.order.price / 100;
    if (state.order.name === "Custom") {
      navigate("../custom-package", { state: { order: state.order } });
    } else if (state.order.name === "Single") {
      navigate("../single-order", { state: { order: state.order } });
    } else {
      navigate("../select-package", { state: { order: state.order } });
    }
  };

  const formatCardNumber = (type) => {
    if (card.number && type === "blur") {
      let newCardNumber = JSON.parse(JSON.stringify(card)).number.split("");

      if (newCardNumber[0] === "3") {
        if (newCardNumber.length > 4) {
          newCardNumber.splice(4, 0, " ");
        }
        if (newCardNumber.length > 11) {
          newCardNumber.splice(11, 0, " ");
        }
      } else {
        if (newCardNumber.length > 4) {
          newCardNumber.splice(4, 0, " ");
        }
        if (newCardNumber.length > 9) {
          newCardNumber.splice(9, 0, " ");
        }
        if (newCardNumber.length > 14) {
          newCardNumber.splice(14, 0, " ");
        }
      }

      changeAttribute("number", newCardNumber.join(""));
    } else {
      let newCardNumber = JSON.parse(JSON.stringify(card)).number.split(" ");
      changeAttribute("number", newCardNumber.join(""));
    }
  };

  const googleSignUp = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const newCard = JSON.parse(JSON.stringify(card));
        if (newCard.number) {
          newCard.number = newCard.number.trim();
        }
        const newOrder = JSON.parse(JSON.stringify(state.order));
        if (newOrder.additionalPrice) {
          newOrder.additionalPrice = newOrder.additionalPrice / 100;
        }

        if (newOrder.id) {
          delete newOrder.id;
        }
        setLoading(true);

        newOrder.discount = discount
          ? discount.type === "amount"
            ? parseInt(discount.amount)
            : (state.order.additionalPrice / 100 +
                formatCurrency(
                  (state.order.price *
                    (state.order.frequency === "year" ? 10 : 1)) /
                    100
                )) *
              (discount.amount / 100)
          : 0;

        authService.registerWithPackageGoogle(
          { email: result.user.email, uid: result.user.uid, type: "customer" },
          newOrder,
          newCard,
          (data) => {
            if (data && data.email) {
              navigate("/auth/check-email", {
                state: { email: result.user.email },
              });
            } else {
              Swal.fire({
                title: "Purchase Failed!",
                text: data,
                heightAuto: false,
                icon: "error",
              }).then(() => {
                setLoading(false);
              });
            }
          }
        );
      })
      .catch((e) => {
        console.log(e);
        throw Error("Something happened");
      });
  };

  return order ? (
    <div
      className="customer-test-layout-order-summary-page"
      style={{ backgroundColor: "white" }}
    >
      {loading ? <LoadingUpload text="Finishing Purchase" /> : null}
      <div className="page-content">
        <img className="blurred-bg" src={BgBlurred} alt="blurred-background" />
        <div className="login-header">
          <img src={LogoLanding} alt="logo" />
          <button
            className="login-button"
            onClick={() => navigate("/auth/sign-in")}
          >
            Login
          </button>
        </div>
        <div className="package-main-content">
          <div className="wrapper">
            {emailPasswordOpen ? (
              <div className="middle-blocks sign-up">
                <img
                  className="blurred-bg"
                  src={BgBlurred}
                  alt="blurred-background"
                />
                <div className="sign-up-container">
                  <span className="title">Sign Up</span>
                  <span className="sub-title">
                    Create an account to finish your purchase
                  </span>
                  <div className="login-info">
                    <div className="input-box">
                      <span className="input-title">Email Address</span>
                      <input
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        type="email"
                        placeholder="youremail@gmail.com"
                      />
                    </div>
                    <div className="input-box">
                      <span className="input-title">Password</span>
                      <div className="password">
                        <input
                          id="password"
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          type={isPasswordShown ? "text" : "password"}
                          placeholder="••••••••"
                        />
                        {isPasswordShown ? (
                          <AiOutlineEye
                            onClick={() => setIsPasswordShows(!isPasswordShown)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            onClick={() => setIsPasswordShows(!isPasswordShown)}
                          />
                        )}
                      </div>
                    </div>
                    {/* <div className="remember-me-forgot-password">
                    <div className="remember-me">
                      <input
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        type="checkbox"
                      />
                      <span>Remember me</span>
                    </div>
                  </div> */}
                  </div>
                  <button
                    className="login-button"
                    onClick={formik.handleSubmit}
                  >
                    Sign Up
                  </button>
                  <span className="or">Or</span>
                  <div className="social">
                    <span>Sign Up With</span>
                    <img
                      onClick={() => googleSignUp()}
                      src={Google}
                      alt="social-login"
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="middle-blocks">
              <div className="details">
                <span className="details-title">Payment Details</span>
                <div className="input-box">
                  <span className="input-title">Card Information</span>
                  <input
                    value={card.name}
                    onChange={(e) => changeAttribute("name", e.target.value)}
                    placeholder="Name on Card"
                  />
                  <div className="card_number">
                    <input
                      onBlur={() => formatCardNumber("blur")}
                      onFocus={() => formatCardNumber("focus")}
                      value={card.number}
                      onChange={(e) =>
                        changeAttribute("number", e.target.value)
                      }
                      placeholder="Credit Card Number"
                      maxlength={16}
                    />
                    <img
                      alt="brand"
                      src={
                        card.number &&
                        card.number !== "" &&
                        card.number.length > 1
                          ? card.number.substring(0, 1) === "4"
                            ? Visa
                            : card.number.substring(0, 1) === "5"
                            ? Master
                            : NeutralCard
                          : NeutralCard
                      }
                    />
                  </div>
                  <div className="inputs">
                    <input
                      value={card.exp_month}
                      onChange={(e) =>
                        changeAttribute("exp_month", e.target.value)
                      }
                      placeholder="MM"
                      maxlength={2}
                    />
                    <input
                      value={card.exp_year}
                      onChange={(e) =>
                        changeAttribute("exp_year", e.target.value)
                      }
                      placeholder="YYYY"
                      maxlength={4}
                    />
                    <input
                      value={card.cvc}
                      onChange={(e) => changeAttribute("cvc", e.target.value)}
                      placeholder="CVV"
                      maxlength={3}
                    />
                  </div>
                </div>
                <div className="actions-buttons">
                  <button
                    onClick={
                      !state.update
                        ? () => {
                            purchase();
                          }
                        : () => {
                            setLoading(true);
                          }
                    }
                    className="complete"
                  >
                    Complete Purchase
                  </button>
                  <button onClick={editPackage} className="cancel">
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
          {state.order.name && state.order.name !== "Single" ? (
            <div className="summary">
              <span className="summary-title">Order Summary</span>
              <div className="grey-line"></div>
              <div className="products">
                <div className="product">
                  <div className="product-item">
                    <span>{state.order.name} Package</span>
                    <div className="price">
                      <span>
                        $
                        {formatCurrency(
                          (state.order.price *
                            (state.order.frequency === "year" ? 10 : 1)) /
                            100
                        )}
                        .00
                      </span>
                    </div>
                  </div>
                  <div className="product-item year">
                    <span className="description">
                      {state.order.frequency === "year"
                        ? "Annually"
                        : "Monthly"}
                    </span>
                  </div>
                  <div className="product-item products">
                    <span className="description">
                      {state.order.videos} video
                      {state.order.videos !== 1 ? "s" : ""} per month
                    </span>
                    <span className="description">
                      {state.order.rawFootage} minute
                      {state.order.rawFootage !== 1 ? "s" : ""} of provided
                      footage per video
                    </span>
                    <span className="description">
                      {state.order.runningVideo} minute
                      {state.order.runningVideo !== 1 ? "s" : ""} of running
                      time per video
                    </span>
                    {state.order.thumbnailWithVideo ? (
                      <span className="description">
                        1 Thumbnail with each video
                      </span>
                    ) : null}

                    {state.order.unlimitedStockFootage ? (
                      <span className="description">
                        Unlimited Stock Footage
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="product">
                  <div className="product-item">
                    <span>Add-Ons</span>
                    <div className="price">
                      <span>
                        ${formatCurrency(state.order.additionalPrice / 100)}.00
                      </span>
                    </div>
                  </div>
                  <div className="product-item products">
                    {state.addOns
                      .filter(
                        (addOn) =>
                          addOn.count &&
                          addOn.type !== "Video" &&
                          addOn.type !== "Raw Footage Time" &&
                          addOn.type !== "Final Running Time"
                      )
                      .map((addOn) => {
                        return (
                          <span className="description">
                            {addOn.count} {addOn.type}
                          </span>
                        );
                      })}
                  </div>
                </div>
                {discount ? (
                  <div className="product">
                    <div className="product-item">
                      <span>Discount ({discount.code})</span>
                      <div className="price">
                        <span>
                          -
                          {discount.type === "amount"
                            ? `$${formatCurrency(parseInt(discount.amount))}.00`
                            : `$${formatCurrency(
                                (state.order.additionalPrice / 100 +
                                  (state.order.price *
                                    (state.order.frequency === "year"
                                      ? 10
                                      : 1)) /
                                    100) *
                                  (discount.amount / 100)
                              )}.00`}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="promo-code-container">
                  <span>Have a discount code?</span>
                  <div className="promo-code">
                    <input
                      placeholder="Enter Code"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                    />
                    <button onClick={() => applyCode()}>Apply Code</button>
                  </div>
                </div>
              </div>
              <div className="total">
                <div className="total-price">
                  <span>Total</span>
                  <div className="price">
                    <span>
                      $
                      {(state.order.price *
                        (state.order.frequency === "year" ? 10 : 1) +
                        state.order.additionalPrice) /
                        100}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerTestOrderSummary;
