import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import Profile from "../../components/profile/Profile";
import SideBar from "../../components/sidebar/SideBar";
import { sideBarItems } from "../../components/sidebar/sideBarItems";
import "./Layout.scss";
import { BiBell, BiLogOut } from "react-icons/bi";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { userService } from "../../services/user.service";
import { orderService } from "../../services/order.service";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import io from "socket.io-client";
import moment from "moment-timezone";
import NotificationSfx from "../../assets/songs/notification.mp3";
import { compare } from "../../utils/sorting";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const EditorLayout = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [currentPageName, setCurrentPageName] = useState("");
  const [askAdmin, setAskAdmin] = useState(false);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState({});
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  /*eslint no-unused-vars: "off"*/
  const [audio, setAudio] = useState(new Audio(NotificationSfx));
  const [newNotification, setNewNotification] = useState(false);

  useEffect(() => {
    if (newNotification) {
      setTimeout(() => {
        setNewNotification(false);
      }, 5000);
    }
  }, [newNotification]);

  /*eslint react-hooks/exhaustive-deps: "off"*/
  useEffect(() => {
    setCurrentPage(window.location.pathname.split("/")[2]);
    userService.getProfile(
      (data) => {
        if (data.type !== "editor") {
          Swal.fire({
            title: "Error!",
            text: "Please login before entering your account",
            heightAuto: false,
            icon: "error",
          }).then(() => {
            navigate("/auth/sign-in");
          });
        } else {
          setUser(data);
        }
      },
      (e) => {
        Swal.fire({
          title: "Error!",
          text: "Please login before entering your account",
          heightAuto: false,
          icon: "error",
        }).then(() => {
          navigate("/auth/sign-in");
        });
      }
    );
  }, []);

  useEffect(() => {
    if (socket && socket !== null) {
      socket.on("notification:new", (data) => {
        audio.play();
        setNotifications([data, ...notifications]);
        setNewNotification(true);
      });
    }
  }, [notifications]);

  useEffect(() => {
    if (window.location.pathname.split("/")[2] === "editors") {
      setCurrentPageName("Editors");
      setCurrentPage("editors");
    } else if (window.location.pathname.split("/")[2] === "dashboard") {
      setCurrentPageName("Dashboard");
      setCurrentPage("dashboard");
    } else if (window.location.pathname.split("/")[2] === "earning") {
      setCurrentPageName("My Earnings");
      setCurrentPage("earnings");
    } else if (window.location.pathname.split("/")[2] === "inbox") {
      setCurrentPageName("Inbox");
      setCurrentPage("inbox");
    } else if (window.location.pathname.split("/")[2] === "support") {
      setCurrentPageName("FAQ");
      setCurrentPage("support");
    } else if (window.location.pathname.split("/")[2] === "profile") {
      setCurrentPageName("Profile");
      setCurrentPage("profile");
    } else if (window.location.pathname.split("/")[2] === "clients") {
      setCurrentPageName("Clients");
      setCurrentPage("clients");
    }

    if (window.location.pathname.split("/")[3]) {
      if (window.location.pathname.split("/")[2] === "editors") {
        setCurrentPageName("Editor Details");
      } else if (window.location.pathname.split("/")[2] === "clients") {
        setCurrentPageName("Client Details");
      } else if (window.location.pathname.split("/")[2] === "orders") {
        setCurrentPageName("Order Delivery");
      } else if (window.location.pathname.split("/")[2] === "packages") {
        if (window.location.pathname.split("/")[3] === "my-package") {
          setCurrentPageName("My Package");
        } else if (
          window.location.pathname.split("/")[3] === "select-package"
        ) {
          setCurrentPageName("Select your monthly package");
        } else if (
          window.location.pathname.split("/")[3] === "order-summary" ||
          window.location.pathname.split("/")[3] === "add-ons"
        ) {
          setCurrentPageName("Order Summary");
        } else if (
          window.location.pathname.split("/")[3] === "custom-package"
        ) {
          setCurrentPageName("Create Custom Package");
        } else if (window.location.pathname.split("/")[3] === "single-order") {
          setCurrentPageName("Single Order");
        }
      }
    }
  });

  useEffect(() => {
    const newSocket = io(
      `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_API_BASE_URL_DEV
          : process.env.REACT_APP_API_BASE_URL_PROD
      }notification`,
      {
        query: { token: Cookies.get("token") },
      }
    );
    setSocket(newSocket);
    newSocket.emit("notification:join");
    newSocket.emit("notification:get");
    newSocket.on("notification:get-return", (data) => {
      const sortedNotifications = data.sort(compare);
      setNotifications(sortedNotifications);
    });

    return () => {
      console.log("disconnecting");
      newSocket.emit("chat:disconnect");
      // newSocket.close()
    };
  }, []);

  useEffect(() => {
    if (window.location.pathname.split("/")[3]) {
      setCurrentPageName("Order Delivery");
    } else {
      for (const item of sideBarItems.sideBarItemsEditor) {
        if (item.id === currentPage) {
          setCurrentPageName(item.name);
        }
      }
    }
  }, [currentPage]);

  const openNotifications = () => {
    setIsNotificationOpen(false);
    socket.emit("notification:read");
    socket.on("notification:read-return", (data) => {
      const sortedNotifications = data.sort(compare);
      setNotifications(sortedNotifications);
    });
  };

  return (
    <div className="editor-layout-main-container">
      {newNotification && (
        <div
          className="new-notification"
          onClick={() => {
            if (notifications[0].link && notifications[0].link !== null) {
              navigate(`/editor${notifications[0].link}`);
            } else {
              navigate("/editor/inbox", {
                state: { chat: { data: { id: notifications[0].chatId } } },
              });
            }
          }}
        >
          <div className="notification">
            <div className="details">
              <div className="text">
                <span>{notifications[0].text} </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMenuOpen && (
        <div className="menu-side">
          <div className="menu-container">
            <div className="menu-header">
              <img className="logo" src={Logo} alt="logo" />
              <div className="icon-menu" onClick={() => setIsMenuOpen(false)}>
                <AiOutlineClose />
              </div>
            </div>
            <div
              className="menu-profile"
              onClick={() => {
                setCurrentPage("profile");
                setCurrentPageName("Profile");
                setIsMenuOpen(false);
                navigate("./profile");
              }}
            >
              <Profile
                name={`${user.firstName} ${user.lastName}`}
                role="Editor"
                image={
                  user.profilePicture !== null
                    ? user.profilePicture.url
                    : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                }
              />
            </div>
            <div className="menu-body main">
              {sideBarItems.sideBarItemsEditor
                .filter((item) => item.show)
                .map((item) => {
                  return (
                    <Link
                      onClick={() => {
                        setCurrentPage(item.id);
                        setCurrentPageName(item.name);
                        setIsMenuOpen(false);
                      }}
                      to={`./${item.id}`}
                      className="item"
                    >
                      <span className="icon">{item.icon}</span>
                      <span>{item.name}</span>
                    </Link>
                  );
                })}
            </div>
            <div className="menu-footer">
              <div className="menu-body">
                <div
                  className="item"
                  onClick={() => {
                    Cookies.remove("token");
                    navigate("/");
                  }}
                >
                  <span className="icon">
                    <BiLogOut />
                  </span>
                  <span>Logout</span>
                </div>
                <div className="terms-and-policy">
                  <span
                    onClick={() => {
                      navigate("/terms-of-service");
                    }}
                  >
                    Terms of Use
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isNotificationOpen && (
        <div className="notifications">
          <div className="notifications-container">
            <div className="notifications-header">
              <span className="title">Notifications</span>
              <div className="icon" onClick={() => openNotifications()}>
                <AiOutlineClose color="black" />
              </div>
            </div>
            <div className="notifications-body">
              {notifications.map((notification) => {
                return (
                  <div
                    className="notification"
                    onClick={() => {
                      if (notification.link !== null) {
                        navigate(`/editor${notification.link}`);
                      } else {
                        navigate("/editor/inbox", {
                          state: {
                            chat: { data: { id: notification.chatId } },
                          },
                        });
                      }
                    }}
                  >
                    <div className="details">
                      <div className="text">
                        <span>
                          {notification.text}
                          {notification.read ? "" : " - New"}
                        </span>
                      </div>
                      <span className="time">
                        {moment(new Date(notification.createdAt))
                          .tz(
                            window.Intl.DateTimeFormat().resolvedOptions()
                              .timeZone
                          )
                          .format("DD MMMM YYYY")}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {getWindowDimensions().width > 950 && (
        <div className="sidebar">
          <SideBar
            notifications={notifications}
            type="editor"
            id={currentPage}
            changePage={setCurrentPage}
            changePageName={setCurrentPageName}
            setNotifications={setNotifications}
          />
        </div>
      )}
      <div className="content-container">
        <div className="header">
          {getWindowDimensions().width < 950 && (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="icon menu" onClick={() => setIsMenuOpen(true)}>
                <AiOutlineMenu />
              </div>
              {getWindowDimensions().width > 450 ? (
                <h1>{currentPageName}</h1>
              ) : (
                <h3>{currentPageName}</h3>
              )}
            </div>
          )}
          {getWindowDimensions().width > 950 && <h1>{currentPageName}</h1>}
          <div className="icons">
            {currentPage === "support" && getWindowDimensions().width > 450 && (
              <button
                onClick={() => {
                  orderService.chatWithAdmin("admin", null, (data) => {
                    navigate("./inbox", { state: { chat: data } });
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#2A9DF4",
                  padding: "1em 2em",
                  fontWeight: "500",
                  border: 0,
                  borderRadius: "25px",
                }}
              >
                Ask to Admin
              </button>
            )}
            {/* <div className="icon">
              <BiSearch />
            </div> */}
            <div
              className="icon notifications-icon"
              onClick={() => {
                setIsNotificationOpen(true);
              }}
            >
              {notifications.filter((item) => !item.read) &&
              notifications.filter((item) => !item.read).length ? (
                <div className="red-dot"></div>
              ) : null}
              <BiBell />
            </div>
            {getWindowDimensions().width > 450 && (
              <div
                className="profile"
                onClick={() => {
                  setCurrentPage("profile");
                  setCurrentPageName("Profile");
                  navigate("./profile");
                }}
              >
                <Profile
                  name={`${user.firstName} ${user.lastName}`}
                  role="Editor"
                  image={
                    user.profilePicture && user.profilePicture !== null
                      ? user.profilePicture.url
                      : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="content">
          <Outlet
            context={[
              askAdmin,
              setAskAdmin,
              user,
              setUser,
              notifications,
              setNotifications,
              socket,
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default EditorLayout;
