import { useState, useEffect } from "react";
import "./Banner.scss";
import { FaCloudUploadAlt, FaRegTrashAlt } from "react-icons/fa";
import { AiFillFile } from "react-icons/ai";
import ProgressBar from "../../../../../../../components/progressBar/ProgressBar";
import { uploadService } from "../../../../../../../services/upload.service";
import LoadingUpload from "../../../../../../loadingUpload/LoadingUpload";
import UploadModal from "../../../../../../../components/uploadModal/UploadModal";
const Banner = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
}) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState("Facebook");
  const [logoStatus, setLogoStatus] = useState("waiting");
  const [extrasStatus, setExtrasStatus] = useState("waiting");
  const [otherSelected, setOtherSelected] = useState(false);
  const [postLocations, setPostLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState([
    "Facebook",
    "YouTube",
    "Twitter",
    "LinkedIn",
    "Website",
    "Instagram",
    "Other",
  ]);
  const [logo, setLogo] = useState(null);
  const [infoRequired, setInfoRequired] = useState("");
  const [avoidDescription, setAvoidDescription] = useState("");
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [otherLocation, setOtherLocation] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (order.banner) {
      if (order.banner.postLocations && order.banner.postLocations.length) {
        if (typeof order.banner.postLocation === "string") {
          order.banner.postLocation = order.banner.postLocation.split(",");
        }
        const newLocations = [];

        for (const postLocation of order.banner.postLocations) {
          if (!checkboxes.includes(postLocation)) {
            setOtherLocation(postLocation);
            setOtherSelected(true);
          } else {
            newLocations.push(postLocation);
          }
        }

        setPostLocations(newLocations);
      }

      if (order.banner.additionalFiles && order.banner.additionalFiles.length) {
        setAdditionalFiles(order.banner.additionalFiles);
      }

      if (order.banner.infoRequired && order.banner.infoRequired !== null) {
        setInfoRequired(order.banner.infoRequired);
      }

      if (
        order.banner.avoidDescription &&
        order.banner.avoidDescription !== null
      ) {
        setAvoidDescription(order.banner.avoidDescription);
      }

      if (order.banner.logo && order.banner.logo !== null) {
        setLogo(order.banner.logo);
      }
    }
  }, [order]);

  const changeStep = (isStepNext) => {
    if (isStepNext) {
      if (validateFields()) {
        const newPostLocations = [...postLocations, otherLocation];
        changeComponent("banner", {
          postLocations:
            typeof postLocations !== "string"
              ? newPostLocations.join(",")
              : `${postLocations},${otherLocation}`,
          logo,
          infoRequired,
          avoidDescription,
          additionalFiles,
        });

        nextStep();
      } else {
        validate("Please check that you added all required information");
      }
    } else {
      prevStep();
    }
  };

  const validateFields = () => {
    if ((!postLocations || !postLocations.length) && !otherLocation) {
      return false;
    }

    if (!infoRequired || infoRequired.trim() === "") {
      return false;
    }

    // if (!avoidDescription || avoidDescription.trim() === "") {
    //   return false;
    // }

    return true;
  };

  return (
    <div className="banner-new-order">
      {loading ? (
        <UploadModal progress={uploadProgress} />
      ) : // <LoadingUpload text="Hang tight, we're working hard to upload your files. Your patience is greatly appreciated. Please don't close the tab or browser." />
      null}
      <div className="inner-content">
        <div className="item">
          <span className="title-item">Where are you using this banner? *</span>
          {checkboxes.map((checkbox) => {
            return (
              <div className="radio-box">
                <input
                  id={checkbox}
                  checked={
                    checkbox === "Other"
                      ? otherSelected
                      : postLocations.includes(checkbox)
                  }
                  name={checkbox}
                  type="checkbox"
                  onChange={(e) => {
                    if (checkbox === "Other") {
                      setOtherSelected(e.target.checked);
                      if (!e.target.checked) {
                        const arr = [...postLocations].filter((post) => {
                          return [...checkboxes].includes(post);
                        });
                        setPostLocations(arr);
                      }
                    } else {
                      if (e.target.checked) {
                        setPostLocations([...postLocations, checkbox]);
                      } else {
                        const arr = [...postLocations].filter(
                          (post) => post !== checkbox
                        );
                        setPostLocations(arr);
                      }
                    }
                  }}
                />
                <label for={checkbox} className="legend">
                  {checkbox}
                </label>
              </div>
            );
          })}
          {otherSelected && (
            <input
              value={otherLocation}
              onChange={(e) => {
                setOtherLocation(e.target.value);
              }}
              placeholder="Please specify"
            />
          )}
        </div>
        <div className="upload">
          <span className="upload-title">
            Please upload if you have a logo you want on the banner
          </span>
          <input
            style={{ display: "none" }}
            type="file"
            onChange={async (e) => {
              const file = e.target.files[0];
              setLoading(true);

              if (file) {
                let data = new FormData();
                data.append("file", file);

                await uploadService.upload(
                  data,
                  (data) => {
                    setLogo(data);
                    setLoading(false);
                  },
                  setUploadProgress
                );
              }
            }}
            id="drop-files-logo"
          />
          {!logo || logo === null ? (
            <label className="upload-box" for="drop-files-logo">
              <FaCloudUploadAlt size={60} color="#D9DFE9" />
              <span>Drop files here or click to upload.</span>
            </label>
          ) : (
            <div className="uploaded-box">
              <div className="files">
                <div className="item">
                  <AiFillFile size={60} color="#2A9DF4" />
                  <span>
                    {logo.originalName}{" "}
                    <FaRegTrashAlt
                      onClick={() => {
                        setLogo(null);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="item">
          <span className="title-item">
            What info is required on your banner? *
          </span>
          <textarea
            placeholder="Add some comments"
            value={infoRequired}
            onChange={(e) => setInfoRequired(e.target.value)}
          />
        </div>
        <div className="item">
          <span className="title-item">
            Is there anything that should be avoided?
          </span>
          <textarea
            placeholder="Add some comments"
            value={avoidDescription}
            onChange={(e) => setAvoidDescription(e.target.value)}
          />
        </div>
        <div className="upload">
          <span className="upload-title">
            Do you have any images, sketches or documents that might be helpful?
          </span>
          <span className="upload-sub-title">
            E.g.Your current logo, fonts, photos, illustrations, content, layout
            ideas etc.
          </span>
          <input
            style={{ display: "none" }}
            type="file"
            multiple
            onChange={async (e) => {
              const arr = e.target.files;
              const filesArr = [...additionalFiles];

              const result = await Promise.all(
                [...arr].map(async (file) => {
                  setLoading(true);
                  if (file) {
                    let data = new FormData();
                    data.append("file", file);

                    await uploadService.upload(
                      data,
                      (data) => {
                        setAdditionalFiles([...filesArr, data]);
                        setLoading(false);
                        filesArr.push(data);
                      },
                      setUploadProgress
                    );
                  }
                })
              );
            }}
            id="drop-files-additional-files"
          />
          {!additionalFiles || !additionalFiles.length ? (
            <label for="drop-files-additional-files" className="upload-box">
              <FaCloudUploadAlt size={60} color="#D9DFE9" />
              <span>Drop files here or click to upload.</span>
            </label>
          ) : (
            <div className="uploaded-box">
              <label className="upload-more" for="drop-files-additional-files">
                Upload more items
              </label>
              <div className="files">
                {additionalFiles.map((file) => {
                  return (
                    <div className="item">
                      <AiFillFile size={60} color="#2A9DF4" />
                      <span>
                        {file.originalName.substring(0, 10)}{" "}
                        <FaRegTrashAlt
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const arr = [...additionalFiles].filter(
                              (item) => item.originalName !== file.originalName
                            );
                            setAdditionalFiles(arr);
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {extrasStatus === "uploaded" && (
            <div className="uploaded-box">
              <div className="files">
                <div className="item">
                  <AiFillFile size={60} color="#2A9DF4" />
                  <span>
                    File 1 <FaRegTrashAlt style={{ cursor: "pointer" }} />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <span
          style={{
            fontSize: "0.8em",
            fontWeight: 600,
            color: "#7d8592",
            textAlign: "start",
          }}
        >
          * Required
        </span>
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default Banner;
