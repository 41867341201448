import { coreService } from "./core.service";

const buyPackage = (userPackage, card, callback) => {
  coreService.post(
    `package/buy`,
    { package: userPackage, card },
    callback,
    undefined,
    undefined,
    true
  );
};

const cancelPackage = (userPackage, callback) => {
  coreService.post(`package/cancel`, userPackage, callback);
};

const deleteCard = (cardId, callback) => {
  coreService.post(`package/delete-card`, { cardId }, callback);
};

const changeDefaultCard = (cardId, callback) => {
  coreService.post(`package/change-default`, { cardId }, callback);
};

const addCard = (card, callback) => {
  coreService.post(`package/add-card`, card, callback);
};

const changeNextPackage = (userPackage, callback) => {
  coreService.post(
    `package/change-next-package`,
    userPackage,
    callback,
    undefined,
    undefined,
    true
  );
};

const buyAddOn = (userPackage, callback, errorCallback = () => {}) => {
  coreService.post(
    "package/buy-add-ons",
    userPackage,
    callback,
    undefined,
    undefined,
    errorCallback
  );
};

const createCustomPackage = async (body, callback) => {
  coreService.post("package/create-custom-package", body, callback);
};

const getPackageByUuid = async (packageUuid, callback) => {
  coreService.get(`package/get-by-uuid/${packageUuid}`, callback);
};

const changeUserPackage = async (newPackage, callback) => {
  coreService.put(`package/change-user-package`, newPackage, callback);
};

const generateStripeCheckout = async (body, callback) => {
  coreService.post(`stripe/create-checkout-session`, body, callback);
};

export const purchaseService = {
  buyPackage,
  cancelPackage,
  deleteCard,
  addCard,
  changeDefaultCard,
  changeNextPackage,
  buyAddOn,
  createCustomPackage,
  getPackageByUuid,
  changeUserPackage,
  generateStripeCheckout,
};
