import { useEffect, useState, useRef } from "react";
import "./Slider.scss";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import VideoJS from "../videoJS/VideoJS";
const Slider = ({ images }) => {
  const [page, setPage] = useState(0);
  const [currentImage, setCurrentImage] = useState({});
  const playerRef = useRef(null);

  useEffect(() => {
    setCurrentImage(images[page]);
  }, [page]);

  const prevImage = () => {
    if (page === 0) {
      setPage(images.length - 1);
    } else {
      setPage((page) => page - 1);
    }
  };

  const nextImage = () => {
    if (page === images.length - 1) {
      setPage(0);
    } else {
      setPage((page) => page + 1);
    }
  };

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: currentImage.url,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    currentImage.mime && (
      <div className="slider-component">
        <AiOutlineArrowLeft
          onClick={() => prevImage()}
          size={20}
          className="arrow"
        />
        {currentImage.mime.includes("video") ? (
          <>
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
          </>
        ) : (
          <>
            <img src={currentImage.url} />
          </>
        )}
        <AiOutlineArrowRight
          onClick={() => nextImage()}
          size={20}
          className="arrow"
        />
      </div>
    )
  );
};

export default Slider;
