import "./CustomPackage.scss";
import Video from "../../../../../assets/video-types/video.png";
import Thumbnail from "../../../../../assets/video-types/thumbnail.png";
import Banner from "../../../../../assets/video-types/banner.png";
import Logo from "../../../../../assets/video-types/logo.png";
import ConsultationCall from "../../../../../assets/video-types/consultation-call.png";
import Outro from "../../../../../assets/video-types/outro.png";
import Intro from "../../../../../assets/video-types/intro.png";
import UnlimitedStock from "../../../../../assets/video-types/unlimited-stock.png";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Loading from "../../../../loading/Loading";
import { PackageManagementService } from "../../../../../services/package-management.service";
import BgBlurred from "../../../../../assets/Bg-blurred.png";
import Time from "../../../../../assets/video-types/time.png";
import Clock from "../../../../../assets/video-types/clock.png";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { CustomPackageRequestService } from "../../../../../services/customPackageRequest.service";
import { purchaseService } from "../../../../../services/package.service";
import moment from "moment-timezone";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";
import LogoLanding from "../../../../../assets/landing/logo-landing.png";

const CustomerTestCustomPackage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [addOns, setAddOns] = useState([]);
  const [addOnsPrice, setAddOnsPrice] = useState(0);
  const [order, setOrder] = useState({
    name: "Custom",
    videos: 0,
    rawFootage: 0,
    runningVideo: 0,
    thumbnails: 0,
    banner: 0,
    logo: 0,
    consultationCall: 0,
    outro: 0,
    intro: 0,
    unlimitedStockFootage: false,
    price: 0,
  });
  const [step, setStep] = useState(1);
  const [thumbnailWithVideo, setThumbnailWithVideo] = useState(false);
  const [exceedError, setExceedError] = useState(false);
  const [isMessageShown, setIsMessageShown] = useState(false);

  const formatCurrency = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    let copyOrder;

    const getPackagesAndAddOns = async () => {
      PackageManagementService.list((data) => {
        let currPrice = 0;
        data.addOns = data.addOns.map((addOn) => {
          if (!state || !state.order) {
            addOn.count = 0;
          } else if (
            addOn.type !== "Video" &&
            addOn.type !== "Raw Footage Time" &&
            addOn.type !== "Final Running Time"
          ) {
            addOn.count =
              state.order[
                `additional${addOn.codeId
                  .substring(0, 1)
                  .toUpperCase()}${addOn.codeId.substring(
                  1,
                  addOn.codeId.length
                )}`
              ];
            currPrice = currPrice + addOn.count * addOn.cost;
          } else {
            addOn.count = state.order[addOn.codeId];
          }
          return addOn;
        });

        setAddOnsPrice(currPrice);
        setAddOns(data.addOns);

        if (state && state.order) {
          console.log(state.order);
          copyOrder = JSON.parse(JSON.stringify(order));
          console.log({ ...copyOrder, ...state.order });
          if (state && state.order && state.order.thumbnailWithVideo) {
            setThumbnailWithVideo(state.order.thumbnailWithVideo);
          }

          setOrder({ ...copyOrder, ...state.order });
        }
      });
    };

    getPackagesAndAddOns();
  }, [state]);

  const messageFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      budget: 0,
      socials: "",
      description: "",
    },
    onSubmit: (values) => {
      if (
        !values.name.trim() ||
        !values.email.trim() ||
        !values.budget ||
        !values.description.trim()
      ) {
        Swal.fire({
          title: "Oops!",
          text: "Please add all required fields and try again",
          heightAuto: false,
          icon: "error",
        });
        return;
      }

      CustomPackageRequestService.saveRequest({ request: values }, () => {
        Swal.fire({
          title: "Thank You!",
          text: "We received your message! We will get back to you within 24 hours with a quote or to discuss specifics of your project. You can leave the checkout screen.",
          heightAuto: false,
          icon: "success",
        }).then(() => {
          setIsMessageShown(false);
          messageFormik.resetForm();
        });
      });
    },
  });

  const changeCount = (operation, type, amount = 1) => {
    console.log(order);
    if (type === "Video") {
      const newOrder = JSON.parse(JSON.stringify(order));
      const newAddOns = [...addOns];
      const video = [...addOns]
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Video")[0];
      const rawTime = [...addOns]
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Raw Footage Time")[0];
      const thumbnail = [...addOns]
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Thumbnail")[0];

      if (operation === "plus") {
        newOrder.price = newOrder.price + video[0].cost;
        newAddOns[video[1]].count =
          parseInt(newAddOns[video[1]].count) + amount;

        if (rawTime[0].count) {
          newOrder.price = newOrder.price + rawTime[0].count * rawTime[0].cost;
        }

        if (thumbnailWithVideo) {
          newOrder.price = newOrder.price + thumbnail[0].cost;
        }

        if (newOrder.unlimitedStockFootage) {
          newOrder.price = newOrder.price + 35;
        }
      } else if (operation === "minus" && video[0].count > 0) {
        newOrder.price = newOrder.price - video[0].cost;
        newAddOns[video[1]].count =
          parseInt(newAddOns[video[1]].count) - amount;

        if (rawTime[0].count) {
          newOrder.price = newOrder.price - rawTime[0].count * rawTime[0].cost;
        }

        if (thumbnailWithVideo) {
          newOrder.price = newOrder.price - thumbnail[0].cost;
        }

        if (newOrder.unlimitedStockFootage) {
          newOrder.price = newOrder.price - 35;
        }
      }

      setAddOns(newAddOns);
      setOrder(newOrder);
    } else if (type === "Raw Footage Time") {
      const newOrder = JSON.parse(JSON.stringify(order));
      const newAddOns = [...addOns];
      const rawTime = newAddOns
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Raw Footage Time")[0];
      const numVideos = newAddOns.filter((addOn) => addOn.type === "Video")[0]
        .count;
      if (operation === "plus") {
        newOrder.price = newOrder.price + rawTime[0].cost * numVideos;
        newAddOns[rawTime[1]].count = parseInt(newAddOns[rawTime[1]].count) + 1;
      } else if (operation === "minus" && rawTime[0].count > 0) {
        newOrder.price = newOrder.price - rawTime[0].cost * numVideos;
        newAddOns[rawTime[1]].count = parseInt(newAddOns[rawTime[1]].count) - 1;
      }

      setAddOns(newAddOns);
      setOrder(newOrder);
    } else if (type === "Final Running Time") {
      const newOrder = JSON.parse(JSON.stringify(order));
      const newAddOns = [...addOns];
      const finalTime = newAddOns
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Final Running Time")[0];

      const rawTime = newAddOns.filter(
        (addOn) => addOn.type === "Raw Footage Time"
      )[0].count;
      if (operation === "plus" && finalTime[0].count + 1 <= rawTime) {
        newAddOns[finalTime[1]].count =
          parseInt(newAddOns[finalTime[1]].count) + 1;
      } else if (operation === "plus" && finalTime[0].count + 1 > rawTime) {
        setExceedError(true);
        setTimeout(() => {
          setExceedError(false);
        }, 3000);
      } else if (operation === "minus" && finalTime[0].count > 0) {
        newAddOns[finalTime[1]].count =
          parseInt(newAddOns[finalTime[1]].count) - 1;
      }

      setAddOns(newAddOns);
      setOrder(newOrder);
    } else {
      const newAddOns = [...addOns].map((addOn) => {
        if (addOn.type === type && operation === "plus") {
          addOn.count = addOn.count + amount;
          setAddOnsPrice((curr) => curr + addOn.cost);
        } else if (
          addOn.type === type &&
          operation === "minus" &&
          addOn.count > 0
        ) {
          addOn.count = addOn.count - amount;
          setAddOnsPrice((curr) => curr - addOn.cost);
        }

        return addOn;
      });

      setAddOns(newAddOns);
    }
  };

  const orderPackage = () => {
    const finalTime = [...addOns].filter(
      (addOn) => addOn.type === "Final Running Time"
    )[0];
    const rawTime = [...addOns].filter(
      (addOn) => addOn.type === "Raw Footage Time"
    )[0];
    const videoCount = [...addOns].filter((addOn) => addOn.type === "Video")[0]
      .count;

    if (videoCount && (!finalTime.count || !rawTime.count)) {
      Swal.fire({
        title: "Oops!",
        text: "Please add the Final Running Time and Minutes of Provided Footage",
        heightAuto: false,
        icon: "error",
      });
      return;
    }

    const obj = JSON.parse(JSON.stringify(order));
    for (const addOn of [...addOns]) {
      if (
        addOn.type !== "Video" &&
        addOn.type !== "Raw Footage Time" &&
        addOn.type !== "Final Running Time"
      ) {
        obj[
          `additional${addOn.codeId
            .substring(0, 1)
            .toUpperCase()}${addOn.codeId.substring(1, addOn.codeId.length)}`
        ] = addOn.count ? addOn.count : 0;
      } else {
        obj[addOn.codeId] = addOn.count ? parseInt(addOn.count) : 0;
      }
    }

    obj.additionalPrice = addOnsPrice * 100;
    obj.deliveryTime = 2;
    obj.price = obj.price * 100;
    obj.nextPrice = obj.price;

    let upd = false;

    if (
      state &&
      state.update &&
      state.update !== null &&
      state.update === true
    ) {
      upd = true;
    }

    obj.type = "month";
    obj.thumbnailWithVideo = thumbnailWithVideo;

    if (obj.price === 0 && obj.additionalPrice === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Select at least one item to proceed to payment!",
      });
      return;
    }

    console.log("reached");
    navigate("../order-summary", {
      state: { order: obj, update: upd, addOns },
    });
  };

  const changeThumbnails = (add, addOnsArray = null, currOrder = null) => {
    const numVideos = (addOnsArray ? [...addOnsArray] : [...addOns]).filter(
      (addOn) => addOn.type === "Video"
    )[0].count;
    const newOrder = JSON.parse(JSON.stringify(currOrder ? currOrder : order));

    for (const addOn of addOnsArray ? [...addOnsArray] : [...addOns]) {
      if (addOn.type === "Thumbnail") {
        if (add) {
          newOrder.price = newOrder.price + numVideos * addOn.cost;
        } else {
          newOrder.price = newOrder.price - numVideos * addOn.cost;
        }
      }
    }

    setOrder(newOrder);
  };

  const changeCountInput = (type, amount) => {
    if (`${amount}`[0] === "0") {
      amount = `${parseInt(amount)}`;
    }

    if (type === "Video") {
      const newOrder = JSON.parse(JSON.stringify(order));
      const newAddOns = [...addOns];
      const thumbnail = [...addOns]
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Thumbnail")[0];
      const video = [...addOns]
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Video")[0];

      const rawTime = [...addOns]
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Raw Footage Time")[0];

      if (video[0].count < amount) {
        newOrder.price =
          newOrder.price + video[0].cost * (amount - video[0].count);

        if (rawTime[0].count) {
          newOrder.price =
            newOrder.price +
            rawTime[0].count * rawTime[0].cost * (amount - video[0].count);
        }

        if (thumbnailWithVideo) {
          const newPrice = `${parseInt(amount - video[0].count)}`;
          newOrder.price = newOrder.price + thumbnail[0].cost * newPrice;
        }

        if (newOrder.unlimitedStockFootage) {
          const newCount = `${parseInt(amount - video[0].count)}`;
          newOrder.price = newOrder.price + 35 * newCount;
        }

        newAddOns[video[1]].count = amount;
      } else if (video[0].count > amount) {
        newOrder.price =
          newOrder.price - video[0].cost * (video[0].count - amount);

        if (rawTime[0].count) {
          newOrder.price =
            newOrder.price -
            rawTime[0].count * rawTime[0].cost * (video[0].count - amount);
        }

        if (thumbnailWithVideo) {
          const newPrice = `${parseInt(video[0].count - amount)}`;
          newOrder.price = newOrder.price - thumbnail[0].cost * newPrice;
        }

        if (newOrder.unlimitedStockFootage) {
          const newCount = `${parseInt(video[0].count - amount)}`;
          newOrder.price = newOrder.price - 35 * newCount;
        }

        newAddOns[video[1]].count = amount;
      }

      setAddOns(newAddOns);
      setOrder(newOrder);
    } else if (type === "Raw Footage Time") {
      const newOrder = JSON.parse(JSON.stringify(order));
      const newAddOns = [...addOns];
      const rawTime = newAddOns
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Raw Footage Time")[0];
      const numVideos = newAddOns.filter((addOn) => addOn.type === "Video")[0]
        .count;
      const runningTime = newAddOns
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Final Running Time")[0];
      if (rawTime[0].count < amount) {
        newOrder.price =
          newOrder.price +
          rawTime[0].cost * numVideos * (amount - rawTime[0].count);
        // newOrder.price =
        //   newOrder.price + rawTime[0].cost * (amount - rawTime[0].count);
        newAddOns[rawTime[1]].count = amount;

        if (amount < runningTime[0].count) {
          newAddOns[runningTime[1]].count = amount;
        }
      } else if (rawTime[0].count > amount) {
        newOrder.price =
          newOrder.price -
          rawTime[0].cost * numVideos * (rawTime[0].count - amount);
        // newOrder.price =
        //   newOrder.price - rawTime[0].cost * (rawTime[0].count - amount);
        newAddOns[rawTime[1]].count = amount;

        if (amount < runningTime[0].count) {
          newAddOns[runningTime[1]].count = amount;
        }
      }

      setAddOns(newAddOns);
      setOrder(newOrder);
    } else if (type === "Final Running Time") {
      const newOrder = JSON.parse(JSON.stringify(order));
      const newAddOns = [...addOns];
      const finalTime = newAddOns
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Final Running Time")[0];
      const numVideos = newAddOns.filter((addOn) => addOn.type === "Video")[0]
        .count;
      const rawTime = newAddOns.filter(
        (addOn) => addOn.type === "Raw Footage Time"
      )[0].count;
      if (
        finalTime[0].count < amount &&
        parseInt(amount) <= parseInt(rawTime)
      ) {
        // newOrder.price =
        //   newOrder.price +
        //   finalTime[0].cost * numVideos * (amount - finalTime[0].count);
        newAddOns[finalTime[1]].count = amount;
      } else if (
        finalTime[0].count < amount &&
        parseInt(amount) > parseInt(rawTime)
      ) {
        newAddOns[finalTime[1]].count = 0;
        // newOrder.price =
        //   newOrder.price - finalTime[0].cost * numVideos * finalTime[0].count;
        setExceedError(true);
        setTimeout(() => {
          setExceedError(false);
        }, 3000);
      } else if (finalTime[0].count > amount) {
        // newOrder.price =
        //   newOrder.price -
        //   finalTime[0].cost * numVideos * (finalTime[0].count - amount);
        newAddOns[finalTime[1]].count = amount;
      }

      setAddOns(newAddOns);
      setOrder(newOrder);
    } else {
      const newAddOns = [...addOns].map((addOn) => {
        if (addOn.type === type && addOn.count < amount) {
          const operation = parseInt(`${addOn.cost * (amount - addOn.count)}`);
          setAddOnsPrice((curr) => curr + operation);
          addOn.count = amount;
        } else if (addOn.type === type && addOn.count > amount) {
          const operation = parseInt(`${addOn.cost * (addOn.count - amount)}`);
          setAddOnsPrice((curr) => curr - operation);
          addOn.count = amount;
        }

        return addOn;
      });

      setAddOns(newAddOns);
    }
  };

  return addOns && addOns.length && order ? (
    <div className="customer-test-layout-custom-package-page">
      {loading ? <LoadingUpload text="Finishing Purchase" /> : null}
      <div className="page-content">
        <img className="blurred-bg" src={BgBlurred} alt="blurred-background" />
        <div className="login-header">
          <img src={LogoLanding} alt="logo" />
          <button
            className="login-button"
            onClick={() => navigate("/auth/sign-in")}
          >
            Login
          </button>
        </div>
        <div className="package-main-content">
          <div className="wrapper">
            {!isMessageShown ? (
              step === 2 ? (
                <div className="middle-blocks">
                  <div className="customer-layout-add-ons-page-block add-ons-header">
                    <div className="title">
                      <span>
                        <span className="number">2</span> Custom Package
                      </span>
                      <button
                        onClick={() =>
                          navigate("/customer-test/packages/select-package")
                        }
                      >
                        Change Package
                      </button>
                    </div>
                  </div>
                  <div className="customer-layout-add-ons-page-block add-ons-content">
                    <div className="title">
                      <span>
                        <span className="number">3</span> Add-Ons
                      </span>
                      <span className="subtitle">One time purchases.</span>
                    </div>
                    <div className="add-ons">
                      {addOns
                        .filter(
                          (addOn) =>
                            addOn.type !== "Video" &&
                            addOn.type !== "Raw Footage Time" &&
                            addOn.type !== "Final Running Time"
                        )
                        .map((addOn) => {
                          return (
                            <div className="add-on">
                              <div className="left">
                                <div className="image-container">
                                  {addOn.type === "Thumbnail" ? (
                                    <img src={Thumbnail} alt="add-on-symbol" />
                                  ) : addOn.type === "Logo" ? (
                                    <img src={Logo} alt="add-on-symbol" />
                                  ) : addOn.type === "Banner Design" ? (
                                    <img src={Banner} alt="add-on-symbol" />
                                  ) : addOn.type === "Consultation Call" ? (
                                    <img
                                      src={ConsultationCall}
                                      alt="add-on-symbol"
                                    />
                                  ) : addOn.type === "Outro" ? (
                                    <img src={Outro} alt="add-on-symbol" />
                                  ) : (
                                    <img src={Intro} alt="add-on-symbol" />
                                  )}
                                </div>
                                <div className="details">
                                  <span className="name">
                                    {addOn.type === "Consultation Call"
                                      ? "15-min Consultation Call"
                                      : addOn.type}
                                  </span>
                                  <span className="price">${addOn.cost}</span>
                                </div>
                              </div>
                              <div className="right">
                                <div
                                  className="symbol"
                                  onClick={() =>
                                    changeCount("minus", addOn.type)
                                  }
                                >
                                  -
                                </div>
                                <div className="counter">
                                  <input
                                    type={"number"}
                                    min={0}
                                    value={addOn.count}
                                    className="no-spin"
                                    onChange={(e) => {
                                      changeCountInput(
                                        addOn.type,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                                <div
                                  className="symbol"
                                  onClick={() =>
                                    changeCount("plus", addOn.type)
                                  }
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="summary mobile">
                    <span className="summary-title">Order Summary</span>
                    <div className="grey-line"></div>
                    <div className="products">
                      <div className="product">
                        <div className="product-item">
                          <span>Custom Order</span>
                          <div className="price">
                            <span>
                              $
                              {formatCurrency(
                                order.price > 0 ? order.price : 0
                              )}
                              .00
                            </span>
                          </div>
                        </div>
                        <div className="product-item products">
                          {addOns
                            .filter(
                              (addOn) =>
                                addOn.count &&
                                (addOn.type === "Video" ||
                                  addOn.type === "Raw Footage Time" ||
                                  addOn.type === "Final Running Time")
                            )
                            .map((addOn) => {
                              return (
                                <span className="description">
                                  {addOn.type === "Video"
                                    ? `${addOn.count} video${
                                        addOn.count !== 1 ? "s" : ""
                                      } per month`
                                    : addOn.type === "Raw Footage Time"
                                    ? `${addOn.count} minute${
                                        addOn.count !== 1 ? "s" : ""
                                      } of provided footage per video`
                                    : `${addOn.count} minute${
                                        addOn.count !== 1 ? "s" : ""
                                      } of running time per video`}
                                </span>
                              );
                            })}
                          {thumbnailWithVideo ? (
                            <span className="description">
                              1 Thumbnail with each video
                            </span>
                          ) : null}

                          {order.unlimitedStockFootage ? (
                            <span className="description">
                              Unlimited Stock Footage
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="product">
                        <div className="product-item">
                          <span>Add-Ons</span>
                          <div className="price">
                            <span>${formatCurrency(addOnsPrice)}.00</span>
                          </div>
                        </div>
                        <div className="product-item products">
                          {addOns
                            .filter(
                              (addOn) =>
                                addOn.count &&
                                addOn.type !== "Video" &&
                                addOn.type !== "Raw Footage Time" &&
                                addOn.type !== "Final Running Time"
                            )
                            .map((addOn) => {
                              return (
                                <span className="description">
                                  {addOn.count} {addOn.type}
                                </span>
                              );
                            })}
                          {order.unlimitedStockFootage ? (
                            <span className="description">
                              Unlimited Stock Footage
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* <div className="total">
                      <div className="total-price">
                        <span>Total</span>
                        <div className="price">
                          <span>
                            ${(order.price > 0 ? order.price : 0) + addOnsPrice}
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <button
                    className="continue-without"
                    onClick={() => orderPackage()}
                  >
                    {addOnsPrice === 0
                      ? "Continue without add-ons"
                      : "Continue"}
                  </button>
                  <button
                    className="cancel"
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    Go back
                  </button>
                </div>
              ) : (
                <div className="middle-blocks">
                  <div className="customer-layout-add-ons-page-block add-ons-content order-details">
                    <div className="title">
                      <span>
                        <span className="number">1</span> Order Details
                      </span>
                      <span className="subtitle">
                        Create your custom monthly package by entering how much
                        or many you want of each item per month.
                        <span className="green">
                          Save money by ordering more than one of each item!
                        </span>
                      </span>
                    </div>
                    <div className="add-ons">
                      {addOns
                        .filter(
                          (addOn) =>
                            addOn.type === "Video" ||
                            addOn.type === "Raw Footage Time" ||
                            addOn.type === "Final Running Time"
                        )
                        .map((addOn) => {
                          return (
                            <div className="add-on">
                              <div className="left">
                                <div className="image-container">
                                  {addOn.type === "Video" ? (
                                    <img src={Video} alt="add-on-symbol" />
                                  ) : addOn.type === "Raw Footage Time" ? (
                                    <img src={Time} alt="add-on-symbol" />
                                  ) : (
                                    <img src={Clock} alt="add-on-symbol" />
                                  )}
                                </div>
                                <div className="details">
                                  <span className="price">
                                    {addOn.type === "Video"
                                      ? `Videos per month ($${addOn.cost} per video)`
                                      : addOn.type === "Raw Footage Time"
                                      ? "Minutes of provided footage"
                                      : "Final Running time of the video in minutes"}{" "}
                                    {addOn.type === "Raw Footage Time"
                                      ? `- $${addOn.cost}/min (Per video)`
                                      : ""}
                                  </span>
                                  <span className="description">
                                    {addOn.type === "Video"
                                      ? "The amount of video projects you'd like us to edit for you per month."
                                      : addOn.type === "Raw Footage Time"
                                      ? "This is the raw, recorded, & unedited footage files you are going to send us to edit for your video."
                                      : "This is the final length of the video being delivered in minutes. This cannot exceed the length of raw footage."}
                                    {exceedError &&
                                    addOn.type === "Final Running Time" ? (
                                      <span className="error">
                                        The final running time cannot exceed the
                                        raw footage time
                                      </span>
                                    ) : null}
                                  </span>
                                </div>
                              </div>
                              <div className="right">
                                <div
                                  className="symbol"
                                  onClick={() =>
                                    changeCount("minus", addOn.type, 1)
                                  }
                                >
                                  -
                                </div>
                                <div className="counter">
                                  <input
                                    type={"number"}
                                    min={0}
                                    value={addOn.count}
                                    className="no-spin"
                                    onChange={(e) => {
                                      changeCountInput(
                                        addOn.type,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                                <div
                                  className="symbol"
                                  onClick={() =>
                                    changeCount(
                                      "plus",
                                      addOn.type,
                                      addOn.type === "Video" ? 1 : 10
                                    )
                                  }
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <div className="add-on">
                        <div className="left">
                          <div className="image-container">
                            <img src={Thumbnail} alt="add-on-symbol" />
                          </div>
                          <div className="details">
                            <span className="price">
                              Thumbnail with each video ($
                              {
                                [...addOns].filter(
                                  (addOn) => addOn.type === "Thumbnail"
                                )[0].cost
                              }
                              /per video)
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <input
                            type="checkbox"
                            checked={thumbnailWithVideo}
                            onChange={(e) => {
                              setThumbnailWithVideo(e.target.checked);
                              if (e.target.checked) {
                                changeThumbnails(true);
                              } else {
                                changeThumbnails(false);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="add-on">
                        <div className="left">
                          <div className="image-container">
                            <img src={UnlimitedStock} alt="add-on-symbol" />
                          </div>
                          <div className="details">
                            <span className="price">
                              Stock Footage ($35/video)
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <input
                            type="checkbox"
                            checked={order.unlimitedStockFootage}
                            onChange={(e) => {
                              const newOrder = JSON.parse(
                                JSON.stringify(order)
                              );

                              const videos = [...addOns].filter(
                                (addOn) => addOn.codeId === "videos"
                              )[0];

                              newOrder.unlimitedStockFootage = e.target.checked;
                              if (e.target.checked) {
                                newOrder.price =
                                  newOrder.price + 35 * videos.count;
                                setOrder(newOrder);
                              } else {
                                newOrder.price =
                                  newOrder.price - 35 * videos.count;
                                setOrder(newOrder);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="message-container">
                        <span className="message-title">
                          Need a more personalized package?
                        </span>
                        <span>
                          Send us a message and we can create a quote for your
                          unique package within 24 hours.
                        </span>
                        <button onClick={() => setIsMessageShown(true)}>
                          Message Us
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="summary mobile">
                    <span className="summary-title">Order Summary</span>
                    <div className="grey-line"></div>
                    <div className="products">
                      <div className="product">
                        <div className="product-item">
                          <span>Custom Order</span>
                          <div className="price">
                            <span>
                              $
                              {formatCurrency(
                                order.price > 0 ? order.price : 0
                              )}
                              .00
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="product">
                        <div className="product-item">
                          <span>Add-Ons</span>
                          <div className="price">
                            <span>${formatCurrency(addOnsPrice)}.00</span>
                          </div>
                        </div>
                        {addOns
                          .filter(
                            (addOn) =>
                              (addOn.count ||
                                (addOn.type === "Thumbnail" &&
                                  thumbnailWithVideo)) &&
                              addOn.type !== "Video" &&
                              addOn.type !== "Raw Footage Time" &&
                              addOn.type !== "Final Running Time"
                          )
                          .map((addOn) => {
                            const videos = [...addOns].filter(
                              (addOn) => addOn.type === "Video"
                            )[0];
                            return (
                              <span className="description">
                                {addOn.type === "Thumbnail" &&
                                thumbnailWithVideo
                                  ? (addOn.count ? parseInt(addOn.count) : 0) +
                                    (videos.count ? parseInt(videos.count) : 0)
                                  : parseInt(addOn.count)}{" "}
                                {addOn.type}
                              </span>
                            );
                          })}
                        {order && order.unlimitedStockFootage ? (
                          <span className="description">
                            Unlimited Stock Footage
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="total">
                      <div className="total-price">
                        <span>Total</span>
                        <div className="price">
                          <span>
                            ${(order.price > 0 ? order.price : 0) + addOnsPrice}
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <button
                    className="continue-without"
                    onClick={() => {
                      const finalTime = [...addOns].filter(
                        (addOn) => addOn.type === "Final Running Time"
                      )[0];
                      const rawTime = [...addOns].filter(
                        (addOn) => addOn.type === "Raw Footage Time"
                      )[0];
                      const videoCount = [...addOns].filter(
                        (addOn) => addOn.type === "Video"
                      )[0].count;

                      if (videoCount && (!finalTime.count || !rawTime.count)) {
                        Swal.fire({
                          title: "Oops!",
                          text: "Please add the Final Running Time and Minutes of Provided Footage",
                          heightAuto: false,
                          icon: "error",
                        });
                        return;
                      }

                      setStep(2);
                    }}
                  >
                    Continue
                  </button>
                </div>
              )
            ) : (
              <div className="middle-blocks message">
                <img
                  className="blurred-bg"
                  src={BgBlurred}
                  alt="blurred-background"
                />
                <div className="message-container">
                  <div className="customer-layout-add-ons-page-block add-ons-content order-details">
                    <div className="title">
                      <span>Message Us About Your Custom Package</span>
                      <span className="subtitle">
                        Leave your details below, and we'll get back to you
                        within 24 hours with a quote or to discuss specifics of
                        your project.
                      </span>
                    </div>
                    <div className="inputs">
                      <div className="input-row">
                        <div className="input">
                          <span className="input-title">Name *</span>
                          <input
                            id="name"
                            value={messageFormik.values.name}
                            onChange={messageFormik.handleChange}
                          />
                        </div>
                        <div className="input">
                          <span className="input-title">Email *</span>
                          <input
                            id="email"
                            value={messageFormik.values.email}
                            onChange={messageFormik.handleChange}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input">
                          <span className="input-title">Phone Number</span>
                          <input
                            id="phoneNumber"
                            value={messageFormik.values.phoneNumber}
                            onChange={messageFormik.handleChange}
                          />
                        </div>
                        <div className="input">
                          <span className="input-title">
                            Your Approximate Budget *
                          </span>
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            $
                            <input
                              style={{ flex: "1" }}
                              id="budget"
                              value={messageFormik.values.budget}
                              onChange={messageFormik.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input">
                          <span className="input-title">Socials</span>
                          <input
                            id="socials"
                            value={messageFormik.values.socials}
                            onChange={messageFormik.handleChange}
                          />
                        </div>
                      </div>
                      <div className="input">
                        <span className="input-title">
                          Detailed Project Description *
                        </span>
                        <textarea
                          rows={4}
                          id="description"
                          value={messageFormik.values.description}
                          onChange={messageFormik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="continue-without"
                    onClick={messageFormik.handleSubmit}
                  >
                    Send Message
                  </button>
                  <button
                    className="cancel"
                    onClick={() => {
                      setIsMessageShown(false);
                    }}
                  >
                    Go back
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="summary desktop">
            <span className="summary-title">Order Summary</span>
            <div className="grey-line"></div>
            <div className="products">
              <div className="product">
                <div className="product-item">
                  <span>Custom Order</span>
                  <div className="price">
                    <span>
                      ${formatCurrency(order.price > 0 ? order.price : 0)}.00
                    </span>
                  </div>
                </div>
                <div className="product-item products">
                  {addOns
                    .filter(
                      (addOn) =>
                        addOn.count &&
                        (addOn.type === "Video" ||
                          addOn.type === "Raw Footage Time" ||
                          addOn.type === "Final Running Time")
                    )
                    .map((addOn) => {
                      return (
                        <span className="description">
                          {addOn.type === "Video"
                            ? `${addOn.count} video${
                                addOn.count !== 1 ? "s" : ""
                              } per month`
                            : addOn.type === "Raw Footage Time"
                            ? `${addOn.count} minute${
                                addOn.count !== 1 ? "s" : ""
                              } of provided footage per video`
                            : `${addOn.count} minute${
                                addOn.count !== 1 ? "s" : ""
                              } of running time per video`}
                        </span>
                      );
                    })}
                  {thumbnailWithVideo ? (
                    <span className="description">
                      1 Thumbnail with each video
                    </span>
                  ) : null}

                  {order && order.unlimitedStockFootage ? (
                    <span className="description">Unlimited Stock Footage</span>
                  ) : null}
                </div>
              </div>
              <div className="product">
                <div className="product-item">
                  <span>Add-Ons</span>
                  <div className="price">
                    <span>${formatCurrency(addOnsPrice)}.00</span>
                  </div>
                </div>
                <div className="product-item products">
                  {addOns
                    .filter(
                      (addOn) =>
                        addOn.count &&
                        addOn.type !== "Video" &&
                        addOn.type !== "Raw Footage Time" &&
                        addOn.type !== "Final Running Time"
                    )
                    .map((addOn) => {
                      return (
                        <span className="description">
                          {addOn.count} {addOn.type}
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="total">
              <div className="total-price">
                <span>Total</span>
                <div className="price">
                  <span>
                    ${(order.price > 0 ? order.price : 0) + addOnsPrice}/mo
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerTestCustomPackage;
