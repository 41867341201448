import "./TermsOfService.scss";
import { useNavigate } from "react-router";
import LogoLanding from "../../assets/landing/logo-landing.png";

const TermsOfService = () => {
  const navigate = useNavigate();

  return (
    <div className="terms-of-service">
      <div className="login-header">
        <img src={LogoLanding} alt="logo" />
        <button
          className="login-button"
          onClick={() => navigate("/auth/sign-in")}
        >
          Login
        </button>
      </div>
      <div className="terms-body">
        <h1>Terms of Service</h1>
        <p>
          Welcome, and thank you for your interest in Mark Studios, LLC (“Mark
          Studios,” “Company,” “we,” “us,” or "our"), which operates the web
          site located at{" "}
          <a href="https://markstudioshub.com">https://markstudioshub.com/</a>{" "}
          (the “Website”). The following Terms of Service are a legal contract
          between you (“you” and “your”) and Mark Studios regarding your use of
          the Website. Visitors and users of the Website are referred to
          individually as “User” and collectively as “Users”. Use of the Website
          is governed by these Terms of Service and our Mark Studios Privacy
          Policy, where the Privacy Policy describes the personal information
          that we collect and how we use and share it.
        </p>
        <p>
          PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY. BY REGISTERING
          FOR, ACCESSING, BROWSING, OR USING THE WEBSITE, YOU ACKNOWLEDGE THAT
          YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING
          TERMS OF SERVICE, AND ANY ADDITIONAL GUIDELINES (AS DEFINED BELOW)
          (COLLECTIVELY, THE “TERMS”), AND THAT YOU HAVE READ THE MARK STUDIOS
          PRIVACY POLICY.
        </p>
        <p>
          PLEASE NOTE THAT THESE TERMS INCLUDE A BINDING ARBITRATION PROVISION,
          INCLUDING A CLASS ACTION WAIVER. BY AGREEING TO BINDING ARBITRATION,
          TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, YOU WAIVE YOUR RIGHT TO
          LITIGATE DISPUTES THROUGH A COURT AND TO HAVE A JUDGE OR JURY DECIDE
          YOUR CASE.
        </p>
        <ol>
          <li>
            <b>Eligibility; Accounts</b>
            <br />
            <p>
              THE WEBSITE IS NOT AVAILABLE TO: (A) ANY USERS PREVIOUSLY
              SUSPENDED OR REMOVED FROM THE WEBSITE BY MARK STUDIOS, OR (B) ANY
              PERSONS WHO DO NOT HAVE THE LEGAL CAPACITY TO ENTER INTO BINDING
              CONTRACTS.
            </p>
            <ol>
              <li>
                <b>Acceptance</b>
                <br />
                <p>
                  By clicking the “Sign Up” button or by otherwise using or
                  registering an account for the Website, you represent that (i)
                  you are a US resident, or (ii) you are not a US resident, and
                  are of legal age of consent to open an account under the laws
                  of your country of residence, or (iii) your use of the Website
                  has been approved by your parent or legal guardian. You also
                  represent that you have not been previously suspended or
                  removed from the Website by Mark Studios, and that your
                  registration and your use of the Website is in compliance with
                  any and all applicable laws.
                </p>
              </li>
              <li>
                <b>Account</b>
                <p>
                  In order to use certain features of the Website, you must
                  register for an account. You may be asked to provide a
                  password in connection with your account. You are solely
                  responsible for maintaining the confidentiality of your
                  account and password, and you agree to accept responsibility
                  for all activities that occur under your account or password.
                  You agree that the information you provide to Mark Studios,
                  whether at registration or at any other time, will be true,
                  accurate, current, and complete. You also agree that you will
                  ensure that this information is kept accurate and up-to-date
                  at all times. If you have reason to believe that your account
                  is no longer secure (e.g., in the event of a loss, theft, or
                  unauthorized disclosure or use of your account ID or
                  password), then you agree to immediately notify Mark Studios
                  at contact@markstudioshub.com. You may be liable for the
                  losses incurred by Mark Studios or others due to any
                  unauthorized use of your account.
                </p>
              </li>
              <li>
                <b>Integrated Service</b>
                <br />
                <p>
                  Mark Studios may permit you to register for the Website
                  through, or otherwise associate your Mark Studios account
                  with, certain third-party social networking or integrated
                  services, including but not limited to Google (“Integrated
                  Service”). By registering for the Website using (or otherwise
                  granting access to) an Integrated Service, you agree that Mark
                  Studios may access your Integrated Service’s account
                  information, and may store and use certain information already
                  associated with the Integrated Service consistent with our
                  Privacy Policy, and you agree to any and all terms and
                  conditions of the Integrated Service regarding your use of the
                  Website via the Integrated Service. You may revoke Mark
                  Studios' access to your account on any Integrated Service at
                  any time by updating the appropriate account settings of the
                  respective Integrated Service. You should check your privacy
                  settings on each Integration Service to understand and change
                  the information sent to us through each Integration Service.
                  You agree that any Integrated Service is a Third-Party wwSite
                  (as defined below) and you are solely responsible for your
                  interactions with the Integrated Service as a result of
                  accessing the Website through the Integrated Service. Mark
                  Studios does not control the practices of an Integrated
                  Service, and you are advised to read the Privacy Policy and
                  terms and conditions of any Integrated Service that you use to
                  understand their practices.
                </p>
              </li>
              <li>
                <b>Child User</b>
                <br />
                <p>
                  If you are under the age of 18, you represent that your parent
                  or legal guardian has reviewed and agreed to the Terms on your
                  behalf. A Child User will only be permitted to use the Website
                  for so long as Mark Studios reasonably believes that such
                  access has been consented to by the Child User’s parent or
                  guardian.
                </p>
              </li>
              <li>
                <b>International Use</b>
                <br />
                <p>
                  Mark Studios operates the Website in the United States. If you
                  choose to access our Website from locations outside the United
                  States, you consent to the collection, transmission, use,
                  storage and processing of content and data (including your
                  personal information) in the United States. You agree to
                  comply with and are solely responsible for ensuring compliance
                  with all local laws, regulations, and rules in the
                  jurisdiction in which you reside or access the Website, if and
                  to the extent local laws are applicable to use of our Website.
                  If you are under the age of 18 (or the age of majority in the
                  jurisdiction in which you reside), you confirm that you have
                  received parental consent, if required in your jurisdiction,
                  to open an account with us and enter into these Terms. Unless
                  we have entered into a separate, mutually executed written
                  agreement with you that says otherwise, we do not represent
                  that our Website is appropriate or available for use in
                  jurisdictions outside the United States. The right to access
                  and use the Website is not granted in jurisdictions, if any,
                  where it may be prohibited, or where your use would render
                  Mark Studios in violation of any applicable laws or
                  regulations, including without limitation, Applicable Privacy
                  Laws.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <b>Privacy Policy</b>
            <br />
            <p>
              Your privacy is important to Mark Studios. Please read the Mark
              Studios Privacy Policy carefully for information relating to Mark
              Studios’ collection, use, and disclosure of your personal
              information. Among other things, our Mark Studios Privacy Policy
              explains how we treat your personal information and protect your
              privacy when you use our Website, and explains the procedures by
              which Users may view, update, correct, or delete their account and
              personal information.
            </p>
          </li>
          <li>
            <b>Other Guidelines</b>
            <br />
            <p>
              When using the Website, you will be subject to any additional
              posted guidelines or rules applicable to specific services and
              features which may be posted from time to time (the “Guidelines”).
              All such Guidelines are hereby incorporated by reference into the
              Terms.
            </p>
          </li>
          <li>
            <b>Modification of the Terms</b>
            <ol>
              <li>
                <b>General</b>
                <br />
                <p>
                  Upon opening an account, you accept the Terms in the form
                  posted on our website. Mark Studios reserves the right, at our
                  discretion, to change, modify, add, or remove portions of the
                  Terms at any time. Please check the Terms and any Guidelines
                  periodically for changes that are made after you open your
                  account. Your continued use of the Website after the posting
                  of changes constitutes your binding acceptance of such
                  changes. For any material changes to the Terms, Mark Studios
                  will make reasonable effort to provide notice to you of such
                  amended Terms, such as by an email notification to the address
                  associated with your account or by posting a notice on the
                  Website, and such amended terms will be effective against you
                  on the earlier of (i) your actual notice of such changes and
                  (ii) thirty days after Mark Studios makes reasonable attempt
                  to provide you such notice. However, changes addressing new
                  functions for a service or changes made for legal reasons will
                  be effective immediately. If you do not agree to the modified
                  Terms, you must discontinue your use of the Website. Disputes
                  arising under these Terms will be resolved in accordance with
                  the version of the Terms in place at the time the dispute
                  arose.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <b>User Content License Grant</b>
            <ol>
              <li>
                <b>User Content and Ownership</b>
                <br />
                <p>
                  Mark Studios may permit (a) the posting and/or publishing by
                  you and other Users of notes, questions, comments, ratings,
                  reviews, images, videos and other audio-visual materials and
                  communications (collectively, “User Postings”) and (b) the
                  posting, creation, or modification by you and other users of
                  computer code (including source code and object code) (“User
                  Code”) (User Postings and User Code, collectively, “User
                  Content”). You understand that whether or not such User
                  Content is published, Mark Studios does not guarantee any
                  confidentiality with respect to any submissions. Consistent
                  with Applicable Law, as between Mark Studios and you, you
                  retain all ownership rights you have in any User Content you
                  post or publish to the Website, and Mark Studios does not
                  claim any ownership rights in or to such User Content. You
                  acknowledge that you are solely responsible for your User
                  Content and the consequences of posting, creating, or
                  publishing such User Content.
                </p>
              </li>
              <li>
                <b>License Grant to Mark Studios</b>
                <br />
                <p>
                  By posting, submitting or distributing User Content on or
                  through the Website, you hereby grant to Mark Studios a
                  worldwide, non-exclusive, transferable, assignable, fully
                  paid-up, royalty-free right and license to (a) host, transfer,
                  display, perform, reproduce, distribute, prepare derivative
                  works of, use, make, have made, import, and otherwise exploit
                  your User Content, in whole or in part, in any media formats
                  and through any media channels (now known or hereafter
                  developed, to the extent consistent with applicable law and
                  Mark Studios’ Privacy Policy). Such license is perpetual and
                  irrevocable, except to the extent required to comply with
                  Applicable Privacy Law relating to ownership and control of
                  your personal information.
                </p>
              </li>
              <li>
                <b>License Grant to Users</b>
                <ol>
                  <li>
                    <span>
                      <b>User Posting</b>. By posting, submitting or
                      distributing User Postings through the Website, you hereby
                      grant to each User of the Website a non-exclusive license
                      to access and use your User Postings in any manner
                      permitted or made available by Mark Studios on or through
                      the Website.
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>Downloadable Content</b>. The Website may permit you to
                      download mobile applications or certain digital content
                      ("Downloadable Content"). Subject to your complete and
                      ongoing compliance with all the terms and conditions set
                      forth herein, Mark Studios grants you, a limited,
                      non-exclusive, non-transferable, non-sublicensable,
                      revocable license to download, install, view and use the
                      Downloadable Content, in object code form, on devices
                      owned or controlled by you, solely for your personal,
                      non-commercial purposes. You agree not to (i) modify or
                      create derivative works of the Downloadable Content (ii)
                      remove, disable, circumvent or otherwise create or
                      implement any workaround to any copy protection, rights
                      management, technical limitations or security features in
                      or protecting the Downloadable Content, and (iii) remove
                      any copyright and other proprietary notices on the
                      Downloadable Content and all copies thereof.
                    </span>
                  </li>
                </ol>
              </li>
              <li>
                <b>Access to Your User Content</b>
                <br />
                <p>
                  Mark Studios may permit Users to share their User Content with
                  a select group of other Users, or make their User Content
                  public for all (even non-Website users) to view. You
                  acknowledge and agree that, although Mark Studios may provide
                  certain features intended to allow you to restrict some User
                  Content you create from others, Mark Studios does not
                  guarantee that such User Content will never be accessible by
                  others. In the event of unauthorized access, Mark Studios will
                  use reasonable efforts to notify you. MARK STUDIOS HEREBY
                  DISCLAIMS ANY AND ALL LIABILITY WITH RESPECT TO ANY
                  UNAUTHORIZED ACCESS TO ANY RESTRICTED USER CONTENT.
                </p>
              </li>
              <li>
                <b>User Content Disclaimer</b>
                <br />
                <p>
                  You understand that when using the Website, you will be
                  exposed to User Content from a variety of sources, and that
                  Mark Studios is not responsible for the accuracy, usefulness,
                  or intellectual property rights of or relating to such User
                  Content. You further understand and acknowledge that you may
                  be exposed to User Content that is inaccurate, offensive,
                  indecent or objectionable, and you agree to waive, and hereby
                  do waive, any legal or equitable rights or remedies you have
                  or may have against Mark Studios with respect thereto. Mark
                  Studios does not endorse any User Content or any opinion,
                  recommendation or advice expressed therein, and Mark Studios
                  expressly disclaims any and all liability in connection with
                  User Content.
                </p>
              </li>
              <li>
                <b>Feedback</b>
                <br />
                <p>
                  By submitting suggestions or other feedback related to our
                  Website to us, you agree that we can (but do not have to) use
                  and share such feedback for any purpose without compensation
                  to you. We will honor any limits we agree to at the time we
                  collect feedback
                </p>
              </li>
            </ol>
          </li>
          <li>
            <b>Digital Millennium Copyright Act</b>
            <br />
            <p>
              It is Mark Studios’ policy to respond to notices of alleged
              copyright infringement that comply with the Digital Millennium
              Copyright Act. Mark Studios will promptly terminate without notice
              your access to the Website if you are determined by Mark Studios
              to be a “repeat infringer.” A repeat infringer is a User who has
              been notified by Mark Studios of infringing activity violations
              more than twice and/or who has had User Content or any other
              user-submitted content removed from the Website more than twice.
            </p>
          </li>
          <li>
            <b>Proprietary Materials; Licenses</b>
            <ol>
              <li>
                <b>Proprietary Materials</b>
                <br />
                <p>
                  The Website is owned and operated by Mark Studios. The visual
                  interfaces, graphics, design, compilation, information,
                  computer code (including source code or object code),
                  software, content, videos, and all other elements of the
                  Website (the “Website Materials”) are protected by United
                  States and international copyright, patent, and trademark
                  laws, international conventions, and other applicable laws
                  governing intellectual property and proprietary rights. Except
                  for any User Content provided and owned by Users and except as
                  otherwise set forth in this Section 7 (Proprietary Materials;
                  Licenses), all Website Materials, and all trademarks, service
                  marks, and trade names, contained on or available through the
                  Website are owned by or licensed to Mark Studios, and Mark
                  Studios reserves all rights therein and thereto not expressly
                  granted by these Terms.
                </p>
              </li>
              <li>
                <b>Licensed Content</b>
                <br />
                <p>
                  Mark Studios may make available on the Website certain videos
                  and related supplementary materials that are owned by Mark
                  Studios or its third-party licensors (the “Licensed Content”).
                  Mark Studios grants to you a non-exclusive, non-transferable
                  right to access and use the Licensed Content as made available
                  on the Website by Mark Studios solely for your personal,
                  non-commercial purposes. Unless expressly indicated on the
                  Website that a particular item of Licensed Content is made
                  available to Users under alternate license terms, you may not
                  download, distribute, sell, lease, modify, or otherwise
                  provide access to the Licensed Content to any third party.
                </p>
                <ol>
                  <li>
                    <span>
                      <b>Alternate Licenses</b>. In certain cases, Mark Studios
                      or its licensors may make available Licensed Content under
                      alternate license terms, such as a variant of the Creative
                      Commons License (as defined below) (each, an “Alternate
                      License”). Where expressly indicated as such on the
                      Website, and subject to the terms and conditions of these
                      Terms, the applicable Licensed Content is licensed to you
                      under the terms of the Alternate License. By using,
                      downloading, or otherwise accessing such Licensed Content,
                      you agree to comply fully with all the terms and
                      conditions of such Alternate License.
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>Creative Commons License</b>. Unless expressly
                      otherwise identified on the Website with respect to a
                      particular item of Licensed Content, any reference to the
                      “Creative Commons”, “CC” or similarly-phrased license
                      shall be deemed to be a reference to the Creative Commons
                      Attribution-NonCommercial-ShareAlike 3.0 United States
                      License (the “Creative Commons License”).
                    </span>
                  </li>
                </ol>
              </li>
              <li>
                <b>Non-Commercial Use</b>
                <br />
                <p>
                  The Licensed Content is intended for personal, non-commercial
                  use only. Without limiting the foregoing, and notwithstanding
                  the terms of any Alternate License for such Licensed Content,
                  the Licensed Content may not be used, distributed or otherwise
                  exploited for any commercial purpose, commercial advantage or
                  private monetary compensation, unless otherwise previously
                  agreed in writing by Mark Studios.
                </p>
                <ol>
                  <li>
                    <b>Impermissible Uses</b>. Without limiting the generality
                    of the foregoing, the following are types of uses that Mark
                    Studios expressly defines as falling outside of
                    “non-commercial” use:
                    <ul>
                      <li>
                        the sale or rental of (1) any part of the Licensed
                        Content, (2) any derivative works based at least in part
                        on the Licensed Content, or (3) any collective work that
                        includes any part of the Licensed Content;
                      </li>
                      <li>
                        providing training, support, or editorial services that
                        use or reference the Licensed Content in exchange for a
                        fee; and
                      </li>
                      <li>
                        the sale of advertisements, sponsorships, or promotions
                        placed on the Licensed Content, or any part thereof, or
                        the sale of advertisements, sponsorships, or promotions
                        on any website or blog containing any part of the
                        Licensed Material, including without limitation any
                        “pop-up advertisements”.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <b>Crediting Mark Studios</b>
                <br />
                <p>
                  If you distribute, publicly perform or display, transmit,
                  publish, or otherwise make available any Licensed Content or
                  any derivative works thereof, you must also provide the
                  following notice prominently along with such Licensed Content
                  or derivative work thereof: “All Mark Studios content is
                  available for free at https://markstudioshub.com/” and adhere
                  to the Mark Studios brand guidelines.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <b>Prohibited Conduct</b>
            <br />
            <p>YOU AGREE NOT TO:</p>
            <ol>
              <li>
                except as expressly permitted under Sections 5.3 (License Grant
                to Users) and 7 (Proprietary Materials; Licenses) of these
                Terms, rent, lease, loan, sell, resell, sublicense, distribute
                or otherwise transfer the licenses for any Website Materials;
              </li>
              <li>
                post, upload, or distribute any defamatory, libelous, or
                inaccurate User Content or other content;
              </li>
              <li>
                post, upload, or distribute any User Content or other content
                that is unlawful or that a reasonable person could deem to be
                objectionable, offensive, indecent, pornographic, harassing,
                threatening, embarrassing, distressing, vulgar, hateful,
                racially or ethnically offensive, promoting of violence,
                hostility, or discrimination, or otherwise inappropriate;
              </li>
              <li>
                use the Website in any manner that is harmful to minors, or in
                any manner that violates Mark Studios’ Community Guidelines;
              </li>
              <li>
                impersonate any person or entity, falsely claim an affiliation
                with any person or entity, or access the Website accounts of
                others without permission, create accounts via bots or other
                automated means, or perform any other fraudulent activity;
              </li>
              <li>
                develop, support or use software, devices, scripts, robots, or
                any other means or processes (including crawlers, browser
                plugins and add-ons, or any other technology) to scrape the
                Website or otherwise copy data from the Website;
              </li>
              <li>
                Use bots or other automated methods to access the Website;
              </li>
              <li>
                delete (or otherwise obscure or alter) the copyright or other
                proprietary rights notices on the Website or on any Licensed
                Content or User Content;
              </li>
              <li>
                assert, or authorize, assist, or encourage any third party to
                assert, against Mark Studios or any of its affiliates or
                licensors any patent infringement or other intellectual property
                infringement claim regarding any Licensed Content or User
                Content you have used, submitted, or otherwise made available on
                or through the Website;
              </li>
              <li>
                make unsolicited offers, advertisements, proposals, or send junk
                mail or spam to other Users of the Website (including, but not
                limited to, unsolicited advertising, promotional materials, or
                other solicitation material, bulk mailing of commercial
                advertising, chain mail, informational announcements, charity
                requests, and petitions for signatures);
              </li>
              <li>
                use the Website for any illegal purpose, or in violation of any
                local, state, national, or international law, including, without
                limitation, laws governing intellectual property and other
                proprietary rights, and data protection and privacy;
              </li>
              <li>
                defame, harass, bully, abuse, threaten or defraud Users of the
                Website, or collect, or attempt to collect, personal information
                about Users or third parties without their consent;
              </li>
              <li>
                probe, scan, remove, circumvent, disable, damage or otherwise
                interfere with or test the vulnerability of security-related
                features of the Website, Licensed Content or User Content,
                features that prevent or restrict use or copying of any content
                accessible through the Website, or features that enforce
                limitations on the use of the Website, Licensed Content or User
                Content, or otherwise access, tamper with, or use non-public
                portions of the Website without our authorization;
              </li>
              <li>
                reverse engineer, decompile, disassemble or otherwise attempt to
                discover the source code of the Website or any part thereof,
                except and only to the extent that such activity is expressly
                permitted by applicable law (in which case you must Contact Mark
                Studios to give notice of the proposed activity and discuss
                alternative means to obtain the desired information from Mark
                Studios) notwithstanding this limitation;
              </li>
              <li>
                modify, adapt, translate or create derivative works based upon
                the Website or any part thereof, except and only to the extent
                expressly permitted by Mark Studios herein or to the extent the
                foregoing restriction is expressly prohibited by applicable law
                (in which case you must Contact Mark Studios to give notice of
                the proposed activity and discuss whether Mark Studios is
                willing to provide the desired derivative works); or
              </li>
              <li>
                intentionally interfere with or damage operation of the Website
                or any user’s enjoyment of it, by any means, including without
                limitation by participation in any denial-of-service type
                attacks or by uploading or otherwise disseminating viruses,
                adware, spyware, worms, or other malicious code.
              </li>
            </ol>
          </li>
          <li>
            <b>Third-Party Sites, Products and Website; Links</b>
            <br />
            <p>
              The Website may include links or references to other websites
              solely as a convenience to Users (“Third-Party Sites”). Mark
              Studios does not endorse any such Third-Party Sites or the
              information, materials, products, or Website contained on or
              accessible through Third-Party Sites. ACCESS AND USE OF
              THIRD-PARTY SITES, INCLUDING THE INFORMATION, MATERIALS, PRODUCTS,
              AND SERVICES ON OR AVAILABLE THROUGH THIRD-PARTY SITES, IS SOLELY
              AT YOUR OWN RISK.
            </p>
          </li>
          <li>
            <b>Term and Termination</b>
            <ol>
              <li>
                <b>Term</b>
                <br />
                <p>
                  These Terms shall remain in full force and effect while you
                  use the Website unless your account is terminated as provided
                  in these Terms, in which case you no longer have the right to
                  use the Website.
                </p>
              </li>
              <li>
                <b>Termination by Mark Studios</b>
                <br />
                <p>
                  Mark Studios, in its sole discretion, for any or no reason,
                  and without penalty, may: (a) restrict, suspend or terminate:
                  (i) any account (or any part thereof) you may have with Mark
                  Studios or (ii) your use of the Website, and (b) remove and
                  discard all or any part of your account, User profile, and
                  User Content, at any time. Mark Studios may also in its sole
                  discretion and at any time discontinue providing access to the
                  Website, or any part thereof, with or without notice. You
                  agree that any termination of your access to the Website or
                  any account you may have, or portion thereof, may be affected
                  without prior notice, and you agree that Mark Studios will not
                  be liable to you or any third party for any such termination.
                  Any suspected fraudulent, abusive or illegal activity may be
                  referred to appropriate law enforcement authorities. These
                  remedies are in addition to any other remedies Mark Studios
                  may have at law or in equity. As discussed herein, Mark
                  Studios does not permit copyright, trademarks, or other
                  intellectual property infringing activities on the Website,
                  and will terminate access to the Website, and remove all User
                  Content or other content submitted, by any Users who are found
                  to be repeat infringers.
                </p>
              </li>
              <li>
                <b>Termination by You</b>
                <br />
                <p>
                  Your only remedy with respect to any dissatisfaction with (i)
                  the Website, (ii) any term of these Terms of Service, (iii)
                  Guidelines, (iv) any policy or practice of Mark Studios in
                  operating the Website, or (v) any content or information
                  transmitted through the Website, is to terminate the Terms and
                  your account. You may terminate these Terms at any time
                  (prospectively only) by deleting your login account with the
                  Website and discontinuing use of any and all parts of the
                  Website.
                </p>
              </li>
              <li>
                <b>Termination of Child Accounts</b>
                <br />
                <p>
                  Certain Users (Parents or Guardians) may terminate these Terms
                  with respect to a Child account that was created by them or at
                  their direction, as provided in this Section.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <b>Representations and Warranties</b>
            <br />
            <p>
              You warrant, represent and agree that you will not provide any
              User Content or otherwise use the Website in a manner that (i)
              infringes, violates or misappropriates another's intellectual
              property rights, rights of publicity or privacy, or other rights;
              (ii) violates any international, federal, state or local law,
              statute, ordinance or regulation or which would render Mark
              Studios in violation of any applicable laws or regulations,
              including without limitation, Applicable Privacy Laws
              (collectively, "Applicable Law"); (iii) is harmful, fraudulent,
              threatening, abusive, harassing, tortuous, defamatory, vulgar,
              obscene, libelous, or otherwise objectionable; or (iv) jeopardizes
              the security of your account or the Website in any way, such as
              allowing someone else access to your account or password or
              submitting User Content that contains viruses. Additionally, you
              represent, warrant and agree that (i) you possess all rights
              necessary to provide your User Content and grant Company the
              rights in these Terms; and (ii) you will comply with Applicable
              Laws in connection with your use of the Website.{" "}
            </p>
          </li>
          <li>
            <b>Indemnification</b> <br />
            <p>
              You agree, to the extent permissible under your state’s laws, to
              indemnify, defend, and hold harmless Mark Studios, and its
              successors, affiliated companies, contractors, officers,
              directors, employees, agents and its third-party suppliers,
              licensors, and partners (“Mark Studios Parties") from and against
              all losses, damages, liabilities, demands, judgments, settlements,
              costs and expenses of any kind (including legal fees and
              expenses), from any claim or demand made by any third-party
              relating to or arising out of (i) your access to, use or misuse of
              the Website; (ii) your breach or alleged breach of these Terms, or
              any violation of the Terms; (iii) any breach of the
              representations, warranties, and covenants made herein, whether by
              you or by any Child User; (iv) your failure to comply with
              Applicable Laws(including any failure to obtain or provide any
              necessary consent or notice); (v) the infringement by you or any
              third-party using your account of any intellectual property,
              privacy, or other right of any person or entity, including in
              connection with your User Content, or (vi) your breach or alleged
              breach of any interaction, agreement, or policy between you and
              any other Users. Mark Studios reserves the right, at your expense,
              to assume the exclusive defense and control of any matter for
              which you are required to indemnify Mark Studios, and you agree to
              cooperate with Mark Studios’ defense of these claims. You agree
              not to settle any such matter without the prior written consent of
              Mark Studios. Mark Studios will use reasonable efforts to notify
              you of any such claim, action, or proceeding upon becoming aware
              of it.
            </p>
          </li>
          <li>
            <b>Disclaimers; No Warranties</b>
            <br />
            <ol>
              <li>
                <b>No Warranties</b>
                <br />
                <p>
                  THE WEBSITE, AND ALL DATA, INFORMATION, SOFTWARE, WEBSITE
                  MATERIALS, CONTENT (WHETHER OWNED OR LICENSED), USER CONTENT,
                  THIRD-PARTY SITES, WEBSITE, OR APPLICATIONS MADE AVAILABLE IN
                  CONJUNCTION WITH OR THROUGH THE WEBSITE (THE "MARK STUDIOS
                  OFFERINGS"), ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE,” AND
                  “WITH ALL FAULTS” BASIS. TO THE FULLEST EXTENT PERMISSIBLE
                  PURSUANT TO APPLICABLE LAW, THE MARK STUDIOS PARTIES DISCLAIM
                  ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
                  STATUTORY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                  ALL IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY,
                  AVAILABILITY, QUIET ENJOYMENT, FITNESS FOR A PARTICULAR
                  PURPOSE, TITLE, AND NON-INFRINGEMENT. NO ADVICE OR
                  INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
                  MARK STUDIOS OR THROUGH THE WEBSITE WILL CREATE ANY WARRANTY
                  NOT EXPRESSLY STATED HEREIN.
                </p>
              </li>
              <li>
                <b>Content</b>
                <br />
                <p>
                  MARK STUDIOS, AND THE MARK STUDIOS PARTIES, DO NOT WARRANT
                  THAT THE WEBSITE OR ANY DATA, USER CONTENT, FUNCTIONS, OR ANY
                  OTHER INFORMATION OFFERED ON OR THROUGH THE WEBSITE WILL BE
                  UNINTERRUPTED, OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL
                  COMPONENTS, AND DO NOT WARRANT THAT ANY OF THE FOREGOING WILL
                  BE CORRECTED.
                </p>
                <p>
                  MARK STUDIOS AND THE MARK STUDIOS PARTIES MAKE NO
                  REPRESENTATION OR WARRANTY THAT (1) THE MARK STUDIOS OFFERINGS
                  WILL (A) MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR BE TO YOUR
                  LIKING, OR (B) WILL BE TIMELY, SECURE, ACCURATE, FREE FROM
                  ERRORS OR LOSS, OR UNINTERRUPTED, (2) THAT THE WEBSITE IS FREE
                  FROM VIRUSES OR OTHER HARMFUL COMPONENTS, OR (3) THAT ANY
                  DEFECTS OR ERRORS WILL BE CORRECTED. SOME FEATURES MAY BE NEW
                  OR EXPERIMENTAL AND MAY NOT HAVE BEEN TESTED IN ANY MANNER.
                </p>
              </li>
              <li>
                <b>Harm to Your Computer</b>
                <br />
                <p>
                  YOU UNDERSTAND AND AGREE THAT YOUR USE, ACCESS, DOWNLOAD, OR
                  OTHERWISE OBTAINING OF CONTENT, WEBSITE MATERIALS, SOFTWARE,
                  OR DATA THROUGH THE WEBSITE (INCLUDING THROUGH ANY API’S) IS
                  AT YOUR OWN DISCRETION AND RISK, AND THAT YOU WILL BE SOLELY
                  RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
                  COMPUTER SYSTEM) OR LOSS OF DATA THAT RESULTS THEREFROM.
                </p>
              </li>
              <li>
                <b>Limitations by Applicable Law</b>
                <br />
                <p>
                  SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION
                  OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY
                  TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM STATE TO
                  STATE AND JURISDICTION TO JURISDICTION.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <b>Limitation of Liability and Damages</b>
            <br />
            <ol>
              <li>
                <b>Limitation of Liability</b>
                <br />
                <p>
                  UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO,
                  NEGLIGENCE, WILL MARK STUDIOS OR THE MARK STUDIOS PARTIES BE
                  LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                  PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES (INCLUDING WITHOUT
                  LIMITATION DAMAGES ARISING FROM ANY UNSUCCESSFUL COURT ACTION
                  OR LEGAL DISPUTE, LOST BUSINESS, LOST REVENUES OR PROFITS,
                  LOSS OF DATA, OR ANY OTHER PECUNIARY OR NON-PECUNIARY LOSS OR
                  DAMAGE OF ANY NATURE WHATSOEVER) EVEN IF MARK STUDIOS OR ONE
                  OF THE MARK STUDIOS PARTIES HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES ARISING OUT OF OR RELATING (i) TO
                  THE TERMS; (ii) YOUR USE OF (OR INABILITY TO USE) THE WEBSITE
                  OR THE MARK STUDIOS OFFERINGS, OR (iii) ANY OTHER INTERACTIONS
                  WITH MARK STUDIOS OR ANY THIRD-PARTY THROUGH OR IN CONNECTION
                  WITH THE MARK STUDIOS OFFERINGS, INCLUDING OTHER USERS.
                  APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF
                  LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                  LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES,
                  MARK STUDIOS’S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT
                  PERMITTED BY APPLICABLE LAW.
                </p>
              </li>
              <li>
                <b>Limitation of Damages</b>
                <br />
                <p>
                  IN NO EVENT WILL MARK STUDIOS’S OR THE MARK STUDIOS PARTIES'
                  TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF
                  ACTION ARISING OUT OF OR RELATING TO THE TERMS OR YOUR USE OF
                  THE WEBSITE OR YOUR INTERACTION WITH OTHER WEBSITE USERS
                  (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY,
                  OR OTHERWISE), EXCEED THE AMOUNT PAID BY YOU TO MARK STUDIOS,
                  IF ANY, FOR ACCESSING THE WEBSITE DURING THE TWELVE MONTHS
                  IMMEDIATELY PRECEDING THE DATE OF THE CLAIM OR ONE HUNDRED
                  DOLLARS ($100), WHICHEVER IS GREATER.
                </p>
              </li>
              <li>
                <b>Basis of the Bargain</b>
                <br />
                <p>
                  YOU ACKNOWLEDGE AND AGREE THAT MARK STUDIOS HAS OFFERED THE
                  WEBSITE AND ENTERED INTO THE TERMS IN RELIANCE UPON THE
                  DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN,
                  THAT THE DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET
                  FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK
                  BETWEEN YOU AND MARK STUDIOS, AND THAT THE DISCLAIMERS AND THE
                  LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL
                  BASIS OF THE BARGAIN BETWEEN YOU AND MARK STUDIOS. MARK
                  STUDIOS WOULD NOT BE ABLE TO PROVIDE THE WEBSITE TO YOU ON AN
                  ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS.
                </p>
              </li>
              <li>
                <b>User Interactions and Release</b>
                <br />
                <ol>
                  <li>
                    User Disputes. Mark Studios is not responsible for the
                    actions, content, information or data of other third
                    parties, including other Users. You are solely responsible
                    for your interactions with other users of the Website, and
                    any other parties with whom you interact through the
                    Service. You should make whatever investigation you feel
                    necessary or appropriate before proceeding with any online
                    or offline interaction with any other person. We reserve the
                    right, but have no obligation, to become involved in any way
                    with these disputes.
                  </li>
                  <li>
                    Release. If you have a dispute with one or more Users, you
                    release us (and the Mark Studios Parties) from all claims,
                    demands and damages (actual and consequential) of every kind
                    and nature, known and unknown, arising out of or in any way
                    connected with such disputes, including damages for loss of
                    profits, goodwill, use, privacy or data. If you are a
                    California resident, you waive your rights under California
                    Civil Code §1542, which says: "A general release does not
                    extend to claims which the creditor does not know or suspect
                    to exist in his or her favor at the time of executing the
                    release, which if known by him or her must have materially
                    affected his settlement with the debtor." And, if you are
                    not a California resident, you waive your rights under any
                    applicable statutes of a similar effect, to the fullest
                    extent permissible under applicable law.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Miscellaneous (Including Dispute Resolution and Arbitration)</b>
            <ol>
              <li>
                <b>Notice</b>
                <br />
                <p>
                  Mark Studios may provide you with notices, including those
                  regarding changes to the Terms, by email, regular mail,
                  postings on the Website, or other reasonable means. Notice
                  will be deemed given twenty-four hours after email is sent,
                  unless Mark Studios is notified that the email address is
                  invalid. Alternatively, we may give you legal notice by mail
                  to a postal address, if provided by you through the Website.
                  In such case, notice will be deemed given three days after the
                  date of mailing. Notices posted on the Website are deemed
                  given 30 days following the initial posting. Any notices
                  directed to Mark Studios shall be sent by email to Mark
                  Studios.
                </p>
              </li>
              <li>
                <b>Waiver</b>
                <br />
                <p>
                  The failure of Mark Studios to exercise or enforce any right
                  or provision of the Terms will not constitute a waiver of such
                  right or provision. Any waiver of any provision of the Terms
                  will be effective only if in writing and signed by Mark
                  Studios.
                </p>
              </li>
              <li>
                <b>Governing Law</b>
                <br />
                <p>
                  The Terms will be governed by and construed in accordance with
                  the laws of the State of Florida, without giving effect to any
                  principles of conflicts of law that would cause the
                  application of the laws of any other jurisdiction.
                </p>
              </li>
              <li>
                <b>Dispute Resolution and Arbitration</b>
                <br />
                <p>
                  PLEASE READ THIS SECTION CAREFULLY BECAUSE IT AFFECTS YOUR
                  RIGHTS. BY AGREEING TO BINDING ARBITRATION, YOU WAIVE YOUR
                  RIGHT TO LITIGATE DISPUTES THROUGH A COURT AND TO HAVE A JUDGE
                  OR JURY DECIDE YOUR CASE.
                </p>
                <ol>
                  <li>
                    Generally. In order to expedite and control the cost of
                    disputes, Mark Studios and you agree that any legal or
                    equitable claim, dispute, action or proceeding arising from
                    or related to your use of the Website or these Terms
                    (“Dispute”) will be resolved as follows to the fullest
                    extent permitted by law:
                  </li>
                  <li>
                    Notice of Dispute. In the event of a Dispute, you or Mark
                    Studios must give the other a written statement that sets
                    forth the name, address, and contact information of the
                    party giving it, the facts giving rise to the Dispute, and a
                    proposed solution (a “Notice of Dispute”). You must send any
                    Notice of Dispute by email to Mark Studios at
                    contact@markstudioshub. Mark Studios will send any Notice of
                    Dispute to you by first class U.S. Mail to your address if
                    Mark Studios has it, or otherwise to your email address. You
                    and Mark Studios will attempt in good faith to resolve any
                    Dispute through informal negotiation within sixty (60) days
                    from the date the Notice of Dispute is sent. After sixty
                    (60) days, you or Mark Studios may commence arbitration.
                  </li>
                  <li>
                    Binding Arbitration. Any Dispute which has not been resolved
                    by negotiation as provided herein within sixty (60) days or
                    such time period as you and Mark Studios may otherwise
                    agree, shall be finally resolved by binding arbitration as
                    described in this Section 15.4 (Dispute Resolution and
                    Arbitration). You are giving up the right to litigate (or
                    participate in as a party or class member) all Disputes in
                    court before a judge or jury. Instead, all Disputes will be
                    resolved before a neutral arbitrator, whose decision will be
                    final except for a limited right of appeal under the Federal
                    Arbitration Act. The place of arbitration shall be St.
                    Petersburgh, Florida. Any court with jurisdiction over the
                    parties may enforce the arbitrator’s award.
                  </li>
                  <li>
                    Class Action Waiver. Any proceedings to resolve or litigate
                    any Dispute in any forum will be conducted solely on an
                    individual basis. Neither you nor Mark Studios will seek to
                    have any Dispute heard as a class action or in any other
                    proceeding in which either party acts or proposes to act in
                    a representative capacity. No arbitration or proceeding will
                    be combined with another without the prior written consent
                    of all parties to all affected arbitrations or proceedings.
                    You may file a Dispute only on your own behalf and cannot
                    seek relief that would affect other Users. If there is a
                    final judicial determination that any particular Dispute
                    cannot be arbitrated in accordance with the limitations of
                    this Section 15.4 (Dispute Resolution and Arbitration), then
                    only that Dispute may be severed and brought in court. All
                    other Disputes remain subject to this Section 15.4 (Dispute
                    Resolution and Arbitration).
                  </li>
                  <li>
                    Arbitration Procedures. Any arbitration will be conducted by
                    JAMS under the JAMS Comprehensive Arbitration Rules and
                    Procedures (“JAMS Rules”) in effect at the time the Dispute
                    is filed. You may request a telephonic or in-person hearing
                    by following the JAMS Rules. In a dispute involving $10,000
                    or less, any hearing will be telephonic unless the
                    arbitrator finds good cause to hold an in-person hearing
                    instead. To the extent the forum provided by JAMS is
                    unavailable, Mark Studios and you agree to select a mutually
                    agreeable alternative dispute resolution service and that
                    such alternative dispute resolution service shall apply the
                    JAMS Rules. The arbitrator may award the same damages to you
                    individually as a court could. The arbitrator may award
                    declaratory or injunctive relief to you only individually,
                    and only to the extent required to satisfy your individual
                    claim.
                  </li>
                  <li>
                    Arbitration Fees. Whoever files the arbitration will pay the
                    initial filing fee. If Mark Studios files, then Mark Studios
                    will pay; if you file, then you will pay unless you get a
                    fee waiver under the applicable arbitration rules. Each
                    party will bear the expense of that party’s attorneys,
                    experts, and witnesses, and other expenses, regardless of
                    which party prevails, but a party may recover any or all
                    expenses from another party if the arbitrator, applying
                    applicable law, so determines.
                  </li>
                  <li>
                    Filing Period. To the extent permitted by law, any Dispute
                    under these Terms must be filed within one (1) year in an
                    arbitration proceeding. The one-year period begins when the
                    events giving rise to the Dispute first occur. If a Dispute
                    is not filed within one year, it is permanently barred.
                  </li>
                  <li>
                    Venue. In the event that any Dispute cannot be resolved by
                    binding arbitration in accordance with this Section 15.4
                    (Dispute Resolution and Arbitration), you agree that such
                    Dispute will be filed only in the state or federal courts in
                    and for St. Petersburg, Florida, and each of you and Mark
                    Studios hereby consent and submit to the personal and
                    exclusive jurisdiction of such courts for the purpose of
                    litigating any such action. Notwithstanding this, Mark
                    Studios shall still be allowed to apply for injunctive or
                    other equitable relief to protect or enforce its
                    intellectual property rights in any court of competent
                    jurisdiction.
                  </li>
                </ol>
              </li>
              <li>
                <b>Severability</b>
                <br />
                <p>
                  If any provision of the Terms or any Guidelines is held to be
                  unlawful, void, or for any reason unenforceable, then that
                  provision will be limited or eliminated from the Terms to the
                  minimum extent necessary and will not affect the validity and
                  enforceability of any remaining provisions.
                </p>
              </li>
              <li>
                <b>Assignment</b>
                <br />
                <p>
                  The Terms and related Guidelines, and any rights and licenses
                  granted hereunder, may not be transferred or assigned by you
                  without Mark Studios’ prior written consent, but may be
                  assigned by Mark Studios without consent or any restriction.
                  Any assignment attempted to be made in violation of the Terms
                  shall be null and void.
                </p>
              </li>
              <li>
                <b>Survival</b>
                <br />
                <p>
                  Upon termination of the Terms, any provision which, by its
                  nature or express terms should survive, will survive such
                  termination or expiration, including, but not limited to;
                  Privacy Policy, Modification of the Terms, Digital Millennium
                  Copyright Act, Proprietary Materials, Non-Commercial Use,
                  Crediting Mark Studios, Prohibited Conduct, and Miscellaneous
                  (Including Dispute Resolution and Arbitration).
                </p>
              </li>
              <li>
                <b>Headings</b>
                <br />
                <p>
                  The heading references herein are for convenience purposes
                  only, do not constitute a part of the Terms, and will not be
                  deemed to limit or affect any of the provisions hereof.
                </p>
              </li>
              <li>
                <b>Entire Agreement</b>
                <br />
                <p>
                  The Terms, the Privacy Policy and Guidelines constitute the
                  entire agreement between you and Mark Studios relating to the
                  subject matter herein and will not be modified except in
                  writing, signed by both parties, or by a change to the Terms,
                  Privacy Policy or Guidelines made by Mark Studios as set forth
                  in Section 4 (Modification of the Terms) above.
                </p>
              </li>
              <li>
                <b>Disclosures</b>
                <br />
                <p>
                  The Website is hosted in the United States, and the services
                  provided hereunder are offered by Mark Studios located at 7901
                  4th St N STE 300, St. Petersburg, FL 33702, USA.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsOfService;
