const currentStep = (user, step) => {
  const steps = [
    {
      title: `Welcome ${user.firstName}!`,
      text: "To get you started, we can show you around our Dashboard where you will be placing all orders, or if you’d like to explore on your own feel free to skip the tutorial.",
    },
    {
      title: `How to Get Started`,
      text: "The first thing you have to do to be able to access the full dashboard is place an order. This is where you will upload any video files and provide us with the info to complete the work you need done.",
    },
    {
      title: `Let’s Place an Order`,
      text: "Click on the ‘My Orders’ button on the left navigation menu highlighted in a dashed, blue box.",
    },
    {
      title: `Start your first order`,
      text: "Now click the 'Place your first order’ button to start!",
    },
    {
      title: `Select Order Items`,
      text: "Here you can select all the items you want in your order.  Select all of the items you want for this order then click the ‘Create Order’ button on the bottom right.",
    },
    {
      title: `Enter Order Details, Get Results`,
      text: `Now you just need to order all of your order details and place it. From there, you can always check on the status of the order from your Dashboard.
        
        If you ever need any help, click on the ‘Help’ page in the left navigation menu.`,
    },
  ];
  console.log(step);
  if (step <= steps.length) {
    return steps[step - 1];
  } else {
    return false;
  }
};

export const onBoardingStepsPlugin = {
  currentStep,
};
