import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import "./Dashboard.scss";
import "react-calendar/dist/Calendar.css";
import DashboardCustomerImage from "../../../../assets/customer-dashboard.png";
import NoDataDashboard from "../../../../assets/dashboard-no-data.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LatestOrders from "../../../../components/ordersCustomer/latestOrders/LatestOrders";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { orderService } from "../../../../services/order.service";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { BsDot } from "react-icons/bs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const CustomerDashboard = () => {
  const [numberOfVideos, setNumberOfVideos] = useState(1);
  const [dateValue, setDateValue] = useState(new Date());
  const [isNavBarShown, setIsNavBarShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [dayOrders, setDayOrders] = useState([]);
  const [newDeliveries, setNewDeliveries] = useState(0);
  const [activeOrders, setActiveOrder] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [lastOrder, setLastOrder] = useState({});
  const [marks, setMarks] = useState([]);

  useEffect(() => {
    orderService.listOrders(
      1,
      { admin: false, type: "all", order: "normal" },
      (data) => {
        setOrders(data.orders);
        orderService.listOrders(
          1,
          { admin: false, type: "all", listAll: true },
          (data) => {
            setMarks(
              data.orders.map((item) => {
                return moment(item.deadline)
                  .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
                  .format("DD-MM-YYYY");
              })
            );
          }
        );
      }
    );
  }, []);

  useEffect(() => {
    if (orders && orders.length) {
      orderService.getOverviewCustomer((data) => {
        setActiveOrder(data.activeOrders);
        setTotalOrders(data.totalOrders);
        setNewDeliveries(data.newDeliveries);
        setCompletedPercentage(data.completedPercentage);
        setLastOrder(data.lastOrder);
      });
    }
  }, [orders]);

  useEffect(() => {
    orderService.getOrdersOfDay(
      {
        date: dateValue,
        tz: window.Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      (data) => {
        setDayOrders(data);
      }
    );
  }, [dateValue]);

  return !orders || !orders.length ? (
    <div className="admin-layout-dashboard-page empty">
      <div className="empty-container">
        <img src={NoDataDashboard} />
        <span>
          You still haven't placed an order. Please place an order to get access
          to the dashboard.
        </span>
        <div className="actions">
          <Link to={"../orders/new-order"} style={{ textDecoration: "none" }}>
            <button className="dashboard">Place Your First Order</button>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className="customer-layout-dashboard-page">
      {getWindowDimensions().width < 450 && isNavBarShown && (
        <div className="side-nav-mobile">
          <div className="side-bar-container">
            <div className="side-nav-header">
              <span className="title">Details</span>
              <div className="icon" onClick={() => setIsNavBarShow(false)}>
                <AiOutlineClose />
              </div>
            </div>
            <div className="calendar">
              <Calendar
                className="react-calendar"
                onChange={setDateValue}
                value={dateValue}
                tileContent={({ date, view }) => {
                  if (
                    view === "month" &&
                    marks.find(
                      (currentDay) =>
                        currentDay ===
                        moment(date)
                          .tz(
                            window.Intl.DateTimeFormat().resolvedOptions()
                              .timeZone
                          )
                          .format("DD-MM-YYYY")
                    )
                  ) {
                    return <BsDot className="day-with-order" />;
                  }
                }}
              />
              <div className="tasks">
                {dayOrders.map((task) => {
                  return (
                    <div className="task">
                      <span className="time">
                        {new Date(parseInt(task.deadline)).toLocaleDateString()}
                      </span>
                      {task.basicDetails ? (
                        <span className="name">
                          {task.basicDetails
                            ? task.basicDetails.title
                            : `Order #${task.number}`}
                        </span>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-dashboard">
        {getWindowDimensions().width < 450 && (
          <div className="icon" onClick={() => setIsNavBarShow(true)}>
            <BiMenuAltRight />
          </div>
        )}
        <div className="last-active-order">
          <div className="circle">
            {/* <CircularProgressbar
              value={75}
              className="circular-level-bar"
              strokeWidth={15}
              counterClockwise={true}
              styles={buildStyles({
                trailColor: `#D6EFFF`,
                pathColor: "#2A9DF4",
                backgroundColor: "white",
                textColor: "#000",
              })}
              text={`75%`}
            /> */}
          </div>
          {lastOrder && lastOrder !== null ? (
            <div className="text">
              <span className="title">Latest Active Order</span>
              <span className="mainText">Your order is being worked on!</span>
              <span className="subText">
                We have already started the editing process on your video.
              </span>
              <span className="footer">
                Delivery:{" "}
                {new Date(parseInt(lastOrder.deadline)).toLocaleDateString()}
              </span>
            </div>
          ) : (
            <div className="text">
              <span className="title">No Active Order</span>
            </div>
          )}
          <img src={DashboardCustomerImage} />
        </div>
        <div className="overall">
          <div className="item new-delivery">
            <span>New Delivery</span>
            <span className="content-item">{newDeliveries}</span>
          </div>
          <div className="item">
            <span>Active Orders</span>
            <span className="content-item">{activeOrders}</span>
          </div>
          <div className="item">
            <span>Total Orders</span>
            <span className="content-item">{totalOrders}</span>
          </div>
        </div>
        <div className="latest-orders">
          <span className="header">Latest Orders</span>
          <LatestOrders orders={orders} />
        </div>
      </div>
      {getWindowDimensions().width > 450 && (
        <div className="side-nav">
          <div className="calendar">
            <Calendar
              className="react-calendar"
              onChange={setDateValue}
              value={dateValue}
              tileContent={({ date, view }) => {
                if (
                  view === "month" &&
                  marks.find(
                    (currentDay) =>
                      currentDay ===
                      moment(date)
                        .tz(
                          window.Intl.DateTimeFormat().resolvedOptions()
                            .timeZone
                        )
                        .format("DD-MM-YYYY")
                  )
                ) {
                  return <BsDot className="day-with-order" />;
                }
              }}
            />
            <div className="tasks">
              {dayOrders.map((task) => {
                return (
                  <div className="task">
                    <span className="time">
                      {new Date(parseInt(task.deadline)).toLocaleDateString()}
                    </span>
                    {task.basicDetails ? (
                      <span className="name">
                        {task.basicDetails
                          ? task.basicDetails.title
                          : `Order #${task.number}`}
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDashboard;
