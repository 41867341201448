import { Navigate } from "react-router-dom";
import EditorLayout from "./views/editor/Layout";
import EditorDashboard from "./views/editor/pages/dashboard/Dashboard";
import EditorEarnings from "./views/editor/pages/earnings/Earnings";
import EditorInbox from "./views/editor/pages/inbox/Inbox";
import EditorNotFound from "./views/editor/pages/notFound/NotFound";
import EditorOrder from "./views/editor/pages/orders/order/Order";
import EditorOrders from "./views/editor/pages/orders/Orders";
import EditorProfile from "./views/editor/pages/profile/Profile";
import EditorSupport from "./views/editor/pages/support/Support";
import AdminLayout from "./views/admin/Layout";
import AdminDashboard from "./views/admin/pages/dashboard/Dashboard";
import AdminInbox from "./views/admin/pages/inbox/Inbox";
import AdminNotFound from "./views/admin/pages/notFound/NotFound";
import AdminOrder from "./views/admin/pages/orders/order/Order";
import AdminOrders from "./views/admin/pages/orders/Orders";
import AdminProfile from "./views/admin/pages/profile/Profile";
import AdminSupport from "./views/admin/pages/support/Support";
import AdminEditors from "./views/admin/pages/editors/Editors";
import AdminEditor from "./views/admin/pages/editors/editor/Editor";
import AdminClients from "./views/admin/pages/clients/Clients";
import AdminClient from "./views/admin/pages/clients/client/Client";
import AuthLayout from "./views/auth/Layout";
import AdminLogin from "./views/auth/admin/login/Login";
import AdminRecovery from "./views/auth/admin/recovery/Recovery";
import AdminSixDigits from "./views/auth/admin/sixDigits/SixDigits";
import GeneralAuthLogin from "./views/auth/general/login/Login";
import GeneralAuthRecovery from "./views/auth/general/recovery/Recovery";
import GeneralAuthSixDigits from "./views/auth/general/sixDigits/SixDigits";
import GeneralAuthRegister from "./views/auth/general/register/Register";
import GeneralAuthCheckEmail from "./views/auth/general/checkEmail/CheckEmail";
import GeneralAuthChooseWay from "./views/auth/general/chooseWay/ChooseWay";
import GeneralAuthFinishSignUp from "./views/auth/general/finishSignUp/FinishSignUp";
import CustomerLayout from "./views/customer/Layout";
import CustomerDashboard from "./views/customer/pages/dashboard/Dashboard";
import CustomerProfile from "./views/customer/pages/profile/Profile";
import CustomerOrders from "./views/customer/pages/orders/Orders";
import CustomerOrder from "./views/customer/pages/orders/order/Order";
import CustomerInbox from "./views/customer/pages/inbox/Inbox";
import CustomerSupport from "./views/customer/pages/support/Support";
import CustomerEditors from "./views/customer/pages/editors/Editors";
import CustomerEditor from "./views/customer/pages/editors/editor/Editor";
import CustomerNotFound from "./views/customer/pages/notFound/NotFound";
import CustomerNewOrder from "./views/customer/pages/orders/newOrder/NewOrder";
import CustomerPackages from "./views/customer/pages/packages/Packages";
import CustomerYourPackage from "./views/customer/pages/packages/yourPackage/YourPackage";
import CustomerPackageList from "./views/customer/pages/packages/packageList/PackageList";
import CustomerSingleOrder from "./views/customer/pages/packages/singleOrder/SingleOrder";
import CustomerOrderSummary from "./views/customer/pages/packages/orderSummary/OrderSummary";
import CustomerAddOns from "./views/customer/pages/packages/addOns/AddOns";
import CustomerCustomPackage from "./views/customer/pages/packages/customPackage/CustomPackage";
import CustomerPurchaseSuccessful from "./views/customer/pages/packages/purchaseSuccessful/PurchaseSuccessful";
import Landing from "./views/landing/Landing";
import Portfolio from "./views/portfolio/Portfolio";
import CustomerTestLayout from "./views/customerTest/Layout";
import CustomerTestDashboard from "./views/customerTest/pages/dashboard/Dashboard";
import CustomerTestPackages from "./views/customerTest/pages/packages/Packages";
import CustomerTestPackageList from "./views/customerTest/pages/packages/packageList/PackageList";
import CustomerTestSingleOrder from "./views/customerTest/pages/packages/singleOrder/SingleOrder";
import CustomerTestAddOns from "./views/customerTest/pages/packages/addOns/AddOns";
import CustomerTestCustomPackage from "./views/customerTest/pages/packages/customPackage/CustomPackage";
import CustomerTestPurchaseSuccessful from "./views/customerTest/pages/packages/purchaseSuccessful/PurchaseSuccessful";
import CustomerTestOrderSummary from "./views/customerTest/pages/packages/orderSummary/OrderSummary";
import CustomerAddAddOns from "./views/customer/pages/packages/addAddOns/AddAddOns";
import CustomerAddOnsSummary from "./views/customer/pages/packages/addOnsSummary/AddOnsSummary";
import AdminPackages from "./views/admin/pages/packages/Packages";
import AdminCustomPackages from "./views/admin/pages/packages/customPackage/CustomPackage";
import TermsOfService from "./views/termsOfService/TermsOfService";
import AdminPromos from "./views/admin/pages/promos/Promos";
import CustomOrderSummary from "./views/customerTest/pages/packages/customOrderSummary/CustomOrderSummary";
import BillingHistory from "./views/admin/pages/clients/billingHistory/billingHistory";
import OrderHistory from "./views/admin/pages/clients/orderHistory/orderHistory";
const routesList = [
  {
    path: "/terms-of-service",
    element: <TermsOfService />,
    key: "terms-of-service",
    subRoutes: [],
  },
  { path: "/home", element: <Landing />, key: "landing", subRoutes: [] },
  { path: "/portfolio", element: <Portfolio />, key: "landing", subRoutes: [] },
  {
    path: "/auth",
    element: <AuthLayout />,
    key: "auth",
    subRoutes: [
      {
        path: "sign-in",
        element: <GeneralAuthLogin />,
        key: "generalAuthLogin",
      },
      {
        path: "recovery/:uuid",
        element: <GeneralAuthRecovery />,
        key: "generalAuthRecovery",
      },
      {
        path: "six-digits",
        element: <GeneralAuthSixDigits />,
        key: "generalAuthSixDigits",
      },
      {
        path: "sign-up",
        element: <GeneralAuthRegister />,
        key: "generalAuthRegister",
      },
      {
        path: "check-email",
        element: <GeneralAuthCheckEmail />,
        key: "generalAuthCheckEmail",
      },
      {
        path: "choose-way/:uuid",
        element: <GeneralAuthChooseWay />,
        key: "generalAuthChooseWay",
      },
      {
        path: "finish-sign-up",
        element: <GeneralAuthFinishSignUp />,
        key: "generalAuthFinishSignUp",
      },
      { path: "admin/sign-in", element: <AdminLogin />, key: "adminLogin" },
      {
        path: "admin/recovery",
        element: <AdminRecovery />,
        key: "adminRecovery",
      },
      {
        path: "admin/six-digits",
        element: <AdminSixDigits />,
        key: "adminSixDigits",
      },
      {
        path: "",
        element: <Navigate to="/auth/sign-in" replace />,
        key: "authGeneral",
      },
    ],
  },
  {
    path: "/editor",
    element: <EditorLayout />,
    key: "editor",
    subRoutes: [
      {
        path: "dashboard",
        element: <EditorDashboard />,
        key: "editorDashboard",
      },
      { path: "profile", element: <EditorProfile />, key: "editorProfile" },
      { path: "orders", element: <EditorOrders />, key: "editorOrders" },
      { path: "orders/:orderId", element: <EditorOrder />, key: "editorOrder" },
      { path: "inbox", element: <EditorInbox />, key: "editorInbox" },
      { path: "support", element: <EditorSupport />, key: "editorSupport" },
      { path: "earnings", element: <EditorEarnings />, key: "editorEarnings" },
      {
        path: "",
        element: <Navigate to="/editor/dashboard" replace />,
        key: "editorGeneral",
      },
      { path: "*", element: <EditorNotFound />, key: "editorNotFound" },
    ],
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    key: "admin",
    subRoutes: [
      { path: "dashboard", element: <AdminDashboard />, key: "adminDashboard" },
      { path: "profile", element: <AdminProfile />, key: "adminProfile" },
      { path: "promos", element: <AdminPromos />, key: "adminPromos" },
      { path: "orders", element: <AdminOrders />, key: "adminOrders" },
      { path: "orders/:orderId", element: <AdminOrder />, key: "adminOrders" },
      { path: "inbox", element: <AdminInbox />, key: "adminInbox" },
      { path: "support", element: <AdminSupport />, key: "adminSupport" },
      {
        path: "packages",
        element: <AdminPackages />,
        key: "adminPackages",
        subRoutes: [
          {
            path: "custom-package",
            element: <AdminCustomPackages />,
            key: "adminCustomPackage",
          },
        ],
      },
      { path: "editors", element: <AdminEditors />, key: "adminEditors" },
      {
        path: "editors/:editorId",
        element: <AdminEditor />,
        key: "adminEditor",
      },
      { path: "clients", element: <AdminClients />, key: "adminClients" },
      {
        path: "clients/:clientId",
        element: <AdminClient />,
        key: "adminClient",
      },
      {
        path: "clients/billing-history/:clientId",
        element: <BillingHistory />,
        key: "adminBillingHistory",
      },
      {
        path: "clients/order-history/:clientId",
        element: <OrderHistory />,
        key: "adminBillingHistory",
      },
      {
        path: "",
        element: <Navigate to="/admin/dashboard" replace />,
        key: "adminGeneral",
      },
      { path: "*", element: <AdminNotFound />, key: "adminNotFound" },
    ],
  },
  {
    path: "/customer",
    element: <CustomerLayout />,
    key: "customer",
    subRoutes: [
      {
        path: "dashboard",
        element: <CustomerDashboard />,
        key: "customerDashboard",
      },
      { path: "profile", element: <CustomerProfile />, key: "customerProfile" },
      { path: "orders", element: <CustomerOrders />, key: "customerOrders" },
      {
        path: "orders/new-order",
        element: <CustomerNewOrder />,
        key: "customerNewOrders",
      },

      {
        path: "orders/:orderId",
        element: <CustomerOrder />,
        key: "customerOrders",
      },
      { path: "inbox", element: <CustomerInbox />, key: "customerInbox" },
      {
        path: "packages",
        element: <CustomerPackages />,
        key: "customerPackages",
        subRoutes: [
          {
            path: "my-package",
            element: <CustomerYourPackage />,
            key: "customerYourPackage",
          },

          {
            path: "add-add-ons",
            element: <CustomerAddAddOns />,
            key: "customerAddAddOns",
          },
          {
            path: "add-ons-summary",
            element: <CustomerAddOnsSummary />,
            key: "customerAddOnsSummary",
          },
          {
            path: "select-package",
            element: <CustomerPackageList />,
            key: "customerPackageList",
          },
          {
            path: "single-order",
            element: <CustomerSingleOrder />,
            key: "customerSingleOrder",
          },
          {
            path: "order-summary",
            element: <CustomerOrderSummary />,
            key: "customerOrderSummary",
          },
          {
            path: "add-ons",
            element: <CustomerAddOns />,
            key: "customerAddOns",
          },
          {
            path: "custom-package",
            element: <CustomerCustomPackage />,
            key: "customerCustomPackage",
          },
          {
            path: "purchase-complete",
            element: <CustomerPurchaseSuccessful />,
            key: "customePurchaseSuccessful",
          },
          {
            path: "",
            element: <Navigate to="/customer/packages/my-package" replace />,
            key: "customerPackagesGeneral",
          },
        ],
      },
      { path: "support", element: <CustomerSupport />, key: "customerSupport" },
      { path: "editors", element: <CustomerEditors />, key: "customerEditors" },
      {
        path: "editors/:editorId",
        element: <CustomerEditor />,
        key: "customerEditor",
      },
      {
        path: "",
        element: <Navigate to="/customer/dashboard" replace />,
        key: "customerGeneral",
      },
      { path: "*", element: <CustomerNotFound />, key: "customerNotFound" },
    ],
  },
  {
    path: "/customer-test",
    element: <CustomerTestLayout />,
    key: "customer-test",
    subRoutes: [
      {
        path: "dashboard",
        element: <CustomerTestDashboard />,
        key: "customerTestDashboard",
      },
      {
        path: "packages",
        element: <CustomerTestPackages />,
        key: "customerTestPackages",
        subRoutes: [
          {
            path: "custom-order-summary",
            element: <CustomOrderSummary />,
            key: "customOrderSummary",
          },
          {
            path: "select-package",
            element: <CustomerTestPackageList />,
            key: "customerTestPackageList",
          },
          {
            path: "single-order",
            element: <CustomerTestSingleOrder />,
            key: "customerTestSingleOrder",
          },
          {
            path: "order-summary",
            element: <CustomerTestOrderSummary />,
            key: "customerTestOrderSummary",
          },
          {
            path: "add-ons",
            element: <CustomerTestAddOns />,
            key: "customerTestAddOns",
          },
          {
            path: "custom-package",
            element: <CustomerTestCustomPackage />,
            key: "customerTestCustomPackage",
          },
          {
            path: "purchase-complete",
            element: <CustomerTestPurchaseSuccessful />,
            key: "customerTestPurchaseSuccessful",
          },
          {
            path: "",
            element: (
              <Navigate to="/customer-test/packages/select-package" replace />
            ),
            key: "customerTestPackagesGeneral",
          },
        ],
      },
      {
        path: "",
        element: <Navigate to="/customer-test/dashboard" replace />,
        key: "customerTestGeneral",
      },
      { path: "*", element: <CustomerNotFound />, key: "customerTestNotFound" },
    ],
  },
  {
    path: "",
    element: <Navigate to="/auth/sign-in" replace />,
    key: "general",
    subRoutes: [],
  },
  // {path: '*', element: <Navigate to="/not-found" replace />, key: 'general'}
];

export default routesList;
