import { RiAttachment2 } from "react-icons/ri";
import axios from "axios"
import JSZip from "jszip";
const download = (url) => {
  window.open(url, "_blank").focus();
};



export const createHTMLOrder = (order) => {
  
  const downloadSingleFile = (item) => { 
    axios({
      url: item.url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${item.originalName}`);
        document.body.appendChild(link);
        link.click();
  
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
  }

  const downloadMultipleFiles = async () => {
    let formData = new FormData();
    order.fileRequired.files.forEach((file) => {
      formData.append(file.originalName, file.url)
    })
    const urls = order.fileRequired.files.map((item) => {return item.url})
  
    const zip = new JSZip();
    const promises = urls.map(url => axios.get(url, { responseType: 'arraybuffer' }));
  
    const responses = await Promise.all(promises);
  
    responses.forEach((response, i) => {
      const filename = urls[i].split('/').pop();
      zip.file(filename, response.data, { binary: true });
    });
  
    const content = await zip.generateAsync({ type: 'blob' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.download = `${order.basicDetails.title}_${new Date().getTime()}.zip`;
    link.click();
  }

  return [
    {
      name: "Files Required",
      shown: order.fileRequired && order.fileRequired !== null,
      item: order.fileRequired ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          <div style={{ wordBreak: "break-all" }} className="files">
            <span className="title" style={{ wordBreak: "break-all" }}>
              Files{" "}
              <span
                onClick={() => {
                  if (
                    order.fileRequired &&
                    order.fileRequired.files &&
                    order.fileRequired.files.length
                  ) {
                    downloadMultipleFiles(order.fileRequired.files);
                  }
                }}
                style={{
                  fontSize: ".8em",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Download All
              </span>
            </span>
            {order.fileRequired ? (
              order.fileRequired.files &&
              order.fileRequired.files !== null &&
              order.fileRequired.files.length ? (
                order.fileRequired.files.map((file) => {
                  return (
                    <div style={{ wordBreak: "break-all" }} className="file">
                      <div
                        style={{ wordBreak: "break-all" }}
                        onClick={() => downloadSingleFile(file)}
                        className="file-content"
                      >
                        <RiAttachment2 />
                        <span style={{ wordBreak: "break-all" }}>
                          {file.originalName}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ wordBreak: "break-all" }} className="text-field">
                  <span style={{ wordBreak: "break-all" }}>
                    <b>Drive Link: </b>
                    {order.fileRequired.link}
                  </span>
                </div>
              )
            ) : null}
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Basic Details",
      shown: order.basicDetails && order.basicDetails !== null,
      item: order.basicDetails ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Title: </b>
              {order.basicDetails
                ? order.basicDetails.title
                : `Order #${order.number}`}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Delivery Day: </b>
              {order.basicDetails.deliveryTime}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Video Type: </b>
              {order.basicDetails.type}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Where it will be posted: </b>
              {order.basicDetails.postLocation}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Names of people: </b>
              {order.basicDetails.names}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Video Elements: </b>
              {order.basicDetails.videoElements}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Video Elements",
      shown: order.videoElements && order.videoElements !== null,
      item: order.videoElements ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Text: </b>
              {order.videoElements.text}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Jump Cuts: </b>
              {order.videoElements.jumpCuts}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Low Third: </b>
              {order.videoElements.lowThird}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Transition: </b>
              {order.videoElements.transition}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Thumbnail",
      shown: order.thumbnail && order.thumbnail !== null,
      item: order.thumbnail ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Where it will be posted: </b>
              {order.thumbnail.location}
            </span>
          </div>
          {order.thumbnail.logo ? (
            <div style={{ wordBreak: "break-all" }} className="file single">
              <span className="title">Logo</span>
              <div
                style={{ wordBreak: "break-all" }}
                onClick={() => download(order.thumbnail.logo.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span style={{ wordBreak: "break-all" }}>
                  {order.thumbnail.logo.originalName}
                </span>
              </div>
            </div>
          ) : null}
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Required Information: </b>
              {order.thumbnail.infoRequired}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>What to avoid: </b>
              {order.thumbnail.avoidDescription}
            </span>
          </div>
          {order.thumbnail.additionalFiles &&
          order.thumbnail.additionalFiles.length ? (
            <div style={{ wordBreak: "break-all" }} className="files">
              <span className="title">Additional Files</span>
              {order.thumbnail.additionalFiles.map((file) => {
                return (
                  <div style={{ wordBreak: "break-all" }} className="file">
                    <div
                      style={{ wordBreak: "break-all" }}
                      onClick={() => download(file.url)}
                      className="file-content"
                    >
                      <RiAttachment2 />
                      <span style={{ wordBreak: "break-all" }}>
                        {file.originalName}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Music",
      shown: order.music && order.music !== null,
      item: order.music ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          {order.music.music ? (
            <div style={{ wordBreak: "break-all" }} className="file single">
              <span className="title">Music File</span>
              <div
                style={{ wordBreak: "break-all" }}
                onClick={() => download(order.music.music.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span style={{ wordBreak: "break-all" }}>
                  {order.music.music.originalName}
                </span>
              </div>
            </div>
          ) : null}
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Let the studio decides: </b>
              {order.music.studioDecides ? "Yes" : "No"}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Comments: </b>
              {order.music.comments}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Personalized Intro",
      shown: order.intro && order.intro !== null,
      item: order.intro ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          {order.intro.intro ? (
            <div style={{ wordBreak: "break-all" }} className="file single">
              <span className="title">Intro File</span>
              <div
                style={{ wordBreak: "break-all" }}
                onClick={() => download(order.intro.intro.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span style={{ wordBreak: "break-all" }}>
                  {order.intro.intro.originalName}
                </span>
              </div>
            </div>
          ) : null}
          {order.intro.logo ? (
            <div style={{ wordBreak: "break-all" }} className="file single">
              <span className="title">Logo File</span>
              <div
                style={{ wordBreak: "break-all" }}
                onClick={() => download(order.intro.logo.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span style={{ wordBreak: "break-all" }}>
                  {order.intro.logo.originalName}
                </span>
              </div>
            </div>
          ) : null}
          {order.intro.music ? (
            <div style={{ wordBreak: "break-all" }} className="file single">
              <span className="title">Music File</span>
              <div
                style={{ wordBreak: "break-all" }}
                onClick={() => download(order.intro.music.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span style={{ wordBreak: "break-all" }}>
                  {order.intro.music.originalName}
                </span>
              </div>
            </div>
          ) : null}
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Let the studio decides the music: </b>
              {order.intro.studioDecides ? "Yes" : "No"}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Comments: </b>
              {order.intro.comments}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Personalized Outro",
      shown: order.outro && order.outro !== null,
      item: order.outro ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          {order.outro.outro ? (
            <div style={{ wordBreak: "break-all" }} className="file single">
              <span className="title">Outro File</span>
              <div
                style={{ wordBreak: "break-all" }}
                onClick={() => download(order.outro.outro.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span style={{ wordBreak: "break-all" }}>
                  {order.outro.outro.originalName}
                </span>
              </div>
            </div>
          ) : null}
          {order.outro.logo ? (
            <div style={{ wordBreak: "break-all" }} className="file single">
              <span className="title">Logo File</span>
              <div
                style={{ wordBreak: "break-all" }}
                onClick={() => download(order.outro.logo.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span style={{ wordBreak: "break-all" }}>
                  {order.outro.logo.originalName}
                </span>
              </div>
            </div>
          ) : null}
          {order.outro.music ? (
            <div style={{ wordBreak: "break-all" }} className="file single">
              <span className="title">Music File</span>
              <div
                style={{ wordBreak: "break-all" }}
                onClick={() => download(order.outro.music.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span style={{ wordBreak: "break-all" }}>
                  {order.outro.music.originalName}
                </span>
              </div>
            </div>
          ) : null}
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Let the studio decides the music: </b>
              {order.outro.studioDecides ? "Yes" : "No"}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Comments: </b>
              {order.outro.comments}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Consultation Call",
      shown: order.consultationCall && order.consultationCall !== null,
      item: order.consultationCall ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Discussion Topic: </b>
              {order.consultationCall.discussionTopic}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Concerns: </b>
              {order.consultationCall.concerns}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Goal: </b>
              {order.consultationCall.goal}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Logo",
      shown: order.logo && order.logo !== null,
      item: order.logo ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          {order.logo.colors.map((color, index) => {
            return (
              <div style={{ wordBreak: "break-all" }} className="text-field">
                <span style={{ wordBreak: "break-all" }}>
                  <b>Color {index + 1}: </b>
                  {color.color}
                </span>
              </div>
            );
          })}
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Text on logo: </b>
              {order.logo.text}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Slogan: </b>
              {order.logo.slogan}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Description: </b>
              {order.logo.description}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Industry: </b>
              {order.logo.industry}
            </span>
          </div>
          {order.logo.additionalFiles && order.logo.additionalFiles.length ? (
            <div style={{ wordBreak: "break-all" }} className="files">
              <span className="title">Additional Files</span>
              {order.logo.additionalFiles.map((file) => {
                return (
                  <div style={{ wordBreak: "break-all" }} className="file">
                    <div
                      style={{ wordBreak: "break-all" }}
                      onClick={() => download(file.url)}
                      className="file-content"
                    >
                      <RiAttachment2 />
                      <span style={{ wordBreak: "break-all" }}>
                        {file.originalName}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Banner",
      shown: order.banner && order.banner !== null,
      item: order.banner ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Where it will be posted: </b>
              {order.banner.location}
            </span>
          </div>
          {order.banner.logo ? (
            <div style={{ wordBreak: "break-all" }} className="file single">
              <span className="title">Logo</span>
              <div
                style={{ wordBreak: "break-all" }}
                onClick={() => download(order.banner.logo.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span style={{ wordBreak: "break-all" }}>
                  {order.banner.logo.originalName}
                </span>
              </div>
            </div>
          ) : null}
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Required Information: </b>
              {order.banner.infoRequired}
            </span>
          </div>
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>What to avoid: </b>
              {order.banner.avoidDescription}
            </span>
          </div>
          {order.banner.additionalFiles &&
          order.banner.additionalFiles.length ? (
            <div style={{ wordBreak: "break-all" }} className="files">
              <span className="title">Additional Files</span>
              {order.banner.additionalFiles.map((file) => {
                return (
                  <div style={{ wordBreak: "break-all" }} className="file">
                    <div
                      style={{ wordBreak: "break-all" }}
                      onClick={() => download(file.url)}
                      className="file-content"
                    >
                      <RiAttachment2 />
                      <span style={{ wordBreak: "break-all" }}>
                        {file.originalName}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null,
      open: false,
    },
    // {
    //   name: "Stock Footage",
    //   item: (
    //     <div style={{ wordBreak: "break-all" }} className="item">
    //       {order.stockFootages.map((stockFootage) => {
    //         return (
    //           <div style={{ wordBreak: "break-all" }} className="footage">
    //             <img src={stockFootage.image.url} />
    //             <div style={{ wordBreak: "break-all" }} className="comments">
    //               {stockFootage.comments.map((comment) => {
    //                 return <span style={{ wordBreak: "break-all" }}>{comment.comment}</span>;
    //               })}
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   ),
    //   open: false,
    // },
    {
      name: "Additional Instructions",
      shown:
        order.additionalInstructions && order.additionalInstructions !== null,
      item: order.additionalInstructions ? (
        <div style={{ wordBreak: "break-all" }} className="item">
          <div style={{ wordBreak: "break-all" }} className="text-field">
            <span style={{ wordBreak: "break-all" }}>
              <b>Additional Instructions: </b>
              {order.additionalInstructions.somethingElse}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    // {
    //   name: "Add-Ons",
    //   item: (
    //     <div style={{ wordBreak: "break-all" }} className="item">
    //       <div style={{ wordBreak: "break-all" }} className="text-field">
    //         <span style={{ wordBreak: "break-all" }}>
    //           <b>Banner: </b>
    //           {order.addOn.banner ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div style={{ wordBreak: "break-all" }} className="text-field">
    //         <span style={{ wordBreak: "break-all" }}>
    //           <b>Unlimited Stock Footage: </b>
    //           {order.addOn.unlimitedStockFootage ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div style={{ wordBreak: "break-all" }} className="text-field">
    //         <span style={{ wordBreak: "break-all" }}>
    //           <b>Logo: </b>
    //           {order.addOn.logo ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div style={{ wordBreak: "break-all" }} className="text-field">
    //         <span style={{ wordBreak: "break-all" }}>
    //           <b>Outro: </b>
    //           {order.addOn.outro ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div style={{ wordBreak: "break-all" }} className="text-field">
    //         <span style={{ wordBreak: "break-all" }}>
    //           <b>Thumbnail: </b>
    //           {order.addOn.thumbnail ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div style={{ wordBreak: "break-all" }} className="text-field">
    //         <span style={{ wordBreak: "break-all" }}>
    //           <b>Consultation Call: </b>
    //           {order.addOn.consultationCall ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div style={{ wordBreak: "break-all" }} className="text-field">
    //         <span style={{ wordBreak: "break-all" }}>
    //           <b>Intro: </b>
    //           {order.addOn.intro ? "Yes" : "No"}
    //         </span>
    //       </div>
    //     </div>
    //   ),
    //   open: false,
    // },
  ];
};
