import "./Profile.scss";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { FiTrash } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import Youtube from "../../../../assets/youtube.png";
import Facebook from "../../../../assets/facebook.png";
import Instagram from "../../../../assets/instagram.png";
import Twitter from "../../../../assets/twitter.png";
import Tiktok from "../../../../assets/tiktok.png";
import Linkedin from "../../../../assets/linkedin.png";
import Snapchat from "../../../../assets/snapchat.png";
import Website from "../../../../assets/website.png";
import { useNavigate, useOutletContext } from "react-router";
import Loading from "../../../loading/Loading";
import { useEffect, useState } from "react";
import { userService } from "../../../../services/user.service";
import { uploadService } from "../../../../services/upload.service";
import Swal from "sweetalert2";
import LoadingUpload from "../../../loadingUpload/LoadingUpload";
import UploadModal from "../../../../components/uploadModal/UploadModal";
const EditorProfile = () => {
  const navigate = useNavigate();
  const [userDisplayed, setUserDisplayed] = useState({});
  const [progressOrders, setProgressOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  /*eslint no-unused-vars: "off"*/
  const [askAdmin, setAskAdmin, user, setUser] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [isEditting, setIsEdditing] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    userService.getProfileDetails("editor", (data) => {
      setUserDisplayed(data.user);
      setProgressOrders(data.orders.progress);
      setCompletedOrders(data.orders.completed);
    });
  }, []);

  const changeAttribute = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj[attribute] = e.target.value;
    setUserDisplayed(obj);
  };

  const changeNetwork = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    if (obj.socialMedias && obj.socialMedias.length) {
      if (
        obj.socialMedias.filter((item) => item.type === attribute) &&
        obj.socialMedias.filter((item) => item.type === attribute).length
      ) {
        obj.socialMedias.filter((item) => item.type === attribute)[0].account =
          e.target.value;
      } else {
        obj.socialMedias.push({
          type: attribute,
          account: e.target.value,
        });
      }
    } else {
      obj.socialMedias = [
        {
          type: attribute,
          account: e.target.value,
        },
      ];
    }
    setUserDisplayed(obj);
  };

  const changeImage = (attribute, data) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj[attribute] = data;

    userService.updateUser(obj, (data) => {
      setUserDisplayed(data);
      userService.getProfile(
        (data) => {
          setUser(data);
        },
        (e) => {
          setLoading(false);
          Swal.fire({
            title: "Error!",
            text: "Please login before entering your account",
            heightAuto: false,
            icon: "error",
          }).then(() => {
            navigate("/auth/admin/sign-in");
          });
        }
      );
    });
  };

  const removeSkill = (skill) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj.skills = obj.skills
      .split(",")
      .filter((item) => item !== skill)
      .join(",");
    setUserDisplayed(obj);
  };

  const addSkill = (skill) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj.skills = `${obj.skills},${skill}`;
    setUserDisplayed(obj);
  };

  const addProject = (project) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj.projects.push({
      name: project,
      files: [],
    });

    setUserDisplayed(obj);
  };

  const addFileToProject = (projectIndex, file) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj.projects[projectIndex].files.push(file);
    setUserDisplayed(obj);
  };

  const networks = [
    {
      name: "Youtube",
      img: Youtube,
      id: "youtube",
    },
    {
      name: "Facebook",
      img: Facebook,
      id: "facebook",
    },
    {
      name: "Instagram",
      img: Instagram,
      id: "instagram",
    },
    {
      name: "Twitter",
      img: Twitter,
      id: "twitter",
    },
    {
      name: "Tiktok",
      img: Tiktok,
      id: "tiktok",
    },
    {
      name: "Linkedin",
      img: Linkedin,
      id: "linkedin",
    },
    {
      name: "Snapchat",
      img: Snapchat,
      id: "snapchat",
    },
    {
      name: "Personal Website",
      img: Website,
      id: "website",
    },
  ];

  return userDisplayed && userDisplayed.firstName ? (
    <div className="editor-layout-profile-page">
      {loading ? <UploadModal progress={uploadProgress} /> : null}
      <div className="header-main">
        {isEditting ? (
          <button
            onClick={() => {
              userService.updateUser(userDisplayed, (data) => {
                setIsEdditing(false);
                setUserDisplayed(data);
                userService.getProfile(
                  (data) => {
                    setUser(data);
                  },
                  (e) => {
                    setLoading(false);
                    Swal.fire({
                      title: "Error!",
                      text: "Please login before entering your account",
                      heightAuto: false,
                      icon: "error",
                    }).then(() => {
                      navigate("/auth/admin/sign-in");
                    });
                  }
                );
              });
            }}
            className="edit"
          >
            Save
          </button>
        ) : (
          <button onClick={() => setIsEdditing(true)} className="edit">
            Edit
          </button>
        )}
      </div>
      <div className="profile-picture-info">
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          style={{ display: "none" }}
          id={`upload-profile-picture-profile-editor`}
          onChange={(e) => {
            let file = e.target.files[0];
            setLoading(true);

            if (file) {
              let data = new FormData();
              data.append("file", file);

              uploadService.upload(
                data,
                (data) => {
                  changeImage("profilePicture", data);
                  setLoading(false);
                },
                setUploadProgress
              );
            }
          }}
        />
        <label
          for="upload-profile-picture-profile-editor"
          className="image-circle"
        >
          <CircularProgressbar
            value={(10 - user.toNextLevel) * 10}
            className="circular-level-bar"
            strokeWidth={2}
            counterClockwise={true}
            styles={buildStyles({
              trailColor: `#F2F5FA`,
              pathColor: "#2A9DF4",
              backgroundColor: "white",
            })}
          />
          <div className="image">
            <img
              alt="profile-img"
              src={
                user.profilePicture
                  ? user.profilePicture.url
                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
              }
            />
          </div>
        </label>
        <span className="name">
          {user.firstName} {user.lastName}
        </span>
        <span className="title">Editor Level {user.level}</span>
        <div className="stats">
          <div className="item">
            <span className="number">{progressOrders}</span>
            <span className="text">
              Video
              <br />
              In Progress
            </span>
          </div>
          <div className="item">
            <span className="number">{completedOrders}</span>
            <span className="text">
              Video
              <br />
              Completed
            </span>
          </div>
        </div>
      </div>
      <div className="mainInfo">
        <div className="name">
          <div className="item">
            <span className="title">First Name</span>
            <div className="input">
              {/* <span>Enter First Name</span> */}
              <input
                readOnly={!isEditting}
                value={userDisplayed.firstName}
                onChange={(e) => changeAttribute("firstName", e)}
              />
            </div>
          </div>
          <div className="item">
            <span className="title">Last Name</span>
            <div className="input">
              {/* <span>Enter Last Name</span> */}
              <input
                readOnly={!isEditting}
                value={userDisplayed.lastName}
                onChange={(e) => changeAttribute("lastName", e)}
              />
            </div>
          </div>
        </div>
        <div className="bio">
          <span className="title">Skills</span>
          <div className="input">
            <div className="skills">
              {userDisplayed.skills.split(",").map((skill) => (
                <div
                  className="skill"
                  onClick={
                    isEditting
                      ? () => {
                          removeSkill(skill);
                        }
                      : null
                  }
                >
                  {skill}
                  {isEditting ? <FiTrash className="trash" /> : null}
                </div>
              ))}
              {isEditting ? (
                <div
                  className="skill add"
                  onClick={() => {
                    Swal.fire({
                      title: "Add a new skill",
                      input: "text",
                      showCancelButton: true,
                      closeOnConfirm: false,
                      inputPlaceholder: "Write a skill name",
                      preConfirm: (inputValue) => {
                        return inputValue;
                      },
                    }).then((inputValue, text) => {
                      if (inputValue.isConfirmed) {
                        if (inputValue.value === null) return false;

                        if (inputValue.value === "") {
                          Swal.showInputError("You need to write something!");
                          return false;
                        }

                        addSkill(inputValue.value);
                      }
                    });
                  }}
                >
                  Add New Skill
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="bio">
          <span className="title">Bio</span>
          <div className="input">
            <input
              value={userDisplayed.bio}
              readOnly={!isEditting}
              onChange={(e) => changeAttribute("bio", e)}
            />
          </div>
        </div>
      </div>
      <div className="portfolioEditor">
        <div className="profile-header">
          <span>Portfolio Editor</span>
        </div>
        <div className="portfolio-content">
          {userDisplayed.projects.map((project, index) => {
            return (
              <div className="project">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg, video/*"
                  style={{ display: "none" }}
                  id={`add-file-to-project-${index}`}
                  readOnly={!isEditting}
                  onChange={(e) => {
                    let file = e.target.files[0];
                    setLoading(true);

                    if (file) {
                      let data = new FormData();
                      data.append("file", file);

                      uploadService.upload(
                        data,
                        (data) => {
                          setLoading(false);
                          addFileToProject(index, data);
                        },
                        setUploadProgress
                      );
                    }
                  }}
                />
                <span>
                  {project.name}
                  {isEditting ? <FiTrash className="trash" /> : null}
                </span>
                <div className="files">
                  {project.files.map((file) =>
                    file.mime.includes("video") ? (
                      <video src={file.url} alt="project" />
                    ) : (
                      <img src={file.url} alt="project" />
                    )
                  )}
                  {isEditting ? (
                    <label
                      for={`add-file-to-project-${index}`}
                      className="add-new"
                    >
                      <AiOutlinePlus color="white" size={30} />
                    </label>
                  ) : null}
                </div>
              </div>
            );
          })}
          {isEditting ? (
            <button
              className="add-new-project"
              onClick={() => {
                Swal.fire({
                  title: "Add a new project",
                  input: "text",
                  showCancelButton: true,
                  closeOnConfirm: false,
                  inputPlaceholder: "Write the project name",
                  preConfirm: (inputValue) => {
                    return inputValue;
                  },
                }).then((inputValue, text) => {
                  if (inputValue.isConfirmed) {
                    if (inputValue.value === null) return false;

                    if (inputValue.value === "") {
                      Swal.showInputError("You need to write something!");
                      return false;
                    }

                    addProject(inputValue.value);
                  }
                });
              }}
            >
              Add New Project
            </button>
          ) : null}
        </div>
      </div>
      <div className="networking-info">
        <div className="profile-header">
          <span>Networking Info</span>
        </div>
        <div className="networking-content">
          {networks.map((network) => {
            return (
              <div className="social-network">
                <div className="name-icon">
                  <img src={network.img} alt="network" />
                  <span style={{ marginLeft: ".5em" }}>{network.name}</span>
                </div>
                <input
                  readOnly={!isEditting}
                  onChange={(e) => {
                    changeNetwork(network.id, e);
                  }}
                  value={
                    userDisplayed.socialMedias.filter(
                      (media) => media.type === network.id
                    ) &&
                    userDisplayed.socialMedias.filter(
                      (media) => media.type === network.id
                    ).length
                      ? userDisplayed.socialMedias.filter(
                          (media) => media.type === network.id
                        )[0].account
                      : ""
                  }
                  className="data"
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="banking-info">
        <div className="profile-header">
          <span>Payment Info</span>
          <FiEdit size={20} color="#a0aec7" />
        </div>
        <div className="banking-content">
          <div className="address">
            <span className="subTitle">Recipient’s address</span>
            <div className="item">
              <span>Country</span>
              <span>
                <IoIosArrowDown />
              </span>
            </div>
            <div className="item">
              <span>City</span>
              <span>
                <IoIosArrowDown />
              </span>
            </div>
            <div className="item">
              <span>Address</span>
              <span></span>
            </div>
            <div className="item">
              <span>Postal Code</span>
              <span></span>
            </div>
          </div>
          <div className="banking">
            <span className="subTitle">Bank Details</span>
            <div className="item">
              <span>Recipient bank</span>
              <span>Citybank of NYC</span>
              <IoIosArrowDown />
            </div>
            <div className="item">
              <span>Branch Code</span>
              <span>1234</span>
            </div>
            <div className="item">
              <span>Account Number</span>
              <span>12345678-9</span>
            </div>
            <div className="item">
              <span>Account Type</span>
              <span>Savings</span>
              <IoIosArrowDown />
            </div>
            <div className="item">
              <span>Recipient’s Ph. No.</span>
              <span>+55 21 555 5555</span>
            </div>
            <div className="item">
              <span>Tax Reg. No.</span>
              <span>123-456-789-12</span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  ) : (
    <Loading />
  );
};

export default EditorProfile;
