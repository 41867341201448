export const packagesVariables = [
  {
    name: "Videos per month",
    variable: "${pkg.videos}",
  },
  {
    name: "Raw footage time",
    variable: "${pkg.rawFootage}",
  },
  {
    name: "Video Running Time",
    variable: "${pkg.runningVideo}",
  },
  {
    name: "Number of Thumbnails",
    variable: "${pkg.thumbnails}",
  },
  {
    name: "Number of Banners",
    variable: "${pkg.banner}",
  },
  {
    name: "Number of Logos",
    variable: "${pkg.logo}",
  },
  {
    name: "Number of Consultation Calls",
    variable: "${pkg.consultationCall}",
  },
  {
    name: "Number of Outro",
    variable: "${pkg.outro}",
  },
  {
    name: "Number of Intro",
    variable: "${pkg.intro}",
  },
  {
    name: "Price",
    variable: "${pkg.price}",
  },
];
