import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2/dist/sweetalert2";

let url = `${
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_BASE_URL_DEV
    : process.env.REACT_APP_API_BASE_URL_PROD
}`;

// if (process.env.NODE_ENV === "production") {
//   url = process.env.REACT_APP_API_BASE_URL_PROD;
// } else {
//   url = process.env.REACT_APP_API_BASE_URL_DEV;
// }

const buildHeaders = (type = "json") => {
  const headers = {
    "Content-Type":
      type === "json"
        ? "application/json"
        : "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*",
  };

  const token = Cookies.get("token");

  if (token && token !== null && token !== "") {
    headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
  }

  return headers;
};

const post = (
  endpoint,
  body,
  callback,
  type = "json",
  error = null,
  errorCallback = false
) => {
  axios
    .post(`${url}${endpoint}`, body, { headers: buildHeaders(type) })
    .then((response) => {
      console.log(response);
      if (response.data.success === true) {
        callback(response.data.data);
      } else if (errorCallback) {
        callback(response.data.message);
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message,
          heightAuto: false,
          icon: "error",
        });
      }
    })
    .catch((e) => {
      console.log(e);
      if (error && error !== null) {
        error(e);
      } else {
        console.log(e);
        Swal.fire({
          title: "Error!",
          text: e,
          heightAuto: false,
          icon: "error",
        });
      }
    });
};

const get = (endpoint, callback, type = "json", error = null) => {
  axios
    .get(`${url}${endpoint}`, { headers: buildHeaders(type) })
    .then((response) => {
      if (response.data.success === true) {
        callback(response.data.data);
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message,
          heightAuto: false,
          icon: "error",
        });
      }
    })
    .catch((e) => {
      // console.log(e);
      if (error && error !== null) {
        error(e);
      } else {
        // console.log(e);
        Swal.fire({
          title: "Error!",
          text: e,
          heightAuto: false,
          icon: "error",
        });
      }
    });
};

const put = (endpoint, body, callback, type = "json") => {
  axios
    .put(`${url}${endpoint}`, body, { headers: buildHeaders(type) })
    .then((response) => {
      console.log(response);
      if (response.data.success === true) {
        callback(response.data.data);
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message,
          heightAuto: false,
          icon: "error",
        });
      }
    })
    .catch((e) => {
      console.log(e);
      Swal.fire({
        title: "Error!",
        text: e,
        heightAuto: false,
        icon: "error",
      });
    });
};

const remove = (endpoint, body, callback, type = "json") => {
  axios
    .delete(`${url}${endpoint}`, body, { headers: buildHeaders(type) })
    .then((response) => {
      console.log(response);
      if (response.data.success === true) {
        callback(response.data.data);
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message,
          heightAuto: false,
          icon: "error",
        });
      }
    })
    .catch((e) => {
      console.log(e);
      Swal.fire({
        title: "Error!",
        text: e,
        heightAuto: false,
        icon: "error",
      });
    });
};

const patch = (endpoint, body, callback, type = "json") => {
  axios
    .patch(`${url}${endpoint}`, body, { headers: buildHeaders(type) })
    .then((response) => {
      console.log(response);
      if (response.data.success === true) {
        callback(response.data.data);
      } else {
        Swal.fire({
          title: "Error!",
          text: response.data.message,
          heightAuto: false,
          icon: "error",
        });
      }
    })
    .catch((e) => {
      console.log(e);
      Swal.fire({
        title: "Error!",
        text: e,
        heightAuto: false,
        icon: "error",
      });
    });
};

export const coreService = {
  post,
  get,
  put,
  remove,
  patch,
};
