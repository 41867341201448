import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import Profile from "../../components/profile/Profile";
import SideBar from "../../components/sidebar/SideBar";
import { sideBarItems } from "../../components/sidebar/sideBarItems";
import "./Layout.scss";
import { BiSearch, BiBell, BiLogOut } from "react-icons/bi";
import {
  AiOutlineArrowLeft,
  AiOutlineClose,
  AiOutlineMenu,
} from "react-icons/ai";
import User2 from "../../assets/user2.png";
import User3 from "../../assets/user3.png";
import User4 from "../../assets/user4.png";
import Logo from "../../assets/logo.png";
import Mark from "../../assets/mark.png";
import { Link } from "react-router-dom";
import { userService } from "../../services/user.service";
import Loading from "../loading/Loading";
import { orderService } from "../../services/order.service";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { FaLock } from "react-icons/fa";
import { onBoardingStepsPackagePlugin } from "./OnBoardingStepsPackage";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const CustomerTestLayout = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [currentPageName, setCurrentPageName] = useState("");
  const [askAdmin, setAskAdmin] = useState(false);
  const [orderTab, setOrderTab] = useState("All Orders");
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState({});
  const [stepPackage, setStepPackage] = useState(1);
  const [onboardingStepPackage, setOnboardingStepPackage] = useState({});
  const [packageOnBoardingOpen, setPackageOnBoardingOpen] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get("email");

  useEffect(() => {
    if (email) {
      localStorage.setItem("customerTestEmail", email);
    }

    if (getWindowDimensions().width <= 450) {
      Swal.fire({
        title: "Hello!",
        text: "I'm so glad you are here! Just wanted to let you know you will have a better experience on desktop.",
      });
    }
    if (window.location.href.includes("dashboard")) {
      setOnboardingStepPackage(onBoardingStepsPackagePlugin.currentStep(1));
      setStepPackage(1);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.split("/")[3]) {
      if (window.location.pathname.split("/")[2] === "editors") {
        setCurrentPageName("Editor Details");
      } else if (window.location.pathname.split("/")[2] === "clients") {
        setCurrentPageName("Client Details");
      } else if (window.location.pathname.split("/")[2] === "orders") {
        setCurrentPageName("Order Delivery");
      } else if (window.location.pathname.split("/")[2] === "packages") {
        if (window.location.pathname.split("/")[3] === "my-package") {
          setCurrentPageName("My Package");
        }
      }
    } else {
      sideBarItems.sideBarItemsAdmin.map((item) => {
        if (item.id === currentPage) {
          setCurrentPageName(item.name);
        }
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (window.location.pathname.split("/")[2] === "editors") {
      setCurrentPageName("Editors");
      setCurrentPage("editors");
    } else if (window.location.pathname.split("/")[2] === "dashboard") {
      setCurrentPageName("Dashboard");
      setCurrentPage("dashboard");
    } else if (window.location.pathname.split("/")[2] === "earning") {
      setCurrentPageName("My Earnings");
      setCurrentPage("earnings");
    } else if (window.location.pathname.split("/")[2] === "inbox") {
      setCurrentPageName("Inbox");
      setCurrentPage("inbox");
    } else if (window.location.pathname.split("/")[2] === "support") {
      setCurrentPageName("FAQ");
      setCurrentPage("support");
    } else if (window.location.pathname.split("/")[2] === "profile") {
      setCurrentPageName("Profile");
      setCurrentPage("profile");
    } else if (window.location.pathname.split("/")[2] === "clients") {
      setCurrentPageName("Clients");
      setCurrentPage("clients");
    }

    if (window.location.pathname.split("/")[3]) {
      if (window.location.pathname.split("/")[2] === "editors") {
        setCurrentPageName("Editor Details");
      } else if (window.location.pathname.split("/")[2] === "clients") {
        setCurrentPageName("Client Details");
      } else if (window.location.pathname.split("/")[2] === "orders") {
        setCurrentPageName("Order Delivery");
      } else if (window.location.pathname.split("/")[2] === "packages") {
        if (window.location.pathname.split("/")[3] === "my-package") {
          setCurrentPageName("My Package");
        } else if (
          window.location.pathname.split("/")[3] === "select-package"
        ) {
          setCurrentPageName("Select your monthly package");
        } else if (
          window.location.pathname.split("/")[3] === "order-summary" ||
          window.location.pathname.split("/")[3] === "add-ons"
        ) {
          setCurrentPageName("Order Summary");
        } else if (
          window.location.pathname.split("/")[3] === "custom-package"
        ) {
          setCurrentPageName("Create Custom Package");
        } else if (window.location.pathname.split("/")[3] === "single-order") {
          setCurrentPageName("Single Order");
        }
      }
    }
  });

  const notifications = [
    {
      image: User2,
      name: "Garrett Watson",
      about: "Video 1",
      action: "sent you a comment in",
      time: "2h ago",
    },
    {
      image: User3,
      name: "Caroline Santos",
      about: "Video 1",
      action: "sent you a comment in",
      time: "6h ago",
    },
    {
      image: User4,
      name: "Leon Nunez",
      about: "Video 1",
      action: "sent you a comment in",
      time: "Today 9:30 am",
    },
    {
      image: User2,
      name: "Garrett Watson",
      about: "Video 1",
      action: "sent you a comment in",
      time: "Tomorrow 2:30 pm",
    },
    {
      image: User3,
      name: "Caroline Santos",
      about: "Video 1",
      action: "sent you a comment in",
      time: "Tomorrow 1:45 pm",
    },
    {
      image: User4,
      name: "Leon Nunez",
      about: "Video 1",
      action: "sent you a comment in",
      time: "Sep 12 | 10:54 am",
    },
    {
      image: User2,
      name: "Garrett Watson",
      about: "Video 1",
      action: "sent you a comment in",
      time: "Sep 12 | 9:05 am",
    },
    {
      image: User3,
      name: "Caroline Santos",
      about: "Video 1",
      action: "sent you a comment in",
      time: "Sep 11 | 8:15 pm",
    },
  ];

  const nextOnboardingStepPackage = async (currStep = null) => {
    const curr = currStep === null ? stepPackage : currStep;
    console.log(curr);
    if (curr !== 2) {
      const newStep = parseInt(`${curr}`) + 1;
      setStepPackage(newStep);
      setOnboardingStepPackage(
        onBoardingStepsPackagePlugin.currentStep(newStep)
      );

      if (newStep === 2 && getWindowDimensions().width > 740) {
        const siderBarItem = document.querySelector(
          `#sidebar-item-packages-customer-test`
        );
        siderBarItem.style.zIndex =
          "999999999999999999999999999999999999999999999999999999";
        siderBarItem.style.backgroundColor = "white";
        siderBarItem.style.borderRadius = "10px";
        siderBarItem.style.border = "2px #2a9df4 dashed";

        const action = () => {
          siderBarItem.style.zIndex = "10000";
          siderBarItem.style.backgroundColor = "transparent";
          siderBarItem.style.borderRadius = "0px";
          siderBarItem.style.border = "0px";
          nextOnboardingStepPackage(curr + 1);
          siderBarItem.removeEventListener("click", action);
          setPackageOnBoardingOpen(false);
        };

        siderBarItem.addEventListener("click", action);
      } else if (getWindowDimensions().width < 740) {
        setPackageOnBoardingOpen(false);
        navigate("/customer-test/packages");
      }
    } else {
      setPackageOnBoardingOpen(false);
    }
  };

  return (
    <div className="customer-test-layout-main-container">
      {packageOnBoardingOpen && currentPage === "dashboard" ? (
        <div className="onbording">
          <div className="onboarding-content" id="onboarding-block">
            <span className="onboarding-title">
              {onboardingStepPackage.title}
            </span>
            <span className="onboarding-text">
              {onboardingStepPackage.text}
            </span>
            <div className="onboarding-buttons">
              {(stepPackage !== 2 && getWindowDimensions().width > 740) ||
              getWindowDimensions().width < 740 ? (
                <button onClick={() => nextOnboardingStepPackage()}>
                  {stepPackage < 2 ? "Next" : "Done"}
                </button>
              ) : null}
            </div>
            <div className="progress-bar">
              <div
                className={`ball${stepPackage === 1 ? " active" : ""}`}
              ></div>
              <div
                className={`ball${stepPackage === 2 ? " active" : ""}`}
              ></div>
            </div>
          </div>
        </div>
      ) : null}
      {isMenuOpen && (
        <div className="menu-side">
          <div className="menu-container">
            <div className="menu-header">
              <img className="logo" src={Logo} />
              <div className="icon-menu" onClick={() => setIsMenuOpen(false)}>
                <AiOutlineClose />
              </div>
            </div>
            <div className="menu-profile">
              <Profile
                name={`Your Name`}
                role="Customer"
                image={
                  "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                }
              />
            </div>
            <div className="menu-body main">
              {sideBarItems.sideBarItemsCustomer.map((item, index) => {
                if (item.show) {
                  return item.locked ? (
                    <div className={`item`} key={`Sidebar item #${index + 1}`}>
                      <span className="icon">{item.icon}</span>
                      <span>{item.name}</span>
                      <span style={{ marginLeft: "1em" }}>
                        <FaLock />
                      </span>
                    </div>
                  ) : (
                    <Link
                      key={`Sidebar item #${index + 1}`}
                      onClick={() => {
                        setCurrentPage(item.id);
                        setCurrentPageName(item.name);
                        setIsMenuOpen(false);
                        setOrderTab("All Orders");
                      }}
                      to={`./${item.id}`}
                      className={`item`}
                    >
                      <span className="icon">{item.icon}</span>
                      <span>{item.name}</span>
                    </Link>
                  );
                }
              })}
            </div>
            <div className="menu-footer">
              <div className="menu-body">
                <div
                  className="item logout"
                  onClick={() => {
                    Cookies.remove("token");
                    navigate("/");
                  }}
                >
                  <span className="icon">
                    <BiLogOut />
                  </span>
                  <span>Logout</span>
                </div>
                <div className="terms-and-policy">
                  <span
                    onClick={() => {
                      navigate("/terms-of-service");
                    }}
                  >
                    Terms of Use
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isNotificationOpen && (
        <div className="notifications">
          <div className="notifications-container">
            <div className="notifications-header">
              <span className="title">Notifications</span>
              <div
                className="icon"
                onClick={() => setIsNotificationOpen(false)}
              >
                <AiOutlineClose />
              </div>
            </div>
            <div className="notifications-body">
              {notifications.map((notification) => {
                return (
                  <div className="notification">
                    <div className="image">
                      <img src={notification.image} />
                    </div>
                    <div className="details">
                      <div className="text">
                        <span className="bold">{notification.name} </span>
                        <span>{notification.action} </span>
                        <span className="bold">{notification.about}</span>
                      </div>
                      <span className="time">{notification.time}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {getWindowDimensions().width > 950 && (
        <div className="sidebar">
          <SideBar
            type="customer-test"
            id={currentPage}
            goBackOrders={setOrderTab}
            changePage={setCurrentPage}
            changePageName={setCurrentPageName}
          />
        </div>
      )}
      <div className="content-container">
        <div className="header">
          {getWindowDimensions().width < 950 && (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="icon menu" onClick={() => setIsMenuOpen(true)}>
                <AiOutlineMenu />
              </div>
              {getWindowDimensions().width > 450 ? (
                <h1>{currentPageName}</h1>
              ) : (
                <h3>{currentPageName}</h3>
              )}
            </div>
          )}
          {getWindowDimensions().width > 950 && <h1>{currentPageName}</h1>}
          <div className="icons">
            <div className="icon" onClick={() => setIsNotificationOpen(true)}>
              <BiBell />
            </div>
            {getWindowDimensions().width > 450 && (
              <div className="profile">
                <Profile
                  name={`Your Name`}
                  role="Customer"
                  image={
                    "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CustomerTestLayout;
