import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import "./Dashboard.scss";
import "react-calendar/dist/Calendar.css";
import NoDataDashboard from "../../../../assets/dashboard-no-data.png";
import DashboardCustomerImage from "../../../../assets/customer-dashboard.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LatestOrders from "../../../../components/ordersCustomer/latestOrders/LatestOrders";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { orderService } from "../../../../services/order.service";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const CustomerTestDashboard = () => {
  const [numberOfVideos, setNumberOfVideos] = useState(1);
  const [dateValue, setDateValue] = useState(new Date());
  const [isNavBarShown, setIsNavBarShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [dayOrders, setDayOrders] = useState([]);
  const [newDeliveries, setNewDeliveries] = useState(0);
  const [activeOrders, setActiveOrder] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const [lastOrder, setLastOrder] = useState({
    deadline: moment()
      .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
      .add(1, "day")
      .toDate(),
  });

  useEffect(() => {
    setOrders([
      {
        basicDetails: {
          title: "Tyler White",
        },
        number: "msh001442",
        status: "progress",
        deadline: moment()
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .add(1, "day")
          .toDate(),
        test: true,
      },
      {
        basicDetails: {
          title: "Jacob Johnson",
        },
        number: "msh001442",
        status: "completed",
        deadline: moment()
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .add(1, "day")
          .toDate(),
        test: true,
      },
      {
        basicDetails: {
          title: "Steph Wild",
        },
        number: "msh001442",
        status: "progress",
        deadline: moment()
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .add(1, "day")
          .toDate(),
        test: true,
      },
    ]);
  }, []);

  useEffect(() => {
    setDayOrders([
      {
        basicDetails: {
          title: "Tyler White",
        },
        number: "msh001442",
        status: "progress",
        delivery: dateValue,
      },
      {
        basicDetails: {
          title: "Jacob Johnson",
        },
        number: "msh001442",
        status: "completed",
        delivery: dateValue,
      },
      {
        basicDetails: {
          title: "Steph Wild",
        },
        number: "msh001442",
        status: "progress",
        delivery: dateValue,
      },
    ]);
  }, [dateValue]);

  return (
    <div className="customer-layout-dashboard-page">
      {getWindowDimensions().width < 450 && isNavBarShown && (
        <div className="side-nav-mobile">
          <div className="side-bar-container">
            <div className="side-nav-header">
              <span className="title">Details</span>
              <div className="icon" onClick={() => setIsNavBarShow(false)}>
                <AiOutlineClose />
              </div>
            </div>
            <div className="calendar">
              <Calendar
                className="react-calendar"
                onChange={setDateValue}
                value={dateValue}
              />
              <div className="tasks">
                {dayOrders.map((task) => {
                  return (
                    <div className="task">
                      <span className="time">
                        {new Date(parseInt(task.deadline)).toLocaleDateString()}
                      </span>
                      <span className="name">
                        {task.basicDetails
                          ? task.basicDetails.title
                          : `Order #${task.number}`}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-dashboard">
        {getWindowDimensions().width < 450 && (
          <div className="icon" onClick={() => setIsNavBarShow(true)}>
            <BiMenuAltRight />
          </div>
        )}
        <div className="last-active-order">
          <div className="circle">
            {/* <CircularProgressbar
              value={75}
              className="circular-level-bar"
              strokeWidth={15}
              counterClockwise={true}
              styles={buildStyles({
                trailColor: `#D6EFFF`,
                pathColor: "#2A9DF4",
                backgroundColor: "white",
                textColor: "#000",
              })}
              text={`75%`}
            /> */}
          </div>
          {lastOrder && lastOrder !== null ? (
            <div className="text">
              <span className="title">Latest Active Order</span>
              <span className="mainText">Your order is being worked on!</span>
              <span className="subText">
                We have already started the editing process on your video.
              </span>
              <span className="footer">
                Delivery: {new Date(lastOrder.deadline).toLocaleDateString()}
              </span>
            </div>
          ) : (
            <div className="text">
              <span className="title">No Active Order</span>
            </div>
          )}
          <img src={DashboardCustomerImage} />
        </div>
        <div className="overall">
          <div className="item new-delivery">
            <span>New Delivery</span>
            <span className="content-item">{newDeliveries}</span>
          </div>
          <div className="item">
            <span>Active Orders</span>
            <span className="content-item">{activeOrders}</span>
          </div>
          <div className="item">
            <span>Total Orders</span>
            <span className="content-item">{totalOrders}</span>
          </div>
        </div>
        <div className="latest-orders">
          <span className="header">Latest Orders</span>
          <LatestOrders orders={orders} />
        </div>
      </div>
      {getWindowDimensions().width > 450 && (
        <div className="side-nav">
          <div className="calendar">
            <Calendar
              className="react-calendar"
              onChange={setDateValue}
              value={dateValue}
            />
            <div className="tasks">
              {dayOrders.map((task) => {
                return (
                  <div className="task">
                    <span className="time">
                      {new Date(parseInt(task.deadline)).toLocaleDateString()}
                    </span>
                    <span className="name">
                      {task.basicDetails
                        ? task.basicDetails.title
                        : `Order #${task.number}`}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerTestDashboard;
