import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./DraftOrders.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate } from "react-router";
import { reference } from "../../ordersReference";
import { orderService } from "../../../services/order.service";
import LoadingUpload from "../../../views/loadingUpload/LoadingUpload";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Draft = ({
  orders,
  getOrders,
  ordersCount,
  setOrders,
  setOrdersCount,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  const updateOrders = (page) => {
    const filters = { admin: true };
    filters.type = "draft";

    orderService.listOrders(page, filters, (data) => {
      setOrders(data.orders);
      setOrdersCount(data.count);
    });
  };

  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="draft-orders-component">
      {loading ? <LoadingUpload text="Deleting..." /> : null}
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            titles={["Name", "Status", "Order Id", ""]}
            customStyle="1fr 1fr 1fr 0.5fr"
          />
          <div className="order-table">
            <TableRow
              customStyle="1fr 1fr 1fr 0.5fr"
              click={(order) => {
                navigate(`/customer/orders/new-order`, {
                  state: { order },
                });
              }}
              items={currentOrders.map((order, index) => {
                return {
                  order,
                  items: [
                    <span style={{ fontWeight: 500 }}>Draft Order</span>,
                    <div className={`status ${order.status}`}>
                      <span>Draft</span>
                    </div>,
                    <span style={{ color: "#A0AEC7", fontSize: ".8em" }}>
                      #{order.number}
                    </span>,
                    <FiTrash2
                      color="#E74C3C"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        orderService.updateOrderStatus(
                          order.id,
                          "cancelled",
                          (data) => {
                            getOrders(1);
                          }
                        );
                      }}
                    />,
                  ],
                };
              })}
            />
          </div>
        </>
      ) : (
        <>
          {currentOrders.map((order, index) => {
            return (
              <div
                className="order-mobile"
                onClick={() => {
                  navigate(`/customer/orders/new-order`, {
                    state: { order },
                  });
                }}
              >
                <div className="header-mobile">
                  <div className={`status ${order.status}`}>
                    <span>Draft</span>
                  </div>
                  <div className="details">
                    <span className="name">Draft Order</span>
                    <span className="number">#{order.number}</span>
                  </div>
                  <FiTrash2
                    color="#E74C3C"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setLoading(true);
                      e.stopPropagation();
                      orderService.updateOrderStatus(
                        order.id,
                        "cancelled",
                        async (data) => {
                          await getOrders(1);
                          setLoading(false);
                        }
                      );
                    }}
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
      <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {ordersCount > currentPage * 6 ? currentPage * 6 : ordersCount} of{" "}
            {ordersCount}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={currentPage === 1 ? null : previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={ordersCount < currentPage * 6 ? "disable" : "active"}
            onClick={ordersCount < currentPage * 6 ? null : nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Draft;
