import "./Profile.scss";
import User1 from "../../../../assets/user1.png";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { FiEdit } from "react-icons/fi";
import { AiOutlineArrowRight, AiOutlineSave } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import Portfolio from "../../../../assets/portfolio.png";
import Mark from "../../../../assets/mark.png";
import { useEffect, useState } from "react";
import { userService } from "../../../../services/user.service";
import { uploadService } from "../../../../services/upload.service";
import { useNavigate, useOutletContext } from "react-router";
import LoadingUpload from "../../../loadingUpload/LoadingUpload";
import Swal from "sweetalert2";
import UploadModal from "../../../../components/uploadModal/UploadModal";
const AdminProfile = () => {
  const navigate = useNavigate();
  const [userDisplayed, setUserDisplayed] = useState({});
  const [editMainInfo, setEditMainInfo] = useState(false);
  const [editSafetyInfo, setEditSafetyInfo] = useState(false);
  const [addQuestion, setAddQuestion, user, setUser] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [isEditting, setIsEdditing] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    userService.getProfileDetails("editor", (data) => {
      data.user.password = "";
      setUserDisplayed(data.user);
    });
  }, []);

  const changeAttribute = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj[attribute] = e.target.value;
    setUserDisplayed(obj);
  };

  const changeImage = (attribute, data) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj[attribute] = data;

    userService.updateUser(obj, (data) => {
      setUserDisplayed(data);
      userService.getProfile(
        (data) => {
          setUser(data);
        },
        (e) => {
          setLoading(false);
          Swal.fire({
            title: "Error!",
            text: "Please login before entering your account",
            heightAuto: false,
            icon: "error",
          }).then(() => {
            navigate("/auth/admin/sign-in");
          });
        }
      );
    });
  };

  return (
    <div className="admin-layout-profile-page">
      {loading ? <UploadModal progress={uploadProgress} /> : null}
      <div className="header-main">
        {isEditting ? (
          <button
            onClick={() => {
              userService.updateUser(userDisplayed, (data) => {
                setIsEdditing(false);
                setUserDisplayed(data);
                userService.getProfile(
                  (data) => {
                    setUser(data);
                  },
                  (e) => {
                    setLoading(false);
                    Swal.fire({
                      title: "Error!",
                      text: "Please login before entering your account",
                      heightAuto: false,
                      icon: "error",
                    }).then(() => {
                      navigate("/auth/admin/sign-in");
                    });
                  }
                );
              });
            }}
            className="edit"
          >
            Save
          </button>
        ) : (
          <button onClick={() => setIsEdditing(true)} className="edit">
            Edit
          </button>
        )}
      </div>
      <div className="profile-picture-info">
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          style={{ display: "none" }}
          id={`upload-profile-picture-profile-admin`}
          onChange={(e) => {
            let file = e.target.files[0];
            setLoading(true);

            if (file) {
              let data = new FormData();
              data.append("file", file);

              uploadService.upload(
                data,
                (data) => {
                  setLoading(false);
                  changeImage("profilePicture", data);
                },
                setUploadProgress
              );
            }
          }}
        />
        <label
          className="image-circle"
          for="upload-profile-picture-profile-admin"
        >
          {/* <CircularProgressbar
            value={60}
            className="circular-level-bar"
            strokeWidth={2}
            counterClockwise={true}
            styles={buildStyles({
              trailColor: `#F2F5FA`,
              pathColor: "#2A9DF4",
              backgroundColor: "white",
            })}
          /> */}
          <div className="image">
            <img
              src={
                user.profilePicture
                  ? user.profilePicture.url
                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
              }
            />
          </div>
        </label>
        <span className="name">
          {user.firstName} {user.lastName}
        </span>
        <span className="title">Admin</span>
      </div>
      <div className="mainInfo">
        <div className="name">
          <div className="item">
            <span className="title">First Name</span>
            <div className="input">
              <input
                readOnly={!isEditting}
                onChange={(e) => {
                  changeAttribute("firstName", e);
                }}
                value={userDisplayed.firstName}
              />
            </div>
          </div>
          <div className="item">
            <span className="title">Last Name</span>
            <div className="input">
              {/* <span>Enter Last Name</span> */}
              <input
                readOnly={!isEditting}
                value={userDisplayed.lastName}
                onChange={(e) => changeAttribute("lastName", e)}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <div className="item">
            <span className="title">Email</span>
            <div className="input">
              <input
                readOnly={!isEditting}
                onChange={(e) => {
                  changeAttribute("email", e);
                }}
                value={userDisplayed.email}
              />
            </div>
          </div>
          <div className="item">
            <span className="title">Change Password</span>
            <div className="input">
              <input
                readOnly={!isEditting}
                value={userDisplayed.password}
                type="password"
                onChange={(e) => {
                  changeAttribute("password", e);
                }}
                placeholder="Password"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
