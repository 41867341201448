import logo from "./logo.svg";
import "./App.css";
import Router from "./Router";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect } from "react";
import { Worker } from "@react-pdf-viewer/core";

function App() {
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyAOMCGmLFxt9eU-P9v6UPSUQHEpBaLcUss",
      authDomain: "mark-studios-3a3b9.firebaseapp.com",
      projectId: "mark-studios-3a3b9",
      storageBucket: "mark-studios-3a3b9.appspot.com",
      messagingSenderId: "292288227061",
      appId: "1:292288227061:web:5210c4d84d1bd7b23807bc",
      measurementId: "G-6P63HN5732",
    };

    firebase.initializeApp(firebaseConfig);
  }, []);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
      <div className="App">
        <Router />
      </div>
    </Worker>
  );
}

export default App;
