import moment, { min } from "moment";

const formatTimeStamp = (time) => {
  const newTime = new Date(time).getTime();

  const difference = Math.abs(
    moment
      .duration(
        moment(newTime)
          .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          .diff(
            moment().tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
          )
      )
      .asMinutes()
  );

  const tempHours = Math.floor(difference / 60);
  const minutes = Math.floor(difference - tempHours * 60);
  const days = Math.floor(tempHours / 24);
  const hours = tempHours - days * 24;

  let formattedTime;

  if (days > 0) {
    formattedTime = `${
      days > 0 ? `${days} day${days > 1 ? "s" : ""}, ${hours}h` : ""
    }`;
  } else if (hours > 1) {
    formattedTime = `${
      hours > 0 ? `${hours}h${minutes > 0 ? `, ` : ""}${minutes} minutes` : ""
    }`;
  } else {
    formattedTime = `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  }

  if (
    moment()
      .tz(window.Intl.DateTimeFormat().resolvedOptions().timeZone)
      .isBefore(
        moment(newTime).tz(
          window.Intl.DateTimeFormat().resolvedOptions().timeZone
        )
      )
  ) {
    formattedTime = `${formattedTime} left`;
  } else {
    if (days > 10) {
      formattedTime = "Very Late";
    } else {
      formattedTime = `${formattedTime} late`;
    }
  }

  return formattedTime;
};

export default formatTimeStamp;
