export const reference = [
  {
    status: "draft",
    name: "Draft",
  },
  {
    status: "progress",
    name: "In Progress",
  },
  {
    status: "new",
    name: "New",
  },
  {
    status: "revision",
    name: "In Revision",
  },
  {
    status: "cancelled",
    name: "Cancelled",
  },
  {
    status: "completed",
    name: "Completed",
  },
  {
    status: "pending",
    name: "Pending approval from admin",
  },
  {
    status: "submitted",
    name: "Pending approval from customer",
  },
];
