import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import "react-circular-progressbar/dist/styles.css";
import "./NewOrders.scss";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { orderService } from "../../../services/order.service";
import formatTimeStamp from "../../../utils/formatTimeStamp";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const NewOrders = ({
  orders,
  getOrders,
  ordersCount,
  setOrders,
  setOrdersCount,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const navigate = useNavigate();

  const updateOrders = (page) => {
    const filters = { admin: true };
    filters.type = "progress";

    orderService.listOrders(page, filters, (data) => {
      setOrders(data.orders);
      setOrdersCount(data.count);
    });
  };

  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const formatTime = (time) => {
    return formatTimeStamp(time);
  };

  return (
    <div className="new-orders-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            titles={[
              "Name",
              "Order Id",
              "Estimate Time",
              "Status",
              // "Order Cost",
            ]}
          />
          <div className="order-table">
            <TableRow
              click={(order) => {
                navigate(`./${order.id}`);
              }}
              items={currentOrders.map((order, index) => {
                return {
                  order,
                  items: [
                    <span
                      style={{ fontWeight: 500 }}
                      onClick={() => navigate(`./${order.id}`)}
                    >
                      {order.basicDetails
                        ? order.basicDetails.title
                        : `Order #${order.number}`}
                    </span>,
                    <span style={{ color: "#A0AEC7", fontSize: ".8em" }}>
                      #{order.number}
                    </span>,
                    // <div style={{ backgroundColor: '#D8FFEE', color: '#00D097', width: '30px', height: '30px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '.8em', borderRadius: '7px', cursor: 'pointer' }}>
                    //     {order.offers}
                    // </div>,
                    <span>{formatTime(order.deadline)}</span>,
                    <div className="status">
                      <span>New</span>
                    </div>,
                    // <span>{formatCurrency(order.price)}</span>,
                  ],
                };
              })}
            />
          </div>
        </>
      ) : (
        <>
          <>
            {currentOrders.map((order, index) => {
              return (
                <div
                  className="order-mobile"
                  onClick={() => navigate(`./${order.id}`)}
                >
                  <div className="header-mobile">
                    <div className="status">
                      <span>New</span>
                    </div>
                    <div className="details">
                      <span
                        className="name"
                        onClick={() => navigate(`./${order.id}`)}
                      >
                        {order.basicDetails
                          ? order.basicDetails.title
                          : `Order #${order.number}`}
                      </span>
                      <span className="number">#{order.number}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </>
      )}
      <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {ordersCount > currentPage * 6 ? currentPage * 6 : ordersCount} of{" "}
            {ordersCount}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={ordersCount < currentPage * 6 ? "disable" : "active"}
            onClick={nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewOrders;
