import "./LoadingUpload.scss";

const LoadingUpload = ({
  text = "Uploading",
  cancelButton = false,
  cancelAction = () => {},
}) => {
  return (
    <div className="loading-container-upload">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span>{text}</span>
      {cancelButton ? (
        <button className="cancel-button" onClick={() => cancelAction()}>
          Cancel Upload
        </button>
      ) : null}
    </div>
  );
};

export default LoadingUpload;
