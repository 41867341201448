import { coreService } from "./core.service";

const saveComments = (newFile, callback) => {
  coreService.post(`files/save-comments`, { newFile }, callback);
};

const getComments = (fileId, callback) => {
  coreService.get(`files/get-one/${fileId}`, callback);
};

export const FileService = {
  saveComments,
  getComments,
};
