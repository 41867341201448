import "./Register.scss";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import LogoWhite from "../../../../assets/logo-white.png";
import Key from "../../../../assets/auth/key.png";
import Modal from "react-modal/lib/components/Modal";
import { useFormik } from "formik";
import { authService } from "../../../../services/auth.service";
import { Link } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import Google from "../../../../assets/google.svg";
import BgBlurred from "../../../../assets/Bg-blurred.png";
import Swal from "sweetalert2";

const GeneralAuthRegister = () => {
  const navigate = useNavigate();
  const [isPasswordShown, setIsPasswordShows] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen] = useOutletContext();
  const [acceptTerms, setAcceptTerms] = useState(false);

  const registerFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      if (!values.email || !values.password) {
        Swal.fire({
          title: "Oops!",
          text: "Please add your email and password",
          heightAuto: false,
          icon: "error",
        });
        return;
      }
      if (!acceptTerms) {
        Swal.fire({
          title: "Oops!",
          text: "Please accept the Terms and Conditions",
          heightAuto: false,
          icon: "error",
        });
        return;
      }
      values.type = "customer";
      authService.register(values, (data) => {
        navigate("../check-email", { state: { email: values.email } });
      });
    },
  });

  const googleSignUp = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const values = {
          email: result.user.email,
          uid: result.user.uid,
          type: "customer",
        };
        authService.registerGoogle(values, (data) => {
          navigate("../check-email", { state: { email: values.email } });
        });
      })
      .catch((e) => {
        console.log(e);
        throw Error("Something happened");
      });
  };

  return (
    <div className="auth-general-register">
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Confirm Email"
        className="modal-register"
        overlayClassName="overlay-modal-register"
      >
        <div className="header-modal">
          <img src={LogoWhite} />
        </div>
        <div className="body-modal">
          <span className="title">Hello!</span>
          <span className="text">
            Enter this code to proceed with password reset: 123456.
          </span>
          <button onClick={() => setIsModalOpen(false)}>Go to App</button>
        </div>
        <div className="footer-modal">
          <span>Please do not reply to this email.</span>
          <span>Copyright 2022 - Mark Studios LLC. All rights reserved.</span>
        </div>
        <img className="illustration" src={Key} />
      </Modal>
      <div className="sign-up">
        <img className="blurred-bg" src={BgBlurred} alt="blurred-background" />
        <div className="sign-up-container">
          <span className="title">Sign Up</span>
          <span className="sub-title">
            Sign up with your information to continue
          </span>
          <div className="login-info">
            <div className="input-box">
              <span className="input-title">Email Address</span>
              <input
                id="email"
                name="email"
                onChange={registerFormik.handleChange}
                value={registerFormik.values.email}
                type="email"
                placeholder="youremail@gmail.com"
              />
            </div>
            <div className="input-box">
              <span className="input-title">Password</span>
              <div className="password">
                <input
                  id="password"
                  name="password"
                  onChange={registerFormik.handleChange}
                  value={registerFormik.values.password}
                  type={isPasswordShown ? "text" : "password"}
                  placeholder="••••••••"
                />
                {isPasswordShown ? (
                  <AiOutlineEye
                    onClick={() => setIsPasswordShows(!isPasswordShown)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    onClick={() => setIsPasswordShows(!isPasswordShown)}
                  />
                )}
              </div>
            </div>
          </div>
          <button
            className="login-button"
            onClick={registerFormik.handleSubmit}
          >
            Sign Up
          </button>
          <span className="or">Or</span>
          <div className="social">
            <span>Sign Up With</span>
            <img
              onClick={() => googleSignUp()}
              src={Google}
              alt="social-login"
            />
          </div>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <input
              type={"checkbox"}
              onChange={(e) => setAcceptTerms(e.target.checked)}
            />
            <span
              className="terms"
              onClick={() => {
                navigate("/terms-of-service");
              }}
            >
              I agree with the Terms and Conditions
            </span>
          </div>
          <Link to={"../sign-in"} className="already-have">
            Already have an account? <b>Sign in</b>
          </Link>
        </div>
      </div>
      <div className="login-box">
        <span className="title">Create an account to get started!</span>
        <div className="login-info">
          <div className="input-box">
            <span className="input-title">Email Address</span>
            <input
              id="email"
              name="email"
              onChange={registerFormik.handleChange}
              value={registerFormik.values.email}
              type="email"
              placeholder="youremail@gmail.com"
            />
          </div>
          <div className="input-box">
            <span className="input-title">Password</span>
            <div className="password">
              <input
                id="password"
                name="password"
                onChange={registerFormik.handleChange}
                value={registerFormik.values.password}
                type={isPasswordShown ? "text" : "password"}
                placeholder="••••••••"
              />
              {isPasswordShown ? (
                <AiOutlineEye
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              )}
            </div>
          </div>
          {/* <div className="remember-me-forgot-password">
            <div className="remember-me">
              <input type="checkbox" />
              <span>Remember me</span>
            </div>
          </div> */}
        </div>
        <button className="login" onClick={registerFormik.handleSubmit}>
          Sign Up
        </button>
      </div>
      <div className="footer">
        <Link to={"../sign-in"} className="already-have">
          Already have an account? <b>Sign in</b>
        </Link>
        <div className="terms-privacy-policy">
          <span onClick={() => setIsTermsOfServiceOpen(true)}>
            Terms of use
          </span>
          <span onClick={() => setIsPrivacyPolicyOpen(true)}>
            Privacy Policy
          </span>
          <span>Support</span>
        </div>
      </div>
    </div>
  );
};

export default GeneralAuthRegister;
